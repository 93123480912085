import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchTasks, 
  fetchTask, 
  createTask, 
  editTask, 
  deleteTask,
  FetchTasksParams 
} from './api';
import { Task } from './types';

export const useTasks = (params?: FetchTasksParams) => {
  const navigate = useNavigate();
  
  return useQuery<Task[], Error>(
    ['tasks', params],
    () => fetchTasks(params || {}, navigate),
    {
      select: (data) => data ?? [],
      staleTime: 0, // This ensures that the data is always considered stale and will be refetched on window focus
    }
  );
};

export const useTask = (slug: string) => {
  const navigate = useNavigate();
  
  return useQuery(['task', slug], () => fetchTask(slug, navigate));
};

export const useCreateTask = (params?: FetchTasksParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newTask: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => createTask(newTask, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tasks', params]);
      },
    }
  );
};

export const useEditTask = (params?: FetchTasksParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedTask }: { slug: string; updatedTask: Partial<Task> }) => 
      editTask(slug, updatedTask, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tasks', params]);
      },
    }
  );
};

export const useDeleteTask = (params?: FetchTasksParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteTask(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tasks', params]);
      },
    }
  );
};