import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { 
  useSnapshots, 
  useCreateSnapshot, 
  useUpdateSnapshot, 
  useSendSnapshot,
  useArchiveSnapshot,
  useDeleteSnapshot, // Import delete snapshot hook
  useSnapshotAction,
  useSnapshot
} from '../api/project/queries';
import { 
  ProjectsSnapshot, 
  ProjectsSnapshotListItem,
  CreateProjectsSnapshot,
  UpdateProjectsSnapshot 
} from '../api/project/types';

interface SnapshotsContainerProps {
  fetchOnMount?: boolean;
  filters?: {
    status?: string;
    startDate?: string;
    endDate?: string;
    createdBy?: string;
  };
  render: (props: {
    data: ProjectsSnapshotListItem[];
    loading: boolean;
    error: string | null;
    onCreate: (newSnapshot: CreateProjectsSnapshot) => Promise<void>;
    onUpdate: (slug: string, updateData: UpdateProjectsSnapshot) => Promise<void>;
    onSend: (slug: string) => Promise<void>;
    onArchive: (slug: string) => Promise<void>;
    onDelete: (slug: string) => Promise<void>; // Add onDelete function to props
    isActionLoading: boolean;
  }) => JSX.Element;
}

export const SnapshotsContainer: React.FC<SnapshotsContainerProps> = ({ 
  fetchOnMount = true, 
  filters,
  render 
}) => {
  const [snapshots, setSnapshots] = useState<ProjectsSnapshotListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  // Fetch snapshots with filters
  const { data, isLoading, isError } = useSnapshots(filters);

  // Mutations
  const createSnapshotMutation = useCreateSnapshot();
  const updateSnapshotMutation = useUpdateSnapshot(''); // slug will be provided in handler
  const sendSnapshotMutation = useSendSnapshot('');
  const archiveSnapshotMutation = useArchiveSnapshot('');
  const deleteSnapshotMutation = useDeleteSnapshot(); // Use delete snapshot mutation

  // Update local state when data changes
  useEffect(() => {
    if (fetchOnMount) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
        if (data) setSnapshots(data);
      }

      if (isError) {
        setError('Failed to fetch snapshots');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create new snapshot
  const handleCreate = async (newSnapshot: CreateProjectsSnapshot) => {
    try {
      const createdSnapshot = await createSnapshotMutation.mutateAsync(newSnapshot);
      // Convert full snapshot to list item
      const listItem: ProjectsSnapshotListItem = {
        id: createdSnapshot.id,
        title: createdSnapshot.title,
        slug: createdSnapshot.slug,
        period_start: createdSnapshot.period_start,
        period_end: createdSnapshot.period_end,
        created_at: createdSnapshot.created_at,
        status: createdSnapshot.status,
        created_by: createdSnapshot.created_by,
        project_count: 0,
        log_entries_count: 0,
        visibility: createdSnapshot.visibility
      };
      setSnapshots((prev) => [...prev, listItem]);
      queryClient.invalidateQueries(['snapshots', filters]);
    } catch (err) {
      setError('Failed to create snapshot');
    }
  };

  // Update existing snapshot
  const handleUpdate = async (slug: string, updateData: UpdateProjectsSnapshot) => {
    try {
      await updateSnapshotMutation.mutateAsync(updateData);
      queryClient.invalidateQueries(['snapshots', filters]);
    } catch (err) {
      setError('Failed to update snapshot');
    }
  };

  // Send snapshot
  const handleSend = async (slug: string) => {
    try {
      await sendSnapshotMutation.mutate();
      setSnapshots((prev) =>
        prev.map(snapshot =>
          snapshot.slug === slug
            ? { ...snapshot, status: 'sent' }
            : snapshot
        )
      );
      queryClient.invalidateQueries(['snapshots', filters]);
    } catch (err) {
      setError('Failed to send snapshot');
    }
  };

  // Archive snapshot
  const handleArchive = async (slug: string) => {
    try {
      await archiveSnapshotMutation.mutate();
      setSnapshots((prev) =>
        prev.map(snapshot =>
          snapshot.slug === slug
            ? { ...snapshot, status: 'archived' }
            : snapshot
        )
      );
      queryClient.invalidateQueries(['snapshots', filters]);
    } catch (err) {
      setError('Failed to archive snapshot');
    }
  };

  // Delete snapshot
  const handleDelete = async (slug: string) => {
    try {
      await deleteSnapshotMutation.mutateAsync(slug);
      setSnapshots((prev) => prev.filter(snapshot => snapshot.slug !== slug));
      queryClient.invalidateQueries(['snapshots', filters]);
    } catch (err) {
      setError('Failed to delete snapshot');
    }
  };

  // Check if any action is loading
  const isActionLoading = 
    createSnapshotMutation.isLoading ||
    updateSnapshotMutation.isLoading ||
    sendSnapshotMutation.isLoading ||
    archiveSnapshotMutation.isLoading ||
    deleteSnapshotMutation.isLoading;

  return render({
    data: snapshots,
    loading,
    error,
    onCreate: handleCreate,
    onUpdate: handleUpdate,
    onSend: handleSend,
    onArchive: handleArchive,
    onDelete: handleDelete, // Pass handleDelete to the render function
    isActionLoading
  });
};

export default SnapshotsContainer;

// Individual Snapshot Container
interface SnapshotContainerProps {
  slug: string;
  render: (props: {
    data: ProjectsSnapshot | null;
    loading: boolean;
    error: string | null;
    onUpdate: (updateData: UpdateProjectsSnapshot) => Promise<void>;
    onSend: () => Promise<void>;
    onArchive: () => Promise<void>;
    onDelete: () => Promise<void>; // Add onDelete function to props
    isActionLoading: boolean;
  }) => JSX.Element;
}

export const SnapshotContainer: React.FC<SnapshotContainerProps> = ({ 
  slug,
  render 
}) => {
  const { data, isLoading, isError } = useSnapshot(slug);
  const updateSnapshotMutation = useUpdateSnapshot(slug);
  const { performAction, isLoading: isActionLoading, error: actionError } = useSnapshotAction(slug);
  const deleteSnapshotMutation = useDeleteSnapshot(); // Add delete snapshot mutation

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isError) setError('Failed to fetch snapshot');
    if (actionError) setError('Action failed');
  }, [isError, actionError]);

  const handleUpdate = async (updateData: UpdateProjectsSnapshot) => {
    try {
      await updateSnapshotMutation.mutateAsync(updateData);
    } catch (err) {
      setError('Failed to update snapshot');
    }
  };

  const handleSend = async () => {
    try {
      await performAction('send');
    } catch (err) {
      setError('Failed to send snapshot');
    }
  };

  const handleArchive = async () => {
    try {
      await performAction('archive');
    } catch (err) {
      setError('Failed to archive snapshot');
    }
  };

  // Delete snapshot
  const handleDelete = async () => {
    try {
      await deleteSnapshotMutation.mutateAsync(slug);
    } catch (err) {
      setError('Failed to delete snapshot');
    }
  };

  return render({
    data: data || null,
    loading: isLoading,
    error,
    onUpdate: handleUpdate,
    onSend: handleSend,
    onArchive: handleArchive,
    onDelete: handleDelete, // Pass handleDelete to the render function
    isActionLoading
  });
};
