import { useState, useRef, useEffect } from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

interface Column {
  header: string;
  accessor?: string;
  component?: (data: any) => JSX.Element;
  sortable?: boolean;
}

interface TableProps {
  columns: Column[];
  data: { [key: string]: any }[];
  paginated?: boolean;
  rowsPerPage?: number;
}

export const Table: React.FC<TableProps> = ({ columns, data, paginated = false, rowsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  // Sorting logic
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig) {
      const { key, direction } = sortConfig;
      const comparison = a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
      return direction === 'asc' ? comparison : -comparison;
    }
    return 0;
  });

  // Pagination logic
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = paginated ? sortedData.slice(startIndex, startIndex + rowsPerPage) : sortedData;

  const handleSort = (accessor: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === accessor && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: accessor, direction });
  };

  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Check for overflow and update shadow states
  const checkForOverflow = () => {
    if (tableWrapperRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tableWrapperRef.current;
      setShowLeftShadow(scrollLeft > 0);
      setShowRightShadow(scrollLeft < scrollWidth - clientWidth - 1); // -1 for rounding errors
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const wrapper = tableWrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener('scroll', checkForOverflow);
      // Initial check
      checkForOverflow();
      
      // Also check on window resize
      window.addEventListener('resize', checkForOverflow);

      return () => {
        wrapper.removeEventListener('scroll', checkForOverflow);
        window.removeEventListener('resize', checkForOverflow);
      };
    }
  }, []);

  return (
    <div className="flex flex-col h-full pb-[140px]">
      {/* Table container with shadow indicators */}
      <div className="relative">
        {/* Left shadow */}
        <div
          className={`absolute left-0 top-0 bottom-0 w-4 bg-gradient-to-r from-gray-200 to-transparent pointer-events-none transition-opacity duration-200 ${
            showLeftShadow ? 'opacity-100' : 'opacity-0'
          }`}
          style={{ zIndex: 1 }}
        />

        {/* Right shadow */}
        <div
          className={`absolute right-0 top-0 bottom-0 w-4 bg-gradient-to-l from-gray-200 to-transparent pointer-events-none transition-opacity duration-200 ${
            showRightShadow ? 'opacity-100' : 'opacity-0'
          }`}
          style={{ zIndex: 1 }}
        />

        {/* Table wrapper */}
        <div
          ref={tableWrapperRef}
          className="overflow-x-auto relative"
        >
          <table className="min-w-full bg-white rounded-lg shadow-sm">
            <thead>
              <tr className="bg-gray-100 text-left text-sm font-semibold text-gray-700">
                {columns.map((column) => (
                  <th
                    key={column.header}
                    onClick={() => column.sortable && column.accessor && handleSort(column.accessor)}
                    className={`p-3 ${column.sortable ? 'cursor-pointer select-none' : ''}  whitespace-nowrap`}
                  >
                    <div className="flex items-center space-x-2">
                      <span>{column.header}</span>
                      {column.sortable && column.accessor && (
                        <span>
                          {sortConfig?.key === column.accessor ? (
                            sortConfig.direction === 'asc' ? (
                              <FaSortUp />
                            ) : (
                              <FaSortDown />
                            )
                          ) : (
                            <FaSort />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="border-b border-gray-200 text-sm text-gray-700 last:border-none hover:bg-gray-50"
                >
                  {columns.map((column, columnIndex) => (
                    <td key={columnIndex} className="p-3 whitespace-nowrap">
                    {column.component ? (
                        column.component(row)
                      ) : (
                        row[column.accessor as string]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      {paginated && (
        <div className="flex justify-between items-center mt-4 px-2 py-2 bg-gray-50 border-t border-gray-200">
          <button
            className="px-3 py-1 bg-gray-200 text-sm text-gray-600 rounded-md hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-400"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            Previous
          </button>
          <span className="text-sm text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="px-3 py-1 bg-gray-200 text-sm text-gray-600 rounded-md hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-400"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};