import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { ModalContent, SelectSingle, Icon, Datepicker } from '../ui';
import { SelectOption } from '../ui/Select';
import PersonSelect from '../person/PersonSelect'; 
import TagSelect from '../tags/TagSelect'; 
import { Task } from '../../api/task/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import TaskContainer from '../../containers/TaskContainer';
import FavoriteContainer from '../../containers/FavoriteContainer';
import TopicSelect from '../topic/TopicSelect';

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: Omit<Task, 'created_at' | 'updated_at'>;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;
  initialTitle?: string;
}

export const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  initialData, 
  initialTitle = '',
  onSuccess, 
  onError, 
  topicId, 
  projectId,
  meetingOccurrenceId,
  meetingTypeId,
  personId,
}) => {
  console.log('TaskModal - Initial Data:', initialData);
  console.log('TaskModal - Project ID:', projectId);
  const [isEditMode, setIsEditMode] = useState(false);
  const [taskData, setTaskData] = useState<Partial<Task>>({
    title: initialTitle.trim() !== '' ? initialTitle : initialData?.title || '',
    owner: initialData?.owner || undefined,
    tags: initialData?.tags || [],
    state: initialData?.state || 'IDENTIFIED',
    dial_stage: initialData?.dial_stage || 'MENTION',
    start_date: initialData?.start_date || undefined,
    due_date: initialData?.due_date || undefined,
    visibility: initialData?.visibility || 'private',
    editable_visibility: initialData?.editable_visibility || false,
    topic: topicId || initialData?.topic || undefined, // Add this line
  });

  const [description, setDescription] = useState(initialData?.description || '');
  const [createMore, setCreateMore] = useState<boolean>(false);
  const initialTitleRef = useRef('');
  const initialDescriptionRef = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedTask: Partial<Task>) => void) | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('modal');

  const queryClient = useQueryClient();

  const stateOptions: SelectOption[] = [
    { value: 'IDENTIFIED', label: 'Identified' },
    { value: 'IN_PROGRESS', label: 'In Progress' },
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'ARCHIVED', label: 'Archived' },
  ];

  const dialOptions: SelectOption[] = [
    { value: 'MENTION', label: 'Mention', icon: <Icon name="MessageCircle" size="sm" color="text" /> },
    { value: 'INVITATION', label: 'Invitation', icon: <Icon name="Mail" size="sm" color="text" /> },
    { value: 'CONVERSATION', label: 'Conversation', icon: <Icon name="Users" size="sm" color="text" /> },
    { value: 'BOUNDARY', label: 'Boundary', icon: <Icon name="Shield" size="sm" color="text" /> },
    { value: 'LIMIT', label: 'Limit', icon: <Icon name="AlertTriangle" size="sm" color="text" /> },
  ];

  const resetForm = useCallback(() => {
    setTaskData({
      title: '',
      owner: undefined,
      tags: [],
      state: 'IDENTIFIED',
      dial_stage: 'MENTION',
      start_date: undefined,
      due_date: undefined,
    });
    setDescription('');
    initialTitleRef.current = '';
    initialDescriptionRef.current = '';
  }, []);

  useEffect(() => {
    if (initialData) {
      setIsEditMode(!!initialData.id);
      setTaskData({
        ...initialData,
        owner: initialData.owner || undefined,
        start_date: initialData.start_date || undefined,
        due_date: initialData.due_date || undefined,
      });
      setDescription(initialData.description || '');
      initialTitleRef.current = initialData.title || '';
      initialDescriptionRef.current = initialData.description || '';
    } else {
      const initialOwner = personId ? { id: personId } as Person : undefined;
      setIsEditMode(false);
      setTaskData({
        title: initialTitle.trim() || '',
        owner: initialOwner,
        tags: [],
        state: 'IDENTIFIED',
        dial_stage: 'MENTION',
        start_date: undefined,
        due_date: undefined,
        visibility: 'private',
        editable_visibility: false,
      });
      setDescription('');
      initialTitleRef.current = initialTitle.trim() || '';
      initialDescriptionRef.current = '';
    }
  }, [initialData, initialTitle, personId]);  

  // Save on refresh/unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isEditMode && initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isEditMode, initialData?.slug, description]);

  // 30m autosave
  useEffect(() => {
    if (!isEditMode || !initialData?.slug || !editFunctionRef.current || description === initialDescriptionRef.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(autoSaveTimer);
  }, [isEditMode, description, initialData?.slug]);

  const handleChange = useCallback((field: keyof Task, value: any, onEdit?: (slug: string, updatedTask: Partial<Task>) => void) => {
    if (field === 'owner') {
      setTaskData(prevData => ({ ...prevData, owner: value }));
      
      if (isEditMode && initialData?.slug && onEdit) {
        onEdit(initialData.slug, { owner: value });
      }
    } else if (field === 'topic') {
      setTaskData(prevData => ({ ...prevData, [field]: value }));
      
      if (isEditMode && initialData?.slug && onEdit) {
        onEdit(initialData.slug, { topic: value });
      }
    } else {
      setTaskData(prevData => ({ ...prevData, [field]: value }));
  
      if (isEditMode && initialData?.slug && onEdit && field !== 'description' && field !== 'title') {
        onEdit(initialData.slug, { [field]: value });
      }
    }
  }, [isEditMode, initialData]);

  const handleClose = useCallback((onEdit: (slug: string, updatedTask: Partial<Task>) => void) => {
    if (isEditMode && initialData?.slug && description !== initialDescriptionRef.current) {
      onEdit(initialData.slug, { description });
      initialDescriptionRef.current = description;
    }
    onClose();
  }, [isEditMode, initialData, description, onClose]);

  const handleTitleBlur = useCallback((onEdit: (slug: string, updatedTask: Partial<Task>) => void) => {
    if (isEditMode && initialData?.slug && taskData.title !== initialTitleRef.current) {
      onEdit(initialData.slug, { title: taskData.title });
      initialTitleRef.current = taskData.title || '';
    }
  }, [isEditMode, initialData, taskData.title]);

  const handleVisibilityToggle = useCallback((isPublic: boolean, onEdit: (slug: string, updatedTask: Partial<Task>) => void) => {
    const newVisibility = isPublic ? 'public' : 'private';
    handleChange('visibility', newVisibility, onEdit);
  }, [handleChange]);

  const validateDates = (startDate: string | undefined, dueDate: string | undefined): boolean => {
    if (startDate && dueDate && new Date(startDate) > new Date(dueDate)) {
      setDateError('Start date cannot be after due date');
      return false;
    }
    setDateError(null);
    return true;
  };

  const handleSave = useCallback((onCreate: (taskData: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void, onEdit: (slug: string, updatedTask: Partial<Task>) => void) => {
    // Check for title only since owner is now handled in initialization
    if (!taskData.title?.trim()) {
      onError('Please provide a title');
      return;
    }

    // If personId is provided, ensure it's set as the owner
    const effectiveOwner = personId ? { id: personId } as Person : taskData.owner;
    if (!effectiveOwner) {
      onError('Please select an owner');
      return;
    }

    const associatedEntity = {
      ...(topicId && { topic: topicId }),
      ...(projectId && { project: projectId }),
      ...(meetingOccurrenceId && { meeting_occurrence: meetingOccurrenceId }),
      ...(meetingTypeId && { meeting_type: meetingTypeId }),
      ...(personId && { person: personId }),
    };

    const taskToSave = {
      ...taskData,
      description,
      title: taskData.title.trim(),
      start_date: taskData.start_date,
      due_date: taskData.due_date,
      created_by: initialData?.created_by || undefined,
      ...associatedEntity,
    };

    if (isEditMode) {
      onEdit(initialData!.slug, taskToSave);
      initialDescriptionRef.current = description;
      onSuccess('Task updated successfully');
      onClose();
    } else {
      onCreate(taskToSave as Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>);
      onSuccess('Task created successfully');
      if (createMore) {
        resetForm();
      } else {
        onClose();
      }
    }

    // Validate dates before saving
    if (!validateDates(taskData.start_date, taskData.due_date)) {
      onError('Invalid date range');
      return;
    }
  }, [isEditMode, taskData, description, initialData, createMore, onSuccess, onError, onClose, resetForm, topicId, projectId, meetingOccurrenceId, meetingTypeId, personId]);

  const handleDelete = useCallback((onDelete: (slug: string) => void) => {
    if (isEditMode && initialData?.slug) {
      onDelete(initialData.slug);
      onSuccess('Task deleted successfully');
      onClose();
    }
  }, [isEditMode, initialData, onSuccess, onClose]);

  const getStateLabels = (states: string[], options: { value: string; label: string }[]) => {
    return states.map(state => {
      const option = options.find(opt => opt.value === state);
      return option ? option.label : state;
    });
  };

  const lifecycleStates = ['IDENTIFIED', 'IN_PROGRESS', 'COMPLETED', 'ARCHIVED'];
  const friendlyLifecycleStates = getStateLabels(lifecycleStates, stateOptions);

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent clicks from reaching the parent TopicModal
  };

  if (!isOpen) return null;

  return (
    <div onClick={handleModalClick} className="modal-content">
    <TaskContainer
      topicId={topicId}
      projectId={projectId}
      meetingOccurrenceId={meetingOccurrenceId}
      meetingTypeId={meetingTypeId}
      personId={personId}
      render={({ onCreate, onEdit, onDelete }) => (
        <FavoriteContainer
          type="accountability_dial.task"
          render={({ onToggle, isFavorited }) => {
            editFunctionRef.current = onEdit;

            return (
              <ModalContent
              title={taskData.title || ''}
              description={description}
              selects={[
                {
                  component: SelectSingle,
                  props: {
                    options: dialOptions,
                    placeholder: 'Select Dial Stage',
                    defaultValue: taskData.dial_stage,
                    onSelect: (value: string) => handleChange('dial_stage', value as Task['dial_stage'], onEdit),
                    enableSearch: false,
                  }
                },
                {
                  component: SelectSingle,
                  props: {
                    options: stateOptions,
                    placeholder: 'Select State',
                    defaultValue: taskData.state,
                    onSelect: (value: string) => handleChange('state', value as Task['state'], onEdit),
                    enableSearch: false,
                  }
                },
                {
                  component: PersonSelect,
                  props: {
                    value: taskData.owner?.id || personId || '',
                    onChange: (person: Person) => handleChange('owner', person, onEdit),
                    enableSearch: true,
                    placeholder: 'Select an owner',
                    isDisabled: !!personId,
                    showAssignButton: true,
                  }
                },
                {
                  component: TopicSelect,
                  props: {
                    value: topicId || (taskData.topic as any)?.id || '',
                    onChange: (topicId: string) => handleChange('topic', topicId, onEdit),
                    enableSearch: true,
                    placeholder: 'Associate to a topic',
                    isDisabled: !!topicId,
                  }
                },
                {
                  component: TagSelect,
                  props: {
                    value: taskData.tags?.map(tag => tag.id) || [],
                    onChange: (tags: Tag[]) => handleChange('tags', tags, onEdit),
                    enableSearch: true,
                  }
                },
                {
                  component: Datepicker,
                  props: {
                    defaultValue: taskData.start_date,
                    onChange: (date: string) => {
                      const formattedDate = date ? new Date(date).toISOString().split('T')[0] : undefined;
                      if (validateDates(formattedDate, taskData.due_date)) {
                        handleChange('start_date', formattedDate, onEdit);
                      }
                    },
                    placeholder: "Start Date",
                    isDisabled: false,
                    loading: false,
                    error: dateError
                  }
                },
                {
                  component: Datepicker,
                  props: {
                    defaultValue: taskData.due_date,
                    onChange: (date: string) => {
                      const formattedDate = date ? new Date(date).toISOString().split('T')[0] : undefined;
                      if (validateDates(taskData.start_date, formattedDate)) {
                        handleChange('due_date', formattedDate, onEdit);
                      }
                    },
                    placeholder: "Due Date",
                    isDisabled: false,
                    loading: false,
                    error: dateError
                  }
                },
                
              ]}
              onCancel={() => handleClose(onEdit)}
              onCreate={() => handleSave(onCreate, onEdit)}
              onDelete={isEditMode ? () => handleDelete(onDelete) : undefined}
              onTitleChange={(newTitle) => setTaskData(prevData => ({ ...prevData, title: newTitle }))}
              onDescriptionChange={setDescription}
              onTitleBlur={() => handleTitleBlur(onEdit)}
              actionButtonLabel={isEditMode ? 'Save Changes' : 'Create Task'}
              activityFeed={isEditMode ? <LogEntryActivityFeed objectId={initialData!.id} objectType='task' /> : null}
              showActivityFeed={isEditMode}
              showCreateMoreToggle={!isEditMode}
              showFooterButtons={true}
              onCreateMoreChange={setCreateMore}
              resetForm={resetForm}
              onVisibilityToggle={(isPublic) => handleVisibilityToggle(isPublic, onEdit)}
              initialVisibility={taskData.visibility}
              isVisibilityEditable={taskData.editable_visibility}
              lifecycleStates={friendlyLifecycleStates}
              currentState={getStateLabels([taskData?.state || ''], stateOptions)[0]}
              showLifecycle={true}
              isSidePanelInitiallyCollapsed={true}
              showFavorite={isEditMode}
              isFavorited={isEditMode && isFavorited(initialData?.id || '')}
              onFavoriteToggle={() => initialData?.id && onToggle('accountability_dial.task', initialData.id)}
              viewMode={modalViewMode}
              onViewModeChange={(mode) => setModalViewMode(mode)}
              />
            );
          }}
        />
      )}
    />
    </div>
  );
};