import React from 'react';

interface BadgeProps {
  children: React.ReactNode; // Allow any valid React children
  variant?: 'outline' | 'primary' | 'secondary' | 'accent' | 'neutral'; // Add outline variant
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  variant = 'neutral',
  size = 'medium',
  className = '',
}) => {
  // Define the size classes for the badge
  const sizeClasses = {
    small: 'px-2 py-1 text-xs',
    medium: 'px-3 py-1 text-sm',
    large: 'px-4 py-2 text-base',
  };

  // Define variant classes for the badge
  const variantClasses = {
    outline: 'border border-gray-300 text-gray-700 bg-transparent',
    primary: 'bg-primary text-white',
    secondary: 'bg-secondary text-white',
    accent: 'bg-accent text-white',
    neutral: 'bg-neutral text-gray-700',
  };

  return (
    <span
      className={`inline-block rounded-md font-medium ${sizeClasses[size]} ${variantClasses[variant]} ${className}`}
    >
      {children}
    </span>
  );
};

export default Badge;