import React, { useState, useMemo } from 'react';
import { Project } from '../../api/project/types';
import { LogEntry } from '../../api/logEntry/types';
import ProjectContainer from '../../containers/ProjectContainer';
import LogEntryContainer from '../../containers/LogEntryContainer';
import EntitySelector from '../ui/EntitySelector';
import { ChevronDown, ChevronUp, CheckCircle2 } from 'lucide-react';
import { Badge } from '../ui/';
import { Filter } from '../ui/data/Filter';
import { FilterConfig } from '../ui/data/Filter';

interface ProjectSelectorProps {
  selectedProjects: Project[];
  selectedLogEntries: Record<string, LogEntry[]>;
  onChange: (projects: Project[]) => void;
  onLogEntriesChange: (projectId: string, logEntries: LogEntry[]) => void;
  startDate?: string;
  endDate?: string;
  multiple?: boolean;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  selectedProjects,
  selectedLogEntries,
  onChange,
  onLogEntriesChange,
  startDate,
  endDate,
  multiple = true,
}) => {
  const [expandedProjects, setExpandedProjects] = useState<string[]>([]);
  const [filters, setFilters] = useState<Record<string, any>>({});

  const toggleExpand = (projectId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedProjects(prev =>
      prev.includes(projectId)
        ? prev.filter(id => id !== projectId)
        : [...prev, projectId]
    );
  };

  const handleLogEntrySelect = (projectId: string, logEntry: LogEntry) => {
    const currentEntries = selectedLogEntries[projectId] || [];
    const isSelected = currentEntries.some(entry => entry.id === logEntry.id);
    
    onLogEntriesChange(
      projectId,
      isSelected
        ? currentEntries.filter(entry => entry.id !== logEntry.id)
        : [...currentEntries, logEntry]
    );
  };

  // Define filter configuration based on available projects
  const filterConfig = (projects: Project[]): FilterConfig => {
    // Get unique tags from all projects
    const allTags = Array.from(new Set(projects.flatMap(project => project.tags.map(tag => tag.name))));
    
    // Get unique owners from all projects
    const allOwners = Array.from(new Set(projects.map(project => project.owner?.name).filter((name): name is string => name !== undefined)));

    return {
      state: {
        label: 'State',
        value: 'state',
        type: 'select',
        options: [
          { label: 'Candidate', value: 'CANDIDATE' },
          { label: 'Backlog', value: 'BACKLOG' },
          { label: 'Definition and Analysis', value: 'DEFINITION_AND_ANALYSIS' },
          { label: 'Development in Progress', value: 'DEVELOPMENT_IN_PROGRESS' },
          { label: 'Testing', value: 'TESTING' },
          { label: 'Rolling Out', value: 'ROLLING_OUT' },
          { label: 'Done', value: 'DONE' }
        ],
      },
      effort: {
        label: 'Effort',
        value: 'effort',
        type: 'select',
        options: [
          { label: 'XS', value: 'XS' },
          { label: 'S', value: 'S' },
          { label: 'M', value: 'M' },
          { label: 'L', value: 'L' },
          { label: 'XL', value: 'XL' }
        ],
      },
      priority: {
        label: 'Priority',
        value: 'priority',
        type: 'select',
        options: [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' }
        ],
      },
      pulse: {
        label: 'Pulse',
        value: 'pulse',
        type: 'select',
        options: [
          { label: 'Green', value: 'GREEN' },
          { label: 'Yellow', value: 'YELLOW' },
          { label: 'Orange', value: 'ORANGE' },
          { label: 'Red', value: 'RED' }
        ],
      },
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
      owner: {
        label: 'Owner',
        value: 'owner',
        type: 'select',
        options: allOwners.map(owner => ({ label: owner, value: owner })),
      },
    };
  };

  const filterProjects = (projects: Project[]): Project[] => {
    return projects.filter(project =>
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
        
        switch (key) {
          case 'state':
            return project.state === value;
          case 'effort':
            return project.effort === value;
          case 'priority':
            return project.priority.toString() === value;
          case 'pulse':
            return project.pulse === value;
          case 'tags':
            if (Array.isArray(value) && value.length > 0) {
              return value.some(tag => project.tags.some(projectTag => projectTag.name === tag));
            }
            return true;
          case 'owner':
            return project.owner?.name === value;
          default:
            return true;
        }
      })
    );
  };

  const LogEntriesList: React.FC<{
    logEntries: LogEntry[];
    projectId: string;
    startDate?: string;
    endDate?: string;
  }> = ({ logEntries, projectId, startDate, endDate }) => {
    const filteredAndSortedEntries = useMemo(() => {
      return logEntries
        .filter(entry => {
          const entryDate = new Date(entry.created_at);
          const normalizedEntryDate = new Date(entryDate.setHours(0, 0, 0, 0)); // Normalize to date only
  
          if (startDate && endDate) {
            const normalizedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
            const normalizedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)); // Include the entire end day
            return normalizedEntryDate >= normalizedStartDate && normalizedEntryDate <= normalizedEndDate;
          }
  
          if (startDate) {
            const normalizedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
            return normalizedEntryDate >= normalizedStartDate;
          }
  
          if (endDate) {
            const normalizedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)); // Include the entire end day
            return normalizedEntryDate <= normalizedEndDate;
          }
  
          return true;
        })
        .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    }, [logEntries, startDate, endDate]);
  
    if (filteredAndSortedEntries.length === 0) {
      return (
        <div className="text-sm text-gray-500 mt-4">
          No log entries available for this project between {startDate ? new Date(startDate).toLocaleDateString() : 'N/A'} and {endDate ? new Date(endDate).toLocaleDateString() : 'N/A'}.
        </div>
      );
    }
  
    return (
      <div className="space-y-2 mt-4">
        {filteredAndSortedEntries.map((entry) => (
          <div
            key={entry.id}
            onClick={() => handleLogEntrySelect(projectId, entry)}
            className={`p-2 rounded cursor-pointer hover:bg-gray-50 ${
              selectedLogEntries[projectId]?.some(e => e.id === entry.id)
                ? 'bg-blue-50'
                : ''
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Badge variant="outline">{entry.log_type.replace(/_/g, ' ')}</Badge>
                <span className="text-sm text-gray-500">
                  {new Date(entry.created_at).toLocaleDateString()}
                </span>
              </div>
              {selectedLogEntries[projectId]?.some(e => e.id === entry.id) && (
                <CheckCircle2 className="w-4 h-4 text-green-500" />
              )}
            </div>
            <p className="text-sm mt-1">{entry.content}</p>
          </div>
        ))}
      </div>
    );
  };  

  const LogEntriesSection = ({ project }: { project: Project }) => (
    <LogEntryContainer
      objectId={project.id}
      render={({ data: logEntries, loading, error }) => {
        if (loading) return <p className="text-sm text-gray-500">Loading updates...</p>;
        if (error) return <p className="text-sm text-red-500">{error}</p>;
        if (!logEntries.length) return <p className="text-sm text-gray-500">No updates available</p>;

        return (
          <LogEntriesList 
            logEntries={logEntries}
            projectId={project.id}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }}
    />
  );

  return (
    <ProjectContainer
      render={({ data: projects, loading, error }) => {
        if (loading) return <p>Loading projects...</p>;
        if (error) return <p>{error}</p>;

        const filteredProjects = filterProjects(projects);

        return (
          <div className="space-y-4">
            <div className="mb-4">
              <Filter
                config={filterConfig(projects)}
                onFilterChange={setFilters}
              />
            </div>
            <EntitySelector
              items={filteredProjects}
              selectedItems={selectedProjects}
              onChange={onChange}
              multiple={multiple}
              renderItem={(project, isSelected, onClick) => (
                <div key={project.id} className="space-y-2">
                  <div
                    className={`border rounded-md p-3 cursor-pointer ${
                      isSelected ? 'border-primary ring-2 ring-primary' : 'border-gray-300'
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <div className="flex-1" onClick={onClick}>
                        <h4 className="font-medium">{project.title}</h4>
                        {project.description && (
                          <div className="text-xs text-gray-500"
                            dangerouslySetInnerHTML={{
                              __html: project.description.length > 500 
                                ? project.description.slice(0, 500) + '...' 
                                : project.description,
                            }}
                          />
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <span className={`px-2 py-1 text-xs rounded-md font-medium ${
                          isSelected ? 'text-primary' : 'text-neutral'
                        }`}>
                          {project.state.replace(/_/g, ' ').toUpperCase()}
                        </span>
                        {isSelected && (
                          <button
                            onClick={(e) => toggleExpand(project.id, e)}
                            className="p-1 hover:bg-gray-100 rounded"
                          >
                            {expandedProjects.includes(project.id) 
                              ? <ChevronUp className="w-4 h-4" />
                              : <ChevronDown className="w-4 h-4" />
                            }
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-2 text-xs text-gray-500">
                      <p>Pulse: {project.pulse}</p>
                      <p>Priority: {project.priority}</p>
                    </div>

                    {isSelected && expandedProjects.includes(project.id) && (
                      <LogEntriesSection project={project} />
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        );
      }}
    />
  );
};

export default ProjectSelector;