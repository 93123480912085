import React from 'react';
import { Goal } from '../../api/goal/types';
import { Table, TableDateComponent, TableActionButtons, TablePersonsComponent, TableTagsComponent, TableBadgeComponent, TablePersonComponent, TableInlineTextInput } from '../ui';
import { TableTeamComponent } from '../ui/data/table-components/TableTeamComponent';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import { Team } from '../../api/team/types';
import { Project } from '../../api/project/types';
import { Topic } from '../../api/topic/types';

interface GoalTableProps {
  data: Goal[];
  onEdit: (goal: Goal) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Goal['state']) => void;
  onUpdateProgress: (slug: string, newProgress: number) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdateParticipants: (slug: string, newParticipants: Person[]) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  onUpdateTitle: (slug: string, newTitle: string) => void;
  onUpdateTeam: (slug: string, newTeam: Team | null) => void;
  onUpdateProjects: (slug: string, newProjects: Project[]) => void;
  onUpdateTopics: (slug: string, newTopics: Topic[]) => void;
}

const goalStateOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'ACHIEVED', label: 'Achieved' },
  { value: 'MISSED', label: 'Missed' },
  { value: 'CANCELED', label: 'Canceled' }
];

const timeframeOptions = [
  { value: 'ANNUAL', label: 'Annual' },
  { value: 'QUARTERLY', label: 'Quarterly' }
];

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const option = options.find(opt => opt.value === value);
  return option?.label || value;
};

export const GoalTable: React.FC<GoalTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateProgress,
  onUpdateTags,
  onUpdateParticipants,
  onUpdateOwner,
  onUpdateTitle,
  onUpdateTeam,
  onUpdateProjects,
  onUpdateTopics
}) => (
  <Table
    data={data}
    columns={[
      {
        header: 'Title',
        accessor: 'title',
        component: (row) => (
          <div onClick={() => onEdit(row)} className="cursor-pointer hover:underline">
            <TableInlineTextInput
              initialValue={row.title}
              onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
              onCancel={() => {}}
            />
          </div>
        ),
      },
      {
        header: 'Timeframe',
        component: (row) => (
          <div className="flex items-center gap-2">
            <TableBadgeComponent
              label={getLabelForValue(row.timeframe, timeframeOptions)}
              type="timeframe"
              options={timeframeOptions}
              isEditable={false}
            />
            <span className="text-sm text-text">{row.year}</span>
            {row.timeframe === 'QUARTERLY' && (
              <span className="text-sm text-text">Q{row.quarter}</span>
            )}
          </div>
        ),
      },
      {
        header: 'State',
        component: (row) => (
          <TableBadgeComponent
            label={getLabelForValue(row.state, goalStateOptions)}
            type="goal_state"
            onUpdate={(newState) => onUpdateState(row.slug, newState as Goal['state'])}
            options={goalStateOptions}
            isEditable={true}
          />
        ),
      },
      {
        header: 'Progress',
        component: (row) => (
          <div className="w-full space-y-1">
            <div className="w-full bg-gray-200 rounded h-2">
              <div 
                className="bg-blue-600 h-2 rounded" 
                style={{ width: `${row.progress}%` }}
              />
            </div>
            <div className="text-xs text-gray-500 text-center">
              {row.progress}%
            </div>
          </div>
        ),
      },
      {
        header: 'Owner',
        component: (row) => (
          <TablePersonComponent
            person={row.owner}
            isEditable={true}
            onUpdate={(newOwner) => onUpdateOwner(row.slug, newOwner)}
          />
        ),
      },
      {
        header: 'Team',
        component: (row) => (
          <TableTeamComponent
            team={row.team}
            isEditable={true}
            onUpdate={(newTeam) => onUpdateTeam(row.slug, newTeam)}
          />
        ),
      },
      {
        header: 'Participants',
        component: (row) => (
          <TablePersonsComponent
            persons={row.participants}
            isEditable={true}
            onUpdate={(newParticipants) => onUpdateParticipants(row.slug, newParticipants)}
          />
        ),
      },
      {
        header: 'Tags',
        component: (row) => (
          <TableTagsComponent
            tags={row.tags}
            isEditable={true}
            onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
            limit={3}
          />
        ),
      },
      {
        header: 'Created At',
        component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
      },
      {
        header: 'Actions',
        component: (row) => (
          <TableActionButtons
            onEdit={() => onEdit(row)}
            onDelete={() => onDelete(row.slug)}
          />
        ),
      },
    ]}
  />
);