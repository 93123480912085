import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchPersons, 
  fetchPerson, 
  createPerson, 
  updatePerson, 
  deletePerson,
  validateCSVPersons
} from './api';
import { Person } from './types';

// Hook for fetching all persons
export const usePersons = () => {
  const navigate = useNavigate();
  return useQuery<Person[], Error>('persons', () => fetchPersons(navigate), {
    select: (data) => data ?? [],
  });
};

// Hook for fetching a single person
export const usePerson = (id: string) => {
  const navigate = useNavigate();
  return useQuery(['person', id], () => fetchPerson(id, navigate));
};

// Hook for creating a new person
export const useCreatePerson = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newPerson: Omit<Person, 'id' | 'created_at' | 'updated_at'>) => createPerson(newPerson, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('persons');
      },
    }
  );
};

// Hook for updating a person
export const useUpdatePerson = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, person }: { id: string; person: Partial<Omit<Person, 'created_at' | 'updated_at'>> }) => 
      updatePerson(id, person, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('persons');
      },
    }
  );
};

// Hook for deleting a person
export const useDeletePerson = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deletePerson(id, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('persons');
      },
    }
  );
};

// Hook for validating CSV data
export const useValidateCSVPersons = () => {
  const navigate = useNavigate();

  return useMutation(
    (csvData: Array<Record<string, any>>) => validateCSVPersons(csvData, navigate)
  );
};