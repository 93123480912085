import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TeamContainer from '../containers/TeamContainer';
import { TeamTable } from '../components/team/TeamTable';
import { TeamModal } from '../components/team/TeamModal';
import { TeamTreeView } from '../components/team/TeamTreeView';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal, Button } from '../components/ui';
import { Team } from '../api/team/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';

const TeamListPage: React.FC = () => {
  const [viewMode, setViewMode] = useState<'table' | 'tree'>('table');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState<string | null>(null);
  const [teamToEdit, setTeamToEdit] = useState<Team | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [teams, setTeams] = useState<Team[]>([]);
  const [sortBy, setSortBy] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (slug && teams.length > 0) {
      const team = teams.find((team) => team.slug === slug);
      if (team) {
        setTeamToEdit(team);
        setShowModal(true);
      }
    }
  }, [slug, teams]);

  const handleDelete = (slug: string) => {
    setTeamToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (teamToDelete) {
      onDelete(teamToDelete);
      setShowDeleteDialog(false);
      setTeamToDelete(null);
      setSnackbarMessage('Team deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setTeamToEdit(null);
    setShowModal(true);
  };

  const handleEditTeam = (team: Team) => {
    navigate(`/teams/${team.slug}`);
    setTeamToEdit(team);
    setShowModal(true);
  };

  const filterConfig: FilterConfig = useMemo(() => {
    const allTags = Array.from(new Set(teams.flatMap(team => team.tags.map(tag => tag.name))));
    const allMembers = Array.from(new Set(teams.flatMap(team => team.members.map(member => member.person.name))));

    return {
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
      members: {
        label: 'Members',
        value: 'members',
        type: 'multi-select',
        options: allMembers.map(member => ({ label: member, value: member })),
      },
    };
  }, [teams]);

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Name', value: 'name' },
    { label: 'Created Date', value: 'created_at' },
    { label: 'Updated Date', value: 'updated_at' },
  ];

  const filterAndSortTeams = (teams: Team[]): Team[] => {
    let filteredTeams = teams.filter(team =>
      team.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
        switch (key) {
          case 'tags':
            return value.some((tag: string) => team.tags.some((teamTag) => teamTag.name === tag));
          case 'members':
            return value.some((member: string) => team.members.some((teamMember) => teamMember.person.name === member));
          default:
            return true;
        }
      })
    );

    return filteredTeams.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'name':
          result = a.name.localeCompare(b.name);
          break;
        case 'created_at':
          result = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
          break;
        case 'updated_at':
          result = new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  const toggleViewMode = (mode: 'table' | 'tree') => {
    setViewMode(mode);
  };

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => toggleViewMode('table')}
        variant={viewMode === 'table' ? 'primary' : 'secondary'}
        title="Table View"
      />
      <Button
        icon="ListCollapse"
        onClick={() => toggleViewMode('tree')}
        variant={viewMode === 'tree' ? 'primary' : 'secondary'}
        title="Tree View"
      />
    </>
  );

  return (
    <div>
      <h1 className="m-4">Teams</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Teams..."
        addButtonLabel="Add New Team"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        defaultSortValue="name"
        filterConfig={filterConfig}
        onFilterChange={handleFilterChange}
        additionalButtons={additionalButtons}
      />

      <TeamContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading teams...</p>
            </div>
          );
          
          if (error) return <div>{error}</div>;

          if (teams.length === 0 && data.length > 0) {
            setTeams(data);
          }

          const filteredAndSortedTeams = filterAndSortTeams(data);

          const handleSaveTeam = (
            teamData: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>,
            slug?: string
          ) => {
            if (slug) {
              onEdit(slug, teamData);
              setSnackbarMessage('Team updated successfully');
            } else {
              onCreate(teamData);
              setSnackbarMessage('Team created successfully');
            }
            setSnackbarType('success');
            setShowSnackbar(true);
          };

          return (
            <>
              {showModal && (
                <TeamModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate('/teams/');
                  }}
                  onSave={teamToEdit ? (data) => handleSaveTeam(data, teamToEdit.slug) : handleSaveTeam}
                  initialData={teamToEdit || undefined}
                  onEdit={onEdit}
                  onDelete={(slug) => {
                    onDelete(slug);
                    setSnackbarMessage('Team deleted successfully');
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this team?</p>
              </Modal>

              {viewMode === 'table' ? (
                <TeamTable
                  data={filteredAndSortedTeams}
                  key={filteredAndSortedTeams.map(t => t.slug).join()}
                  onEdit={handleEditTeam}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdateName={(slug, newName) => onEdit(slug, { name: newName })}
                  onUpdateDescription={(slug, newDescription) => onEdit(slug, { description: newDescription })}
                  onUpdateTags={(slug, newTags) => onEdit(slug, { tags: newTags })}
                />
              ) : (
                <TeamTreeView className="p-4" />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default TeamListPage;
