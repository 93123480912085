import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import TopicContainer from '../../containers/TopicContainer';
import { Topic } from '../../api/topic/types';

type SelectSize = 'small' | 'medium' | 'large';

interface TopicSelectProps {
  value: string;
  onChange: (selectedTopic: Topic) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  meetingTypeId?: string;
  personId?: string;
}

const TopicSelect: React.FC<TopicSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select a topic',
  size = 'medium',
  isOpen,
  onClose,
  meetingTypeId,
  personId,
}) => {
  return (
    <TopicContainer
      meetingTypeId={meetingTypeId}
      personId={personId}
      render={({ data: topics, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        // Filter out archived and completed topics
        const activeTopics = topics;

        // Map filtered topics to options for the SelectSingle component
        const topicOptions = activeTopics
          .map((topic) => ({
            value: topic.id,
            label: topic.title,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        const handleSelect = (topicId: string) => {
          const selectedTopic = activeTopics.find((topic) => topic.id === topicId);
          if (selectedTopic) {
            onChange(selectedTopic);
          }
          if (onClose) {
            onClose();
          }
        };

        return (
          <SelectSingle
            placeholder={placeholder}
            options={topicOptions}
            defaultValue={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

export default TopicSelect;
