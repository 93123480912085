import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Board } from '../ui';
import { Task } from '../../api/task/types';
import { format, isBefore, startOfDay, endOfDay, parseISO, addDays, addWeeks } from 'date-fns';
import { Button } from '../ui';
import { Icon } from '../ui/base/Icon';
import { getTaskStateLabelAndIcon, getDialStageLabelAndIcon } from '../../utils/entitiesOptions';

interface TaskBoardViewProps {
  tasks: Task[];
  onOpenTask: (task: Task) => void;
  onEdit: (slug: string, updatedTask: Partial<Task>) => void;
}

interface Badge {
  label: string;
  style?: string;
  icon?: React.ReactNode;
}

interface BoardItem {
  id: string;
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
  isBacklog?: boolean;
}

export const TaskBoardView: React.FC<TaskBoardViewProps> = ({
  tasks: initialTasks,
  onOpenTask,
  onEdit,
}) => {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [weekOffset, setWeekOffset] = useState(0);

  const today = useMemo(() => startOfDay(addWeeks(new Date(), weekOffset)), [weekOffset]);
  const isCurrentWeek = weekOffset === 0;

  const weekDates = useMemo(
    () =>
      Array.from({ length: 5 }, (_, i) => {
        const date = addDays(today, i);
        return {
          date,
          formatted: format(date, 'EEEE, MMM d'),
          dayEnd: endOfDay(date),
          id: format(date, 'yyyy-MM-dd'),
        };
      }),
    [today]
  );

  const handlePreviousWeek = () => setWeekOffset(prev => prev - 1);
  const handleNextWeek = () => setWeekOffset(prev => prev + 1);
  const handleCurrentWeek = () => setWeekOffset(0);

  const mapTaskToBoardItem = useCallback(
    (task: Task): BoardItem => {
      const stateBadge = getTaskStateLabelAndIcon(task.state);
      const dialStageBadge = getDialStageLabelAndIcon(task.dial_stage);

      return {
        id: task.slug,
        title: task.title,
        badges: [stateBadge, dialStageBadge].filter(Boolean),
      };
    },
    []
  );

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks, isUpdating]);

  const updateColumns = useCallback(() => {
    const backlogItems = tasks
      .filter(task => !task.due_date || isBefore(parseISO(task.due_date), today))
      .map(mapTaskToBoardItem);

    const dateColumns = weekDates.map(({ date, formatted, id }) => ({
      id,
      title: formatted,
      items: tasks
        .filter(task => {
          const dueDate = task.due_date ? parseISO(task.due_date) : null;
          return dueDate && format(dueDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        })
        .map(mapTaskToBoardItem),
    }));

    setColumns([
      { id: 'backlog', title: 'Backlog & Overdue', items: backlogItems, isBacklog: true },
      ...dateColumns,
    ]);
  }, [tasks, today, weekDates, mapTaskToBoardItem]);

  useEffect(() => {
    updateColumns();
  }, [updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const task = tasks.find(t => t.slug === item.id);
      if (task) {
        onOpenTask(task);
      }
    },
    [tasks, onOpenTask]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
        return;
      }

      const task = tasks.find(t => t.slug === draggableId);
      if (!task) {
        console.error('Task not found:', draggableId);
        return;
      }

      const patchPayload = {
        due_date: destination.droppableId === 'backlog' ? null : destination.droppableId,
      };

      setIsUpdating(true);

      try {
        const updatedTask: Task = {
          ...task,
          due_date: destination.droppableId === 'backlog' ? undefined : destination.droppableId,
        };

        const updatedTasks = tasks.map(t => (t.slug === draggableId ? updatedTask : t));
        setTasks(updatedTasks);

        await onEdit(draggableId, patchPayload as any);
      } catch (error) {
        console.error('Failed to update task:', error);
        setTasks(tasks);
        updateColumns();
      } finally {
        setIsUpdating(false);
      }
    },
    [tasks, onEdit, updateColumns]
  );

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4">
        <Button icon="ArrowLeft" label="Previous Week" buttonStyle="ghost" onClick={handlePreviousWeek} iconSize="md" />
        
        <div className="flex items-center gap-2">
          <h2 className="font-semibold text-lg">{format(today, 'MMMM yyyy')}</h2>
          {!isCurrentWeek && (
            <Button
              label="This Week"
              buttonStyle="ghost"
              onClick={handleCurrentWeek}
              iconSize="md"
              className="text-xs"
            />
          )}
        </div>

        <Button
          icon="ArrowRight"
          label="Next Week"
          buttonStyle="ghost"
          onClick={handleNextWeek}
          iconSize="md"
          iconPosition="right"
        />
      </div>

      <Board
        columns={columns}
        minHeight="calc(100vh - 300px)"
        allowAddItems={false}
        onOpenItem={handleOpenItem}
        onMoveItem={handleMoveItem}
      />
    </div>
  );
};

export default TaskBoardView;
