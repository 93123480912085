import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchTopics, 
  fetchTopic, 
  createTopic, 
  editTopic, 
  createTopicLogEntry, 
  deleteTopic,
  FetchTopicsParams
} from './api';
import { Topic } from './types';
import { LogEntry } from '../logEntry/types';

// Hook for fetching all topics
export const useTopics = (params?: FetchTopicsParams) => {
  const navigate = useNavigate();
  return useQuery(
    ['topics', params],
    () => fetchTopics(params || {}, navigate),
    {
      staleTime: 0, // Force refetch on window focus and invalidation
      select: (data) => data ?? [], // Ensure we always return an array
    }
  );
};

// Hook for creating a new topic
export const useCreateTopic = (params?: FetchTopicsParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newTopic: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>) => 
      createTopic(newTopic, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topics', params]);
      },
    }
  );
};

// Hook for editing a topic
export const useEditTopic = (params?: FetchTopicsParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedTopic }: { slug: string; updatedTopic: Partial<Topic> }) => 
      editTopic(slug, updatedTopic, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topics', params]);
      },
    }
  );
};

// Hook for deleting a topic
export const useDeleteTopic = (params?: FetchTopicsParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteTopic(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topics', params]);
      },
    }
  );
};

// Hook for creating a log entry
export const useCreateLogEntry = (params?: FetchTopicsParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ topicId, logEntryData }: { 
      topicId: string; 
      logEntryData: Omit<LogEntry, 'id' | 'created_at' | 'created_by'> 
    }) => createTopicLogEntry(topicId, logEntryData, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topics', params]);
      },
    }
  );
};