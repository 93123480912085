import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Topic } from '../../api/topic/types';
import { getTopicStateLabelAndIcon } from '../../utils/entitiesOptions';

interface TopicHeatmapViewProps {
  topics?: Topic[];
  onOpenTopic: (topic: Topic) => void; // onOpenTopic prop for opening modal
  onEdit: (slug: string, updatedTopic: Partial<Topic>) => void; // onEdit prop for updates
}

const importanceLabels: { [key: number]: string } = {
  5: 'Critical',
  4: 'High',
  3: 'Medium',
  2: 'Low',
  1: 'Very Low',
};

export const TopicHeatmapView: React.FC<TopicHeatmapViewProps> = ({ topics: propTopics, onOpenTopic, onEdit }) => {
  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    if (propTopics) {
      setTopics(propTopics);
    }
  }, [propTopics]);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return;

    const [destImportance, destStatus] = destination.droppableId.split('-');
    const updatedTopics = topics.map(topic => {
      if (topic.id === draggableId) {
        return {
          ...topic,
          importance: parseInt(destImportance, 10),
          heatmap_status: destStatus as Topic['heatmap_status'],
        };
      }
      return topic;
    });

    setTopics(updatedTopics);
    const updatedTopic = updatedTopics.find(topic => topic.id === draggableId);
    if (updatedTopic) {
      onEdit(updatedTopic.slug, {
        importance: updatedTopic.importance,
        heatmap_status: updatedTopic.heatmap_status,
      });
    }
  };

  const renderHeatmapGrid = () => {
    return [...Array(5)].map((_, idx) => {
      const importance = 5 - idx;
      return (
        <tr key={importance} className="border-b border-neutral">
          <td className="p-2 font-bold text-left w-32">{importanceLabels[importance]}</td>
          {['GREEN', 'YELLOW', 'ORANGE', 'RED'].map((status) => (
            <td key={status} className="p-2 border-l border-neutral">
              <Droppable droppableId={`${importance}-${status}`} direction="vertical" type="HEATMAP">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="min-h-[50px]">
                    {topics
                      .filter(topic => topic.importance === importance && topic.heatmap_status === status)
                      .map((topic, index) => {
                        const { label: stateLabel, icon: stateIcon } = getTopicStateLabelAndIcon(topic.state);

                        return (
                          <Draggable key={topic.id} draggableId={topic.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`p-2 rounded-md shadow-sm ${getStatusColor(topic.heatmap_status)} hover:opacity-80 transition-opacity duration-200 mb-2`}
                                onClick={() => onOpenTopic(topic)} // Open modal without changing styles
                              >
                                <span className="text-sm font-medium text-text">{topic.title}</span>
                                <span className="block text-xs text-text-muted flex items-center space-x-1">
                                  {stateIcon} <span>{stateLabel}</span>
                                </span>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </td>
          ))}
        </tr>
      );
    });
  };

  const getStatusColor = (status: Topic['heatmap_status']) => {
    switch (status) {
      case 'GREEN': return 'bg-accent bg-opacity-20';
      case 'YELLOW': return 'bg-secondary bg-opacity-20';
      case 'ORANGE': return 'bg-secondary-dark bg-opacity-20';
      case 'RED': return 'bg-red-500 bg-opacity-20';
      default: return 'bg-neutral';
    }
  };

  return (
    <div className="bg-white mt-20">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="overflow-x-auto">
          <table className="w-full max-w-6xl mx-auto table-fixed border-collapse">
            <thead>
              <tr>
                <th className="p-2 text-left font-semibold text-text w-32"></th>
                <th className="p-2 text-center font-semibold text-accent">Green</th>
                <th className="p-2 text-center font-semibold text-secondary">Yellow</th>
                <th className="p-2 text-center font-semibold text-secondary-dark">Orange</th>
                <th className="p-2 text-center font-semibold text-red-500">Red</th>
              </tr>
            </thead>
            <tbody>
              {topics.length > 0 ? renderHeatmapGrid() : (
                <tr>
                  <td colSpan={5} className="p-4 text-center text-placeholder">No topics available for the heatmap.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DragDropContext>
    </div>
  );
};

export default TopicHeatmapView;
