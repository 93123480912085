import React, { useMemo, useState } from 'react';
import { Team, TeamMembership } from '../../api/team/types';
import TreeView from '../ui/data/TreeView';
import { type TreeItemData } from '../ui/data/TreeView';
import { Icon, Avatar, Badge } from '../ui';
import { Tag } from '../../api/tag/types';
import TeamContainer from '../../containers/TeamContainer';
import { IconName } from '../ui';
import { PersonModal } from '../person/PersonModal';
import { Person } from '../../api/person/types';


interface TeamTreeViewProps {
  className?: string;
  initialExpandedNodes?: Set<string>;
}

interface TeamMetadata {
  slug: string;
  description?: string;
  tags?: Tag[];
  members?: TeamMembership[];
}

interface TeamTreeItemData extends TreeItemData {
  icon?: IconName;
}

function isTeamTreeItem(item: TreeItemData): item is TreeItemData & { metadata: TeamMetadata } {
  return item.metadata !== undefined && 'slug' in item.metadata;
}

interface RenderContentProps {
  teams: Team[];
  loading: boolean;
  error: string | null;
  expandedNodes: Set<string>;
  onNodeToggle: (nodeId: string) => void;
  className?: string;
}

const TeamTreeViewContent: React.FC<RenderContentProps> = ({
  teams,
  loading,
  error,
  expandedNodes,
  onNodeToggle,
  className
}) => {
  const treeData = useMemo(() => {
    // Find root level teams (teams without parents)
    const rootTeams = teams.filter(team => !team.parent);

    // Create a member node for the tree
    const createMemberNode = (membership: TeamMembership): TeamTreeItemData => ({
      id: `member-${membership.id}`,
      title: membership.person.name,
      type: 'member',
      metadata: { membership }
    });

    const createMembersGroup = (team: Team, memberNodes: TeamTreeItemData[]): TeamTreeItemData => ({
      id: `members-${team.id}`,
      title: 'Members',
      type: 'members-group',
      icon: 'Users' as IconName,
      children: memberNodes
    });

    // Helper function to get child teams
    const getChildTeams = (parentId: string) => {
        return teams.filter(team => String(team.parent) === parentId);
    };

    const buildTeamTree = (team: Team): TeamTreeItemData => {
        const childTeams = teams.filter(t => String(t.parent) === team.id);
        const memberNodes = team.members.map(createMemberNode);
        
        return {
          id: team.id,
          title: team.name,
          type: 'team',
          icon: 'Users' as IconName,
          metadata: {
            slug: team.slug,
            description: team.description,
            tags: team.tags,
            members: team.members
          },
          children: [
            ...childTeams.map(buildTeamTree),
            ...memberNodes
          ]
        };
      };

    return {
      id: 'root',
      title: 'Teams',
      type: 'organization',
      icon: 'Building2' as IconName,
      children: rootTeams.map(buildTeamTree)
    } as TeamTreeItemData;
  }, [teams]);

    const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined>(undefined);

    const openPersonModal = (person: Person) => {
    setSelectedPerson(person);
    setIsPersonModalOpen(true);
    };

    const closePersonModal = () => {
    setIsPersonModalOpen(false);
    setSelectedPerson(undefined);
    };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Icon name="Loader2" className="animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-8 text-destructive">
        <Icon name="AlertTriangle" className="mr-2" />
        {error}
      </div>
    );
  }

  const getNodeStyle = (item: TreeItemData) => {
    switch (item.type) {
      case 'organization':
        return 'bg-primary/10 border-primary/30';
      case 'team':
        return 'bg-background border-neutral';
      case 'members-group':
        return 'bg-accent/5 border-accent/20';
      case 'member':
        return 'bg-background/50 border-neutral/50';
      default:
        return 'bg-background border-neutral';
    }
  };

  // Modify the renderContent function to include team count:

const renderContent = (item: TreeItemData) => {
  function isMemberTreeItem(item: TreeItemData): item is TreeItemData & { 
    metadata: { membership: TeamMembership } 
  } {
    return item.type === 'member' && 
           item.metadata !== undefined && 
           'membership' in item.metadata;
  }

  // Helper function to count child teams
  const getChildTeamCount = (item: TreeItemData): number => {
    if (!item.children) return 0;
    return item.children.filter(child => child.type === 'team').length;
  };

  if (item.type === 'member' && isMemberTreeItem(item)) {
    return (
      <div 
        className="flex items-center gap-2 cursor-pointer hover:bg-accent/5 p-1 rounded"
        onClick={() => openPersonModal(item.metadata.membership.person)}
      >
        <Avatar
          name={item.title}
          size="sm"
        />
        <div className="flex-1 min-w-0 ml-4">
          <div className="font-medium text-sm truncate">{item.title}</div>
        </div>
      </div>
    );
  }

  if (!isTeamTreeItem(item)) {
    return (
      <div className="flex items-center justify-between flex-1 min-w-0">
        <div className="min-w-0">
          <div className="font-medium text-sm truncate">{item.title}</div>
          {item.subtitle && (
            <div className="text-text/60 text-xs truncate">{item.subtitle}</div>
          )}
          {item.children && item.type !== 'members-group' && (
            <div className="text-text/60 text-xs">
              {getChildTeamCount(item)} team{getChildTeamCount(item) !== 1 ? 's' : ''}
            </div>
          )}
        </div>
      </div>
    );
  }

  const childTeamCount = getChildTeamCount(item);

  return (
    <div className="flex items-center justify-between flex-1 min-w-0">
      <div className="min-w-0">
        <div className="font-medium text-sm truncate">{item.title}</div>
        <div className="flex items-center gap-2 mt-1">
          {item.metadata.tags?.map((tag: Tag) => (
            <Badge key={tag.id} variant="outline" className="text-xs">
              {tag.name}
            </Badge>
          ))}
          <div className="flex items-center gap-2 text-text/60 text-xs">
            {item.metadata.members && (
              <span>
                {item.metadata.members.length} member{item.metadata.members.length !== 1 ? 's' : ''}
              </span>
            )}
            {childTeamCount > 0 && (
              <>
                <span>•</span>
                <span>
                  {childTeamCount} team{childTeamCount !== 1 ? 's' : ''}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

  return (
    <>
      <TreeView
        data={treeData}
        expandedNodes={expandedNodes}
        onNodeToggle={onNodeToggle}
        renderContent={renderContent}
        getNodeStyle={getNodeStyle}
        className={className}
      />
      
      {isPersonModalOpen && selectedPerson && (
        <PersonModal
          isOpen={isPersonModalOpen}
          onClose={closePersonModal}
          initialData={selectedPerson}
          onSuccess={(message) => {
            console.log(message);
            closePersonModal();
          }}
          onError={(message) => console.log(message)}
        />
      )}
    </>
  );
};

export const TeamTreeView: React.FC<TeamTreeViewProps> = ({
  className,
  initialExpandedNodes = new Set(['root'])
}) => {
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(initialExpandedNodes);

  const handleNodeToggle = (nodeId: string) => {
    setExpandedNodes(prev => {
      const next = new Set(prev);
      if (next.has(nodeId)) {
        next.delete(nodeId);
      } else {
        next.add(nodeId);
      }
      return next;
    });
  };

  return (
    <TeamContainer
      render={({ data: teams, loading, error }) => (
        <TeamTreeViewContent
          teams={teams}
          loading={loading}
          error={error}
          expandedNodes={expandedNodes}
          onNodeToggle={handleNodeToggle}
          className={className}
        />
      )}
    />
  );
};

export default TeamTreeView;