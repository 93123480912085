import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
  content: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  children: React.ReactNode;
  delay?: number;
  variant?: 'default' | 'primary' | 'secondary';
}

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = 'top',
  children,
  delay = 0,
  variant = 'default',
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => setIsVisible(true), delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const variantClasses = {
    default: 'bg-white text-gray-800 border border-gray-200',
    primary: 'bg-blue-500 text-white border border-blue-600',
    secondary: 'bg-gray-500 text-white border border-gray-600',
  };

  const getTooltipStyles = () => {
    if (!tooltipRef.current) return {};

    const offset = 8; // Space between tooltip and button
    switch (position) {
      case 'top':
        return { bottom: `calc(100% + ${offset}px)`, left: '50%', transform: 'translateX(-50%)' };
      case 'right':
        return { left: `calc(100% + ${offset}px)`, top: '50%', transform: 'translateY(-50%)' };
      case 'bottom':
        return { top: `calc(100% + ${offset}px)`, left: '50%', transform: 'translateX(-50%)' };
      case 'left':
        return { right: `calc(100% + ${offset}px)`, top: '50%', transform: 'translateY(-50%)' };
      default:
        return {};
    }
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && (
        <div
          ref={tooltipRef}
          className={`absolute z-10 px-3 py-2 text-xs font-sans rounded-md shadow-lg transition-opacity duration-300 ease-in-out ${variantClasses[variant]}`}
          style={{
            ...getTooltipStyles(),
            whiteSpace: 'nowrap',
            pointerEvents: 'none',
          }}
          role="tooltip"
        >
          {content}
        </div>
      )}
    </div>
  );
};
