import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import GoalContainer from '../containers/GoalContainer';
import { GoalTable } from '../components/goal/GoalTable';
import { GoalModal } from '../components/goal/GoalModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Goal } from '../api/goal/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';
import { Button } from '../components/ui';
import { GoalBoardView } from '../components/goal/GoalBoardView';

const GoalListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<string | null>(null);
  const [goalToEdit, setGoalToEdit] = useState<Goal | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [goals, setGoals] = useState<Goal[]>([]);
  const [sortBy, setSortBy] = useState('timeframe');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showBoardView, setShowBoardView] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (!showModal) {
      setShowBoardView(location.pathname === "/goals/board");
    }
  }, [location.pathname, showModal]);

  useEffect(() => {
    if (slug && goals.length > 0) {
      const goal = goals.find((goal) => goal.slug === slug);
      if (goal) {
        setGoalToEdit(goal);
        setShowModal(true);
      }
    }
  }, [slug, goals]);

  const getViewMode = (): 'modal' | 'fullscreen' | 'sidePeek' => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    switch(mode) {
      case 'f': return 'fullscreen';
      case 's': return 'sidePeek';
      default: return 'modal';
    }
  };

  // Update view mode in URL
  const handleViewModeChange = (mode: 'modal' | 'fullscreen' | 'sidePeek') => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('mode', mode === 'fullscreen' ? 'f' : mode === 'sidePeek' ? 's' : 'c');
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleEditGoal = (goal: Goal) => {
    const searchParams = new URLSearchParams(location.search);
    // Preserve existing mode or default to 'modal'
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
    navigate(`/goals/${goal.slug}?${searchParams.toString()}`);
    setGoalToEdit(goal);
    setShowModal(true);
  };

  const handleAddNew = () => {
    setGoalToEdit(null);
    setShowModal(true);
  };

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Timeframe', value: 'timeframe' },
    { label: 'Progress', value: 'progress' },
    { label: 'Created Date', value: 'created_at' },
  ];

  const filterConfig: FilterConfig = useMemo(() => ({
    state: {
      label: 'State',
      value: 'state',
      type: 'select',
      options: [
        { label: 'Draft', value: 'DRAFT' },
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Achieved', value: 'ACHIEVED' },
        { label: 'Missed', value: 'MISSED' },
        { label: 'Canceled', value: 'CANCELED' }
      ],
    },
    timeframe: {
      label: 'Timeframe',
      value: 'timeframe',
      type: 'select',
      options: [
        { label: 'Annual', value: 'ANNUAL' },
        { label: 'Quarterly', value: 'QUARTERLY' }
      ],
    }
  }), []);

  const filterAndSortGoals = (goals: Goal[]): Goal[] => {
    let filtered = goals.filter(goal => 
      goal.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return goal[key as keyof Goal] === value;
      })
    );

    return filtered.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'title':
          result = a.title.localeCompare(b.title);
          break;
        case 'progress':
          result = a.progress - b.progress;
          break;
        case 'created_at':
          result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
          break;
        case 'timeframe':
          result = a.timeframe.localeCompare(b.timeframe) || a.year - b.year || 
            ((a.quarter || 0) - (b.quarter || 0));
          break;
        default:
          result = 0;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => {
          setShowBoardView(false);
          navigate('/goals/');
        }}
        variant={showBoardView ? 'secondary' : 'primary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => {
          setShowBoardView(true);
          navigate('/goals/board');
        }}
        variant={showBoardView ? 'primary' : 'secondary'}
        iconSize="md"
        title="Board View"
        iconColor="white"
      />
    </>
  );

  const handleSaveGoal = (
    goalData: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>, 
    onEdit: (slug: string, data: Partial<Goal>) => void,
    onCreate: (data: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void,
    slug?: string
  ) => {
    if (!goalData.title) return alert('Please provide a title');
    if (!goalData.owner) return alert('Please select an owner');
    
    if (slug) {
      onEdit(slug, goalData);
      setSnackbarMessage('Goal updated successfully');
    } else {
      onCreate(goalData);
      setSnackbarMessage('Goal created successfully');
    }
    setSnackbarType('success');
    setShowSnackbar(true);
    setShowModal(false);
    navigate('/goals');
  };

  return (
    <div>
      <h1 className="m-4">Goals</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={setSearchTerm}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Goals..."
        addButtonLabel="Add New Goal"
        sortOptions={sortOptions}
        onSortChange={setSortBy}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
        filterConfig={filterConfig}
        onFilterChange={setFilters}
        additionalButtons={additionalButtons}
      />

      <GoalContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading goals...</p>
            </div>
          );

          if (error) return <div>{error}</div>;

          if (goals.length === 0 && data.length > 0) {
            setGoals(data);
          }

          const filteredAndSortedGoals = filterAndSortGoals(data);

          return (
            <>
            {showBoardView ? (
              <GoalBoardView 
                goals={filteredAndSortedGoals}
                onOpenGoal={(goal) => {
                  setGoalToEdit(goal);
                  setShowModal(true);
                  navigate(`/goals/${goal.slug}`);
                }}
                onEdit={onEdit}
              />
            ) : (
              <GoalTable 
                data={filteredAndSortedGoals}
                onEdit={handleEditGoal}
                onDelete={(slug) => {
                  setGoalToDelete(slug);
                  setShowDeleteDialog(true);
                }}
                onUpdateState={(slug, newState) => onEdit(slug, { state: newState })}
                onUpdateProgress={(slug, newProgress) => onEdit(slug, { progress: newProgress })}
                onUpdateTags={(slug, newTags) => onEdit(slug, { tags: newTags })}
                onUpdateParticipants={(slug, newParticipants) => onEdit(slug, { participants: newParticipants })}
                onUpdateOwner={(slug, newOwner) => onEdit(slug, { owner: newOwner })}
                onUpdateTitle={(slug, newTitle) => onEdit(slug, { title: newTitle })}
                onUpdateTeam={(slug, newTeam) => onEdit(slug, { team: newTeam })}
                onUpdateProjects={(slug, newProjects) => onEdit(slug, { projects: newProjects })}
                onUpdateTopics={(slug, newTopics) => onEdit(slug, { topics: newTopics })}
              />
              )}

              {showModal && (
                <GoalModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    setGoalToEdit(null);
                    navigate(showBoardView ? '/goals/board' : '/goals');
                  }}
                  onSave={(data, slug) => handleSaveGoal(data, onEdit, onCreate, slug)}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  initialData={goalToEdit || undefined}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => {
                  if (goalToDelete) {
                    onDelete(goalToDelete);
                    setShowDeleteDialog(false);
                    setSnackbarMessage('Goal deleted successfully');
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }
                }}
              >
                <p>Are you sure you want to delete this goal?</p>
              </Modal>

              {showSnackbar && (
                <Snackbar
                  message={snackbarMessage}
                  type={snackbarType}
                  onClose={() => setShowSnackbar(false)}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default GoalListPage;