import { Icon } from '../../base/Icon'; // Adjust the path to your Icon.tsx

interface TableActionButtonsProps {
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const TableActionButtons: React.FC<TableActionButtonsProps> = ({ onView, onEdit, onDelete }) => {
  return (
    <div className="flex space-x-2">
      {onView && (
        <button
          onClick={onView}
          className="bg-neutral text-text p-2 rounded hover:bg-neutral-dark focus:outline-none"
          aria-label="View"
        >
          <Icon name="Eye" size="sm" color="text" />
        </button>
      )}
      {onEdit && (
        <button
          onClick={onEdit}
          className="bg-neutral text-text p-2 rounded hover:bg-neutral-dark focus:outline-none"
          aria-label="Edit"
        >
          <Icon name="Edit" size="sm" color="text" />
        </button>
      )}
      {onDelete && (
        <button
          onClick={onDelete}
          className="bg-neutral text-text p-2 rounded hover:bg-neutral-dark focus:outline-none"
          aria-label="Delete"
        >
          <Icon name="Trash" size="sm" color="text" />
        </button>
      )}
    </div>
  );
};
