import React, { useEffect, useState } from 'react';
import { useStateTransitions } from '../api/stateTransition/queries';
import { StateTransition } from '../api/stateTransition/types';

interface StateTransitionContainerProps {
  model: string;
  objectId: string;
  fetchOnMount?: boolean;  // Optional prop to control whether to fetch state transitions on mount
  render: (props: {
    data: StateTransition[];
    loading: boolean;
    error: string | null;
  }) => JSX.Element;
}

const StateTransitionContainer: React.FC<StateTransitionContainerProps> = ({ model, objectId, fetchOnMount = true, render }) => {
  const [stateTransitions, setStateTransitions] = useState<StateTransition[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);

  // Fetch state transitions using the useStateTransitions hook
  const { data, isLoading, isError } = useStateTransitions(model, objectId, (path: string) => {
    console.log(`Navigate to: ${path}`);
  });

  // Conditionally set data from the useStateTransitions hook
  useEffect(() => {
    if (fetchOnMount) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
        if (data) setStateTransitions(data);
      }

      if (isError) {
        setError('Failed to fetch state transitions');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Render the UI
  return render({
    data: stateTransitions,
    loading,
    error,
  });
};

export default StateTransitionContainer;
