import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { MentionList } from './MentionList';
import { MentionListRef } from './MentionList';

export interface SuggestionItem {
  id: string;
  name: string;
  type: 'person' | 'tag';
}

const suggestion = (items: SuggestionItem[]) => {
  return {
    char: '@',
    items: ({ query }: { query: string }) => {
      const cleanedQuery = query.trim().toLowerCase();

      // Filter items by the query (both persons and tags)
      const filteredItems = items
        .filter(item => item.name.toLowerCase().startsWith(cleanedQuery))
        .map(item => ({ ...item, label: item.name })) // Ensure `label` is set correctly
        .slice(0, 10);

      return filteredItems;
    },
    render: () => {
      let component: ReactRenderer<MentionListRef>;
      let popup: any;

      return {
        onStart: (props: any) => {
          if (!props.clientRect) return;

          component = new ReactRenderer(MentionList, {
            props: { ...props, items: props.items },
            editor: props.editor,
          });

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },
        onUpdate(props: any) {
          component.updateProps({ ...props, items: props.items });

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },
        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();
            return true;
          }

          return component.ref?.onKeyDown(props) ?? false;
        },
        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
};


export default suggestion;
