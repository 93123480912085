import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { UserProvider, useUser } from './contexts/UserContext'; 
import { Analytics } from '@vercel/analytics/react';
import CommandKModal from './components/CommandKModal';
import { TaskModal } from './components/task/TaskModal';
import TaskContainer from './containers/TaskContainer';
import Login from './components/auth/Login';
import Profile from './components/Profile';  
import TagsListPage from './pages/TagsListPage';
import PersonListPage from './pages/PersonListPage';
import PersonMindMap from './components/person/PersonMindMap';
import TopicListPage from './pages/TopicListPage';
import TaskListPage from './pages/TaskListPage';
import ProjectListPage from './pages/ProjectListPage';
import MeetingTypeListPage from './pages/MeetingTypeListPage';
import MeetingOccurrenceListPage from './pages/MeetingOccurrenceListPage';
import SidebarMenu from './components/ui/navigation/SidebarMenu';
import SnapshotListPage from './pages/SnapshotListPage';
import CreateSnapshotPage from './components/snapshot/CreateSnapshotPage';
import SnapshotDetailPage from './components/snapshot/SnapshotDetailPage';
import TeamListPage from './pages/TeamListPage';
import PersonImportPage from './components/person/PersonImportPage';
import StrategyListPage from './pages/StrategyListPage';
import GoalListPage from './pages/GoalListPage';
import { CookieBanner } from './components/CookieBanner';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import IntegrationListPage from './pages/IntegrationListPage';
import ProjectImportPage from './components/integration/ProjectImportPage';
import { SidePanesContainer } from './components/ui/navigation/SidePanesContainer';
import NetworkPage from './pages/NetworkPage';
import { TagNetworkViewWrapper } from './components/tags/TagNetworkView';
import MainLayout from './components/layout/MainLayout';



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const GA_MEASUREMENT_ID = 'G-ZZDM7J56RK';

const AppContent: React.FC = () => {
  const [isCommandKModalOpen, setIsCommandKModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    // Send pageview with each route change
    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setIsCommandKModalOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Set page title
  useEffect(() => {
    // Map routes to page titles
    const getTitleFromPath = (pathname: string): string => {
      // Handle nested routes with slugs
      const basePath = pathname.split('/')[1];
      
      const titles: { [key: string]: string } = {
        'tasks': 'Tasks',
        'persons': 'People',
        'teams': 'Teams',
        'topics': 'Topics',
        'projects': 'Projects',
        'strategies': 'Strategies',
        'goals': 'Goals',
        'meeting-types': 'Meeting Types',
        'meeting-occurrences': 'Meetings',
        'snapshots': 'Snapshots',
        'profile': 'Profile',
        'tags': 'Tags',
        'login': 'Login'
      };

      return titles[basePath] || 'Dashboard';
    };

    const pageTitle = getTitleFromPath(location.pathname);
    document.title = `${pageTitle} | Align`;
  }, [location]);

  const handleCreateTask = () => {
    setIsTaskModalOpen(true);
    setIsCommandKModalOpen(false);
  };

  const handleTaskModalClose = () => {
    setIsTaskModalOpen(false);
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex h-screen bg-gray-800">
      {/* Left Sidebar */}
      <SidebarMenu />
      
      {/* Main Content Area */}

      <MainLayout>
      <div className="flex-1 pt-3 pb-3 pl-3 pr-3 overflow-hidden">
        <div className="bg-white rounded-lg shadow-lg h-full overflow-auto">
          <div className="p-4">
            <CommandKModal 
              isOpen={isCommandKModalOpen} 
              onClose={() => setIsCommandKModalOpen(false)}
              onCreateTask={handleCreateTask}
            />
            <TaskContainer
              render={() => (
                <>
                  {isTaskModalOpen && (
                    <TaskModal
                      isOpen={isTaskModalOpen}
                      onClose={handleTaskModalClose}
                      onSuccess={(message) => {
                        console.log('Success:', message);
                        queryClient.invalidateQueries(['tasks']);
                        handleTaskModalClose();
                      }}
                      onError={(message) => console.error('Error:', message)}
                    />
                  )}
                </>
              )}
            />
            <Routes>
              <Route path="/profile" element={<Profile />} />
              <Route path="/tags" element={<TagsListPage />} />
              <Route path="/tags/:id/network" element={<TagNetworkViewWrapper />} />
              <Route path="/persons" element={<PersonListPage />} />
              <Route path="/persons/:slug" element={<PersonListPage />} />
              <Route path="/persons/:slug/mindmap" element={<PersonMindMap />} />
              <Route path="/persons/import" element={<PersonImportPage />} />
              <Route path="/teams" element={<TeamListPage />} />
              <Route path="/teams/:slug" element={<TeamListPage />} />
              <Route path="/topics" element={<TopicListPage />} />
              <Route path="/topics/all" element={<TopicListPage />} />
              <Route path="/topics/active" element={<TopicListPage />} />
              <Route path="/topics/backlog" element={<TopicListPage />} />
              <Route path="/topics/completed" element={<TopicListPage />} />
              <Route path="/topics/:slug" element={<TopicListPage />} />
              <Route path="/tasks" element={<TaskListPage />} />
              <Route path="/tasks/all" element={<TaskListPage />} />
              <Route path="/tasks/active" element={<TaskListPage />} />
              <Route path="/tasks/overdue" element={<TaskListPage />} />
              <Route path="/tasks/assigned" element={<TaskListPage />} />
              <Route path="/tasks/:slug" element={<TaskListPage />} />
              <Route path="/projects" element={<ProjectListPage />} />
              <Route path="/projects/all" element={<ProjectListPage />} />
              <Route path="/projects/active" element={<ProjectListPage />} />
              <Route path="/projects/completed" element={<ProjectListPage />} />
              <Route path="/projects/:slug" element={<ProjectListPage />} />
              <Route path="/strategies" element={<StrategyListPage />} />
              <Route path="/strategies/:slug" element={<StrategyListPage />} />
              <Route path="/goals" element={<GoalListPage />} />
              <Route path="/goals/:slug" element={<GoalListPage />} />
              <Route path="/meeting-types" element={<MeetingTypeListPage />} />
              <Route path="/meeting-types/:slug" element={<MeetingTypeListPage />} />
              <Route path="/meeting-occurrences" element={<MeetingOccurrenceListPage />} />
              <Route path="/meeting-occurrences/:slug" element={<MeetingOccurrenceListPage />} />
              <Route path="/tasks/kanban" element={<TaskListPage />} />
              
              {/* Add Snapshot pages here */}
              <Route path="/snapshots" element={<SnapshotListPage />} />
              <Route path="/snapshots/create" element={<CreateSnapshotPage />} />
              <Route path="/snapshots/:slug" element={<SnapshotDetailPage />} />

              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/integrations/list" element={<IntegrationListPage />} />
              <Route path="/integrations/projects/import" element={<ProjectImportPage />} />
              <Route path="/network" element={<NetworkPage />} />
              <Route path="/" element={<Navigate to="/tasks" replace />} />
              <Route path="*" element={<Navigate to="/tasks" replace />} />
            </Routes>
            <Analytics />
            <CookieBanner />
          </div>
        </div>
      </div>
      </MainLayout>
      {/* Right Calendar Pane */}
      <div className="pt-3 pr-3 pb-3">
        <div className="h-full">
          <SidePanesContainer />
        </div>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <AppContent />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </QueryClientProvider> 
  );
};

declare global {
  interface Window {
    gtag: (command: string, ...args: any[]) => void;
    dataLayer: any[];
  }
}

export default App;
