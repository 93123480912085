import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/';

// Login function
export const login = async (username: string, password: string) => {
    const response = await axios.post(`${API_URL}token/`, { username, password });
  
    // Log the response to verify
    console.log('Login response:', response.data);
  
    if (response.data.access) {
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
    }
    
    // Return the tokens and IDs
    return {
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
      personId: response.data.person_id,  // Note that it's person_id in the response
      companyId: response.data.company_id // company_id as well
    };
  };
  
// Refresh the access token
export const refreshToken = async () => {
  const refresh = localStorage.getItem('refreshToken');
  const response = await axios.post(`${API_URL}token/refresh/`, { refresh });
  if (response.data.access) {
    localStorage.setItem('accessToken', response.data.access);  // Store the refreshed access token
    return {
      accessToken: response.data.access,
      refreshToken: response.data.refresh || refresh,  // Keep refresh token or use the new one if provided
    };
  } else {
    throw new Error('Token refresh failed');
  }
};

// Logout function
export const logout = async () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  await axios.post(`${API_URL}logout/`);
  window.location.href = '/login';  // Redirect to login on logout
};

// Axios interceptor to refresh token on 401 error
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Prevent infinite retry loops
      try {
        const { accessToken } = await refreshToken();  // Attempt to refresh the access token
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios(originalRequest);  // Retry the original request
      } catch (refreshError) {
        logout();  // If refreshing fails, log the user out
      }
    }
    return Promise.reject(error);
  }
);
