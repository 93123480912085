import React, { useState, useEffect } from 'react';
import { Accordion } from './Accordion';

export interface AccordionItem {
  id: string;
  title: string;
  content: string;
  descriptionPlaceholder?: string;
}

interface AccordionListProps {
  items: AccordionItem[];
  allowMultipleOpen?: boolean;
  onUpdateItemContent: (id: string, content: string) => void;
  onAddItem: () => void;
  onRemoveItem: (id: string) => void;
}

export const AccordionList: React.FC<AccordionListProps> = ({
  items,
  allowMultipleOpen = true,
  onUpdateItemContent,
  onAddItem,
  onRemoveItem,
}) => {
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);

  const handleAccordionChange = (id: string, isOpen: boolean) => {
    if (allowMultipleOpen) {
      setOpenAccordions((prev) =>
        isOpen ? [...prev, id] : prev.filter((accordionId) => accordionId !== id)
      );
    } else {
      setOpenAccordions(isOpen ? [id] : []);
    }
  };

  return (
    <div className='mt-6'>
      {items.length === 0 ? (
        <p className='text-sm'>No items added yet...</p>
      ) : (
        items.map((item) => (
          <Accordion
            key={item.id}
            title={item.title}
            content={item.content}
            defaultOpen={openAccordions.includes(item.id)}
            onChange={(isOpen) => handleAccordionChange(item.id, isOpen)}
            onRemove={() => onRemoveItem(item.id)}
            descriptionPlaceholder={item.descriptionPlaceholder}
            onContentChange={(content) => onUpdateItemContent(item.id, content)}
          />
        ))
      )}
    </div>
  );
};