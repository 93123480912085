import React, { useCallback } from 'react';
import { MarkDownEditor } from './MarkDownEditor';
import Mention from '@tiptap/extension-mention';
import PersonContainer from '../../../containers/PersonContainer';
import TagContainer from '../../../containers/TagContainer';
import { Person } from '../../../api/person/types';
import { Tag } from '../../../api/tag/types';
import { SuggestionItem } from '../utilities/suggestion';
import { Extension } from '@tiptap/core';

interface MarkDownEditorActivityFeedProps {
  initialContent?: string;
  placeholder?: string;
  onChange?: (content: string) => void;
  parentObjectType?: 'topic' | 'project';
  parentObjectId?: string;
}

const MarkDownEditorActivityFeed: React.FC<MarkDownEditorActivityFeedProps & { persons: Person[]; tags: Tag[] }> = ({
  initialContent = '',
  placeholder = 'Start typing...',
  onChange,
  parentObjectType,
  parentObjectId,
  persons,
  tags,
}) => {
  const suggestions = useCallback(() => ({
    items: ({ query }: { query: string }) => {
      const allItems: SuggestionItem[] = [
        ...persons.map(person => ({
          id: person.id,
          name: person.name,
          type: 'person' as const,
        })),
        ...tags.map(tag => ({
          id: tag.id,
          name: tag.name,
          type: 'tag' as const,
        })),
      ];
      return allItems.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
    },
    render: () => {
      // You can implement a custom render function here if needed
      // For now, we'll use the default rendering
      return {};
    },
  }), [persons, tags]);

  return (
    <MarkDownEditor
      initialContent={initialContent}
      placeholder={placeholder}
      onChange={onChange}
      parentObjectType={parentObjectType}
      parentObjectId={parentObjectId}
    />
  );
};

// Higher-order component to wrap MarkDownEditorActivityFeed with PersonContainer and TagContainer
const MarkDownEditorActivityFeedAndData: React.FC<MarkDownEditorActivityFeedProps> = (props) => {
  return (
    <PersonContainer
      render={({ data: persons, loading: personsLoading, error: personsError }) => (
        <TagContainer
          render={({ data: tags, loading: tagsLoading, error: tagsError }) => {
            if (personsLoading || tagsLoading) {
              return <div>Loading...</div>;
            }
            if (personsError || tagsError) {
              return <div>Error loading data</div>;
            }
            return <MarkDownEditorActivityFeed {...props} persons={persons} tags={tags} />;
          }}
        />
      )}
    />
  );
};

export { MarkDownEditorActivityFeedAndData as MarkDownEditorActivityFeed };