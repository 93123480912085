import React from 'react';
import { Button, Snackbar } from '../ui';
import { useSnackbar } from '../../utils/useSnackbar';

interface Generate515ReportProps {
  snapshotProjects: any[]; // Replace with your SnapshotProject type
  periodStart: string;
  periodEnd: string;
}

const generate515Content = (projects: any[], periodStart: string, periodEnd: string) => {
  // Group all log entries by type
  const accomplishments: string[] = [];
  const challenges: string[] = [];
  const priorities: string[] = [];

  projects.forEach(project => {
    // Add project state changes and major updates
    if (project.summary_data.state_changes > 0) {
      accomplishments.push(`${project.project.title}: Moved to ${project.state}`);
    }

    // Process log entries
    project.log_entries.forEach((entry: any) => {
      const prefix = `${project.project.title}: `;
      
      switch (entry.log_type.toLowerCase()) {
        case 'accomplishment':
        case 'milestone':
        case 'completion':
          accomplishments.push(prefix + entry.content);
          break;
        case 'blocker':
        case 'issue':
        case 'risk':
          challenges.push(prefix + entry.content);
          break;
        case 'next_steps':
        case 'planned':
          priorities.push(prefix + entry.content);
          break;
      }
    });

    // Add high priority items
    if (project.priority >= 3) {
      priorities.push(`${project.project.title}: High priority work (P${project.priority})`);
    }
  });

  return `# 5:15 Report (${new Date(periodStart).toLocaleDateString()} - ${new Date(periodEnd).toLocaleDateString()})

## Key Accomplishments 🎯
${accomplishments.map(a => `- ${a}`).join('\n')}

## Challenges & Blockers ⚠️
${challenges.map(c => `- ${c}`).join('\n')}

## Priorities & Next Steps 📋
${priorities.map(p => `- ${p}`).join('\n')}

## Project Health Summary 📊
${projects.map(project => `- ${project.project.title}: ${project.state.toUpperCase()} (Priority: ${project.priority}, Progress: ${project.progress}%)`).join('\n')}
`;
};

export const Generate515Report: React.FC<Generate515ReportProps> = ({
    snapshotProjects,
    periodStart,
    periodEnd,
  }) => {
    const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  
    const handle515Generation = () => {
      const reportContent = generate515Content(snapshotProjects, periodStart, periodEnd);
      
      navigator.clipboard.writeText(reportContent).then(
        () => {
          showSnackbar('5:15 Report copied to clipboard!', 'success');
        },
        (err) => {
          showSnackbar('Failed to copy report to clipboard', 'error');
          console.error('Failed to copy report:', err);
        }
      );
    };
  
    return (
      <>
        <Button
          onClick={handle515Generation}
          variant="primary"
          buttonStyle="outlined"
          label="Generate 5:15 Report"
          icon="FileText"
        />
        {snackbar.visible && (
          <Snackbar
            message={snackbar.message}
            type={snackbar.type}
            onClose={hideSnackbar}
          />
        )}
      </>
    );
};

export default Generate515Report;