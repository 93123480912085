import React from 'react';
import { Table, TableActionButtons, TablePersonsComponent, TableCountComponent, TableBadgeComponent } from '../ui';
import { MeetingOccurrence } from '../../api/meeting/types';

interface MeetingOccurrenceTableProps {
  data: MeetingOccurrence[];
  onEdit: (updatedOccurrence: MeetingOccurrence) => void;
  onDelete: (slug: string) => void;
}

const MeetingOccurrenceTable: React.FC<MeetingOccurrenceTableProps> = ({ data, onEdit, onDelete }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}  // Trigger the onEdit function when clicking the title
              className="cursor-pointer hover:underline"
            >
              {row.title}
            </div>
          ),
        },
        {
          header: 'Date',
          accessor: 'date',
        },
        {
          header: 'Meeting Type',
          accessor: 'meeting_type',
          component: (row) => (
            <TableBadgeComponent
              label={row.meeting_type?.name ?? 'Ad Hoc'}
              type="state"
            />
          ),
        },
        {
          header: 'Attendees',
          component: (row) => (
            <TablePersonsComponent
              persons={row.attendees || []}
              isEditable={false}
              onUpdate={() => {}}
              placeholder='No attendees'
            />
          ),
        },
        {
          header: 'Topics',
          accessor: 'topic_notes',
          component: (row) => <TableCountComponent count={row.topic_notes?.length || 0} noItemsPlaceholder='No topics' />,
        },
        {
          header: 'Tasks',
          accessor: 'tasks',
          component: (row) => <TableCountComponent count={row.tasks?.length || 0} noItemsPlaceholder='No tasks'/>,
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};

export default MeetingOccurrenceTable;
