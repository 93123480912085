import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Card from '../Card';
import { CardContent } from '../Card';
import { Button } from '../Button';

interface Badge {
  label: string;
  icon?: React.ReactNode;
}

interface Item {
  id: string;
  title: string;
  badges: Badge[];
}

interface Column {
  id: string;
  title: string;
  items: Item[];
  isBacklog?: boolean;
  isCollapsible?: boolean;
  style?: string;
}

interface BoardProps {
  columns: Column[];
  minHeight?: string;
  allowAddColumns?: boolean;
  allowRemoveColumns?: boolean;
  allowAddItems?: boolean;
  backlogStyle?: string;
  columnStyle?: string;
  onOpenItem?: (item: { id: string }) => void;
  onMoveItem?: (result: DropResult) => void;
}

const BoardItem = ({
  item,
  onOpen,
  index,
}: {
  item: Item;
  onOpen: (item: Item) => void;
  index: number;
}) => {
  const badgeTypeStyles: { [key: string]: string } = {
    Identified: "bg-blue-100 text-blue-800",
    "In Progress": "bg-yellow-100 text-yellow-800",
    Ongoing: "bg-green-100 text-green-800",
    Completed: "bg-gray-100 text-gray-800",
    Archived: "bg-purple-100 text-purple-800",
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.8 : 1,
          }}
          className="min-h-fit"
        >
          <Card className="group relative bg-white hover:bg-gray-100 transition-colors duration-200 !p-0 h-full">
            <CardContent className="!px-3 !py-3">
              <div className="flex flex-col gap-2">
                <div className="flex items-start justify-between gap-1">
                  <h4
                    className="flex-1 text-xs font-medium text-text leading-tight break-words whitespace-normal"
                    onClick={() => onOpen(item)}
                  >
                    {item.title}
                  </h4>
                  <Button
                    icon="Edit2"
                    buttonStyle="ghost"
                    size="small"
                    iconSize="sm"
                    rounded
                    onClick={() => onOpen(item)}
                    className="opacity-0 group-hover:opacity-100 -my-1 -mr-1 ml-1 flex-shrink-0"
                  />
                </div>
                <div className="flex items-center justify-between text-xxs">
                  <div className="flex flex-wrap gap-1">
                    {item.badges.map((badge, idx) => (
                      <span
                        key={idx}
                        className={`px-1.5 py-0.5 rounded-full font-medium flex items-center ${
                          badgeTypeStyles[badge.label] || "bg-gray-200 text-gray-800"
                        }`}
                      >
                        {badge.icon && <span className="mr-1">{badge.icon}</span>}
                        <span>{badge.label}</span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

const BoardColumn = ({
  column,
  style,
  minHeight,
  allowAddItems = false,
  onOpenItem,
}: {
  column: Column;
  style: string;
  minHeight: string;
  allowAddItems?: boolean;
  onOpenItem?: (item: Item) => void;
}) => {
  // Default `isCollapsed` to true only if `isCollapsible` is true
  const [isCollapsed, setIsCollapsed] = useState(() => column.isCollapsible === true);

  return (
    <Droppable droppableId={column.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`flex-shrink-0 ${isCollapsed ? 'w-[180px]' : 'basis-[180px]'} rounded-md ${style} ${
            snapshot.isDraggingOver ? 'ring-2 ring-primary/50' : ''
          }`}
          style={{
            minHeight: isCollapsed ? 'auto' : minHeight, // Adjust the minHeight based on collapse state
            maxHeight: isCollapsed ? '40px' : 'none', // Limit height when collapsed
          }}
        >
          <div className="p-2">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center justify-between flex-1 mr-2">
                <h3 className="font-semibold text-sm text-text">{column.title}</h3>
                <span className="text-xxs px-1.5 py-0.5 rounded-full bg-text/5 text-text/60">
                  {column.items.length}
                </span>
              </div>
              {column.isCollapsible && (
                <Button
                  icon={isCollapsed ? "ChevronDown" : "ChevronUp"}
                  buttonStyle="ghost"
                  size="small"
                  iconSize="sm"
                  rounded
                  className="ml-2"
                  onClick={() => setIsCollapsed(!isCollapsed)} // Toggle collapse
                />
              )}
            </div>

            {/* Show items only if not collapsed */}
            {!isCollapsed && (
              <div className="space-y-2">
                {column.items.map((item, index) => (
                  <BoardItem key={item.id} item={item} index={index} onOpen={(item) => onOpenItem?.(item)} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

const defaultStyles = {
  backlog: 'bg-secondary/10 border-t-4 border-t-secondary/30',
  column: 'bg-primary/10 border-t-4 border-t-primary/30',
};

export const Board: React.FC<BoardProps> = ({
  columns,
  minHeight = '400px',
  allowAddColumns = false,
  allowRemoveColumns = false,
  allowAddItems = false,
  backlogStyle = defaultStyles.backlog,
  columnStyle = defaultStyles.column,
  onOpenItem,
  onMoveItem,
}) => {
  const handleDragEnd = (result: DropResult) => {
    if (onMoveItem) {
      onMoveItem(result);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="h-full p-4 font-sans overflow-x-auto">
        <div className="grid auto-cols-[200px] grid-flow-col gap-3 pb-4">
          {columns.map((column) => (
            <BoardColumn
              key={column.id}
              column={column}
              style={column.style || (column.isBacklog ? backlogStyle : columnStyle)} // Apply column-specific style if provided
              minHeight={minHeight}
              allowAddItems={allowAddItems}
              onOpenItem={onOpenItem}
            />
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};

export default Board;
