import React, { useState, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Board from '../ui/data/Board';
import { useTopics } from '../../api/topic/queries'; 
import { Topic } from '../../api/topic/types';
import { getImportanceLabelAndIcon, getHeatmapStatusLabelAndIcon, topicStateOptions } from '../../utils/entitiesOptions';

interface TopicBoardViewProps {
  onOpenTopic: (topic: Topic) => void; // Opens TopicModal with selected topic
  onEdit: (slug: string, updatedTopic: Partial<Topic>) => void;
}

interface Badge {
  label: string;
  style?: string;
  icon?: React.ReactNode;
}

interface BoardItem {
  id: string; // Use slug as id
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
}

export const TopicBoardView: React.FC<TopicBoardViewProps> = ({
  onOpenTopic,
  onEdit,
}) => {
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data: topics, isLoading, error } = useTopics();

  const mapTopicToBoardItem = useCallback(
    (topic: Topic): BoardItem => {
      const heatmapStatus = getHeatmapStatusLabelAndIcon(topic.heatmap_status);
      const importance = getImportanceLabelAndIcon(topic.importance.toString());

      return {
        id: topic.slug,
        title: topic.title,
        badges: [
          { label: heatmapStatus.label, icon: heatmapStatus.icon },
          { label: importance.label, icon: importance.icon },
        ],
      };
    },
    []
  );

  const updateColumns = useCallback((topics: Topic[]) => {
    const newColumns = topicStateOptions.map(stateOption => ({
      id: stateOption.value,
      title: stateOption.label,
      isCollapsible: (stateOption.value === 'ARCHIVED'), // Collapsible option for archived state
      items: topics
        .filter(topic => topic.state === stateOption.value)
        .map(mapTopicToBoardItem),
      // Apply pastel green style to both "COMPLETED" and "ARCHIVED"
      style: (stateOption.value === 'COMPLETED' || stateOption.value === 'ARCHIVED')
        ? 'bg-green-50 border-t-4 border-green-200' // Pastel green for both "COMPLETED" and "ARCHIVED"
        : '', // Default empty style for other columns
    }));
    setColumns(newColumns);
  }, [mapTopicToBoardItem]);
  

  useEffect(() => {
    if (!isUpdating && topics) {
      updateColumns(topics);
    }
  }, [topics, isUpdating, updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const topic = topics?.find((t) => t.slug === item.id);
      if (topic) {
        onOpenTopic(topic); // Opens the modal with selected topic
      }
    },
    [topics, onOpenTopic]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId: slug } = result;
  
      if (!destination || destination.droppableId === source.droppableId) return;
  
      const newState = destination.droppableId as Topic['state'];
      setIsUpdating(true);
  
      try {
        await onEdit(slug, { state: newState });
  
        setColumns(prevColumns => {
          const sourceColumn = prevColumns.find(col => col.id === source.droppableId);
          const destColumn = prevColumns.find(col => col.id === destination.droppableId);
  
          if (sourceColumn && destColumn) {
            const item = sourceColumn.items.find(i => i.id === slug);
            if (item) {
              sourceColumn.items = sourceColumn.items.filter(i => i.id !== slug);
              destColumn.items.splice(destination.index, 0, item);
            }
          }
          return [...prevColumns];
        });
      } catch (error) {
        console.error('Failed to update topic:', error);
      } finally {
        setIsUpdating(false);
      }
    },
    [onEdit]
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) {
    const errorMessage = (error as Error).message || "An error occurred";
    return <p>Error: {errorMessage}</p>;
  }

  return (
    <Board
      columns={columns}
      minHeight="calc(100vh - 300px)"
      allowAddItems={false}
      onOpenItem={handleOpenItem} // Opens TopicModal on item click
      onMoveItem={handleMoveItem}
    />
  );
};

export default TopicBoardView;
