import { useState, useEffect } from 'react';
import { useTopics } from '../topic/queries';
import { useTasks } from '../task/queries';
import { useProjects } from '../project/queries';
import { usePersons } from '../person/queries';
import { useTeams } from '../team/queries';
import { useGoals } from '../goal/queries';
import { useMeetingOccurrences } from '../meeting/queries';
import { useMeetingTypes } from '../meeting/queries';
import { useStrategies } from '../strategy/queries';

interface SearchResult {
  id: string;
  title: string;
  type: 'topic' | 'task' | 'project' | 'person' | 'tag' | 'meeting' | 'meeting type' | 'team' | 'goal' | 'strategy';
  path: string;
}

export const useGlobalSearch = () => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { data: topics = [] } = useTopics();
  const { data: tasks = [] } = useTasks({});
  const { data: projects = [] } = useProjects();
  const { data: persons = [] } = usePersons();
  const { data: teams = [] } = useTeams();  // Fetch teams data
  const { data: meetings = [] } = useMeetingOccurrences();
  const { data: meetingTypes = [] } = useMeetingTypes();
  const { data: goals = [] } = useGoals();
  const { data: strategies = [] } = useStrategies();
  useEffect(() => {
    if (topics.length && tasks.length && projects.length && persons.length && teams.length) {
      setIsLoading(false);
    }
  }, [topics, tasks, projects, persons, teams]);

  const search = (term: string) => {
    const lowerTerm = term.toLowerCase();
    
    const results: SearchResult[] = [
      ...topics.filter(t => t.title.toLowerCase().includes(lowerTerm))
        .map(t => ({ id: t.slug, title: t.title, type: 'topic' as const, path: `/topics/${t.slug}` })),
      ...tasks.filter(t => t.title.toLowerCase().includes(lowerTerm))
        .map(t => ({ id: t.slug, title: t.title, type: 'task' as const, path: `/tasks/${t.slug}` })),
      ...projects.filter(p => p.title.toLowerCase().includes(lowerTerm))
        .map(p => ({ id: p.slug, title: p.title, type: 'project' as const, path: `/projects/${p.slug}` })),
      ...persons.filter(p => p.name.toLowerCase().includes(lowerTerm))
        .map(p => ({ id: p.id, title: p.name, type: 'person' as const, path: `/persons/${p.slug}` })),
      ...teams.filter(t => t.name.toLowerCase().includes(lowerTerm))  // Include teams in search results
        .map(t => ({ id: t.slug, title: t.name, type: 'team' as const, path: `/teams/${t.slug}` })),
      ...meetings.filter(m => m.title.toLowerCase().includes(lowerTerm))
        .map(m => ({ id: m.slug, title: m.title, type: 'meeting' as const, path: `/meeting-occurrences/${m.slug}` })),
      ...meetingTypes.filter(m => m.name.toLowerCase().includes(lowerTerm))
        .map(m => ({ id: m.slug, title: m.name, type: 'meeting type' as const, path: `/meeting-types/${m.slug}` })),
      ...goals.filter(g => g.title.toLowerCase().includes(lowerTerm))
        .map(g => ({ id: g.slug, title: g.title, type: 'goal' as const, path: `/goals/${g.slug}` })),
      ...strategies.filter(s => s.title.toLowerCase().includes(lowerTerm))
        .map(s => ({ id: s.slug, title: s.title, type: 'strategy' as const, path: `/strategies/${s.slug}` })),
    ];

    setSearchResults(results);
  };

  return { search, searchResults, isLoading };
};
