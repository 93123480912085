// src/pages/PrivacyPolicyPage.tsx
import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-lg font-bold mb-5">Privacy Policy</h1>
      
      <div className="text-sm">
        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">1. Introduction</h2>
          <p className="mb-3">
            This Privacy Policy explains how Align ("we", "our", "us") processes your personal data. We are committed to protecting your privacy and handling your data in an open and transparent manner.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">2. Legal Basis for Processing</h2>
          <p className="mb-2">We process your personal data on the following legal bases:</p>
          <ul className="list-disc ml-5 mb-3">
            <li>Contract performance: To provide our service to you</li>
            <li>Legal obligation: To comply with legal requirements</li>
            <li>Legitimate interests: To improve our services</li>
            <li>Consent: For non-essential cookies and analytics</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">3. Personal Data We Process</h2>
          <div className="mb-3">
            <h3 className="font-medium mb-2">3.1 Data you provide:</h3>
            <ul className="list-disc ml-5 mb-3">
              <li>Account information (name, email)</li>
              <li>Task and project data</li>
              <li>Team member information</li>
            </ul>
          </div>
          <div className="mb-3">
            <h3 className="font-medium mb-2">3.2 Automatically collected data:</h3>
            <ul className="list-disc ml-5 mb-3">
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Usage data via Google Analytics (with your consent)</li>
            </ul>
          </div>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">4. Data Retention</h2>
          <p className="mb-3">
            We keep your personal data for as long as necessary to provide our services and comply with legal obligations. When data is no longer needed, it is securely deleted.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">5. Your Rights</h2>
          <p className="mb-2">Under GDPR, you have the following rights:</p>
          <ul className="list-disc ml-5 mb-3">
            <li>Right to access your data</li>
            <li>Right to rectification</li>
            <li>Right to erasure ('right to be forgotten')</li>
            <li>Right to restrict processing</li>
            <li>Right to data portability</li>
            <li>Right to object</li>
            <li>Right to withdraw consent</li>
          </ul>
          <p className="mb-3">
            To exercise these rights, contact us at [Your Email]. We'll respond within 30 days.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">6. International Data Transfers</h2>
          <p className="mb-3">
            We use Google Analytics, which may process data outside the EEA. This transfer is protected by appropriate safeguards including Standard Contractual Clauses.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">7. Cookies</h2>
          <p className="mb-2">We use the following types of cookies:</p>
          <ul className="list-disc ml-5 mb-3">
            <li>Essential cookies: Required for the application to function</li>
            <li>Analytics cookies (optional): Google Analytics tracking (only with your consent)</li>
          </ul>
          <p className="mb-3">
            You can manage cookie preferences through our cookie banner or browser settings.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-base font-semibold mb-3">8. Complaints</h2>
          <p className="mb-3">
            You have the right to lodge a complaint with your local data protection authority. For Portugal, contact the Comissão Nacional de Proteção de Dados (CNPD).
          </p>
        </section>

        <footer className="text-xs text-gray-600 mt-8 mb-6">
          Last updated: December 2024<br />
          If you have questions about this Privacy Policy, contact us at [Your Email]
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;