import React from 'react';
import { Button } from './Button';
import { TextInput } from './TextInput';

interface EditableProgressBarProps {
  value: number;
  onChange: (value: number) => void;
  label?: string;
}

const EditableProgressBar = ({ 
  value = 0, 
  onChange,
  label = "Progress" 
}: EditableProgressBarProps) => {
  const handleIncrement = () => {
    const newValue = Math.min(value + 5, 100);
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = Math.max(value - 5, 0);
    onChange(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Math.min(Math.max(Number(e.target.value) || 0, 0), 100);
    onChange(newValue);
  };

  return (
    <div className="w-80">
      <div className="text-sm font-medium text-text mb-2">{label}</div>
      <div className="space-y-4">
        <div className="h-2 w-full bg-neutral rounded-full overflow-hidden">
          <div 
            className="h-full bg-primary transition-all duration-200"
            style={{ width: `${value}%` }}
          />
        </div>
        
        <div className="flex items-center justify-between gap-2">
          <Button
            icon="Minus"
            variant="secondary"
            buttonStyle="ghost"
            rounded
            onClick={handleDecrement}
            disabled={value <= 0}
          />
          
          <div className="w-20">
            <TextInput
              value={value}
              onChange={handleInputChange}
              type="number"
              min={0}
              max={100}
              inputSize="medium"
              className="text-center"
            />
          </div>
          
          <Button
            icon="Plus"
            variant="secondary"
            buttonStyle="ghost"
            rounded
            onClick={handleIncrement}
            disabled={value >= 100}
          />
        </div>
      </div>
    </div>
  );
};

export default EditableProgressBar;