// src/containers/IntegrationContainer.tsx
import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Integration } from '../api/integration/types';
import {
  fetchIntegrations,
  fetchProviders,
  verifyIntegration,
  deleteIntegration,
  createIntegration,
} from '../api/integration/api';

interface IntegrationContainerProps {
  children: (props: {
    integrations: Integration[];
    providers: any[];
    loading: boolean;
    error: string | null;
    onVerifyIntegration: (integration: Integration) => Promise<void>;
    onDeleteIntegration: (integration: Integration) => Promise<void>;
    onCreateIntegration: (data: any) => Promise<Integration>;
    refreshIntegrations: () => Promise<void>;
  }) => ReactElement; // Ensure `children` returns a ReactElement
}

const IntegrationContainer = ({ children }: IntegrationContainerProps): ReactElement | null => {
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [providers, setProviders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const [integrationsData, providersData] = await Promise.all([
        fetchIntegrations({}, navigate),
        fetchProviders(navigate),
      ]);
      setIntegrations(integrationsData);
      setProviders(providersData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleVerifyIntegration = async (integration: Integration) => {
    try {
      const result = await verifyIntegration(integration.id, navigate);
      if (result.is_valid) {
        await loadData();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to verify integration');
      throw err;
    }
  };

  const handleDeleteIntegration = async (integration: Integration) => {
    try {
      await deleteIntegration(integration.id, navigate);
      setIntegrations((prev) => prev.filter((i) => i.id !== integration.id));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete integration');
      throw err;
    }
  };

  const handleCreateIntegration = async (data: any) => {
    try {
      const newIntegration = await createIntegration(data, navigate);
      await loadData();
      return newIntegration;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create integration');
      throw err;
    }
  };

  return children({
    integrations,
    providers,
    loading,
    error,
    onVerifyIntegration: handleVerifyIntegration,
    onDeleteIntegration: handleDeleteIntegration,
    onCreateIntegration: handleCreateIntegration,
    refreshIntegrations: loadData,
  });
};

export default IntegrationContainer;
