import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import TeamContainer from '../../containers/TeamContainer';
import { Team } from '../../api/team/types';

type SelectSize = 'small' | 'medium' | 'large';

interface TeamSelectProps {
  value: string;
  onChange: (selectedTeam: Team) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  parentSlug?: string;
  currentTeamId?: string; // New prop
}

const TeamSelect: React.FC<TeamSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select a team',
  size = 'medium',
  isOpen,
  onClose,
  currentTeamId, // Destructure the new prop
}) => {
  return (
    <TeamContainer
      fetchOnMount={true}
      render={({ data: teams, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const teamOptions = teams
          .filter((team) => team.id !== currentTeamId) // Exclude the current team
          .map((team) => ({
            value: team.id,
            label: team.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        const handleSelect = (teamId: string) => {
          const selectedTeam = teams.find((team) => team.id === teamId);
          if (selectedTeam) {
            onChange(selectedTeam);
          }
          if (onClose) {
            onClose();
          }
        };

        return (
          <SelectSingle
            placeholder={placeholder}
            options={teamOptions}
            defaultValue={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

export default TeamSelect;
