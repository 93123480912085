import React from 'react';
import { Tooltip } from './feedback/Tooltip';  // Adjust the import path as needed

interface AvatarProps {
  name?: string;
  avatarUrl?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const Avatar: React.FC<AvatarProps> = ({ name = '', avatarUrl, size = 'sm' }) => {
  const sizeClasses = {
    xs: 'w-6 h-6 text-xxs',
    sm: 'w-7 h-7 text-[10px]',
    md: 'w-8 h-8 text-sm',
    lg: 'w-10 h-10 text-base',
  };

  const bgColor = 'bg-primary';

  const getInitials = (name: string) => {
    if (!name) return '?';
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();
    return initials || '?';
  };

  const avatarContent = (
    <div
      className={`${sizeClasses[size]} rounded-full flex items-center justify-center overflow-hidden ${bgColor} text-white`}
    >
      {avatarUrl ? (
        <img src={avatarUrl} alt={name || 'Avatar'} className="w-full h-full object-cover" />
      ) : (
        <span>{getInitials(name)}</span>
      )}
    </div>
  );

  // Only wrap with Tooltip if there's a name
  return name ? (
    <Tooltip content={name} position="top">
      {avatarContent}
    </Tooltip>
  ) : (
    avatarContent
  );
};