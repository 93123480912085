// src/api/alert/api.ts
import { Alert } from './types';
import { fetchWithAuth } from '../fetchWithAuth';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAlerts = async (navigate: (path: string) => void): Promise<Alert[]> => {
  const response = await fetchWithAuth(
    `${API_URL}alerts/alerts/`,
    {},
    navigate
  );

  if (!response.ok) throw new Error('Failed to fetch alerts');
  return response.json();
};