import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchProjects, 
  fetchProject, 
  createProject, 
  editProject, 
  deleteProject, 
  fetchSnapshots,
  fetchSnapshot,
  createSnapshot,
  updateSnapshot,
  sendSnapshot,
  archiveSnapshot,
  updateSnapshotProject,
  removeSnapshotProject,
  deleteSnapshot
} from './api';
import { 
  Project, 
  ProjectsSnapshot, 
  CreateProjectsSnapshot, 
  UpdateProjectsSnapshot,
  ProjectsSnapshotListItem 
} from './types';

// Hook for fetching all projects, supporting optional filtering by personId
export const useProjects = (params?: { 
  personId?: string, 
  teamId?: string,
  startDate?: string, 
  endDate?: string 
}) => {
  const navigate = useNavigate();
  
  // Adjust query key to include personId, startDate, and endDate if provided
  return useQuery(
    ['projects', params], 
    () => fetchProjects(params || {}, navigate)
  );
};

// Hook for fetching a single project
export const useProject = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(['project', slug], () => fetchProject(slug, navigate));
};

// Hook for creating a new project, invalidating queries with optional personId
export const useCreateProject = (params?: { personId?: string }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newProject: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>) => createProject(newProject, navigate),
    {
      onSuccess: () => {
        // Invalidate queries for the projects, either filtered by personId or not
        queryClient.invalidateQueries(['projects', params || {}]);
      },
    }
  );
};

// Hook for editing a project, invalidating queries with optional personId
export const useEditProject = (params?: { personId?: string }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedProject }: { slug: string; updatedProject: Partial<Project> }) => 
      editProject(slug, updatedProject, navigate),
    {
      onSuccess: () => {
        // Invalidate queries for the projects, either filtered by personId or not
        queryClient.invalidateQueries(['projects', params || {}]);
      },
    }
  );
};

// Hook for deleting a project, invalidating queries with optional personId
export const useDeleteProject = (params?: { personId?: string }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteProject(slug, navigate),
    {
      onSuccess: () => {
        // Invalidate queries for the projects, either filtered by personId or not
        queryClient.invalidateQueries(['projects', params || {}]);
      },
    }
  );
};

// Hook for fetching all snapshots with filtering
export const useSnapshots = (params?: { 
  status?: string;
  startDate?: string;
  endDate?: string;
  createdBy?: string;
}) => {
  const navigate = useNavigate();
  return useQuery(
    ['snapshots', params],
    () => fetchSnapshots(params || {}, navigate),
    {
      keepPreviousData: true,
    }
  );
};

// Hook for fetching a single snapshot
export const useSnapshot = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(
    ['snapshot', slug],
    () => fetchSnapshot(slug, navigate),
    {
      enabled: !!slug
    }
  );
};

// Hook for creating a new snapshot
export const useCreateSnapshot = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (snapshotData: CreateProjectsSnapshot) => createSnapshot(snapshotData, navigate),
    {
      onSuccess: (data: ProjectsSnapshot) => {
        queryClient.invalidateQueries('snapshots');
        // Optionally navigate to the new snapshot
        navigate(`/snapshots/${data.slug}`);
      },
    }
  );
};

// Hook for updating a snapshot
export const useUpdateSnapshot = (slug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (updateData: UpdateProjectsSnapshot) => updateSnapshot(slug, updateData, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['snapshots']);
        queryClient.invalidateQueries(['snapshot', slug]);
      },
    }
  );
};

// Hook for sending a snapshot
export const useSendSnapshot = (slug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    () => sendSnapshot(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['snapshots']);
        queryClient.invalidateQueries(['snapshot', slug]);
      },
    }
  );
};

// Hook for archiving a snapshot
export const useArchiveSnapshot = (slug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    () => archiveSnapshot(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['snapshots']);
        queryClient.invalidateQueries(['snapshot', slug]);
      },
    }
  );
};

// Hook for updating a project in a snapshot
export const useUpdateSnapshotProject = (snapshotSlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (projectData: { project_id: string; summary?: string }) => 
      updateSnapshotProject(snapshotSlug, projectData, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['snapshot', snapshotSlug]);
      },
    }
  );
};

// Hook for removing a project from a snapshot
export const useRemoveSnapshotProject = (snapshotSlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (projectId: string) => removeSnapshotProject(snapshotSlug, projectId, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['snapshot', snapshotSlug]);
      },
    }
  );
};

// Helper hook for snapshot actions
export const useSnapshotAction = (slug: string) => {
  const sendMutation = useSendSnapshot(slug);
  const archiveMutation = useArchiveSnapshot(slug);

  const performAction = async (action: 'send' | 'archive') => {
    switch (action) {
      case 'send':
        return sendMutation.mutateAsync();
      case 'archive':
        return archiveMutation.mutateAsync();
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  return {
    performAction,
    isLoading: sendMutation.isLoading || archiveMutation.isLoading,
    error: sendMutation.error || archiveMutation.error,
  };
};

// Hook for prefetching snapshot data
export const usePrefetchSnapshot = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (slug: string) => {
    queryClient.prefetchQuery(
      ['snapshot', slug],
      () => fetchSnapshot(slug, navigate)
    );
  };
};

// Hook for deleting a snapshot, invalidating queries for snapshots
export const useDeleteSnapshot = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteSnapshot(slug, navigate),
    {
      onSuccess: () => {
        // Invalidate queries to refresh the list of snapshots after deletion
        queryClient.invalidateQueries('snapshots');
      },
    }
  );
};