import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { fetchGoals, fetchGoal, createGoal, editGoal, deleteGoal, FetchGoalsParams } from './api';
import { Goal } from './types';

export const useGoals = (params?: FetchGoalsParams) => {
 const navigate = useNavigate();
 return useQuery(
   ['goals', params],
   () => fetchGoals(params || {}, navigate),
   {
     staleTime: 0,
     select: (data) => data ?? [],
   }
 );
};

export const useGoal = (slug: string) => {
 const navigate = useNavigate();
 return useQuery(['goal', slug], () => fetchGoal(slug, navigate));
};

export const useCreateGoal = (params?: FetchGoalsParams) => {
 const queryClient = useQueryClient();
 const navigate = useNavigate();

 return useMutation(
   (newGoal: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>) => 
     createGoal(newGoal, navigate),
   {
     onSuccess: () => {
       queryClient.invalidateQueries(['goals', params]);
     },
   }
 );
};

export const useEditGoal = (params?: FetchGoalsParams) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  type EditGoalPayload = {
    slug: string;
    updatedGoal: Partial<Goal> & {
      tag_ids?: string[];
      participant_ids?: string[];
      project_ids?: string[];
      topic_ids?: string[];
      team_id?: string;
      owner_id?: string;
    };
  };

  return useMutation(
    ({ slug, updatedGoal }: EditGoalPayload) => editGoal(slug, updatedGoal, navigate),
    {
      onSuccess: (_, { slug }) => {
        queryClient.invalidateQueries(['goals', params]);
        queryClient.invalidateQueries(['goal', slug]);
      },
    }
  );
};

export const useDeleteGoal = (params?: FetchGoalsParams) => {
 const queryClient = useQueryClient();
 const navigate = useNavigate();

 return useMutation(
   (slug: string) => deleteGoal(slug, navigate),
   {
     onSuccess: () => {
       queryClient.invalidateQueries(['goals', params]);
     },
   }
 );
};