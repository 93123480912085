import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MeetingOccurrenceContainer from '../containers/MeetingOccurrenceContainer';
import MeetingOccurrenceTable from '../components/meeting/MeetingOccurrenceTable';
import MeetingOccurrenceModal from '../components/meeting/MeetingOccurrenceModal';
import { Modal, TableHeader, Snackbar, Button, SeparatorHorizontal } from '../components/ui';
import { MeetingOccurrence } from '../api/meeting/types';
import { MeetingType } from '../api/meeting/types';
import { MeetingOccurrenceStartModal } from '../components/meeting/MeetingOccurrenceStartModal';
import { FilterConfig } from '../components/ui/data/Filter'; // Adjust the import path as necessary

const MeetingOccurrenceListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [occurrenceToDelete, setOccurrenceToDelete] = useState<string | null>(null);
  const [occurrenceToEdit, setOccurrenceToEdit] = useState<MeetingOccurrence | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [occurrences, setOccurrences] = useState<MeetingOccurrence[]>([]);
  const [showStartMeetingModal, setShowStartMeetingModal] = useState(false);
  const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (slug && occurrences.length > 0) {
      const occurrence = occurrences.find((occ) => occ.slug === slug);
      if (occurrence) {
        setOccurrenceToEdit(occurrence);
        setShowModal(true);
      }
    }
  }, [slug, occurrences]);

  const handleDelete = (slug: string) => {
    setOccurrenceToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (occurrenceToDelete) {
      onDelete(occurrenceToDelete);
      setShowDeleteDialog(false);
      setOccurrenceToDelete(null);
      setSnackbarMessage('Meeting Occurrence deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handlePlanNew = () => {
    setOccurrenceToEdit(null);
    setShowModal(true);
  };

  const handleEditOccurrence = (occurrence: MeetingOccurrence) => {
    navigate(`/meeting-occurrences/${occurrence.slug}`);
    setOccurrenceToEdit(occurrence);
    setShowModal(true);
  };

  const handleStartNewMeeting = () => {
    setShowStartMeetingModal(true);
  };

  // New handler to open the MeetingOccurrenceModal in add or edit mode
  const handleStartMeeting = (meetingTypeId: string, meetingOccurrenceId?: string) => {
    if (meetingOccurrenceId) {
      // Open in edit mode by finding the meeting occurrence by its ID
      const occurrenceToEdit = occurrences.find(occ => occ.id === meetingOccurrenceId);
      if (occurrenceToEdit) {
        setOccurrenceToEdit(occurrenceToEdit);
      } else {
        console.error(`Could not find occurrence with id ${meetingOccurrenceId}`);
        return; // Exit the function if we can't find the occurrence
      }
    } else {
      // Open in add mode, create a minimal MeetingOccurrence object
      const newOccurrence: MeetingOccurrence = {
        id: '', // This will be generated by the backend
        title: '',
        slug: '', // This will be generated by the backend
        company: 'default-company-id', // Replace with actual company ID if available
        meeting_type: meetingTypeId,
        date: new Date().toISOString().split('T')[0], // Today's date
        description: '',
        attendees: [],
        tags: [],
        tasks: [],
        projects: [],
        topic_notes: [],
        is_adhoc: false,
        created_at: '', // These will be set by the backend
        updated_at: '',
        created_by: null,
      };
      setOccurrenceToEdit(newOccurrence);
    }
    setShowModal(true);
  };

const filterConfig: FilterConfig = useMemo(() => {
  // Create a Set of unique meeting types
  const uniqueMeetingTypes = Array.from(new Set(meetingTypes.map(type => JSON.stringify(type))))
    .map(typeString => JSON.parse(typeString));

  return {
    meeting_type: {
      label: 'Meeting Type',
      value: 'meeting_type',
      type: 'select',
      options: uniqueMeetingTypes.map(type => ({ label: type.name, value: type.id }))
    },
    date: {
      label: 'Date',
      value: 'date',
      type: 'input',
    },
    // Add more filter options as needed
  };
}, [meetingTypes]);

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  // Type guard to check if the meeting_type is a MeetingType object
  const isMeetingType = (meetingType: string | MeetingType | undefined): meetingType is MeetingType => {
    return typeof meetingType === 'object' && meetingType !== null && 'id' in meetingType;
  };

  // Update meetingTypes when occurrences change
  useEffect(() => {
    if (occurrences.length > 0) {
      const uniqueTypes = Array.from(new Set(occurrences.map(occ => occ.meeting_type).filter(isMeetingType)));
      setMeetingTypes(uniqueTypes);
    }
  }, [occurrences]);

  return (
    <div>
      <h1 className='m-4'>Meetings Occurrrences</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handlePlanNew}
        searchPlaceholder="Search Meeting Occurrences..."
        addButtonLabel="Plan Meeting Occurrence"
        additionalButtons={
          <Button
            label="Start Meeting"
            onClick={handleStartNewMeeting}
            variant="secondary"
            buttonStyle="filled"
            size="medium"
            icon="Play"
            iconColor="white"
            iconSize="sm"
          />
        }
        filterConfig={filterConfig}
        onFilterChange={handleFilterChange}
      />

      <MeetingOccurrenceContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 bg-background text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading meeting occurrences...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          // Set occurrences on initial load
          if (occurrences.length === 0 && data.length > 0) {
            setOccurrences(data);
          }

          const filteredOccurrences = data.filter(occurrence => {
            const matchesSearch = occurrence.title.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFilters = Object.entries(filters).every(([key, value]) => {
              if (key === 'meeting_type') {
                if (occurrence.meeting_type === undefined) {
                  // Handle the case where meeting_type is undefined
                  return value === '' || value === 'undefined';
                } else if (isMeetingType(occurrence.meeting_type)) {
                  return occurrence.meeting_type.id === value;
                } else {
                  // If meeting_type is a string, compare directly
                  return occurrence.meeting_type === value;
                }
              }
              if (key === 'date') {
                // Implement date filtering logic here
                // For example, checking if the occurrence date is after the filter date
                return new Date(occurrence.date) >= new Date(value as string);
              }
              // Add more filter logic for other fields as needed
              return true;
            });
            return matchesSearch && matchesFilters;
          });

          const handleSaveOccurrence = async (
            occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>,
            slug?: string
          ): Promise<MeetingOccurrence> => {
            try {
              let savedOccurrence: MeetingOccurrence;
              if (slug) {
                // Edit existing occurrence
                savedOccurrence = await onEdit(slug, occurrenceData);
                setSnackbarMessage('Meeting Occurrence updated successfully');
              } else {
                // Create new occurrence
                savedOccurrence = await onCreate(occurrenceData);
                setSnackbarMessage('Meeting Occurrence created successfully');
              }
          
              setSnackbarType('success');
              setShowSnackbar(true);
          
              return savedOccurrence;
            } catch (error) {
              console.error('Error saving meeting occurrence:', error);
              setSnackbarMessage('Failed to save Meeting Occurrence');
              setSnackbarType('error');
              setShowSnackbar(true);
              throw error;
            }
          };

          return (
            <>
              {/* Meeting Occurrence Modal */}
              {showModal && (
                <MeetingOccurrenceModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate('/meeting-occurrences/');
                  }}
                  onSave={occurrenceToEdit ? (data) => handleSaveOccurrence(data, occurrenceToEdit.slug) : handleSaveOccurrence}
                  onEdit={onEdit}
                  initialData={occurrenceToEdit || undefined}
                />
              )}

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this meeting occurrence?</p>
              </Modal>

              <MeetingOccurrenceStartModal
                isOpen={showStartMeetingModal}
                onClose={() => setShowStartMeetingModal(false)}
                onStartMeeting={handleStartMeeting} // Pass the handler here
                futureMeetingOccurrences={data.filter(occurrence => new Date(occurrence.date) > new Date())}
              />

              {/* Meeting Occurrence Table */}
              <MeetingOccurrenceTable
                data={filteredOccurrences}
                key={filteredOccurrences.map((meeting) => meeting.slug).join()}
                onEdit={handleEditOccurrence}
                onDelete={handleDelete}
              />
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default MeetingOccurrenceListPage;