import React, { useState, useEffect, useRef } from 'react';
import { Icon } from './base/Icon'; // Adjust the import path

type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

type SelectSize = 'small' | 'medium' | 'large';

interface SelectSearchProps {
  options: SelectOption[];
  placeholder?: string;
  defaultIcon?: React.ReactNode;
  onSelect: (option: SelectOption) => void; // Outputs the entire option
  isDisabled?: boolean;
  enableSearch?: boolean;
  size?: SelectSize;
  onCreate?: (newLabel: string) => void; // New prop to handle creating new options
}

const SelectSearch: React.FC<SelectSearchProps> = ({
  options,
  placeholder = 'Select an option',
  defaultIcon,
  onSelect,
  isDisabled = false,
  enableSearch = true,
  size = 'medium',
  onCreate,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');  // State for the search input
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);  // State for filtered options
  const selectRef = useRef<HTMLDivElement>(null);  // Reference for outside click detection

  // Define size classes
  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-3 py-2 text-base',
    large: 'px-4 py-3 text-lg',
  };

  // Effect to filter options based on the search query
  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchQuery, options]);

  const handleSelect = (option: SelectOption) => {
    onSelect(option); // Pass the entire option to the callback
    setDropdownOpen(false);
    setSearchQuery('');  // Clear search after selection
  };

  // Handle creating a new option
  const handleCreateNewOption = () => {
    if (onCreate && searchQuery.trim()) {
      onCreate(searchQuery.trim()); // Pass the trimmed search query to onCreate
      setSearchQuery(''); // Clear the search input
      setDropdownOpen(false); // Close the dropdown
    }
  };

  // Close dropdown when clicking outside the component
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={selectRef}>
      {/* Button that triggers the dropdown */}
      <button
        className={`flex items-center space-x-2 border border-neutral rounded-md shadow-sm cursor-pointer bg-white focus:outline-none focus:border-primary ${sizeClasses[size]} ${
          isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={() => !isDisabled && setDropdownOpen(!dropdownOpen)}
        disabled={isDisabled}
      >
        {/* Show default icon */}
        {defaultIcon && <span className="text-text">{defaultIcon}</span>}
        <span className="text-text">{placeholder}</span>
        <svg
          className="w-4 h-4 text-neutral"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>

      {/* Dropdown Modal */}
      {dropdownOpen && !isDisabled && (
        <div className="absolute z-10 mt-2 w-auto min-w-[200px] border border-neutral bg-white rounded-md shadow-lg max-h-60 overflow-auto">
          {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-2 border border-neutral rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isDisabled}
              />
            </div>
          )}

          {/* Filtered options */}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className="cursor-pointer p-2 hover:bg-neutral flex items-center justify-between"
                onClick={() => handleSelect(option)}
              >
                <div className="flex items-center space-x-2">
                  {option.icon && (
                    <span>
                      {React.cloneElement(option.icon as React.ReactElement<any>, {
                        color: 'text',
                      })}
                    </span>
                  )}
                  <span>{option.label}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No options found</div>
          )}

          {/* Option to create a new item */}
          {onCreate && searchQuery && (
            <div
              className="cursor-pointer p-2 text-primary hover:bg-neutral-light flex items-center justify-between"
              onClick={handleCreateNewOption}
            >
              <Icon name="Plus" size="sm" />
              <span>Create "{searchQuery}"</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { SelectSearch };
