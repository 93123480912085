import React, { useState, useRef, useEffect } from 'react';

const colorPalette = [
  { name: 'neutral-light', hex: '#F8F8F8' },
  { name: 'primary-light', hex: '#A3CFF7' },
  { name: 'secondary-light', hex: '#FBD59E' },
  { name: 'accent-light', hex: '#B2F0E0' },
  { name: 'neutral', hex: '#D0D0D0' },
  { name: 'primary', hex: '#4A90E2' },
  { name: 'secondary', hex: '#F5A623' },
  { name: 'accent', hex: '#20C7A0' },
  { name: 'neutral-dark', hex: '#707070' },
  { name: 'primary-dark', hex: '#2A5B98' },
  { name: 'secondary-dark', hex: '#B07600' },
  { name: 'accent-dark', hex: '#00866D' },
];

interface ColorPaletteProps {
  currentColor?: string;
  onChange: (color: string) => void;
}

export const ColorPalette: React.FC<ColorPaletteProps> = ({ currentColor = '#4A90E2', onChange }) => {
  const [isPaletteOpen, setIsPaletteOpen] = useState(false);
  const paletteRef = useRef<HTMLDivElement>(null); // Reference for detecting outside clicks

  const handleColorChange = (color: string) => {
    onChange(color);
    setIsPaletteOpen(false);  // Close the palette after selection
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (paletteRef.current && !paletteRef.current.contains(event.target as Node)) {
      setIsPaletteOpen(false);  // Close palette if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={paletteRef}>
      {/* Color Circle */}
      <div
        className="w-6 h-6 rounded-full cursor-pointer border border-gray-300"
        style={{ backgroundColor: currentColor }}
        onClick={() => setIsPaletteOpen((prev) => !prev)} // Toggle palette visibility
      ></div>

      {/* Color Palette */}
      {isPaletteOpen && (
        <div
          className="absolute z-10 mt-2 flex flex-wrap gap-2 p-2 bg-white border border-gray-300 rounded-md shadow-lg"
          style={{ width: '170px' }}
        >
          {colorPalette.map((color) => (
            <div
              key={color.name}
              className="w-8 h-8 rounded-full cursor-pointer border border-gray-200"
              style={{ backgroundColor: color.hex }}
              onClick={() => handleColorChange(color.hex)} // Update color on click
              title={color.name}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorPalette;
