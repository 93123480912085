import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PersonContainer from '../../containers/PersonContainer';
import TaskContainer from '../../containers/TaskContainer';
import TopicContainer from '../../containers/TopicContainer';
import ProjectContainer from '../../containers/ProjectContainer';
import { Person } from '../../api/person/types';
import { ChevronLeft } from 'lucide-react';
import MindMapNode from '../ui/data/MindMapNode';
import { Task } from '../../api/task/types'; 
import { Topic } from '../../api/topic/types'; 
import { TaskModal } from '../task/TaskModal'; 
import { TopicModal } from '../topic/TopicModal'; 
import { PersonModal } from '../person/PersonModal';
import { taskStateOptions, topicStateOptions, projectStateOptions } from '../../utils/entitiesOptions';

// Sorting function
const sortByState = (items: any[], stateOptions: any[]) => {
  return items.sort((a, b) => {
    const stateAIndex = stateOptions.findIndex(option => option.value === a.state);
    const stateBIndex = stateOptions.findIndex(option => option.value === b.state);
    return stateAIndex - stateBIndex;
  });
};

const CategoryWrapper = ({ 
    title, 
    x, 
    y, 
    width, 
    height, 
    category,  
    children 
  }: { 
    title: string;
    x: number;
    y: number;
    width: number;
    height: number;
    category: 'tasks' | 'topics' | 'projects';
    children: React.ReactNode;
  }) => (
    <g transform={`translate(${x},${y})`}>
        <rect
        x={-width / 2}
        y={-height / 2}
        width={width}
        height={height}
        rx="15"
        ry="15"
        className="fill-gray-100 stroke-gray-200"
        />
        <text
        x={-width / 2 + 20}
        y={-height / 2 + 25}
        className="fill-gray-500 text-xs font-medium"
        >
        {title}
        <tspan x={-width / 2 + 20} dy="20" className="fill-gray-400 text-xs">
            {React.Children.count(children)} items
        </tspan>
        </text>
        {/* Add more vertical offset for tasks/topics content */}
        <g transform={`translate(0,${category === 'projects' ? -height / 2 + 45 : -height / 2 + 90})`}>
        {children}
        </g>
    </g>
);

const CurvedLink = ({
  x1,
  y1,
  x2,
  y2,
}: {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}) => {
  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;

  return (
    <path
      d={`M ${x1} ${y1} Q ${midX} ${midY} ${x2} ${y2}`}
      className="stroke-primary opacity-30"
      strokeWidth="2"
      fill="none"
    />
  );
};

const PersonMindMapContent: React.FC<{ slug: string }> = ({ slug }) => {
    const navigate = useNavigate();
  
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
    const [isPersonModalOpen, setIsPersonModalOpen] = useState(false); // Add this state
    const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
    const [selectedTopic, setSelectedTopic] = useState<Topic | undefined>(undefined);
  
    const openTaskModal = (task: Task) => {
      setSelectedTask(task);
      setIsTaskModalOpen(true);
    };
  
    const closeTaskModal = () => {
      setIsTaskModalOpen(false);
      setSelectedTask(undefined);
    };
  
    const openTopicModal = (topic: Topic) => {
      setSelectedTopic(topic);
      setIsTopicModalOpen(true);
    };
  
    const closeTopicModal = () => {
      setIsTopicModalOpen(false);
      setSelectedTopic(undefined);
    };
  
    const openPersonModal = () => {
      setIsPersonModalOpen(true);
    };
  
    const closePersonModal = () => {
      setIsPersonModalOpen(false);
    };

  const calculateDynamicSize = (total: number, category: 'tasks' | 'topics' | 'projects') => {
    const nodeHeight = 45;
    const nodeWidth = 100;
    const verticalSpacing = 35;
    const horizontalSpacing = 50;
    const headerHeight = 50;
    const sidePadding = 60; // Padding for each side
    
    if (category === 'projects') {
      const projectsPerRow = 5;
      const rows = Math.ceil(total / projectsPerRow);
      const itemsInRow = Math.min(total, projectsPerRow); // Number of items in current row
      
      // Width calculation: (number of items * (node width + spacing between nodes)) + padding on both sides
      const width = Math.max(280, itemsInRow * (nodeWidth + horizontalSpacing));
      const height = Math.max(80, 90 + ((rows - 1) * 60));
      
      return { width, height };
    } else {
      // Adjusted height calculation for tasks and topics
      const height = (nodeHeight + 8) * total + sidePadding  + verticalSpacing;
      return {
        width: 180, // Slightly wider for better text fit
        height: Math.max(100, height)
      };
    }
  };

  const calculateSVGSize = (tasks: Task[], topics: Topic[], projects: any[]) => {
    const tasksDimensions = calculateDynamicSize(tasks.length, 'tasks');
    const topicsDimensions = calculateDynamicSize(topics.length, 'topics');
    const projectsDimensions = calculateDynamicSize(projects.length, 'projects');
  
    const width = Math.max(800, topicsDimensions.width + tasksDimensions.width + 300);
    
    // Adjusted height calculation
    const verticalContentHeight = Math.max(tasksDimensions.height, topicsDimensions.height);
    const height = Math.max(
      600, // Reduced minimum height
      verticalContentHeight + projectsDimensions.height + 150 // Reduced padding
    );
  
    return { width, height };
  };

  const calculateCategories = (tasks: Task[], topics: Topic[], projects: any[]) => {
    const svgSize = calculateSVGSize(tasks, topics, projects);
    const centerX = svgSize.width / 2;
    const centerY = svgSize.height / 3; // Move from /5 to /3 to start lower
    
    return {
      tasks: { 
        x: centerX - 250,
        y: centerY, 
        ...calculateDynamicSize(tasks.length, 'tasks')
      },
      topics: { 
        x: centerX + 250, 
        y: centerY, 
        ...calculateDynamicSize(topics.length, 'topics')
      },
      projects: { 
        x: centerX, 
        y: svgSize.height - 200,  // Adjust this if needed
        ...calculateDynamicSize(projects.length, 'projects')
      }
    };
  };

  const calculateNodePosition = (index: number, total: number, category: 'tasks' | 'topics' | 'projects', categories: any) => {
    const spacing = category === 'projects' ? 110 : 55;
  
    if (category === 'projects') {
      const projectsPerRow = 5;
      const row = Math.floor(index / projectsPerRow);
      const indexInRow = index % projectsPerRow;
  
      // Calculate total items in this row
      const itemsInThisRow = row === Math.floor((total - 1) / projectsPerRow) 
        ? ((total - 1) % projectsPerRow) + 1 
        : projectsPerRow;
  
      // Center single items in their row
      if (itemsInThisRow === 1) {
        return {
          x: 0, // Center point
          y: row * 60
        };
      }
      
      // For multiple items, calculate position from center
      const startX = -((itemsInThisRow - 1) * spacing) / 2;
      const x = startX + (indexInRow * spacing);
      const y = row * 60;
      
      return { x, y };
    } else {
      return {
        x: 0,
        y: index * spacing
      };
    }
  };

  const renderMindMap = (
    person: Person | undefined,
    topics: Topic[],
    tasks: Task[],
    projects: any[]
  ) => {
    if (!person) {
      return (
        <div className="flex items-center justify-center h-[600px] text-gray-500">
          Person not found
        </div>
      );
    }
  
    const svgSize = calculateSVGSize(tasks, topics, projects);
    const categories = calculateCategories(tasks, topics, projects);
  
    // Sort tasks, topics, and projects by their state
    const sortedTasks = sortByState(tasks, taskStateOptions);
    const sortedTopics = sortByState(topics, topicStateOptions);
    const sortedProjects = sortByState(projects, projectStateOptions);
  
    return (
      <>
        <div className="flex items-center gap-4 mb-6">
          <button
            onClick={() => navigate('/persons')}
            className="flex items-center gap-2 text-primary text-sm hover:text-primary-dark"
          >
            <ChevronLeft className="w-4 h-4" />
            Back to Persons
          </button>
          <h1 className="text-sm font-medium">{person.name}'s Mind Map</h1>
        </div>
  
        <svg 
          viewBox={`0 0 ${svgSize.width} ${svgSize.height}`}
          className="w-full"
        >
          {/* Links between person and categories */}
          <CurvedLink 
            x1={svgSize.width / 2} 
            y1={svgSize.height / 5} 
            x2={categories.tasks.x} 
            y2={categories.tasks.y} 
          />
          <CurvedLink 
            x1={svgSize.width / 2} 
            y1={svgSize.height / 5} 
            x2={categories.topics.x} 
            y2={categories.topics.y} 
          />
          <CurvedLink 
            x1={svgSize.width / 2} 
            y1={svgSize.height / 5} 
            x2={categories.projects.x} 
            y2={categories.projects.y} 
          />
  
          {/* Task category */}
          <CategoryWrapper
            title="Tasks"
            category="tasks"
            {...categories.tasks}
          >
            {sortedTasks.map((task, index) => {
              const position = calculateNodePosition(index, sortedTasks.length, 'tasks', categories);
              return (
                <g key={task.slug} transform={`translate(${position.x},${position.y})`}>
                  <MindMapNode
                    title={task.title}
                    state={taskStateOptions.find(option => option.value === task.state)?.label || task.state} 
                    slug={task.slug}
                    type="Task"
                    onClick={() => openTaskModal(task)}
                  />
                </g>
              );
            })}
          </CategoryWrapper>
  
          {/* Topics category */}
          <CategoryWrapper
            title="Topics"
            category="topics"
            {...categories.topics}
          >
            {sortedTopics.map((topic, index) => {
              const position = calculateNodePosition(index, sortedTopics.length, 'topics', categories);
              return (
                <g key={topic.slug} transform={`translate(${position.x},${position.y})`}>
                  <MindMapNode
                    title={topic.title}
                    state={topicStateOptions.find(option => option.value === topic.state)?.label || topic.state} 
                    slug={topic.slug}
                    type="Topic"
                    onClick={() => openTopicModal(topic)}
                  />
                </g>
              );
            })}
          </CategoryWrapper>
  
          {/* Projects category */}
          <CategoryWrapper
            title="Projects"
            category="projects" 
            {...categories.projects}
          >
            {sortedProjects.map((project, index) => {
              const position = calculateNodePosition(index, sortedProjects.length, 'projects', categories);
              return (
                <g key={project.slug} transform={`translate(${position.x},${position.y})`}>
                  <MindMapNode
                    title={project.title}
                    state={projectStateOptions.find(option => option.value === project.state)?.label || project.state} 
                    slug={project.slug}
                    type="Project"
                    onClick={() => openTopicModal(project)}
                  />
                </g>
              );
            })}
          </CategoryWrapper>
  
            {/* Person node */}
            <g transform={`translate(${svgSize.width / 2},${svgSize.height / 5})`} onClick={openPersonModal} className="cursor-pointer">
            <rect
                width="140"
                height="60"
                x="-70"
                y="-30"
                className="fill-primary"
                rx="10"
                ry="10"
            />
            <text
                textAnchor="middle"
                className="fill-white text-base text-sm font-medium pointer-events-none" // Ensures text doesn't interfere with the click
                y="3"
            >
                {person.name}
            </text>
            </g>
        </svg>
    
        {/* Task Modal */}
        {isTaskModalOpen && selectedTask && (
          <TaskModal
            isOpen={isTaskModalOpen}
            onClose={closeTaskModal}
            initialData={selectedTask}
            onSuccess={(message) => console.log(message)}
            onError={(message) => console.log(message)}
          />
        )}
  
        {/* Topic Modal */}
        {isTopicModalOpen && selectedTopic && (
          <TopicModal
            isOpen={isTopicModalOpen}
            onClose={closeTopicModal}
            initialData={selectedTopic}
            onSuccess={(message) => console.log(message)}
            onError={(message) => console.log(message)}
          />
        )}
  
        {/* Person Modal */}
        {isPersonModalOpen && person && (
          <PersonModal
            isOpen={isPersonModalOpen}
            onClose={() => setIsPersonModalOpen(false)}
            initialData={person}
            onSuccess={(message, updatedPerson) => console.log(message, updatedPerson)}
            onError={(message) => console.log(message)}
          />
        )}
      </>
    );
  };
  

  return (
    <div className="w-full max-w-6xl mx-auto p-5">
      <PersonContainer
        fetchOnMount={true}
        render={({ data: persons }) => {
          const person = persons.find((p) => p.slug === slug);
          const personId = person?.id;

          if (!personId) {
            return <div>Person not found</div>;
          }

          return (
            <TopicContainer
              personId={personId}
              render={({ data: topics }) => (
                <TaskContainer
                  personId={personId}
                  render={({ data: tasks }) => (
                    <ProjectContainer
                      personId={personId}
                      render={({ data: projects }) =>
                        renderMindMap(person, topics, tasks, projects)
                      }
                    />
                  )}
                />
              )}
            />
          );
        }}
      />
    </div>
  );
};

const PersonMindMap: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  if (!slug) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">Person not found</p>
      </div>
    );
  }

  return <PersonMindMapContent slug={slug} />;
};

export default PersonMindMap;