import React from 'react';
import { SelectSearch } from '../ui/SelectSearch'; // Import SelectSearch component
import TopicContainer from '../../containers/TopicContainer'; // Import TopicContainer
import { Topic } from '../../api/topic/types';
import { Person } from '../../api/person/types';

interface TopicListSelectProps {
  value: string | null; // Track the selected topic ID
  onChange: (selectedTopic: Topic | null) => void; // Return the full Topic object to the parent
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
}

const TopicListSelect: React.FC<TopicListSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = "Select or Create Topic",
}) => {
  return (
    <TopicContainer
      render={({ data: topics, loading, error, onCreate }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        // Map topics to SelectOption format expected by SelectSearch
        const topicOptions = topics.map((topic) => ({
          value: topic.id,
          label: topic.title,
          icon: (
            <div
              className="w-4 h-4 rounded-full bg-gray-200" // Optional style for a placeholder icon
              title={topic.title} // Show topic title as a tooltip
            />
          ),
        }));

        const handleSelect = (selectedOption: { value: string; label: string }) => {
          const selectedTopic = topics.find((topic) => topic.id === selectedOption.value) || null;
          onChange(selectedTopic); // Pass the full Topic object to the parent
        };

        const handleCreateNewTopic = (newTopicTitle: string) => {
          if (!newTopicTitle) {
            // Handle error (e.g., show error message)
            return;
          }

          // Create a topic with default values
          const topicData: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'> = {
            title: newTopicTitle,
            description: '', // Default empty description
            state: 'IDENTIFIED', // Default state
            importance: 1, // Default importance
            heatmap_status: 'GREEN', // Default heatmap status
            created_by: { id: 'default-user-id' } as Person, // Default created_by
            persons: [], // Default empty persons array
            tags: [], // Default empty tags array
            company: 'default-company-id', // Default company ID
            visibility: 'private', // Default visibility
            editable_visibility: false, // Default editable_visibility
            is_favorited: false, // Default is_favorited
            favorites_count: 0, // Default favorites_count
          };

          // Pass the data to `onCreate`
          onCreate(topicData);
        };

        return (
          <SelectSearch
            placeholder={placeholder}
            options={topicOptions}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            onCreate={handleCreateNewTopic}
          />
        );
      }}
    />
  );
};

export default TopicListSelect;
