import React, { useState, useEffect, useCallback } from 'react';
import { ModalContent } from '../ui';
import { Tag } from '../../api/tag/types';
import { ColorPalette } from '../ui/base/ColorPalette';
import { TextInput } from '../ui/TextInput';
import FavoriteContainer from '../../containers/FavoriteContainer';

interface TagModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (tagData: Omit<Tag, 'id' | 'created_at' | 'updated_at'>, shouldClose: boolean) => void;
  initialData?: Tag;
}

export const TagModal: React.FC<TagModalProps> = ({ isOpen, onClose, onSave, initialData }) => {
  const [tagName, setTagName] = useState<string>('');
  const [tagColor, setTagColor] = useState<string>('#4A90E2');
  const [createMore, setCreateMore] = useState<boolean>(false);
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('modal');

  useEffect(() => {
    if (initialData) {
      setTagName(initialData.name);
      setTagColor(initialData.color || '#4A90E2');
    } else {
      setTagName('');
      setTagColor('#4A90E2');
    }
  }, [initialData]);

  const resetForm = useCallback(() => {
    setTagName('');
    setTagColor('#4A90E2');
  }, []);

  const handleSave = () => {
    if (!tagName) {
      return alert('Please provide a tag name');
    }
  
    const tagData = {
      name: tagName,
      color: tagColor,
      favorites_count: 0,
      is_favorited: false
    };
  
    onSave(tagData, !createMore);
  
    if (createMore) {
      setTagName('');
      setTagColor('#4A90E2');
    }
  };

  return isOpen ? (
    <FavoriteContainer
      type="organization.tag"  // Assuming tags are in the organization app
      render={({ onToggle, isFavorited }) => (
        <ModalContent
          title={tagName}
          onTitleChange={setTagName}
          onCancel={onClose}
          onCreate={handleSave}
          actionButtonLabel="Save"
          showCreateMoreToggle={!initialData}
          onCreateMoreChange={setCreateMore}
          showDescription={false}
          isSidePanelInitiallyCollapsed={true}
          // Add favorite functionality
          showFavorite={!!initialData}
          isFavorited={Boolean(initialData?.id && isFavorited(initialData.id))}
          onFavoriteToggle={() => initialData?.id && onToggle('organization.tag', initialData.id)}
          viewMode={modalViewMode}
          onViewModeChange={(mode) => setModalViewMode(mode)}
        >
          {/* Color Selection Section */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Select Tag Color:</label>
            <ColorPalette currentColor={tagColor} onChange={setTagColor} />
          </div>
        </ModalContent>
      )}
    />
  ) : null;
};

export default TagModal;