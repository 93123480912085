import React from 'react';
import { Icon } from '../';
import { type IconName } from '../';

export interface TreeItemData {
  id: string;
  title: string;
  subtitle?: string;
  type?: string;
  icon?: IconName;
  children?: TreeItemData[];
  metadata?: Record<string, any>;
}

interface TreeNodeProps {
  item: TreeItemData;
  expanded?: boolean;
  onToggle?: () => void;
  renderIcon?: (item: TreeItemData) => React.ReactNode;
  renderContent?: (item: TreeItemData) => React.ReactNode;
  getNodeStyle?: (item: TreeItemData) => string;
  className?: string;
  expandedNodes?: Set<string>;
  onNodeToggle?: (nodeId: string) => void;
}

interface TreeViewProps {
  data: TreeItemData;
  expandedNodes?: Set<string>;
  onNodeToggle: (nodeId: string) => void;
  renderIcon?: (item: TreeItemData) => React.ReactNode;
  renderContent?: (item: TreeItemData) => React.ReactNode;
  getNodeStyle?: (item: TreeItemData) => string;
  className?: string;
}

const TreeNode: React.FC<TreeNodeProps> = ({ 
  item,
  expanded = true,
  onToggle,
  renderIcon,
  renderContent,
  getNodeStyle,
  className = '',
  expandedNodes,
  onNodeToggle
}) => {
  const defaultNodeStyle = (item: TreeItemData) => {
    return 'bg-background border-neutral';
  };

  const defaultRenderIcon = (item: TreeItemData) => {
    if (!item.icon) return null;
    return (
      <Icon 
        name={item.icon} 
        size="sm"
        className="shrink-0 text-text"
      />
    );
  };

  const defaultRenderContent = (item: TreeItemData) => {
    return (
      <div className="min-w-0">
        <div className="font-medium text-sm truncate">{item.title}</div>
        {item.subtitle && (
          <div className="text-text/60 text-xs truncate">{item.subtitle}</div>
        )}
      </div>
    );
  };

  const hasChildren = item.children && item.children.length > 0;

  return (
    <div className="flex flex-col">
      <button
        onClick={onToggle}
        className={`
          flex items-center gap-2 p-3 rounded-md border
          ${getNodeStyle?.(item) || defaultNodeStyle(item)}
          ${hasChildren ? 'cursor-pointer hover:bg-neutral/10' : ''}
          transition-colors duration-150
          text-left w-full
          ${className}
        `}
      >
        {hasChildren && (
          <Icon 
            name={expanded ? 'ChevronDown' : 'ChevronRight'} 
            size="sm"
            className="text-text/50"
          />
        )}
        {renderIcon?.(item) || defaultRenderIcon(item)}
        {renderContent?.(item) || defaultRenderContent(item)}
      </button>
      {expanded && hasChildren && item.children && (
        <div className="ml-6 mt-2 space-y-2">
          {item.children.map(child => (
            <TreeNode
              key={child.id}
              item={child}
              expanded={expandedNodes?.has(child.id)}
              onToggle={() => onNodeToggle?.(child.id)}
              renderIcon={renderIcon}
              renderContent={renderContent}
              getNodeStyle={getNodeStyle}
              className={className}
              expandedNodes={expandedNodes}
              onNodeToggle={onNodeToggle}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeView: React.FC<TreeViewProps> = ({
  data,
  expandedNodes,
  onNodeToggle,
  renderIcon,
  renderContent,
  getNodeStyle,
  className
}) => {
  return (
    <div className="p-5 max-w-4xl mx-auto">
      <div className="space-y-4">
        <TreeNode
          item={data}
          expanded={expandedNodes?.has(data.id)}
          onToggle={() => onNodeToggle(data.id)}
          renderIcon={renderIcon}
          renderContent={renderContent}
          getNodeStyle={getNodeStyle}
          className={className}
          expandedNodes={expandedNodes}
          onNodeToggle={onNodeToggle}
        />
      </div>
    </div>
  );
};

export default TreeView;