// src/components/base/Dialog.tsx
import React from 'react';
import { Icon } from './base/Icon';

interface DialogProps {
 isOpen: boolean;
 onClose: () => void;
 title?: string;
 children: React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, title, children }) => {
 if (!isOpen) return null;

 return (
   <>
     <div 
       className="fixed inset-0 bg-black bg-opacity-50 z-40"
       onClick={onClose}
     />
     
     <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
       <div className="bg-white rounded-md shadow-lg w-full max-w-md">
         <div className="flex justify-between items-center p-4 border-b border-neutral">
           {title && <h2 className="font-semibold">{title}</h2>}
           <button 
             onClick={onClose}
             className="p-1 hover:bg-neutral rounded-full"
           >
             <Icon name="X" size="sm" />
           </button>
         </div>
         
         <div className="p-4">
           {children}
         </div>
       </div>
     </div>
   </>
 );
};