import { FaTimes } from 'react-icons/fa';
import { Button } from '../../ui';  

interface ModalProps {
  isOpen: boolean;
  title: string;
  children: JSX.Element;
  onClose: () => void;
  onConfirm: () => void;  // Add a prop for confirmation action
}

export function Modal({ isOpen, title, children, onClose, onConfirm }: ModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal Content */}
      <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-4 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
        >
          <FaTimes />
        </button>

        {/* Modal Title */}
        <h2 className="text-lg font-semibold mb-4">{title}</h2>

        {/* Modal Body */}
        <div>{children}</div>

        {/* Modal Actions */}
        <div className="mt-4 flex justify-end space-x-2">
          <Button
            label="Cancel"
            variant="secondary"
            buttonStyle="filled"
            onClick={onClose}
          />
          <Button
            label="Confirm"
            variant="primary"
            buttonStyle="filled"
            onClick={onConfirm}  // Trigger the confirm action
          />
        </div>
      </div>
    </div>
  );
}