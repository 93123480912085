import React, { useEffect, useState } from 'react';
import { useProjects, useCreateProject, useEditProject, useDeleteProject } from '../api/project/queries';
import { Project } from '../api/project/types';
import { useQueryClient } from 'react-query';
import { ALERTS_QUERY_KEY } from '../api/alert/queries';

interface ProjectContainerProps {
  personId?: string;       // Optional prop for filtering projects by personId
  teamId?: string;         // New optional prop for filtering projects by teamId
  startDate?: string;      // Optional prop for filtering projects by start date
  endDate?: string;        // Optional prop for filtering projects by end date
  fetchOnMount?: boolean;  // Optional prop to control whether to fetch Projects on mount
  render: (props: {
    data: Project[];
    loading: boolean;
    error: string | null;
    onCreate: (newProject: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedProject: Partial<Project>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const ProjectContainer: React.FC<ProjectContainerProps> = ({ 
  fetchOnMount = true,  
  personId, 
  teamId,
  startDate, 
  endDate, 
  render 
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  // Fetch projects using the useProjects hook with teamId, personId, startDate, and endDate
  const params = { teamId, personId, startDate, endDate };  // Add teamId to params
  const { data, isLoading, isError } = useProjects(params);  // Pass params to useProjects hook

  // Mutations for create, edit, and delete operations
  const createProjectMutation = useCreateProject();
  const editProjectMutation = useEditProject();
  const deleteProjectMutation = useDeleteProject();

  // Conditionally set data from the useProjects hook
  useEffect(() => {
    if (fetchOnMount) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
        if (data) setProjects(data);
      }

      if (isError) {
        setError('Failed to fetch Projects');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create new project
  const handleCreate = (newProject: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
    createProjectMutation.mutate(newProject, {
      onSuccess: (createdProject) => {
        setProjects((prevProjects) => [...prevProjects, createdProject]);
        queryClient.invalidateQueries(['projects', params]);  // Invalidate query to refetch
      },
      onError: () => setError('Failed to create Project'),
    });
  };

  // Edit an existing project
  const handleEdit = (slug: string, updatedProject: Partial<Project>) => {
    editProjectMutation.mutate({ slug, updatedProject }, {
      onSuccess: (editedProject) => {
        setProjects((prevProjects) => prevProjects.map(project => project.slug === slug ? editedProject : project));
        queryClient.invalidateQueries(['projects', params]);  // Invalidate query to refetch
        queryClient.invalidateQueries(ALERTS_QUERY_KEY);
      },
      onError: () => setError('Failed to edit Project'),
    });
  };

  // Delete a project
  const handleDelete = (slug: string) => {
    deleteProjectMutation.mutate(slug, {
      onSuccess: () => {
        setProjects((prevProjects) => prevProjects.filter(project => project.slug !== slug));
        queryClient.invalidateQueries(['projects', params]);  // Invalidate query to refetch
        queryClient.invalidateQueries(ALERTS_QUERY_KEY);
      },
      onError: () => setError('Failed to delete Project'),
    });
  };

  // Render the UI
  return render({
    data: projects,
    loading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default ProjectContainer;
