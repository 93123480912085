import React, { useState, useEffect, useRef } from 'react';

interface TableInlineTextInputProps {
  initialValue: string;
  onSave: (value: string) => void;
  onCancel: () => void;
}

export const TableInlineTextInput: React.FC<TableInlineTextInputProps> = ({
  initialValue,
  onSave,
  onCancel
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSave(value);
      setIsEditing(false);
    } else if (e.key === 'Escape') {
      setValue(initialValue);
      onCancel();
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    onSave(value);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className="w-full text-sm border border-primary rounded-sm focus:outline-none focus:ring-1 focus:ring-primary p-0.5 bg-blue-50"
      />
    );
  }

  return (
    <span
      onDoubleClick={handleDoubleClick}
      className="inline-block w-full text-sm cursor-pointer font-semibold"
    >
      {value}
    </span>
  );
};