import { StateTransition } from './types';
import { fetchWithAuth } from '../fetchWithAuth';  // Import fetchWithAuth method
import { getCompanyId } from '../../utils/utils';  // Assuming you have a getCompanyId helper

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all State Transitions for a specific model and object
export const fetchStateTransitions = async (
  model: string, 
  objectId: string, 
  navigate: (path: string) => void
): Promise<StateTransition[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}transitions/${model}/${objectId}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch state transitions for ${model} with ID ${objectId}`);
  }
  return response.json();
};
