import React, { useState, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Board from '../ui/data/Board';
import { useProjects } from '../../api/project/queries';
import { Project } from '../../api/project/types';
import {
  getPriorityLabelAndIcon,
  getEffortLabelAndIcon,
  getPulseLabelAndIcon,
  projectStateOptions,
} from '../../utils/entitiesOptions';

interface ProjectBoardViewProps {
  onOpenProject: (project: Project) => void; // Opens ProjectModal with selected project
  onEdit: (slug: string, updatedProject: Partial<Project>) => void;
}

interface Badge {
  label: string;
  style?: string;
  icon?: React.ReactNode;
}

interface BoardItem {
  id: string; // Use slug as identifier
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
  isCollapsible?: boolean;
  style?: string;
}

export const ProjectBoardView: React.FC<ProjectBoardViewProps> = ({
  onOpenProject,
  onEdit,
}) => {
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data: projects, isLoading, error } = useProjects();

  const mapProjectToBoardItem = useCallback(
    (project: Project): BoardItem => {
      const priority = getPriorityLabelAndIcon(project.priority.toString());
      const effort = getEffortLabelAndIcon(project.effort);
      const pulse = getPulseLabelAndIcon(project.pulse);

      return {
        id: project.slug,
        title: project.title,
        badges: [
          { label: priority.label, icon: priority.icon },
          { label: effort.label, icon: effort.icon },
          { label: pulse.label, icon: pulse.icon },
        ],
      };
    },
    []
  );

  const updateColumns = useCallback(
    (projects: Project[]) => {
      const newColumns = projectStateOptions.map((stateOption) => ({
        id: stateOption.value,
        title: stateOption.label,
        isCollapsible: stateOption.value === 'DONE', // Collapsible option for 'DONE' state
        items: projects
          .filter((project) => project.state === stateOption.value)
          .map(mapProjectToBoardItem),
        // Apply pastel green style to "DONE"
        style:
          stateOption.value === 'DONE'
            ? 'bg-green-50 border-t-4 border-green-200'
            : '', // Default empty style for other columns
      }));
      setColumns(newColumns);
    },
    [mapProjectToBoardItem]
  );

  useEffect(() => {
    if (!isUpdating && projects) {
      updateColumns(projects);
    }
  }, [projects, isUpdating, updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const project = projects?.find((p) => p.slug === item.id);
      if (project) {
        onOpenProject(project); // Opens the modal with selected project
      }
    },
    [projects, onOpenProject]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId: slug } = result;

      if (!destination || destination.droppableId === source.droppableId) return;

      const newState = destination.droppableId as Project['state'];
      setIsUpdating(true);

      try {
        await onEdit(slug, { state: newState });

        setColumns((prevColumns) => {
          const sourceColumn = prevColumns.find(
            (col) => col.id === source.droppableId
          );
          const destColumn = prevColumns.find(
            (col) => col.id === destination.droppableId
          );

          if (sourceColumn && destColumn) {
            const item = sourceColumn.items.find((i) => i.id === slug);
            if (item) {
              sourceColumn.items = sourceColumn.items.filter((i) => i.id !== slug);
              destColumn.items.splice(destination.index, 0, item);
            }
          }
          return [...prevColumns];
        });
      } catch (error) {
        console.error('Failed to update project:', error);
      } finally {
        setIsUpdating(false);
      }
    },
    [onEdit]
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) {
    const errorMessage = (error as Error).message || 'An error occurred';
    return <p>Error: {errorMessage}</p>;
  }

  return (
    <Board
      columns={columns}
      minHeight="calc(100vh - 300px)"
      allowAddItems={false}
      onOpenItem={handleOpenItem} // Opens ProjectModal on item click
      onMoveItem={handleMoveItem}
    />
  );
};

export default ProjectBoardView;
