// src/api/logEntry/queries.ts

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchLogEntries, 
  fetchLogEntry, 
  fetchLogEntriesByObjectId,
  createLogEntry, 
  editLogEntry, 
  deleteLogEntry 
} from './api';
import { LogEntry } from './types';

// Hook for fetching all log entries
export const useLogEntries = () => {
  const navigate = useNavigate();
  return useQuery<LogEntry[], Error>('logEntries', () => fetchLogEntries(navigate), {
    select: (data) => data ?? [],
  });
};

// Hook for fetching a single log entry
export const useLogEntry = (id: string) => {
  const navigate = useNavigate();
  return useQuery(['logEntry', id], () => fetchLogEntry(id, navigate));
};

// Hook for fetching log entries by object ID
export const useLogEntriesByObjectId = (objectId: string) => {
  const navigate = useNavigate();
  return useQuery(['logEntries', objectId], () => fetchLogEntriesByObjectId(objectId, navigate));
};

// Hook for creating a new log entry
export const useCreateLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>) => createLogEntry(newLogEntry, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('logEntries');
      },
    }
  );
};

// Hook for editing a log entry
export const useEditLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, updatedLogEntry }: { id: string; updatedLogEntry: Partial<LogEntry> }) => 
      editLogEntry(id, updatedLogEntry, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('logEntries');
      },
    }
  );
};

// Hook for deleting a log entry
export const useDeleteLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deleteLogEntry(id, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('logEntries');
      },
    }
  );
};