import React, { useState, useEffect, useCallback } from 'react';
import { Icon, IconName } from '../base/Icon';
import { Button, Snackbar, Tabs } from '../../ui';
import { MarkDownEditorActivityFeed } from '../utilities/MarkDownEditorActivityFeed';
import Timeline, { TimelineEvent } from '../Timeline'; // Import the Timeline component

// Types
type TagType = 'Manual' | 'AI' | 'Automatic';
type LogType =
  | 'Created'
  | 'Comment'
  | 'Notes Update'
  | 'State Change'
  | 'Priority Change'
  | 'Person Change'
  | 'Task Change'
  | 'Heatmap Change'
  | 'Effort Change'
  | 'Dial Stage Change'
  | 'Meeting Associated'
  | 'Discussed in Meeting'
  | 'Owner Change'
  | 'Person Assigned as Task Owner'
  | 'Person Participated in Meeting'
  | 'Person Assigned as Project Owner'
  | 'Person Assigned as Strategy Owner'
  | 'Person Added to Topic'
  | 'Due Date Change'
  | 'Team Change'
  | 'Project Assigned to Team'
  | 'Person Removed'
  | 'Content Update'
  | 'Collaborators Updated'
  | 'Start Date Change'
  | 'Target Date Change'
  | 'Progress Update';

export type FeedEvent = {
  id: string;
  log_type: LogType;
  user: string;
  content: string;
  timestamp: string;
  tag?: TagType;
};

export interface ActivityFeedProps {
  events: FeedEvent[];
  timelineEvents?: TimelineEvent[]; // New prop for timeline events
  isDisabled?: boolean;
  onEventClick?: (event: FeedEvent) => void;
  onAddComment?: (comment: string) => void;
  onDeleteEvent?: (eventId: string) => void;
  parentObjectType?: 'topic' | 'project';
  parentObjectId?: string;
  showTimeline?: boolean;  // Prop to toggle showing the Timeline tab
}

const mapImportanceToText = (importance: number): string => {
  const importanceMap: { [key: number]: string } = {
    5: 'CRITICAL',
    4: 'HIGH',
    3: 'MEDIUM',
    2: 'LOW',
    1: 'VERY LOW',
  };
  return importanceMap[importance] || importance.toString();
};

const getLogTypeDetails = (log_type: LogType, content: string): { icon: IconName, iconColor: string } => {
  if (log_type === 'Priority Change') {
    const match = content.match(/Importance changed from (\d+) to (\d+)/);
    if (match) {
      const oldImportance = parseInt(match[1], 10);
      const newImportance = parseInt(match[2], 10);
      return newImportance > oldImportance 
        ? { icon: 'ArrowUpCircle', iconColor: 'text-green-600' }
        : { icon: 'ArrowDownCircle', iconColor: 'text-red-600' };
    }
  }

  if (log_type === 'Heatmap Change') {
    const match = content.match(/Heatmap status changed from (\w+) to (\w+)/);
    if (match) {
      const newHeatmap = match[2];
      const heatmapColors: { [key: string]: string } = {
        GREEN: 'text-green-600',
        YELLOW: 'text-yellow-500',
        ORANGE: 'text-orange-600',
        RED: 'text-red-500',
      };
      return { icon: 'Thermometer', iconColor: heatmapColors[newHeatmap] || 'text-gray-600' };
    }
  }

  switch (log_type) {
    case 'Created': 
        return { icon: 'PlusCircle', iconColor: 'text-green-700' };
    case 'Comment': 
        return { icon: 'MessageCircle', iconColor: 'text-blue-600' };
    case 'Notes Update': 
        return { icon: 'Edit', iconColor: 'text-yellow-600' };
    case 'State Change': 
        return { icon: 'Activity', iconColor: 'text-purple-600' };
    case 'Person Change': 
        return { icon: 'UserPlus', iconColor: 'text-pink-600' };
    case 'Task Change': 
        return { icon: 'CheckCircle', iconColor: 'text-teal-600' };
    case 'Heatmap Change': 
        return { icon: 'Thermometer', iconColor: 'text-orange-600' };
    case 'Person Removed':
        return { icon: 'UserMinus', iconColor: 'text-red-600' };
    case 'Effort Change': 
        return { icon: 'TrendingUp', iconColor: 'text-yellow-500' };
    case 'Dial Stage Change': 
        return { icon: 'Gauge', iconColor: 'text-teal-500' };
    case 'Discussed in Meeting': 
        return { icon: 'MessageSquare', iconColor: 'text-indigo-600' };
    case 'Owner Change': 
        return { icon: 'UsersRound', iconColor: 'text-blue-600' };
    case 'Meeting Associated': 
        return { icon: 'Calendar', iconColor: 'text-indigo-700' };
    case 'Person Assigned as Task Owner': 
        return { icon: 'UserCheck', iconColor: 'text-green-600' };
    case 'Person Participated in Meeting': 
        return { icon: 'Users', iconColor: 'text-purple-500' };
    case 'Person Assigned as Project Owner': 
        return { icon: 'Briefcase', iconColor: 'text-yellow-700' };
    case 'Person Added to Topic': 
        return { icon: 'FilePlus2', iconColor: 'text-pink-500' };
    case 'Due Date Change':
        return { icon: 'Calendar', iconColor: 'text-blue-600' };
    case 'Team Change':
        return { icon: 'Users', iconColor: 'text-purple-700' }; // Icon and color for team change
    case 'Project Assigned to Team':
        return { icon: 'FolderPlus', iconColor: 'text-teal-700' }; // Icon and color for project assigned to team
    case 'Content Update':
      return { icon: 'BarChart2', iconColor: 'text-blue-500' };
    case 'Collaborators Updated':
      return { icon: 'Users', iconColor: 'text-purple-500' };
    case 'Progress Update':
      return { icon: 'BarChart2', iconColor: 'text-blue-500' };
    case 'Start Date Change':
      return { icon: 'Calendar', iconColor: 'text-blue-600' };
    case 'Target Date Change':
      return { icon: 'Calendar', iconColor: 'text-blue-600' };
    default: 
        return { icon: 'Activity', iconColor: 'text-gray-600' };
  }
};

const createMarkup = (htmlContent: string) => {
  return { __html: htmlContent };
};

// Component for rendering the Activity Feed view
const ActivityFeedView: React.FC<ActivityFeedProps> = ({
  events,
  isDisabled = false,
  onEventClick,
  onAddComment,
  onDeleteEvent,
  parentObjectType,
  parentObjectId
}) => {
  const [comment, setComment] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [editorKey, setEditorKey] = useState(0);

  const handleEventClick = (event: FeedEvent) => {
    if (!isDisabled && onEventClick) {
      onEventClick(event);
    }
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  };

  const handleCommentSubmit = useCallback(() => {
    if (onAddComment && comment.trim()) {
      onAddComment(comment.trim());
      setComment('');
      setEditorKey((prevKey) => prevKey + 1); // Force re-render of MarkDownEditorActivityFeed
      setShowSnackbar(true);
    }
  }, [onAddComment, comment]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        handleCommentSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleCommentSubmit]);

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">Activities</h2>
      {onAddComment && (
        <div className="mb-12">
          <div className="border border-neutral rounded-md p-2">
            <MarkDownEditorActivityFeed
              key={editorKey}
              initialContent=""
              placeholder="Add Activity details..."
              onChange={handleCommentChange}
              parentObjectType={parentObjectType}
              parentObjectId={parentObjectId}
            />
          </div>
          <div className="mt-4">
            <Button
              label="Add Activity Notes"
              onClick={handleCommentSubmit}
              disabled={isDisabled || !comment.trim()}
              variant="primary"
              buttonStyle="filled"
              size="medium"
              icon="MessageCircle"
            />
          </div>
        </div>
      )}
      <div className="space-y-4">
        {events.map((event) => {
          const { icon, iconColor } = getLogTypeDetails(event.log_type, event.content);

          return (
            <div
              key={event.id}
              className={`flex items-start space-x-3 p-3 rounded-md ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'
              }`}
              onClick={() => handleEventClick(event)}
            >
              <div className="flex-shrink-0">
                <Icon name={icon} size="md" className={iconColor} />
              </div>
              <div className="flex-grow">
                <div className="flex justify-between items-start">
                  <span className="font-semibold text-sm">{event.user}</span>
                  <span className="text-xs text-gray-500">{event.timestamp}</span>
                </div>
                <div 
                  className="text-sm mt-1"
                  dangerouslySetInnerHTML={createMarkup(event.content)}
                />
              </div>
              {event.log_type === 'Comment' && onDeleteEvent && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteEvent(event.id);
                  }}
                  className="ml-2 cursor-pointer hover:text-red-600"
                >
                  <Icon
                    name="MessageCircleX"
                    size="md"
                    color="text"
                  />
                </div>
              )}
              {event.tag && (
                <span className="text-xs px-2 py-1 bg-accent text-white rounded-full ml-2">
                  {event.tag}
                </span>
              )}
            </div>
          );
        })}
      </div>
      {showSnackbar && (
        <Snackbar
          message="Comment added successfully!"
          type="success"
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

// Main ActivityFeed component with optional Timeline tab
export const ActivityFeed: React.FC<ActivityFeedProps> = ({
  events,
  timelineEvents = [], // Default to an empty array
  isDisabled,
  onEventClick,
  onAddComment,
  onDeleteEvent,
  parentObjectType,
  parentObjectId,
  showTimeline = false,  // New prop to conditionally show the Timeline tab
}) => {

  // Conditionally render the Tabs component if showTimeline is true
  if (showTimeline) {
    return (
      <Tabs
        tabs={[
          { label: 'Activity Feed', content: <ActivityFeedView {...{ events, isDisabled, onEventClick, onAddComment, onDeleteEvent, parentObjectType, parentObjectId }} /> },
          { label: 'Timeline', content: <Timeline events={timelineEvents} showElapsed /> }
        ]}
      />
    );
  }

  // Fallback to just the ActivityFeed view when Timeline is not enabled
  return <ActivityFeedView {...{ events, isDisabled, onEventClick, onAddComment, onDeleteEvent, parentObjectType, parentObjectId }} />;
};

export default ActivityFeed;
