import React from 'react';
import { Table, TableDateComponent, TableActionButtons, TablePersonsComponent, TableBadgeComponent, TableTagsComponent, TableInlineTextInput } from '../ui';
import { topicStateOptions, heatmapStatusOptions, importanceOptions } from '../../utils/entitiesOptions';
import { Topic } from '../../api/topic/types';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';

interface TopicTableProps {
  data: Topic[];
  onEdit: (topic: Topic) => void;
  onDelete: (id: string) => void;
  onUpdateState: (topicId: string, newState: string) => void;
  onUpdateTags: (topicId: string, newTags: Tag[]) => void;
  onUpdatePersons: (topicId: string, newPersons: Person[]) => void;
  onUpdateTitle: (topicId: string, newTitle: string) => void;
}

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value; // Fallback to value if no label is found
};

export const TopicTable: React.FC<TopicTableProps> = ({ 
  data, 
  onEdit, 
  onDelete, 
  onUpdateState, 
  onUpdateTags,
  onUpdatePersons,
  onUpdateTitle
}) => {
  return (
    <Table
      data={data}
      columns={[{
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onEdit(row)} // Trigger the onEdit function when clicking the title
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.title}
                onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
                onCancel={() => {}}
              />
            </div>
          ),
        },      
        {
          header: 'State',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.state, topicStateOptions)} // Use readable label
              type="state"
              onUpdate={(newState) => onUpdateState(row.slug, newState)}
              options={topicStateOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Heatmap Status',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.heatmap_status, heatmapStatusOptions)} // Use readable label
              type="heatmap_status"
              onUpdate={(newState) => onUpdateState(row.slug, newState)}
              options={heatmapStatusOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Importance',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.importance.toString(), importanceOptions)} // Use readable label
              type="importance"
              onUpdate={(newState) => onUpdateState(row.slug, newState)}
              options={importanceOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Persons',
          component: (row) => (
            <TablePersonsComponent
              persons={row.persons}
              isEditable={true}
              onUpdate={(newPersons) => onUpdatePersons(row.slug, newPersons)}
            />
          ),
        },
        {
          header: 'Tags',
          component: (row) => (
            <TableTagsComponent
              tags={row.tags}
              isEditable={true}
              onUpdate={(newTags) => {
                const updatedTags = newTags.map(tag => ({
                  ...tag,
                  created_at: tag.created_at || new Date().toISOString(),
                  updated_at: new Date().toISOString()
                }));
                onUpdateTags(row.slug, updatedTags);
              }}
            />
          ),
        },
        {
          header: 'Created At',
          component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
        },
        {
          header: 'Last Updated',
          component: (row) => <TableDateComponent dateString={row.updated_at} showTime={false} />,
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        }
      ]}
    />
  );
};
