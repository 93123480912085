import React from 'react';
import { Task } from '../../api/task/types';
import { Table, TableDateComponent, TableActionButtons, TableBadgeComponent, TablePersonsComponent, TableTagsComponent, TablePersonComponent, TableInlineTextInput } from '../ui';
import { taskStateOptions, dialStageOptions } from '../../utils/entitiesOptions'; // Import from entitiesOptions
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';

interface TaskTableProps {
  data: Task[];
  onEdit: (task: Task) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Task['state']) => void;
  onUpdateDialStage: (slug: string, newDialStage: Task['dial_stage']) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  onUpdateStartDate: (slug: string, newDate: string) => void;
  onUpdateDueDate: (slug: string, newDate: string) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdateTitle: (slug: string, newTitle: string) => void;
}

// Utility function to get the human-readable label from task state options
const getTaskStateLabel = (state: string): string => {
  const foundOption = taskStateOptions.find(option => option.value === state);
  return foundOption ? foundOption.label : 'Unknown';
};

// Utility function to get the human-readable label from dial stage options
const getDialStageLabel = (dialStage: string): string => {
  const foundOption = dialStageOptions.find(option => option.value === dialStage);
  return foundOption ? foundOption.label : 'Unknown';
};

export const TaskTable: React.FC<TaskTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateDialStage,
  onUpdateOwner,
  onUpdateStartDate,
  onUpdateDueDate,
  onUpdateTags,
  onUpdateTitle
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onEdit(row)} // Trigger the onEdit function when clicking the title
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.title}
                onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
                onCancel={() => {}}
              />
            </div>
          ),
        },        
        {
          header: 'State',
          component: (row) => (
            <TableBadgeComponent
              label={getTaskStateLabel(row.state)}  // Use human-readable label
              type="state"
              onUpdate={(newState) => onUpdateState(row.slug, newState as Task['state'])}
              options={taskStateOptions}  // Use options from entitiesOptions
              isEditable={true}
            />
          ),
        },
        {
          header: 'Dial Stage',
          component: (row) => (
            <TableBadgeComponent
              label={getDialStageLabel(row.dial_stage)}  // Use human-readable label
              type="dial_stage"
              onUpdate={(newDialStage) => onUpdateDialStage(row.slug, newDialStage as Task['dial_stage'])}
              options={dialStageOptions}  // Use options from entitiesOptions
              isEditable={true}
            />
          ),
        },
        {
          header: 'Owner',
          component: (row) => (
            <TablePersonComponent
              person={row.owner}
              isEditable={true}
              onUpdate={(newOwner) => onUpdateOwner(row.slug, newOwner)}
            />
          ),
        },
        {
          header: 'Start Date',
          component: (row) => (
            <TableDateComponent
              dateString={row.start_date}
              isEditable={true}
              onUpdate={(newDate) => onUpdateStartDate(row.slug, newDate)}
            />
          ),
        },
        {
          header: 'Due Date',
          component: (row) => (
            <TableDateComponent
              dateString={row.due_date}
              isEditable={true}
              onUpdate={(newDate) => onUpdateDueDate(row.slug, newDate)}
            />
          ),
        },
        {
          header: 'Tags',
          component: (row) => (
            <TableTagsComponent
              tags={row.tags}
              isEditable={true}
              onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
            />
          ),
        },
        {
          header: 'Created At',
          component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};
