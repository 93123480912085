import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectSource, ProjectImport, ProjectMapping, Integration, Team } from '../../api/integration/types';
import ProjectImportContainer from '../../containers/ProjectImportContainer';
import IntegrationContainer from '../../containers/IntegrationContainer';
import { Button, Icon, TextInput, Card, CardContent, Steps, ProgressIndicator, SelectSingle, Datepicker, Checkbox } from '../ui';
import { projectStateOptions, priorityOptions, effortOptions } from '../../utils/entitiesOptions';
import PersonSelect from '../person/PersonSelect';
import TeamSelect from '../team/TeamSelect';
import TagSelect from '../tags/TagSelect';
import { Project } from '../../api/project/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import { fetchTeams } from '../../api/integration/api';

type ImportStep = 'select' | 'map' | 'review' | 'summary';

interface ProjectToImport extends ProjectSource {
    selected: boolean;
    name: string;
    metadata: {
      progress: number;
      start_date?: string | null;
      target_date?: string | null;
      lead_id?: string | null;
      lead_name?: string | null;
      lead_email?: string | null;
      created: string;
      updated: string;
      [key: string]: any;
    };
    mappings: {
      state: string;
      priority: string;
      effort: string;
      owner?: Person | null;
      team?: Team | null;
      tags: Tag[];
      visibility: 'public' | 'private';
    };
  }

const steps = [
  { id: 1, title: 'Select', description: 'Select projects to import' },
  { id: 2, title: 'Map Fields', description: 'Map project fields' },
  { id: 3, title: 'Review', description: 'Review and confirm' },
  { id: 4, title: 'Summary', description: 'Summary of imported projects' },
];

// Add Linear state mapping
const LINEAR_STATE_MAPPING: { [key: string]: string } = {
  'backlog': 'BACKLOG',
  'planned': 'DEFINITION_AND_ANALYSIS',
  'started': 'DEVELOPMENT_IN_PROGRESS',
  'completed': 'DONE',
  'canceled': 'CANDIDATE'
};

// Add Jira mapping
const JIRA_STATE_MAPPING: { [key: string]: string } = {
  'to do': 'BACKLOG',
  'in progress': 'DEVELOPMENT_IN_PROGRESS',
  'done': 'DONE',
  'closed': 'DONE',
  'blocked': 'BLOCKED'
};

const ProjectImportPage: React.FC = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [integrationSearchQuery, setIntegrationSearchQuery] = useState('');
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
  const [projects, setProjects] = useState<ProjectSource[]>([]);
  const [localProjects, setLocalProjects] = useState<ProjectToImport[]>([]);
  const [currentMappingIndex, setCurrentMappingIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [importedProjects, setImportedProjects] = useState<ProjectMapping[]>([]);
  const [showProjects, setShowProjects] = useState(false);
  const [importError, setImportError] = useState<string | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [loadingTeams, setLoadingTeams] = useState(false);

  useEffect(() => {
    if (!showProjects || !selectedIntegration) {
      setProjects([]);
      setLocalProjects([]);
    }
  }, [showProjects, selectedIntegration]);

  const handleRetrieveProjects = async (refreshProjects: () => Promise<void>) => {
    try {
      setShowProjects(false);
      setLocalProjects([]); // Clear local projects before fetching
      await refreshProjects();
      setShowProjects(true);
    } catch (error) {
      console.error('Failed to retrieve projects:', error);
    }
  };

  const fetchAndSetTeams = async (integrationId: string) => {
    try {
      setLoadingTeams(true);
      const fetchedTeams = await fetchTeams(integrationId, navigate); // Fetch teams
      setTeams(
        fetchedTeams.map((team) => ({
          id: team.id,
          name: team.name,
        })) // Map to the required structure
      );
    } catch (err) {
      console.error("Failed to fetch teams:", err);
    } finally {
      setLoadingTeams(false);
    }
  };
  
  
  const handleIntegrationSelect = (integration: Integration) => {
    setSelectedIntegration(integration);
    setSelectedTeam(null); // Reset team selection
    setShowProjects(false);
    fetchAndSetTeams(integration.id); // Fetch teams for this integration
  };

  const initializeLocalProjects = (projects: ProjectSource[]) => {
    setLocalProjects(
      projects.map((source) => ({
        ...source,
        selected: false,
        metadata: {
          ...source.metadata,
          start_date: source.metadata.start_date || null,
          target_date: source.metadata.due_date || source.metadata.target_date || null,
        },
        mappings: {
          state: source.status,
          priority: '3',
          effort: 'M',
          owner: null,
          team: null,
          tags: [],
          visibility: 'private'
        },
      }))
    );
  };

  const handleImport = async (importSelectedProjects: (projects: ProjectImport[]) => Promise<{ message: string; projects: ProjectMapping[] }>) => {
    const selectedProjects = localProjects.filter((p) => p.selected);
    if (selectedProjects.length === 0) return;
  
    try {
      setImportError(null);
      setIsImporting(true);
      const response = await importSelectedProjects(
        selectedProjects.map((p) => ({
          external_id: p.id,
          mappings: {
            state: p.mappings.state,
            priority: p.mappings.priority,
            effort: p.mappings.effort,
            title: p.name,
            start_date: p.metadata.start_date || undefined,
            target_date: p.metadata.target_date || undefined,
          }
        }))
      );
  
      setImportedProjects(response.projects);
      setCurrentStep(4);
    } catch (err: any) {
      console.error('Failed to import projects:', err);
      if (err.response?.status === 409) {
        // Handle existing projects error
        const existingProjects = err.response.data.existing_projects;
        const existingProjectNames = localProjects
          .filter(p => existingProjects.includes(p.id))
          .map(p => p.name)
          .join(', ');
        
        setImportError(
          `The following projects have already been imported: ${existingProjectNames}. Please remove them from selection.`
        );
      } else {
        const errorMessage = err.response?.data?.error || err.message || 'Failed to import projects';
        setImportError(errorMessage);
      }
    } finally {
      setIsImporting(false);
    }
  };

  useEffect(() => {
    initializeLocalProjects(projects);
  }, [projects]);

  const renderSelectStep = ({
    integrations,
    loading: integrationsLoading,
    refreshProjects,
    teams: containerTeams, // Pass teams
    selectedTeam: containerSelectedTeam, // Pass selectedTeam
    setTeamFilter, // Pass setTeamFilter from container
  }: {
    integrations: Integration[];
    loading: boolean;
    refreshProjects: () => Promise<void>;
    teams: Team[];
    selectedTeam: string | null;
    setTeamFilter: (teamId: string | null) => void;
  }) => (
    <div className="space-y-4">
      {integrationsLoading ? (
        <div className="flex justify-center p-8">
          <Icon name="Loader" className="animate-spin text-primary" />
        </div>
      ) : (
        <>
          <div className="space-y-2">
            {integrations.map((integration) => (
              <div
                key={integration.id}
                className={`flex items-start p-4 border rounded-md cursor-pointer ${
                  selectedIntegration?.id === integration.id
                    ? 'border-primary bg-primary bg-opacity-5'
                    : 'border-neutral'
                }`}
                onClick={() => {
                  setSelectedIntegration(integration);
                  setShowProjects(false);
                  fetchAndSetTeams(integration.id); // Fetch teams when an integration is selected
                }}
              >
                <div className="flex-1">
                  <div className="font-medium">{integration.name}</div>
                  <div className="text-sm text-text opacity-70">
                    {integration.provider}
                  </div>
                  <div className="text-xs text-text opacity-70 mt-1">
                    Last synced:{' '}
                    {integration.last_sync_at
                      ? new Date(integration.last_sync_at).toLocaleString()
                      : 'Never'}
                  </div>
                </div>
                <div
                  className={`w-4 h-4 rounded-full border-2 ${
                    selectedIntegration?.id === integration.id
                      ? 'border-primary bg-primary'
                      : 'border-neutral'
                  }`}
                />
              </div>
            ))}
          </div>
  
          {selectedIntegration && (
            <div className="space-y-4">
              <h3 className="text-md font-medium">Select a Team</h3>
              {loadingTeams ? (
                <p className="text-sm text-text opacity-70">Loading teams...</p>
              ) : (
                <SelectSingle
                  options={[
                    { value: '', label: 'All Teams' },
                    ...teams.map((team) => ({
                      value: team.id,
                      label: team.name,
                    })),
                  ]}
                  defaultValue={selectedTeam || ''}
                  onSelect={(teamId: string) => {
                    console.log('Team selected for filter:', teamId); // Log the selected team
                    setTeamFilter(teamId || null); // Update filter via container prop
                  }}
                  placeholder="Select a team"
                />
              )}
            </div>
          )}
  
          <div className="flex justify-end pt-4">
          <Button
            label="Retrieve Projects"
            variant="primary"
            onClick={() => handleRetrieveProjects(refreshProjects)} // Pass refreshProjects as is
            loading={loading}
            disabled={!selectedIntegration}
          />
          </div>
        </>
      )}
  
      {showProjects && localProjects.length > 0 && (
        <Card>
          <CardContent className="space-y-4">
            <div className="flex justify-between items-center">
              <TextInput
                placeholder="Search projects..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                startIcon={<Icon name="Search" size="sm" />}
              />
            </div>
            <div className="space-y-2">
              {localProjects
                .filter((project) =>
                  project.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((project) => (
                  <div
                    key={project.id}
                    className={`flex items-start p-4 border rounded-md cursor-pointer bg-white ${
                      project.selected
                        ? 'border-primary bg-primary bg-opacity-5'
                        : 'border-neutral'
                    }`}
                    onClick={() => {
                      if (!project.exists) {
                        setLocalProjects((prev) =>
                          prev.map((p) =>
                            p.id === project.id ? { ...p, selected: !p.selected } : p
                          )
                        );
                      }
                    }}
                  >
                    <div className="flex-1">
                      <div className="font-medium text-sm flex items-center gap-2">
                        {project.name}
                        {project.exists && (
                          <span className="text-xs px-2 py-0.5 bg-neutral text-text rounded">
                            Already imported
                          </span>
                        )}
                      </div>
                      {project.description && (
                        <div className="text-sm text-text opacity-70 mt-1">
                          {project.description}
                        </div>
                      )}
                    </div>
                    {!project.exists && (
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          project.selected
                            ? 'border-primary bg-primary'
                            : 'border-neutral'
                        }`}
                      />
                    )}
                  </div>
                ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );  

  const renderMapStep = () => {
    const selectedProjects = localProjects.filter((p) => p.selected);
    if (selectedProjects.length === 0) return <p className='text-sm text-text opacity-70'>No selected projects to map.</p>;
  
    return (
      <div className="space-y-6">
        <h3 className="text-lg font-medium">Configure Projects</h3>
        <p className="text-sm text-text opacity-70">
          Configure each project's fields individually
        </p>
  
        <Card>
          <CardContent className="space-y-6">
            <div className="border-b border-neutral pb-4">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="font-medium">Project {currentMappingIndex + 1} of {selectedProjects.length}</h4>
                  <p className="text-sm text-text opacity-70">{selectedProjects[currentMappingIndex].name}</p>
                </div>
                <div className="flex space-x-2">
                  <Button
                    label="Previous"
                    variant="secondary"
                    buttonStyle="outlined"
                    disabled={currentMappingIndex === 0}
                    onClick={() => setCurrentMappingIndex(currentMappingIndex - 1)}
                  />
                  <Button
                    label="Next"
                    variant="secondary"
                    buttonStyle="outlined"
                    disabled={currentMappingIndex === selectedProjects.length - 1}
                    onClick={() => setCurrentMappingIndex(currentMappingIndex + 1)}
                  />
                </div>
              </div>
            </div>
  
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">Title</label>
                <TextInput
                  value={selectedProjects[currentMappingIndex].name}
                  onChange={(e) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, name: e.target.value }
                          : p
                      )
                    );
                  }}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Project State</label>
                <SelectSingle
                  options={projectStateOptions}
                  placeholder="Select State"
                  defaultValue={selectedProjects[currentMappingIndex].mappings.state}
                  onSelect={(value) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, state: value } }
                          : p
                      )
                    );
                  }}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Priority</label>
                <SelectSingle
                  options={priorityOptions}
                  placeholder="Select Priority"
                  defaultValue={selectedProjects[currentMappingIndex].mappings.priority}
                  onSelect={(value) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, priority: value } }
                          : p
                      )
                    );
                  }}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Effort</label>
                <SelectSingle
                  options={effortOptions}
                  placeholder="Select Effort"
                  defaultValue={selectedProjects[currentMappingIndex].mappings.effort}
                  onSelect={(value) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, effort: value } }
                          : p
                      )
                    );
                  }}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Start Date</label>
                <Datepicker
                  value={selectedProjects[currentMappingIndex].metadata.start_date || ''}
                  onChange={(date) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, metadata: { ...p.metadata, start_date: date } }
                          : p
                      )
                    );
                  }}
                  placeholder="Select Start Date"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Target Date</label>
                <Datepicker
                  value={selectedProjects[currentMappingIndex].metadata.target_date || ''}
                  onChange={(date) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, metadata: { ...p.metadata, target_date: date } }
                          : p
                      )
                    );
                  }}
                  placeholder="Select Target Date"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Owner</label>
                <PersonSelect
                  value={selectedProjects[currentMappingIndex].mappings.owner?.id || ''}
                  onChange={(person) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, owner: person } }
                          : p
                      )
                    );
                  }}
                  enableSearch={true}
                  placeholder="Select an owner"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Team</label>
                <TeamSelect
                  value={selectedProjects[currentMappingIndex].mappings.team?.id || ''}
                  onChange={(team) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, team: team } }
                          : p
                      )
                    );
                  }}
                  placeholder="Select a team"
                  enableSearch={true}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Tags</label>
                <TagSelect
                  value={selectedProjects[currentMappingIndex].mappings.tags?.map((tag) => tag.id) || []}
                  onChange={(tags) => {
                    setLocalProjects((prev) =>
                      prev.map((p) =>
                        p.id === selectedProjects[currentMappingIndex].id
                          ? { ...p, mappings: { ...p.mappings, tags: tags } }
                          : p
                      )
                    );
                  }}
                  enableSearch={true}
                />
              </div>
            </div>
  
            <div className="border-t border-neutral pt-4">
              <p className="text-sm text-text opacity-70">
                Projects will be imported with visibility set to private.
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderReviewStep = ({
    importSelectedProjects,
  }: {
    importSelectedProjects: (projects: ProjectImport[]) => Promise<{ message: string; projects: ProjectMapping[] }>;
  }) => (
    <div className="space-y-4">
      <h3 className="font-medium text-lg">Projects to Import</h3>
      {importError && (
        <div className="p-4 border border-secondary bg-secondary bg-opacity-10 rounded-md text-secondary text-sm flex justify-between items-center">
          <span>{importError}</span>
          <div className="relative group">
            <Icon 
              name="Info" 
              size="sm" 
              className="text-secondary cursor-help"
            />
            <div className="absolute bottom-full right-0 mb-2 hidden group-hover:block w-80">
              <div className="bg-background border border-neutral shadow-lg rounded-md p-2 text-xs">
                Failed to import projects. Some projects might have already been imported.
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="space-y-2">
        {localProjects.filter((p) => p.selected).map((project) => (
          <div key={project.id} className="p-4 border border-neutral rounded-md bg-white">
            <div className="font-medium text-sm">{project.name}</div>
            <div className="text-sm text-text opacity-70">
              <div>State: {project.mappings?.state}</div>
              <div>Priority: {project.mappings?.priority}</div>
              <div>Effort: {project.mappings?.effort}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          label="Import Projects"
          variant="primary"
          onClick={() => handleImport(importSelectedProjects)}
          loading={isImporting}
        />
      </div>
    </div>
  );

  const renderSummaryStep = () => (
    <div className="space-y-4">
      <h3 className="font-medium text-lg">Import Summary</h3>
      <div className="space-y-2">
        {importedProjects.map((project) => (
          <div key={project.id} className="p-4 border border-neutral rounded-md bg-white">
            <div className="font-medium text-sm">
              <a 
                href={`/projects/${project.project?.slug}`}
                className="hover:text-primary"
              >
                {project.project?.title || 'Untitled Project'}
              </a>
            </div>
            <div className="text-sm text-text opacity-70 mt-1">
              <a
                href={project.external_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                View External Project
              </a>
            </div>
            <div className="text-xs text-text opacity-70 mt-1">
              Last Synced: {new Date(project.last_sync_at).toLocaleString()}
            </div>
          </div>
        ))}
      </div>
      <Button
        label="Go to Projects"
        variant="primary"
        onClick={() => navigate('/projects')}
      />
    </div>
  );

  return (
    <IntegrationContainer>
      {({ integrations, loading: integrationsLoading }) => (
        <ProjectImportContainer
        integrationId={showProjects && selectedIntegration ? selectedIntegration.id : undefined}
        children={({
          projects: containerProjects,
          loading: containerLoading,
          error: containerError,
          importSelectedProjects,
          refreshProjects,
          teams: containerTeams, // Ensure teams are passed
          selectedTeam: containerSelectedTeam, // Ensure selectedTeam is passed
          setTeamFilter, // Ensure setTeamFilter is passed
        }) => {
          setProjects(containerProjects);
          setLoading(containerLoading);
          setError(containerError);
      
          return (
            <div className="container mx-auto p-4">
              <Steps steps={steps} currentStep={currentStep - 1} />
              <ProgressIndicator
                steps={steps.map((s) => s.title)}
                currentStep={currentStep - 1}
                variant="bars"
              />
              <div className="mt-6">
                {currentStep === 1 &&
                  renderSelectStep({
                    integrations,
                    loading: integrationsLoading,
                    refreshProjects,
                    teams: containerTeams, // Pass teams
                    selectedTeam: containerSelectedTeam, // Pass selected team
                    setTeamFilter, // Pass setTeamFilter
                  })}
                {currentStep === 2 && renderMapStep()}
                {currentStep === 3 && renderReviewStep({ importSelectedProjects })}
                {currentStep === 4 && renderSummaryStep()}
              </div>
              <div className="flex justify-between mt-6">
                {currentStep > 1 && currentStep < 4 && (
                  <Button
                    label="Back"
                    variant="secondary"
                    buttonStyle="outlined"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                )}
                {currentStep < steps.length - 1 && currentStep !== 3 && (
                  <Button
                    label="Next"
                    variant="primary"
                    disabled={
                      currentStep === 1 && !localProjects.some((p) => p.selected)
                    }
                    onClick={() => setCurrentStep(currentStep + 1)}
                  />
                )}
              </div>
            </div>
          );
        }}
      />
      
      
      )}
    </IntegrationContainer>
  );
};

export default ProjectImportPage;