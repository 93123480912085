import React from 'react';

interface TableCountComponentProps {
  count: number | null | undefined;
  label?: string;
  noItemsPlaceholder?: string;
}

export const TableCountComponent: React.FC<TableCountComponentProps> = ({ 
  count, 
  label = "items", 
  noItemsPlaceholder = "No items" 
}) => {
  if (count === null || count === undefined || count === 0) {
    return <span className="text-xs text-gray-500">{noItemsPlaceholder}</span>;
  }

  return (
    <span className="text-xs text-text">
      {count} {count === 1 ? label.replace(/s$/, '') : label}
    </span>
  );
};