import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModalContent, SelectSingle, Datepicker, SeparatorHorizontal, Button } from '../ui'; 
import { MeetingOccurrence, MeetingTopicNote, MeetingType } from '../../api/meeting/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import { Task } from '../../api/task/types';
import { Project } from '../../api/project/types';
import { Topic } from '../../api/topic/types';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TagSelect from '../tags/TagSelect';
import { SelectOption } from '../ui/Select';
import { useNavigate } from 'react-router-dom';
import MeetingTypeContainer from '../../containers/MeetingTypeContainer';
import { AccordionList, AccordionItem } from '../ui/AccordionList';
import MeetingTopicNoteContainer from '../../containers/MeetingTopicNoteContainer';
import MeetingOccurrenceSidePanel from './MeetingOccurrenceSidePanel';
import { useMeetingType } from '../../api/meeting/queries';

interface MeetingOccurrenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>, slug?: string) => Promise<MeetingOccurrence>;
  onEdit: (slug: string, updatedOccurrence: Partial<MeetingOccurrence>) => Promise<MeetingOccurrence>;
  initialData?: Omit<MeetingOccurrence, 'created_at' | 'updated_at'>;
  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;
}

export const MeetingOccurrenceModal: React.FC<MeetingOccurrenceModalProps> = ({ 
  isOpen, 
  onClose, 
  onSave, 
  onEdit, 
  initialData,
  onSuccess,
  onError 
}) => {
  const [occurrenceData, setOccurrenceData] = useState<Partial<MeetingOccurrence>>({
    date: new Date().toISOString().split('T')[0],
    attendees: [],
    tags: [],
    meeting_type: undefined,
    company: 'default-company-id',
    tasks: [],
    projects: [],
  });
  const [title, setTitle] = useState(initialData?.title || '');
  const [description, setDescription] = useState('');
  const [isPersonSelectOpen, setIsPersonSelectOpen] = useState(false);
  const [meetingTypeSlug, setMeetingTypeSlug] = useState<string | undefined>(undefined);
  const [savedOccurrenceId, setSavedOccurrenceId] = useState<string | undefined>(initialData?.id);
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [topicMap, setTopicMap] = useState<{ [key: string]: Topic }>({});
  
  const isInitialMount = useRef(true);
  const initialTitleRef = useRef('');
  const initialDescriptionRef = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedOccurrence: Partial<MeetingOccurrence>) => Promise<MeetingOccurrence>) | null>(null);
  const navigate = useNavigate();

  const { data: meetingTypeDetails } = useMeetingType(meetingTypeSlug || "");

  const isEditMode = !!initialData;

  useEffect(() => {
    if (isInitialMount.current) {
      if (initialData) {
        setOccurrenceData({
          ...initialData,
          date: initialData.date,
        });
        setTitle(initialData.title);
        setDescription(initialData.description || '');
        initialTitleRef.current = initialData.title;
        initialDescriptionRef.current = initialData.description || '';
        setMeetingTypeSlug(
          typeof initialData.meeting_type === 'object' && initialData.meeting_type !== null
            ? initialData.meeting_type.slug
            : undefined
        );
        setSavedOccurrenceId(initialData.id);

        const newAccordionItems = initialData.topic_notes?.map((note) => ({
          id: `accordion-${note.topic.id}`,
          title: note.topic.title,
          content: note.notes,
          descriptionPlaceholder: `Enter description for ${note.topic.title}`,
          discussion_order: note.discussion_order,
        })) || [];

        const newTopicMap: Record<string, Topic> = initialData.topic_notes?.reduce((acc, note) => {
          acc[`accordion-${note.topic.id}`] = note.topic;
          return acc;
        }, {} as Record<string, Topic>) || {};

        newAccordionItems.sort((a, b) => a.discussion_order - b.discussion_order);

        setAccordionItems(newAccordionItems);
        setTopicMap(newTopicMap);
      }
      isInitialMount.current = false;
    }
  }, [initialData]);

  // Save on refresh/unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [initialData?.slug, description]);

  // 30m autosave
  useEffect(() => {
    if (!initialData?.slug || !editFunctionRef.current || description === initialDescriptionRef.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(autoSaveTimer);
  }, [description, initialData?.slug]);

  useEffect(() => {
    if (meetingTypeDetails && !isEditMode) {
      // Set tags and attendees based on meeting type for create mode
      setOccurrenceData(prev => ({
        ...prev,
        attendees: meetingTypeDetails.regular_attendees || [],
        tags: meetingTypeDetails.tags || [],
      }));

      // Set the description only if it's empty
      if (!description || description.trim() === '') {
        setDescription(meetingTypeDetails.description || '');
      }

      // Clear the accordion list in create mode
      setAccordionItems([]);
      setTopicMap({});
    }

    if (meetingTypeDetails && isEditMode) {
      // For edit mode, set accordion items based on existing topic notes
      const newAccordionItems = meetingTypeDetails.backlog_topics?.map((topic) => ({
        id: `accordion-${topic.id}`,
        title: topic.title,
        content: '',
        descriptionPlaceholder: `Enter description for ${topic.title}`,
      })) || [];

      const newTopicMap: Record<string, Topic> = meetingTypeDetails.backlog_topics?.reduce((acc, topic) => {
        acc[`accordion-${topic.id}`] = topic;
        return acc;
      }, {} as Record<string, Topic>) || {};

      setAccordionItems(newAccordionItems);
      setTopicMap(newTopicMap);
    }
}, [meetingTypeDetails, isEditMode]);


  const handleChange = useCallback((field: keyof Omit<MeetingOccurrence, 'title'>, value: any) => {
    setOccurrenceData(prevData => ({ ...prevData, [field]: value }));

    if (isEditMode && initialData?.slug) {
      onEdit(initialData.slug, { [field]: value });
    }
  }, [isEditMode, initialData, onEdit]);

  const handleTitleBlur = useCallback(() => {
    if (initialData?.slug && title !== initialTitleRef.current) {
      onEdit(initialData.slug, { title });
      initialTitleRef.current = title;
    }
  }, [initialData, title, onEdit]);

  const handleClose = useCallback(() => {
    if (initialData?.slug && description !== initialDescriptionRef.current) {
      onEdit(initialData.slug, { description });
      initialDescriptionRef.current = description;
    }
    onClose();
  }, [initialData, description, onEdit, onClose]);

  const handleSave = async (
    onCreateTopicNote: (note: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at' | 'meeting_occurrence'>, occurrenceId: string) => void | Promise<void>,
    onEditTopicNote: (id: string, updatedNote: Partial<MeetingTopicNote>) => void | Promise<void>,
    onDeleteTopicNote: (id: string) => void | Promise<void>
  ) => {
    if (!title.trim()) {
      alert('Please provide a title');
      return;
    }
  
    const dataToSave = {
      ...occurrenceData,
      title,
      description,
      date: occurrenceData.date,
    };
  
    try {
      const savedOccurrence = await onSave(dataToSave as Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>, initialData?.slug);
  
      if (!savedOccurrence || !savedOccurrence.id) {
        throw new Error('Failed to get the ID of the saved occurrence');
      }
  
      const newOccurrenceId = savedOccurrence.id;
      setSavedOccurrenceId(newOccurrenceId);
      initialDescriptionRef.current = description;
  
      // Delete removed topic notes
      const currentTopicIds = Object.values(topicMap).map((topic) => topic.id);
      const removedTopicNotes = initialData?.topic_notes?.filter((note) => !currentTopicIds.includes(note.topic.id)) || [];
  
      for (const note of removedTopicNotes) {
        await Promise.resolve(onDeleteTopicNote(note.id));
      }
  
      // Create or edit topic notes
      for (const item of accordionItems) {
        const topic = topicMap[item.id];
        if (!topic) continue;
  
        const noteData: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at' | 'meeting_occurrence'> = {
          topic: topic,
          notes: item.content,
          is_discussed: false,
          discussion_order: accordionItems.indexOf(item),
        };
  
        // Check if this note already exists
        const existingNote = initialData?.topic_notes?.find(note => note.topic.id === topic.id);
  
        if (existingNote) {
          // Edit existing note
          await Promise.resolve(onEditTopicNote(existingNote.id, noteData));
        } else {
          // Create new note
          await Promise.resolve(onCreateTopicNote(noteData, newOccurrenceId));
        }
      }
  
      onClose();
      if (onSuccess) onSuccess('Meeting occurrence and topic notes saved successfully');
    } catch (error) {
      console.error('Error saving Meeting Occurrence or Topic Notes:', error);
      if (onError) onError('Failed to save Meeting Occurrence or Topic Notes.');
      alert('Failed to save Meeting Occurrence or Topic Notes. Please try again.');
    }
  };
  
  // Rest of handlers stay the same...
  const handleTopicClick = (topic: Topic) => {
    const exists = Object.values(topicMap).some((existingTopic) => existingTopic.id === topic.id);

    if (exists) {
      return;
    }

    const newAccordionItemId = `accordion-${topic.id}`;

    const newAccordionItem: AccordionItem = {
      id: newAccordionItemId,
      title: topic.title,
      content: '',
      descriptionPlaceholder: `Enter description for ${topic.title}`,
    };
    setAccordionItems((prev) => [...prev, newAccordionItem]);

    setTopicMap((prev) => ({ ...prev, [newAccordionItemId]: topic }));
  };

  const updateAccordionContent = (id: string, content: string) => {
    setAccordionItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, content } : item
      )
    );
  };

  const removeAccordionItem = (id: string) => {
    setAccordionItems((prevItems) => prevItems.filter((item) => item.id !== id));
    setTopicMap((prev) => {
      const newMap = { ...prev };
      delete newMap[id];
      return newMap;
    });
  };

  const addNewAccordionItem = () => {
    const newId = `accordion-${Date.now()}`;
    const newItem: AccordionItem = {
      id: newId,
      title: `New Topic ${accordionItems.length + 1}`,
      content: '',
      descriptionPlaceholder: 'Enter description for the new topic',
    };
    setAccordionItems((prev) => [...prev, newItem]);
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  if (!isOpen) return null;

  return (
    <MeetingTopicNoteContainer
      meetingOccurrenceId={savedOccurrenceId || ''}
      render={({ onCreate, onEdit: onEditTopicNote, onDelete }) => {
        // Keep track of edit function
        editFunctionRef.current = onEdit;

        return (
          <MeetingTypeContainer
            render={({ data: meetingTypes, loading, error }) => {
              if (loading) return <div>Loading Meeting Types...</div>;
              if (error) return <div>{error}</div>;
  
              const meetingTypeOptions: SelectOption[] = meetingTypes.map((type) => ({
                label: type.name, 
                value: type.id, 
                slug: type.slug,
              }));

              return (
                <ModalContent
                  title={title}
                  description={description}
                  onCancel={handleClose}
                  onCreate={() => handleSave(onCreate, onEditTopicNote, onDelete)}
                  onTitleChange={setTitle}
                  onTitleBlur={handleTitleBlur}
                  onDescriptionChange={setDescription}
                  actionButtonLabel={isEditMode ? 'Save Changes' : 'Create Meeting Occurrence'}                
                  showSidePanel={true}
                  showFooterButtons={true}
                  sidePanelContent={
                    isSidePanelOpen && occurrenceData.meeting_type && (
                      <MeetingOccurrenceSidePanel
                        meetingTypeId={typeof occurrenceData.meeting_type === 'string' ? occurrenceData.meeting_type : occurrenceData.meeting_type.id}
                        onTopicClick={handleTopicClick}
                      />
                    )
                  }
                  selects={[
                    {
                      component: SelectSingle,
                      props: {
                        options: meetingTypeOptions,
                        placeholder: 'Select Meeting Type',
                        value: typeof occurrenceData.meeting_type === 'string' ? occurrenceData.meeting_type : occurrenceData.meeting_type?.id || '',
                        onSelect: (value: string) => {
                          const selectedMeetingType = meetingTypeOptions.find((option) => option.value === value);
                          if (selectedMeetingType) {
                            handleChange('meeting_type', selectedMeetingType.value);
                            setMeetingTypeSlug(selectedMeetingType.slug);
                          }
                        },
                        isDisabled: isEditMode,
                      },
                    },
                    {
                      component: () => (
                        <Datepicker
                          defaultValue={occurrenceData.date || ''}
                          onChange={(newDate: string) => handleChange('date', newDate)}
                          placeholder="Select Date"
                        />
                    
                      ),
                      props: {},
                    },
                    {
                      component: PersonSelectMulti,
                      props: {
                        value: occurrenceData.attendees?.map((person) => person.id) || [],
                        onChange: (newAttendees: Person[]) => handleChange('attendees', newAttendees),
                        enableSearch: true,
                        placeholder: 'Select Attendees',
                      },
                    },
                    {
                      component: TagSelect,
                      props: {
                        value: occurrenceData.tags?.map((tag) => tag.id) || [],
                        onChange: (newTags: Tag[]) => handleChange('tags', newTags),
                        enableSearch: true,
                        placeholder: 'Select Tags',
                      },
                    },
                  ]}
                >
                  <SeparatorHorizontal className="my-6" />
                  <h2 className="text-xl font-semibold">Topics</h2>
                  
                  <AccordionList
                    items={accordionItems}
                    allowMultipleOpen={true}
                    onUpdateItemContent={updateAccordionContent}
                    onRemoveItem={removeAccordionItem}
                    onAddItem={addNewAccordionItem}
                  />

                  <SeparatorHorizontal className="my-6" />
                </ModalContent>
                );
              }}
            />
          );
        }}
      />
    );
  };

  export default MeetingOccurrenceModal;
