import React, { useState } from 'react';
import { Task } from '../../api/task/types';
import { PanelList, Icon, Button, SeparatorHorizontal, Snackbar } from '../ui';
import TaskContainer from '../../containers/TaskContainer';
import { TaskModal } from '../task/TaskModal';
import { useQueryClient } from 'react-query';

interface TaskPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;  // New prop for personId
  onTaskClick?: (task: Task) => void;
  title?: string;
  showAddTaskButton?: boolean;
}

const TaskPanel: React.FC<TaskPanelProps> = ({
  isOpen,
  onClose,
  topicId,
  projectId,
  meetingOccurrenceId,
  meetingTypeId,
  personId,  // Include personId in the destructured props
  onTaskClick,
  title = '',
  showAddTaskButton = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const queryClient = useQueryClient();

  const openTaskCreateModal = () => {
    setTaskToEdit(null);
    setShowModal(true);
  };

  const openTaskEditModal = (task: Task) => {
    setTaskToEdit(task);
    setShowModal(true);
  };

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {showAddTaskButton && (
          <Button
            size="medium"
            variant="primary"
            label="Add New Task"
            onClick={openTaskCreateModal}
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <TaskContainer
          topicId={topicId}
          projectId={projectId}
          meetingOccurrenceId={meetingOccurrenceId}
          meetingTypeId={meetingTypeId}
          personId={personId}  // Pass personId to TaskContainer
          render={({ data: tasksFromContainer, loading, error, onCreate, onEdit }) => {
            const sortedTasks = [...tasksFromContainer].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            return (
              <>
                {loading && <div className="p-4">Loading tasks...</div>}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedTasks.length === 0 && (
                  <div className="p-4 text-gray-500 text-sm">No tasks associated</div>
                )}

                {!loading && !error && sortedTasks.length > 0 && (
                  <>
                    <PanelList
                      items={sortedTasks}
                      loading={loading}
                      renderItem={(task) => (
                        <div key={task.id} className="relative p-4">
                          <div className="absolute top-2 right-2 flex space-x-1">
                            <button
                              onClick={() => openTaskEditModal(task)}
                              className="text-primary hover:text-primary-dark focus:outline-none"
                              title="Edit Task"
                            >
                              <Icon name="Maximize" size="sm" />
                            </button>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <span className="text-base font-semibold">{task.title}</span>
                            <div className="flex text-xs text-gray-500 space-x-2">
                              <p>Owner: {task.owner.name}</p>
                              {task.start_date && <p>• Start: {task.start_date}</p>}
                              {task.due_date && <p>• Due: {task.due_date}</p>}
                            </div>
                            <p className="text-sm text-gray-600">{task.description}</p>
                            <div className="flex text-xs text-gray-500 space-x-2 mt-1">
                              <p>State: {task.state}</p>
                              <p>• Lifecycle: {task.dial_stage}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}

                {showModal && (
                  <TaskModal
                    isOpen={showModal}
                    initialData={taskToEdit || undefined}
                    onClose={() => setShowModal(false)}
                    onSuccess={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('success');
                      setShowSnackbar(true);
                      setShowModal(false);
                      queryClient.invalidateQueries([
                        'tasks',
                        { topicId, projectId, meetingOccurrenceId, meetingTypeId, personId },  // Include personId in invalidation
                      ]);
                    }}
                    onError={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('error');
                      setShowSnackbar(true);
                    }}
                    topicId={topicId}
                    projectId={projectId}
                    meetingOccurrenceId={meetingOccurrenceId}
                    meetingTypeId={meetingTypeId}
                    personId={personId}  // Pass personId to TaskModal
                  />
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TaskPanel;