import React from 'react';
import { Project } from '../../api/project/types';
import { Table, TableDateComponent, TableActionButtons, TablePersonsComponent, TableTagsComponent, TableBadgeComponent, TablePersonComponent, TableInlineTextInput } from '../ui';
import { TableTeamComponent } from '../ui/data/table-components/TableTeamComponent';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import { Team } from '../../api/team/types';
import { projectStateOptions, effortOptions, priorityOptions, pulseOptions } from '../../utils/entitiesOptions';

interface ProjectTableProps {
  data: Project[];
  onEdit: (project: Project) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Project['state']) => void;
  onUpdateEffort: (slug: string, newEffort: Project['effort']) => void;
  onUpdatePriority: (slug: string, newPriority: Project['priority']) => void;
  onUpdatePulse: (slug: string, newPulse: Project['pulse']) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdatePersons: (slug: string, newPersons: Person[]) => void;
  onUpdateStartDate: (slug: string, newDate: string) => void;
  onUpdateTargetDate: (slug: string, newDate: string) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  onUpdateTitle: (slug: string, newTitle: string) => void;
  onUpdateTeam: (slug: string, newTeam: Team | null) => void;  // Add team update handler
}

// Helper function to get the label from options based on the value
const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value; // Fallback to value if no label is found
};

export const ProjectTable: React.FC<ProjectTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateEffort,
  onUpdatePriority,
  onUpdatePulse,
  onUpdateTags,
  onUpdatePersons,
  onUpdateStartDate,
  onUpdateTargetDate,
  onUpdateOwner,
  onUpdateTitle,
  onUpdateTeam
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.title}
                onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
                onCancel={() => {}}
              />
            </div>
          ),
        },
        {
          header: 'State',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.state, projectStateOptions)}
              type="projectState"
              onUpdate={(newState) => onUpdateState(row.slug, newState as Project['state'])}
              options={projectStateOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Effort',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.effort, effortOptions)}
              type="effort"
              onUpdate={(newEffort) => onUpdateEffort(row.slug, newEffort as Project['effort'])}
              options={effortOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Priority',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.priority.toString(), priorityOptions)}
              type="priority"
              onUpdate={(newPriority) => onUpdatePriority(row.slug, parseInt(newPriority) as Project['priority'])}
              options={priorityOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Pulse',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.pulse, pulseOptions)}
              type="pulse"
              onUpdate={(newPulse) => onUpdatePulse(row.slug, newPulse as Project['pulse'])}
              options={pulseOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Owner',
          component: (row) => (
            <TablePersonComponent
              person={row.owner}
              isEditable={true}
              onUpdate={(newOwner) => onUpdateOwner(row.slug, newOwner)}
            />
          ),
        },
        {
          header: 'Team',
          component: (row) => (
            <TableTeamComponent
              team={row.team}
              isEditable={true}
              onUpdate={(newTeam: Team | null) => onUpdateTeam(row.slug, newTeam)} // Specify type for newTeam
            />
          ),
        },
        {
          header: 'Start Date',
          component: (row) => (
            <TableDateComponent
              dateString={row.start_date}
              isEditable={true}
              onUpdate={(newDate) => onUpdateStartDate(row.slug, newDate)}
            />
          ),
        },
        {
          header: 'Target Date',
          component: (row) => (
            <TableDateComponent
              dateString={row.target_date}
              isEditable={true}
              onUpdate={(newDate) => onUpdateTargetDate(row.slug, newDate)}
            />
          ),
        },
        {
          header: 'Persons',
          component: (row) => (
            <TablePersonsComponent
              persons={row.persons}
              isEditable={true}
              onUpdate={(newPersons) => onUpdatePersons(row.slug, newPersons)}
            />
          ),
        },
        {
          header: 'Tags',
          component: (row) => (
            <TableTagsComponent
              tags={row.tags}
              isEditable={true}
              onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
            />
          ),
        },
        {
          header: 'Created At',
          component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};
