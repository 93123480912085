import React from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { personId, companyId, isAuthenticated, clearSession } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearSession();
    navigate('/login');  // Redirect to login after logging out
  };

  return (
    <div>
      <h1>Welcome to your profile!</h1>
      <div>
        <p>Person ID: {personId}</p>
        <p>Company ID: {companyId}</p>
        <p>Authenticated: {isAuthenticated ? 'Yes' : 'No'}</p>
        <button onClick={handleLogout}>Log out</button>
      </div>
    </div>
  );
};

export default Profile;
