import React, { useState } from 'react';
import { format } from 'date-fns';

interface TableDateComponentProps {
  dateString: string | null;
  formatType?: string;
  showTime?: boolean;
  isEditable?: boolean;
  onUpdate?: (newDate: string) => void;
  textSize?: 'text-xs' | 'text-sm' | 'text-base';  // Option to control text size
}

export const TableDateComponent: React.FC<TableDateComponentProps> = ({
  dateString,
  formatType,
  showTime = false,
  isEditable = false,
  onUpdate,
  textSize = 'text-sm'  // Default text size
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const defaultDateFormat = 'yyyy-MM-dd';
  const defaultDateTimeFormat = 'yyyy-MM-dd HH:mm';
  const displayFormat = formatType || (showTime ? 'MMM d, yyyy, HH:mm' : 'MMM d, yyyy');

  const formattedDate = dateString 
    ? format(new Date(dateString), displayFormat)
    : 'No date set';

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    if (onUpdate) {
      onUpdate(newDate);
    }
    setIsEditing(false);
  };

  const handleClick = () => {
    if (isEditable) {
      setIsEditing(true);
    }
  };

  if (isEditable && isEditing) {
    return (
      <input
        type="date"
        defaultValue={dateString ? format(new Date(dateString), defaultDateFormat) : ''}
        onChange={handleDateChange}
        onBlur={() => setIsEditing(false)}
        autoFocus
        className={`border p-1 rounded ${textSize}`}  // Apply text size to input
      />
    );
  }

  return (
    <span 
      onClick={handleClick}
      className={`${isEditable ? 'cursor-pointer hover:bg-gray-100 p-1 rounded' : ''} ${textSize}`}  // Apply text size to span
    >
      {formattedDate}
    </span>
  );
};
