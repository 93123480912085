import React from 'react';
import { Icon, IconName } from '../';

// Define the props for a single list item, making it a generic type
interface PanelListProps<T> {
  items: T[];
  isDisabled?: boolean;
  loading?: boolean;
  onItemClick?: (item: T) => void;
  renderItem: (item: T) => JSX.Element; // Custom render function
}

export const PanelList = <T,>({
  items,
  isDisabled = false,
  loading = false,
  onItemClick,
  renderItem,
}: PanelListProps<T>) => {
  if (loading) {
    return <div className="text-text text-center">Loading...</div>;
  }

  return (
    <div className={`space-y-2 ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
      {items.map((item, index) => (
        <div
          key={index}
          className="bg-background p-2 rounded-md shadow-sm"
          // Reduced padding and shadow to make items smaller
        >
          {renderItem(item)}
        </div>
      ))}
    </div>
  );
};

export default PanelList;
