import React, { InputHTMLAttributes, forwardRef } from 'react';

export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  helperText?: string;
  error?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  inputSize?: 'small' | 'medium' | 'large'; // Renamed size prop to inputSize
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ helperText, error, startIcon, endIcon, inputSize = 'medium', className, ...props }, ref) => {
    // Adjust size classes to match Select component
    const sizeClasses = {
      small: 'px-2 py-1 text-sm',
      medium: 'px-2.5 py-1.5 text-sm', // match Select's medium size
      large: 'px-3 py-2 text-base',
    };

    const inputClasses = `
      w-full border border-neutral rounded-md shadow-sm bg-white transition duration-150 ease-in-out
      focus:outline-none focus:ring-1 focus:border-primary ${sizeClasses[inputSize]}
      ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'focus:border-primary'}
      ${startIcon ? 'pl-8' : ''} ${endIcon ? 'pr-8' : ''}
      ${props.disabled ? 'bg-neutral bg-opacity-50 cursor-not-allowed' : ''}
    `;

    return (
      <div className="relative inline-block w-full">
        <div className="relative">
          {startIcon && (
            <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
              {startIcon}
            </div>
          )}
          <input
            ref={ref}
            className={`${inputClasses} ${className}`}
            {...props}
          />
          {endIcon && (
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              {endIcon}
            </div>
          )}
        </div>
        {helperText && !error && (
          <p className="mt-1 text-xs text-text opacity-70">{helperText}</p>
        )}
        {error && (
          <p className="mt-1 text-xs text-red-500">{error}</p>
        )}
      </div>
    );
  }
);
