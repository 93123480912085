import React, { useState, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Board } from '../ui';
import { Goal } from '../../api/goal/types';
import { Button } from '../ui';

interface GoalBoardViewProps {
  goals: Goal[];
  onOpenGoal: (goal: Goal) => void;
  onEdit: (slug: string, updatedGoal: Partial<Goal>) => void;
}

interface Badge {
  label: string;
  style?: string;
  icon?: React.ReactNode;
}

interface BoardItem {
  id: string;
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
}

const getGoalStateBadge = (state: Goal['state']): Badge => {
  const stateColors: Record<string, string> = {
    DRAFT: 'bg-gray-100 text-gray-800',
    ACTIVE: 'bg-blue-100 text-blue-800',
    ACHIEVED: 'bg-green-100 text-green-800',
    MISSED: 'bg-red-100 text-red-800',
    CANCELED: 'bg-purple-100 text-purple-800',
  };

  return {
    label: state,
    style: stateColors[state],
  };
};

export const GoalBoardView: React.FC<GoalBoardViewProps> = ({
  goals: initialGoals,
  onOpenGoal,
  onEdit,
}) => {
  const [goals, setGoals] = useState<Goal[]>(initialGoals);
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [yearOffset, setYearOffset] = useState(0);

  const currentYear = new Date().getFullYear();
  const displayYear = currentYear + yearOffset;
  const isCurrentYear = yearOffset === 0;

  const mapGoalToBoardItem = useCallback(
    (goal: Goal): BoardItem => {
      const stateBadge = getGoalStateBadge(goal.state);
      const timeframeBadge: Badge = {
        label: goal.timeframe,
        style: 'bg-primary/10 text-primary',
      };
      const progressBadge: Badge = {
        label: `${goal.progress}%`,
        style: 'bg-blue-100 text-blue-800',
      };

      return {
        id: goal.slug,
        title: goal.title,
        badges: [stateBadge, timeframeBadge, progressBadge],
      };
    },
    []
  );

  useEffect(() => {
    setGoals(initialGoals);
  }, [initialGoals, isUpdating]);

  const updateColumns = useCallback(() => {
    // Create columns for the current display year
    const quarterColumns = [1, 2, 3, 4].map(quarter => ({
      id: `${displayYear}-Q${quarter}`,
      title: `Q${quarter}`,
      style: 'bg-primary/10 border-t-4 border-t-primary/30',
      items: goals
        .filter(goal => 
          goal.year === displayYear && 
          ((goal.timeframe === 'QUARTERLY' && goal.quarter === quarter) ||
           (goal.timeframe === 'ANNUAL' && quarter === 1))
        )
        .map(mapGoalToBoardItem),
    }));
  
    
    const nextYear = displayYear + 1;
    quarterColumns.push({
    id: `${nextYear}-Q1`,
    title: `${nextYear} Q1`,
    style: 'bg-secondary/10 border-t-4 border-t-secondary/30',
    items: goals
        .filter(goal => 
        goal.year === nextYear && 
        ((goal.timeframe === 'QUARTERLY' && goal.quarter === 1) ||
            goal.timeframe === 'ANNUAL')
        )
        .map(mapGoalToBoardItem),
    });
  
    setColumns(quarterColumns);
  }, [goals, displayYear, isCurrentYear, mapGoalToBoardItem]);

  useEffect(() => {
    updateColumns();
  }, [updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const goal = goals.find(g => g.slug === item.id);
      if (goal) {
        onOpenGoal(goal);
      }
    },
    [goals, onOpenGoal]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      const goal = goals.find(g => g.slug === draggableId);
      if (!goal) return;

      const [destYear, destQuarter] = destination.droppableId.split('-Q');
      const updates: Partial<Goal> = {
        year: parseInt(destYear),
        timeframe: 'QUARTERLY',
        quarter: parseInt(destQuarter),
      };

      setIsUpdating(true);

      try {
        const updatedGoal = { ...goal, ...updates };
        const updatedGoals = goals.map(g => (g.slug === draggableId ? updatedGoal : g));
        setGoals(updatedGoals);

        await onEdit(draggableId, updates);
      } catch (error) {
        console.error('Failed to update goal:', error);
        setGoals(goals);
        updateColumns();
      } finally {
        setIsUpdating(false);
      }
    },
    [goals, onEdit, updateColumns]
  );

  const handlePreviousYear = () => setYearOffset(prev => prev - 1);
  const handleNextYear = () => setYearOffset(prev => prev + 1);
  const handleCurrentYear = () => setYearOffset(0);

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-4 px-4">
        <Button 
          icon="ArrowLeft" 
          buttonStyle="ghost" 
          onClick={handlePreviousYear}
          label="Previous Year" 
        />
        
        <div className="flex items-center gap-2">
          <span className="text-sm text-text/50">Year</span>
          <h2 className="font-semibold text-lg">{displayYear}</h2>
          {!isCurrentYear && (
            <Button
              label="Current Year"
              buttonStyle="ghost"
              onClick={handleCurrentYear}
              className="text-xs"
            />
          )}
        </div>

        <Button
          icon="ArrowRight"
          buttonStyle="ghost"
          onClick={handleNextYear}
          label="Next Year"
          iconPosition="right"
        />
      </div>

      <Board
        columns={columns}
        minHeight="calc(100vh - 300px)"
        allowAddItems={false}
        onOpenItem={handleOpenItem}
        onMoveItem={handleMoveItem}
      />
    </div>
  );
};

export default GoalBoardView;