import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchMeetingTypes, 
  fetchMeetingType, 
  createMeetingType, 
  editMeetingType, 
  deleteMeetingType,
  fetchMeetingOccurrences,
  fetchMeetingOccurrence,
  createMeetingOccurrence,
  editMeetingOccurrence,
  deleteMeetingOccurrence,
  fetchSmartSuggestions,
  createSmartSuggestion,
  editSmartSuggestion,
  deleteSmartSuggestion,
  fetchMeetingTopicNotes,
  createMeetingTopicNote,
  editMeetingTopicNote,
  deleteMeetingTopicNote
} from './api';
import { MeetingType, MeetingOccurrence, SmartSuggestion, MeetingTopicNote } from './types';


// ========================= Meeting Types ========================= //

// Hook for fetching all meeting types
export const useMeetingTypes = () => {
  const navigate = useNavigate();
  return useQuery('meeting-types', () => fetchMeetingTypes(navigate));
};

// Hook for fetching a single meeting type by slug
export const useMeetingType = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(['meeting-type', slug], () => fetchMeetingType(slug, navigate));
};

// Hook for creating a new meeting type
export const useCreateMeetingType = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>) => createMeetingType(newMeetingType, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-types');
      },
    }
  );
};

// Hook for editing a meeting type
export const useEditMeetingType = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedMeetingType }: { slug: string; updatedMeetingType: Partial<MeetingType> }) => 
      editMeetingType(slug, updatedMeetingType, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-types');
      },
    }
  );
};

// Hook for deleting a meeting type
export const useDeleteMeetingType = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteMeetingType(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-types');
      },
    }
  );
};

// ========================= Meeting Occurrences ========================= //

// Hook for fetching all meeting occurrences
export const useMeetingOccurrences = () => {
  const navigate = useNavigate();
  return useQuery('meeting-occurrences', () => fetchMeetingOccurrences(navigate));
};

// Hook for fetching a single meeting occurrence by slug
export const useMeetingOccurrence = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(['meeting-occurrence', slug], () => fetchMeetingOccurrence(slug, navigate));
};

// Hook for creating a new meeting occurrence
export const useCreateMeetingOccurrence = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newOccurrence: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>) => 
      createMeetingOccurrence(newOccurrence, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-occurrences');
      },
    }
  );
};

// Hook for editing a meeting occurrence
export const useEditMeetingOccurrence = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedOccurrence }: { slug: string; updatedOccurrence: Partial<MeetingOccurrence> }) => 
      editMeetingOccurrence(slug, updatedOccurrence, navigate),
    {
      onSuccess: (updatedOccurrence) => {
        queryClient.invalidateQueries('meeting-occurrences');
        queryClient.invalidateQueries(['meeting-occurrence', updatedOccurrence.slug]);
        queryClient.invalidateQueries(['meeting-topic-notes', updatedOccurrence.id]);
      },
    }
  );
};

// Hook for deleting a meeting occurrence
export const useDeleteMeetingOccurrence = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteMeetingOccurrence(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-occurrences');
      },
    }
  );
};


// ========================= Meeting Topic Notes ========================= //

// Hook for fetching all meeting topic notes for a specific meeting occurrence
export const useMeetingTopicNotes = (meetingOccurrenceId: string) => {
  const navigate = useNavigate();
  return useQuery(['meeting-topic-notes', meetingOccurrenceId], () => fetchMeetingTopicNotes(meetingOccurrenceId, navigate));
};

// Hook for creating a new meeting topic note
export const useCreateMeetingTopicNote = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newNote: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at'>) => createMeetingTopicNote(newNote, navigate),
    {
      onSuccess: (note) => {
        queryClient.invalidateQueries(['meeting-topic-notes', note.meeting_occurrence]);
        queryClient.invalidateQueries(['meeting-occurrence', note.meeting_occurrence]);
      },
    }
  );
};

// Hook for editing a meeting topic note
export const useEditMeetingTopicNote = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, updatedNote }: { id: string; updatedNote: Partial<MeetingTopicNote> }) => 
      editMeetingTopicNote(id, updatedNote, navigate),
    {
      onSuccess: (note) => {
        queryClient.invalidateQueries(['meeting-topic-notes', note.meeting_occurrence]);
        queryClient.invalidateQueries(['meeting-occurrence', note.meeting_occurrence]);
      },
    }
  );
};

// Hook for deleting a meeting topic note
export const useDeleteMeetingTopicNote = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deleteMeetingTopicNote(id, navigate),
    {
      onSuccess: (_, id) => {
        // We need to invalidate all meeting-topic-notes queries as we don't know the specific meeting occurrence
        queryClient.invalidateQueries('meeting-topic-notes');
        // We might also want to invalidate all meeting-occurrence queries
        queryClient.invalidateQueries('meeting-occurrence');
      },
    }
  );
};


// ========================= Smart Suggestions ========================= //

// Hook for fetching all smart suggestions related to a meeting occurrence
export const useSmartSuggestions = (meetingOccurrenceId: string) => {
  const navigate = useNavigate();
  return useQuery(['smart-suggestions', meetingOccurrenceId], () => fetchSmartSuggestions(meetingOccurrenceId, navigate));
};

// Hook for creating a new smart suggestion
export const useCreateSmartSuggestion = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newSuggestion: Omit<SmartSuggestion, 'id' | 'created_at'>) => createSmartSuggestion(newSuggestion, navigate),
    {
      onSuccess: (suggestion, variables) => {
        // Invalidate suggestions for the specific occurrence
        queryClient.invalidateQueries(['smart-suggestions', variables.meeting_occurrence]);
      },
    }
  );
};

// Hook for editing a smart suggestion
export const useEditSmartSuggestion = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, updatedSuggestion }: { id: string; updatedSuggestion: Partial<SmartSuggestion> }) => 
      editSmartSuggestion(id, updatedSuggestion, navigate),
    {
      onSuccess: (suggestion, variables) => {
        // Invalidate suggestions for the specific occurrence
        queryClient.invalidateQueries(['smart-suggestions', variables.updatedSuggestion.meeting_occurrence]);
      },
    }
  );
};

// Hook for deleting a smart suggestion
export const useDeleteSmartSuggestion = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deleteSmartSuggestion(id, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('smart-suggestions');
      },
    }
  );
};
