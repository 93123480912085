import React from 'react';
import { useTags, useCreateTag, useEditTag, useDeleteTag } from '../api/tag/queries';
import { Tag } from '../api/tag/types';

interface TagContainerProps {
  render: (props: {
    data: Tag[];
    loading: boolean;
    error: string | null;
    onCreate: (newTag: Omit<Tag, 'id' | 'created_at' | 'updated_at'>) => void;
    onEdit: (id: string, updatedTag: Partial<Tag>) => void;
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

const TagContainer: React.FC<TagContainerProps> = ({ render }) => {
  const { data: tags = [], isLoading, error } = useTags();
  const createTagMutation = useCreateTag();
  const editTagMutation = useEditTag();
  const deleteTagMutation = useDeleteTag();

  const handleCreate = (newTag: Omit<Tag, 'id' | 'created_at' | 'updated_at'>) => {
    createTagMutation.mutate(newTag);
  };

  const handleEdit = (id: string, updatedTag: Partial<Tag>) => {
    editTagMutation.mutate({ id, updatedTag });
  };

  const handleDelete = (id: string) => {
    deleteTagMutation.mutate(id);
  };

  // Sort tags by name
  const sortedTags = [...tags].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return render({
    data: sortedTags,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default TagContainer;