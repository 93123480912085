import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchTags, 
  fetchTag, 
  createTag, 
  editTag, 
  deleteTag 
} from './api';
import { Tag } from './types';

// Hook for fetching all tags
export const useTags = () => {
  const navigate = useNavigate();
  return useQuery<Tag[], Error>('tags', () => fetchTags(navigate), {
    select: (data) => data ?? [],
  });
};

// Hook for fetching a single tag
export const useTag = (id: string) => {
  const navigate = useNavigate();
  return useQuery(['tag', id], () => fetchTag(id, navigate));
};

// Hook for creating a new tag
export const useCreateTag = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newTag: Omit<Tag, 'id' | 'created_at' | 'updated_at'>) => createTag(newTag, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    }
  );
};

// Hook for editing a tag
export const useEditTag = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, updatedTag }: { id: string; updatedTag: Partial<Tag> }) => 
      editTag(id, updatedTag, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    }
  );
};

// Hook for deleting a tag
export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deleteTag(id, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    }
  );
};