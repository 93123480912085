// src/components/CookieBanner.tsx
import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';

const GA_MEASUREMENT_ID = 'G-ZZDM7J56RK';

export const CookieBanner: React.FC = () => {
  useEffect(() => {
    // If user has already accepted cookies, enable GA
    const hasConsent = localStorage.getItem('cookieConsent') === 'true';
    if (hasConsent) {
      enableGA();
    }
  }, []);

  const enableGA = () => {
    // Enable GA tracking
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    }
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    enableGA();
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    // Disable GA tracking
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    }
  };

  // Don't show banner if decision was already made
  if (localStorage.getItem('cookieConsent') !== null) {
    return null;
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      style={{
        background: '#2B373B',
        zIndex: 9999
      }}
      buttonStyle={{
        background: '#4F46E5',
        color: 'white',
        fontSize: '13px',
        borderRadius: '4px',
        padding: '8px 16px'
      }}
      declineButtonStyle={{
        background: '#64748B',
        color: 'white',
        fontSize: '13px',
        borderRadius: '4px',
        padding: '8px 16px'
      }}
    >
      We use cookies to analyze site traffic and improve your experience. See our{' '}
      <a 
        href="/privacy-policy"
        style={{ color: '#4F46E5' }}
        onClick={(e) => e.stopPropagation()}
      >
        Privacy Policy
      </a>
    </CookieConsent>
  );
};