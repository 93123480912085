import React from 'react';

interface EntitySelectorProps<T> {
  items: T[];
  selectedItems: T[];
  onChange: (items: T[]) => void;
  renderItem: (item: T, isSelected: boolean, onClick: () => void) => React.ReactNode;
  multiple?: boolean;
}

export const EntitySelector = <T extends { id: string }>({
  items,
  selectedItems,
  onChange,
  renderItem,
  multiple = true,
}: EntitySelectorProps<T>) => {
  const handleItemChange = (item: T) => {
    const isSelected = selectedItems.some((i) => i.id === item.id);

    if (multiple) {
      if (isSelected) {
        onChange(selectedItems.filter((i) => i.id !== item.id));
      } else {
        onChange([...selectedItems, item]);
      }
    } else {
      onChange(isSelected ? [] : [item]);
    }
  };

  return (
    <div>
      {items.map((item) => {
        const isSelected = selectedItems.some((i) => i.id === item.id);
        return (
          <div
            key={item.id}
            style={{
              backgroundColor: 'white',        // White background for each item
              //padding: '12px 12px 16px 12px',  // Larger internal padding, especially at the bottom
              marginBottom: '16px',            // Bottom margin for spacing
              //border: '1px solid #ddd',        // Thinner border for each item
              //borderRadius: '8px',             // Rounded corners
            }}
          >
            {renderItem(item, isSelected, () => handleItemChange(item))}
          </div>
        );
      })}
    </div>
  );
};

export default EntitySelector;
