// utils/dateUtils.ts

/**
 * Format a date string into a human-readable format
 * @param dateString - ISO date string or any valid date string
 * @param options - Optional formatting options
 * @returns Formatted date string
 */
export const formatDate = (
    dateString?: string | null,
    options: {
      includeTime?: boolean;
      includeSeconds?: boolean;
      includeYear?: boolean;
      shortMonth?: boolean;
    } = {}
  ): string => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
  
    const {
      includeTime = false,
      includeSeconds = false,
      includeYear = true,
      shortMonth = false,
    } = options;
  
    // Date formatting options
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: shortMonth ? 'short' : 'long',
      day: 'numeric',
      year: includeYear ? 'numeric' : undefined,
    };
  
    // Time formatting options
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: includeSeconds ? '2-digit' : undefined,
      hour12: false,
    };
  
    try {
      let formatted = date.toLocaleDateString(undefined, dateOptions);
      
      if (includeTime) {
        const time = date.toLocaleTimeString(undefined, timeOptions);
        formatted += ` ${time}`;
      }
      
      return formatted;
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString; // Return original string if formatting fails
    }
  };
  
  /**
   * Check if a date string is in the past
   */
  export const isPastDate = (dateString?: string | null): boolean => {
    if (!dateString) return false;
    const date = new Date(dateString);
    return date < new Date();
  };
  
  /**
   * Check if a date string is in the future
   */
  export const isFutureDate = (dateString?: string | null): boolean => {
    if (!dateString) return false;
    const date = new Date(dateString);
    return date > new Date();
  };
  
  /**
   * Get relative time string (e.g., "2 days ago", "in 3 hours")
   */
  export const getRelativeTimeString = (dateString?: string | null): string => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInSeconds < 60) return 'just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInHours < 24) return `${diffInHours}h ago`;
    if (diffInDays < 30) return `${diffInDays}d ago`;
    
    return formatDate(dateString, { shortMonth: true });
  };
  
  /**
   * Format a date range
   */
  export const formatDateRange = (
    startDate?: string | null,
    endDate?: string | null,
    options: {
      includeTime?: boolean;
      shortMonth?: boolean;
    } = {}
  ): string => {
    if (!startDate || !endDate) return '';
  
    const start = formatDate(startDate, options);
    const end = formatDate(endDate, options);
  
    return `${start} - ${end}`;
  };
  
  /**
   * Get the start and end of a day
   */
  export const getDayBoundaries = (dateString: string) => {
    const date = new Date(dateString);
    const startOfDay = new Date(date.setHours(0, 0, 0, 0));
    const endOfDay = new Date(date.setHours(23, 59, 59, 999));
  
    return {
      start: startOfDay.toISOString(),
      end: endOfDay.toISOString(),
    };
  };
  
  /**
   * Check if two dates are the same day
   */
  export const isSameDay = (date1?: string | null, date2?: string | null): boolean => {
    if (!date1 || !date2) return false;
    
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };