import React from 'react';
import { Team } from '../../api/team/types';
import { Tag } from '../../api/tag/types';
import { Table, TableCountComponent, TableDateComponent, TableActionButtons, TableTagsComponent, TablePersonComponent, TableInlineTextInput } from '../ui';

interface TeamTableProps {
  data: Team[];
  onEdit: (team: Team) => void;
  onDelete: (slug: string) => void;
  onUpdateName: (slug: string, newName: string) => void;
  onUpdateDescription: (slug: string, newDescription: string) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
}

export const TeamTable: React.FC<TeamTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateName,
  onUpdateDescription,
  onUpdateTags,
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Name',
          accessor: 'name',
          component: (row) => (
            <div
              onClick={() => onEdit(row)} // Trigger the onEdit function when clicking the title
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.name}
                onSave={(newName) => onUpdateName(row.slug, newName)}
                onCancel={() => {}}
              />
            </div>
          ),
        },
        {
          header: 'Tags',
          component: (row) => (
            <TableTagsComponent
              tags={row.tags}
              isEditable={true}
              onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
            />
          ),
        },
        {
          header: 'Created By',
          component: (row) => (
            <TablePersonComponent
              person={row.created_by}
              isEditable={false}
            />
          ),
        },
        {
          header: 'Members', // New column
          component: (row) => (
            <TableCountComponent
              count={row.members.length}
              label="member(s)"
              noItemsPlaceholder="No members"
            />
          ),
        },
        {
          header: 'Created At',
          component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};
