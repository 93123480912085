import { useState } from 'react';

interface TabsProps {
  tabs: { label: string; content: JSX.Element }[];
}

export function Tabs({ tabs }: TabsProps) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="w-full">
      {/* Tab Navigation */}
      <div className="flex space-x-4 border-b border-neutral">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === index
                ? 'border-b-2 border-primary text-primary'
                : 'text-neutral hover:text-primary'
            } transition-colors duration-300`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-4 text-sm">
        {tabs[activeTab]?.content}
      </div>
    </div>
  );
}
