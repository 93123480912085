// src/features/integration/AddIntegrationModal.tsx
import React, { useState } from 'react';
import { Integration, IntegrationProvider } from '../../api/integration/types';
import { Button, TextInput } from '../ui'
import { Dialog } from '../ui/Dialog'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (integration: Partial<Integration>) => Promise<Integration>;
    providers: Array<{
      id: string;
      name: string;
      description: string;
      required_fields: string[];
    }>;
  }

const AddIntegrationModal: React.FC<Props> = ({ isOpen, onClose, onAdd, providers }) => {
  const [step, setStep] = useState(1);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [credentials, setCredentials] = useState<Record<string, string>>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const provider = providers.find(p => p.id === selectedProvider);
    if (!provider) return;

    await onAdd({
      provider: selectedProvider as IntegrationProvider,
      name,
      credentials
    });
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={step === 1 ? "Select Provider" : "Configure Integration"}
    >
      {step === 1 ? (
        <div className="space-y-4">
          {providers.map(provider => (
            <div
              key={provider.id}
              className="p-4 border border-neutral rounded-md cursor-pointer hover:border-primary"
              onClick={() => {
                setSelectedProvider(provider.id);
                setStep(2);
              }}
            >
              <h3 className="font-semibold">{provider.name}</h3>
              <p className="text-sm text-text opacity-70">{provider.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <TextInput
            placeholder="Integration Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          {providers
            .find(p => p.id === selectedProvider)
            ?.required_fields.map(field => (
              <TextInput
                key={field}
                placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                type={field.includes('token') || field.includes('key') ? 'password' : 'text'}
                value={credentials[field] || ''}
                onChange={(e) => setCredentials(prev => ({
                  ...prev,
                  [field]: e.target.value
                }))}
                required
                autoComplete="new-password" // Add this line
              />
            ))}

          <div className="flex justify-end space-x-2">
            <Button
              variant="secondary"
              buttonStyle="ghost"
              onClick={() => {
                setStep(1);
                setSelectedProvider(null);
              }}
              label="Back"
            />
            <Button
              type="submit"
              label="Add Integration"
              disabled={!name || Object.keys(credentials).length === 0}
            />
          </div>
        </form>
      )}
    </Dialog>
  );
};

export default AddIntegrationModal;