import { LogEntry } from './types';
import { fetchWithAuth } from '../fetchWithAuth';  // Import fetchWithAuth method
import { getCompanyId } from '../../utils/utils';  // Assuming you have a getCompanyId helper

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all LogEntries
export const fetchLogEntries = async (navigate: (path: string) => void): Promise<LogEntry[]> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}log_entries/?company_id=${company_id}`, {}, navigate);
    if (!response.ok) {
        throw new Error(`Failed to fetch LogEntries`);
    }
    return response.json();
};

// Fetch a single LogEntry by ID
export const fetchLogEntry = async (id: string, navigate: (path: string) => void): Promise<LogEntry> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}log_entries/${id}/?company_id=${company_id}`, {}, navigate);
    if (!response.ok) {
        throw new Error(`Failed to fetch LogEntry with id ${id}`);
    }
    return response.json();
};

// Fetch all LogEntries related to a specific object by object_id
export const fetchLogEntriesByObjectId = async (objectId: string, navigate: (path: string) => void): Promise<LogEntry[]> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}log_entries/?company_id=${company_id}&object_id=${objectId}`, {}, navigate);
    if (!response.ok) {
        throw new Error(`Failed to fetch LogEntries for object_id ${objectId}`);
    }

    return response.json();
};

// Create a new LogEntry
export const createLogEntry = async (
    logEntryData: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>, 
    navigate: (path: string) => void
): Promise<LogEntry> => {
    const company_id = getCompanyId();

    const response = await fetchWithAuth(`${API_URL}log_entries/`, {
        method: 'POST',
        body: JSON.stringify({
            ...logEntryData,
            company: company_id,  // Always send the company ID
        }),
    }, navigate);
    if (!response.ok) {
        throw new Error('Failed to create LogEntry');
    }
    return response.json();
};

// Edit an existing LogEntry (using PATCH)
export const editLogEntry = async (
    id: string, 
    updatedLogEntry: Partial<LogEntry>, 
    navigate: (path: string) => void
): Promise<LogEntry> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}log_entries/${id}/`, {
        method: 'PATCH',  // Using PATCH for partial updates
        body: JSON.stringify({
            ...updatedLogEntry,
            company_id,  // Send company ID with the edit
        }),
    }, navigate);
    if (!response.ok) {
        throw new Error(`Failed to edit LogEntry with id ${id}`);
    }
    return response.json();
};

// Delete an existing LogEntry
export const deleteLogEntry = async (id: string, navigate: (path: string) => void): Promise<void> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}log_entries/${id}/?company_id=${company_id}`, {
        method: 'DELETE',
    }, navigate);
    if (!response.ok) {
        throw new Error(`Failed to delete LogEntry with id ${id}`);
    }
};
