import React from 'react';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, disabled, error }) => {
  return (
    <div>
      <label className={`flex items-center space-x-2 ${disabled ? 'opacity-50' : ''}`}>
        <input
          type="checkbox"
          className={`form-checkbox h-4 w-4 text-primary ${error ? 'border-secondary-dark' : ''}`}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <span className={`text-sm ${error ? 'text-secondary-dark' : 'text-text'}`}>{label}</span>
      </label>
      {error && <p className="text-xs text-secondary-dark mt-1">{error}</p>}
    </div>
  );
};
