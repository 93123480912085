import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
    fetchFavorites, 
    toggleFavorite, 
    FetchFavoritesParams 
} from './api';

export const useFavorites = (params?: FetchFavoritesParams) => {
    const navigate = useNavigate();
    return useQuery(
        ['favorites', params],
        () => fetchFavorites(params || {}, navigate),
        {
            staleTime: 0,
            select: (data) => data ?? [],
        }
    );
};

export const useToggleFavorite = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(
        ({ contentType, objectId }: { contentType: string; objectId: string }) =>
            toggleFavorite(contentType, objectId, navigate),
        {
            onSuccess: (_, { contentType }) => {
                // Invalidate favorites queries
                queryClient.invalidateQueries(['favorites']);
                queryClient.invalidateQueries(['favorites', { type: contentType }]);
                
                // Invalidate the specific content type queries if needed
                // For example, when favoriting a topic, invalidate topics query
                const baseType = contentType.split('.')[1]; // e.g., 'topic' from 'topic.topic'
                queryClient.invalidateQueries([baseType]);
            },
        }
    );
};