import React, { useState, useEffect } from 'react';
import { Lock, Globe } from 'lucide-react';

interface VisibilityToggleProps {
  initialVisibility?: 'public' | 'private';
  onToggle?: (isPublic: boolean) => void;
  disabled?: boolean;
}

const VisibilityToggle: React.FC<VisibilityToggleProps> = ({
  initialVisibility = 'private',
  onToggle,
  disabled = false,
}) => {
  const [isPublic, setIsPublic] = useState(initialVisibility === 'public');

  // Use effect to update isPublic when initialVisibility changes
  useEffect(() => {
    setIsPublic(initialVisibility === 'public');
  }, [initialVisibility]);

  const handleToggle = () => {
    if (disabled) return;  // Prevent toggling if disabled
    const newVisibility = !isPublic;
    setIsPublic(newVisibility);
    onToggle?.(newVisibility);
  };

  return (
    <button
      onClick={handleToggle}
      disabled={disabled}  // Disable the button if `disabled` is true
      className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
        isPublic
          ? 'bg-accent text-white hover:bg-accent-dark'
          : 'bg-neutral text-text hover:bg-gray-300'
      } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}  // Style for disabled state
    >
      {isPublic ? (
        <>
          <Globe size={16} className="mr-2" />
          Public
        </>
      ) : (
        <>
          <Lock size={16} className="mr-2" />
          Private
        </>
      )}
    </button>
  );
};

export default VisibilityToggle;