import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModalContent, SelectSingle, ListMinimal, ListMinimalHeader } from '../ui'; 
import { MeetingType, RecurrenceType, DurationType } from '../../api/meeting/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TagSelect from '../tags/TagSelect';
import { SelectOption } from '../ui/Select';
import TopicListSelect from '../topic/TopicListSelect';
import { Topic } from '../../api/topic/types';
import { useNavigate } from 'react-router-dom';
import MeetingTypeSidePanel from './MeetingTypeSidePanel';

interface MeetingTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (meetingTypeData: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>, slug?: string) => void;
  onEdit: (slug: string, updatedMeetingType: Partial<MeetingType>) => void;
  initialData?: Omit<MeetingType, 'created_at' | 'updated_at'>;
}

export const MeetingTypeModal: React.FC<MeetingTypeModalProps> = ({ 
  isOpen, 
  onClose, 
  onSave, 
  onEdit, 
  initialData 
}) => {
  const [meetingTypeData, setMeetingTypeData] = useState<Partial<MeetingType>>({
    name: '',
    description: '',
    recurrence: 'WEEKLY',
    duration: '1h',
    regular_attendees: [],
    tags: [],
    backlog_topics: [],
    company: 'default-company-id',
  });
  const [description, setDescription] = useState('');
  const isInitialMount = useRef(true);
  const initialNameRef = useRef('');
  const initialDescriptionRef = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedMeetingType: Partial<MeetingType>) => void) | null>(null);
  const navigate = useNavigate();

  const recurrenceOptions: SelectOption[] = [
    { value: 'DAILY', label: 'Daily' },
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'QUARTERLY', label: 'Quarterly' },
    { value: 'YEARLY', label: 'Yearly' },
    { value: 'CUSTOM', label: 'Custom' },
  ];

  const durationOptions: SelectOption[] = [
    { value: '15m', label: '15 minutes' },
    { value: '30m', label: '30 minutes' },
    { value: '45m', label: '45 minutes' },
    { value: '1h', label: '1 hour' },
    { value: '1h30m', label: '1 hour 30 minutes' },
    { value: '2h', label: '2 hours' },
    { value: '3h', label: '3 hours' },
    { value: '4h', label: '4 hours' },
    { value: '1d', label: '1 day' },
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      if (initialData) {
        setMeetingTypeData(initialData);
        setDescription(initialData.description || '');
        initialNameRef.current = initialData.name || '';
        initialDescriptionRef.current = initialData.description || '';
      }
      isInitialMount.current = false;
    } else if (initialData) {
      setMeetingTypeData(prevData => ({
        ...prevData,
        company: initialData.company,
      }));
    }
  }, [initialData, isOpen]);

  // Save on refresh/unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [initialData?.slug, description]);

  // 30m autosave
  useEffect(() => {
    if (!initialData?.slug || !editFunctionRef.current || description === initialDescriptionRef.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(autoSaveTimer);
  }, [description, initialData?.slug]);

  const handleChange = useCallback((field: keyof MeetingType, value: any) => {
    setMeetingTypeData(prevData => ({ ...prevData, [field]: value }));

    if (initialData?.slug && field !== 'description' && field !== 'name') {
      onEdit(initialData.slug, { [field]: value });
    }
  }, [initialData, onEdit]);

  const handleClose = useCallback(() => {
    if (initialData?.slug && description !== initialDescriptionRef.current) {
      onEdit(initialData.slug, { description });
      initialDescriptionRef.current = description;
    }
    onClose();
  }, [initialData, description, onEdit, onClose]);

  const handleNameBlur = useCallback(() => {
    if (initialData?.slug && meetingTypeData.name !== initialNameRef.current) {
      onEdit(initialData.slug, { name: meetingTypeData.name });
      initialNameRef.current = meetingTypeData.name || '';
    }
  }, [initialData, meetingTypeData.name, onEdit]);

  const handleSave = useCallback(() => {
    if (!meetingTypeData.name?.trim()) {
      return alert('Please provide a name');
    }

    const dataToSave = {
      ...meetingTypeData,
      description,
    };

    onSave(dataToSave as Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>, initialData?.slug);
    initialDescriptionRef.current = description;
    onClose();
  }, [meetingTypeData, description, initialData, onSave, onClose]);

  const handleAddTopic = useCallback((item: { label: string; value: string }) => {
    const topicExists = meetingTypeData.backlog_topics?.some(topic => topic.id === item.value);
  
    if (!topicExists) {
      const newTopic: Topic = { id: item.value, title: item.label } as Topic;
      const updatedTopics = [...(meetingTypeData.backlog_topics || []), newTopic];
      handleChange('backlog_topics', updatedTopics);
    }
  }, [meetingTypeData.backlog_topics, handleChange]);

  const handleRemoveTopic = useCallback((id: string) => {
    const updatedTopics = meetingTypeData.backlog_topics?.filter(topic => topic.id !== id) || [];
    handleChange('backlog_topics', updatedTopics);
  }, [meetingTypeData.backlog_topics, handleChange]);

  if (!isOpen) return null;

  // Keep track of edit function
  editFunctionRef.current = onEdit;

  return (
    <ModalContent
      title={meetingTypeData.name || ''}
      description={description}
      onCancel={handleClose}
      onCreate={handleSave}
      onTitleChange={(newName) => setMeetingTypeData(prevData => ({ ...prevData, name: newName }))}
      onTitleBlur={handleNameBlur}
      onDescriptionChange={setDescription}
      descriptionPlaceholder='Add a template...'
      actionButtonLabel={initialData ? 'Save Changes' : 'Create Meeting Type'}
      showFooterButtons={true}
      selects={[
        {
          component: SelectSingle,
          props: {
            options: recurrenceOptions,
            placeholder: 'Select Recurrence',
            defaultValue: meetingTypeData.recurrence,
            onSelect: (value: string) => handleChange('recurrence', value as RecurrenceType),
          },
        },
        {
          component: SelectSingle,
          props: {
            options: durationOptions,
            placeholder: 'Select Duration',
            defaultValue: meetingTypeData.duration,
            onSelect: (value: string) => handleChange('duration', value as DurationType),
          },
        },
        {
          component: PersonSelectMulti,
          props: {
            value: meetingTypeData.regular_attendees?.map((person) => person.id) || [],
            onChange: (newAttendees: Person[]) => handleChange('regular_attendees', newAttendees),
            enableSearch: true,
            placeholder: 'Select Regular Attendees',
          },
        },
        {
          component: TagSelect,
          props: {
            value: meetingTypeData.tags?.map((tag) => tag.id) || [],
            onChange: (newTags: Tag[]) => handleChange('tags', newTags),
            enableSearch: true,
            placeholder: 'Select Tags',
          },
        },
      ]}
      showSidePanel={!!initialData}
      sidePanelContent={
        initialData && (
          <MeetingTypeSidePanel
            meetingTypeId={initialData?.id}
          />
        )
      }
    >
      <ListMinimalHeader
        headerText="Backlog Topics"
        selectPlaceholder="Select or Create a Topic"
        onAddItem={handleAddTopic}
        SelectComponent={({ onSelect, isDisabled, placeholder }) => (
          <TopicListSelect
            value={null}
            onChange={(selectedTopic) => {
              if (selectedTopic) {
                onSelect({ label: selectedTopic.title, value: selectedTopic.id });
              }
            }}
            isDisabled={isDisabled}
            placeholder={placeholder}
          />
        )}
      />

      <ListMinimal
        items={meetingTypeData.backlog_topics?.map((topic) => ({ id: topic.id, slug: topic.slug, title: topic.title })) || []}
        onRemoveItem={handleRemoveTopic}
        isDisabled={false}
        isLoading={false}
        emptyMessage="No topics available"
        onDetailClick={(slug) => window.open(`/topics/${slug}`, '_blank')}
      />
    </ModalContent>
  );
};

export default MeetingTypeModal;