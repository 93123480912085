import React from 'react';
import { TabContainer } from './TabContainer';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => (
  <div className="relative flex-1 flex flex-col min-w-0 h-full">
    <TabContainer>
      {children}
    </TabContainer>
  </div>
);

export default MainLayout;