import React from 'react';
import { InfoBox } from '../feedback/InfoBox';

interface GanttItem {
  id: string | number;
  title: string;
  startDate: Date;
  endDate: Date;
  status: 'completed' | 'in-progress' | 'delayed';
  tasks?: GanttTask[];
  isExpanded?: boolean;
}

interface GanttTask {
  id: string | number;
  title: string;
  startDate: Date;
  endDate: Date;
  status: 'completed' | 'in-progress' | 'delayed';
}

interface GanttChartProps {
  items: GanttItem[];
  className?: string;
  onItemClick?: (itemId: string | number) => void;
  onTaskClick?: (itemId: string | number, taskId: string | number) => void;
  onExpandToggle?: (itemId: string | number) => void;
}

interface GanttBarProps extends GanttItem {
  pixelStart: number;
  pixelWidth: number;
  dayWidth: number;
  startDate: Date;
  onItemClick?: (itemId: string | number) => void;
  onTaskClick?: (itemId: string | number, taskId: string | number) => void;
  onExpandToggle?: (itemId: string | number) => void;
}

const GanttBar: React.FC<GanttBarProps> = ({
  id,
  title,
  startDate,
  endDate,
  status,
  tasks,
  isExpanded,
  pixelStart,
  pixelWidth,
  dayWidth,
  onItemClick,
  onTaskClick,
  onExpandToggle,
}) => {
  const statusStyles = {
    completed: 'bg-accent hover:bg-accent-dark',
    'in-progress': 'bg-primary hover:bg-primary-dark',
    delayed: 'bg-secondary hover:bg-secondary-dark',
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const handleExpandToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onExpandToggle) {
      onExpandToggle(id);
    }
  };

  const getInfoBoxContent = (title: string, startDate: Date, endDate: Date) => ({
    title,
    items: [
      { key: 'Start Date', value: formatDate(startDate) },
      { key: 'End Date', value: formatDate(endDate) },
      { key: 'Status', value: status.charAt(0).toUpperCase() + status.slice(1) }
    ]
  });

  // Set a minimum width for visibility
  const minWidth = 15; // Minimum width in pixels for any project item

  const hasTasks = (tasks?.length ?? 0) > 0;

  return (
    <div className={`flex flex-col ${hasTasks ? 'mb-2' : ''}`}>
      <div className="flex h-10 items-center group">
        {/* Project Name Column */}
        <div className="w-48 pr-6 flex-shrink-0 flex items-center">
          {hasTasks && (
            <button
              onClick={handleExpandToggle}
              className="mr-2 w-4 h-4 flex items-center justify-center text-text hover:text-primary"
            >
              {isExpanded ? '−' : '+'}
            </button>
          )}
          <span
            className="text-sm font-medium text-text group-hover:text-primary truncate block cursor-pointer"
            onClick={() => onItemClick?.(id)}
          >
            {title}
          </span>
        </div>

        {/* Timeline Bar Container */}
        <div className="flex-grow relative">
          <InfoBox 
            content={getInfoBoxContent(title, startDate, endDate)}
            position="top"
            variant="default"
          >
            <div
              className={`absolute inset-y-0 h-7 my-auto rounded-md transition-all duration-200 cursor-pointer ${statusStyles[status]}`}
              style={{
                left: `${pixelStart}px`,
                width: `${Math.max(pixelWidth, minWidth)}px`,
              }}
              onClick={() => onItemClick?.(id)}
            >
              <div className="px-3 h-full flex items-center justify-center overflow-hidden">
                <span className="text-xs text-white font-medium truncate">
                  {formatDate(startDate)} - {formatDate(endDate)}
                </span>
              </div>
            </div>
          </InfoBox>
        </div>
      </div>

      {/* Tasks Section */}
      {isExpanded && hasTasks && tasks && (
        <div className="ml-48 space-y-1 mt-1">
          {tasks.map((task) => {
            const taskPosition = getTimelinePositioning(task, startDate, dayWidth);
            return (
              <div key={task.id} className="flex h-8 items-center">
                <div className="flex-grow relative">
                  <InfoBox
                    content={getInfoBoxContent(task.title, task.startDate, task.endDate)}
                    position="top"
                    variant="default"
                  >
                    <div
                      className={`absolute inset-y-0 h-6 my-auto rounded-sm transition-all duration-200 cursor-pointer opacity-80 hover:opacity-100 ${statusStyles[task.status]}`}
                      style={{
                        left: `${taskPosition.pixelStart}px`,
                        width: `${Math.max(taskPosition.pixelWidth, minWidth)}px`,
                      }}
                      onClick={() => onTaskClick?.(id, task.id)}
                    >
                      <div className="px-2 h-full flex items-center overflow-hidden">
                        <span className="text-xs text-white font-medium truncate">
                          {task.title}
                        </span>
                      </div>
                    </div>
                  </InfoBox>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// Helper function to calculate the pixel start and width based on days
const getTimelinePositioning = (
  item: GanttItem,
  startDate: Date,
  dayWidth: number
): { pixelStart: number; pixelWidth: number } => {
  // Calculate the start offset in days
  const itemStartDays = (item.startDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  
  // Calculate the duration in days
  const itemDurationDays = (item.endDate.getTime() - item.startDate.getTime()) / (1000 * 60 * 60 * 24);

  // Convert days to pixels
  const pixelStart = itemStartDays * dayWidth;
  const pixelWidth = itemDurationDays * dayWidth;

  return { pixelStart, pixelWidth };
};

export const GanttChart: React.FC<GanttChartProps> = ({
  items,
  className = '',
  onItemClick,
  onTaskClick,
  onExpandToggle,
}) => {
  // Set the timeline to start at the first day of the earliest month
  const startDate = new Date(Math.min(...items.map((item) => item.startDate.getTime())));
  startDate.setDate(1); // Start from the 1st of the earliest month

  // Set the timeline to end at the last day of the latest month
  const endDate = new Date(Math.max(...items.map((item) => item.endDate.getTime())));
  endDate.setMonth(endDate.getMonth() + 1, 0); // End on the last day of the latest month

  // Define new widths
  const monthWidth = 180; // Increase month width to 270px
  const dayWidth = monthWidth / 30; // Recalculate day width based on the new month width

  // Generate month labels based on the adjusted start and end dates
  const months: Date[] = [];
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    months.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return (
    <div className={`bg-white p-6 overflow-x-auto ${className}`}>
      <div className="flex flex-col" style={{ minWidth: `${48 + months.length * monthWidth}px` }}>
        {/* Header with month labels */}
        <div className="flex mb-4">
          {/* Space for titles */}
          <div className="w-48 flex-shrink-0" />

          {/* Month labels */}
          <div className="flex-grow flex">
            {months.map((month, index) => (
              <div
                key={index}
                className="text-sm font-medium text-text text-center"
                style={{
                  width: `${monthWidth}px`, // Fixed width per month
                  minWidth: `${monthWidth}px`,
                }}
              >
                {month.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })}
              </div>
            ))}
          </div>
        </div>

        {/* Main content area */}
        <div className="relative">
          {/* Grid lines */}
          <div className="absolute inset-0 flex ml-48">
            {months.map((_, index) => (
              <div
                key={index}
                className="border-l border-neutral h-full"
                style={{
                  width: `${monthWidth}px`, // Fixed width per month
                  minWidth: `${monthWidth}px`,
                }}
              />
            ))}
          </div>

          {/* Gantt Items */}
          <div className="relative space-y-2">
            {items.map((item) => {
              const { pixelStart, pixelWidth } = getTimelinePositioning(item, startDate, dayWidth);

              return (
                <GanttBar
                  key={item.id}
                  {...item}
                  pixelStart={pixelStart}
                  pixelWidth={pixelWidth}
                  dayWidth={dayWidth}
                  startDate={startDate}
                  onItemClick={onItemClick}
                  onTaskClick={onTaskClick}
                  onExpandToggle={onExpandToggle}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GanttChart;
