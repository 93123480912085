import React, { TextareaHTMLAttributes, forwardRef } from 'react';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  helperText?: string;
  error?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, helperText, error, className, ...props }, ref) => {
    const textareaClasses = `
      w-full px-2 py-1.5 bg-white border rounded-md appearance-none transition duration-150 ease-in-out text-sm
      focus:outline-none focus:ring-1 focus:border-primary
      ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-neutral focus:border-primary'}
      ${props.disabled ? 'bg-neutral bg-opacity-50 cursor-not-allowed' : ''}
    `;

    return (
      <div className="mb-3">
        <label className="block text-xs font-medium text-text mb-1" htmlFor={props.id}>
          {label}
        </label>
        <textarea
          ref={ref}
          className={`${textareaClasses} ${className}`}
          rows={4}
          {...props}
        />
        {helperText && !error && (
          <p className="mt-1 text-xs text-text opacity-70">{helperText}</p>
        )}
        {error && (
          <p className="mt-1 text-xs text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';