import React, { useState } from 'react';
import { PanelList, Icon, Button, SeparatorHorizontal, Snackbar } from '../ui';
import TopicContainer from '../../containers/TopicContainer';
import { Topic } from '../../api/topic/types';
import { TopicModal } from '../topic/TopicModal';
import { useQueryClient } from 'react-query';

interface MeetingOccurrenceTopicPanelProps {
  isOpen: boolean;
  onClose: () => void;
  meetingTypeId: string;
  onTopicClick?: (topic: Topic) => void;
  title?: string;
}

const MeetingOccurrenceTopicPanel: React.FC<MeetingOccurrenceTopicPanelProps> = ({
  isOpen,
  onClose,
  meetingTypeId,
  onTopicClick,
  title = 'Related Topics',
}) => {
  const [showModal, setShowModal] = useState(false);
  const [topicToEdit, setTopicToEdit] = useState<Topic | null>(null);
  const queryClient = useQueryClient();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  const openTopicCreateModal = () => {
    setTopicToEdit(null);
    setShowModal(true);
  };

  const openTopicEditModal = (topic: Topic) => {
    setTopicToEdit(topic);
    setShowModal(true);
  };

  const handleModalSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('success');
    setShowSnackbar(true);
    setShowModal(false);
    queryClient.invalidateQueries(['topics', meetingTypeId]);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        <Button
          size="medium"
          variant="primary"
          label="Add New Topic"
          onClick={openTopicCreateModal}
        />
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <TopicContainer
          render={({ data: topics, loading, error }) => {
            const relatedTopics = topics.filter(
              topic => topic.meeting_types?.some(mt => mt.id === meetingTypeId)
            );

            const sortedTopics = [...relatedTopics].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            if (loading) return <div className="p-4">Loading topics...</div>;
            if (error) return <div className="p-4 text-red-500">Error: {error}</div>;
            if (sortedTopics.length === 0) return <div className="p-4">No topics found</div>;

            return (
              <PanelList
                items={sortedTopics}
                loading={loading}
                renderItem={(topic) => (
                  <div
                    key={topic.id}
                    className="relative p-4 cursor-pointer"
                    onClick={() => onTopicClick && onTopicClick(topic)}
                  >
                    <div className="absolute top-2 right-2 flex space-x-1">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openTopicEditModal(topic);
                        }}
                        className="text-primary hover:text-primary-dark focus:outline-none"
                        title="Edit Topic"
                      >
                        <Icon name="Maximize" size="sm" />
                      </button>
                    </div>
                    <div className="flex flex-col space-y-1">
                      <span className="text-base font-semibold">{topic.title}</span>
                      <div className="flex text-xs text-gray-500 space-x-2">
                        {topic.created_at && (
                          <p>• Created on: {new Date(topic.created_at).toLocaleDateString()}</p>
                        )}
                      </div>
                      <div className="flex text-xs text-gray-500 space-x-2 mt-1">
                        <p>State: {topic.state}</p>
                        <p>• Importance: {topic.importance}</p>
                      </div>
                    </div>
                  </div>
                )}
              />
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}

        {showModal && (
          <TopicModal
            isOpen={showModal}
            initialData={topicToEdit || undefined}
            onClose={() => setShowModal(false)}
            onSuccess={handleModalSuccess}
            onError={(message: string) => {
              setSnackbarMessage(message);
              setSnackbarType('error');
              setShowSnackbar(true);
            }}
            meetingTypeId={meetingTypeId}
          />
        )}
      </div>
    </div>
  );
};

export default MeetingOccurrenceTopicPanel;