import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getPersonId, getCompanyId, setTokens, clearTokens, isAuthenticated, getTokenExpirationTime } from '../utils/auth';
import { refreshToken as refreshTokenService } from '../services/authService';

interface UserContextProps {
    personId: string | null;
    companyId: string | null;
    setSession: (accessToken: string, refreshToken: string, personId: string, companyId: string) => void;
    clearSession: () => void;
    isAuthenticated: boolean;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [personId, setPersonId] = useState<string | null>(getPersonId());
    const [companyId, setCompanyId] = useState<string | null>(getCompanyId());
    const [authenticated, setAuthenticated] = useState<boolean>(isAuthenticated());
    let refreshTimeout: NodeJS.Timeout | null = null;

    // Set session and store tokens
    const setSession = (accessToken: string, refreshToken: string, personId: string, companyId: string) => {
        setPersonId(personId);
        setCompanyId(companyId);
        setAuthenticated(true);
        setTokens(accessToken, refreshToken, personId, companyId);  // Store tokens and IDs in localStorage
        scheduleTokenRefresh();  // Schedule token refresh
    };

    // Clear session and tokens
    const clearSession = () => {
        setPersonId(null);
        setCompanyId(null);
        setAuthenticated(false);
        clearTokens();  // Clear from localStorage
        if (refreshTimeout) clearTimeout(refreshTimeout);  // Clear token refresh timeout
    };

    // Refresh tokens
    const refreshAccessToken = async () => {
        try {
            const { accessToken, refreshToken } = await refreshTokenService();
            setTokens(accessToken, refreshToken);  // Update tokens in localStorage
            setAuthenticated(true);
            scheduleTokenRefresh();  // Schedule next refresh
        } catch (error) {
            console.error('Token refresh failed, logging out:', error);
            clearSession();  // Log out if refresh fails
        }
    };

    // Schedule token refresh based on token expiration
    const scheduleTokenRefresh = () => {
        const expirationTime = getTokenExpirationTime();
        if (!expirationTime) return;

        const now = Date.now();
        const refreshTime = expirationTime - now - 60 * 1000;  // Refresh 1 minute before expiration

        if (refreshTimeout) clearTimeout(refreshTimeout);  // Clear any existing timeout
        refreshTimeout = setTimeout(() => {
            refreshAccessToken();  // Refresh token when timeout is reached
        }, refreshTime);
    };

    // On mount, check session and schedule the token refresh
    useEffect(() => {
        setPersonId(getPersonId());
        setCompanyId(getCompanyId());
        setAuthenticated(isAuthenticated());
        if (isAuthenticated()) {
            scheduleTokenRefresh();  // Schedule token refresh if authenticated
        }
    }, []);

    return (
        <UserContext.Provider value={{ personId, companyId, setSession, clearSession, isAuthenticated: authenticated }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
