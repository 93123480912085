import React, { useState, useEffect } from 'react';
import { Icon } from '../';
import { FilterSelect, FilterOption } from '../FilterSelect';

export interface FilterConfig {
  [key: string]: FilterOption;
}

export interface FilterProps {
  config: FilterConfig;
  onFilterChange: (filters: Record<string, any>) => void;
  initialValues?: Record<string, any>; // Prop for initial filter values
}

export const Filter: React.FC<FilterProps> = ({ config, onFilterChange, initialValues = {} }) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [filterValues, setFilterValues] = useState<Record<string, any>>(initialValues);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [lastAddedFilter, setLastAddedFilter] = useState<string | null>(null);

  // Set initial filter values only on the first render
  useEffect(() => {
    setActiveFilters(Object.keys(initialValues));
    setFilterValues(initialValues);
    onFilterChange(initialValues); // Send initial values to parent once on mount
  }, []); // Empty dependency array ensures this runs only once

  const handleAddFilter = (filterKey: string) => {
    if (!activeFilters.includes(filterKey)) {
      setActiveFilters((prev) => [...prev, filterKey]);
      setFilterValues((prev) => ({
        ...prev,
        [filterKey]: config[filterKey].type === 'multi-select' ? [] : null,
      }));
      setLastAddedFilter(filterKey);
    }
    setIsSelectOpen(false);
  };

  const handleRemoveFilter = (filterKey: string) => {
    setActiveFilters((prev) => prev.filter((key) => key !== filterKey));
    setFilterValues((prev) => {
      const newValues = { ...prev };
      delete newValues[filterKey];
      onFilterChange(newValues);
      return newValues;
    });
    if (lastAddedFilter === filterKey) {
      setLastAddedFilter(null);
    }
  };

  const handleFilterValueChange = (filterKey: string, value: any) => {
    setFilterValues((prev) => {
      const newValues = { ...prev, [filterKey]: value };
      onFilterChange(newValues);
      return newValues;
    });
  };

  const handleClearAllFilters = () => {
    setActiveFilters([]);
    setFilterValues({});
    onFilterChange({});
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {activeFilters.map((filterKey) => (
        <FilterSelect
          key={filterKey}
          option={config[filterKey]}
          value={filterValues[filterKey]}
          onChange={(value) => handleFilterValueChange(filterKey, value)}
          onRemove={() => handleRemoveFilter(filterKey)}
          initiallyOpen={filterKey === lastAddedFilter}
        />
      ))}

      <div className="relative inline-block">
        <button
          onClick={() => setIsSelectOpen(!isSelectOpen)}
          className="flex items-center space-x-1 rounded-md bg-light px-3 py-1 hover:bg-primary hover:text-white group"
        >
          <Icon name="Filter" size="sm" color="text" className="group-hover:text-white" />
          <span className="text-sm text-text group-hover:text-white">Add filter</span>
        </button>

        {isSelectOpen && (
          <div className="absolute z-10 mt-1 w-auto min-w-[200px] bg-white border border-neutral rounded-md shadow-lg">
            {Object.entries(config)
              .filter(([key]) => !activeFilters.includes(key))
              .map(([key, value]) => (
                <div
                  key={key}
                  className="px-3 py-2 text-sm cursor-pointer hover:bg-neutral"
                  onClick={() => handleAddFilter(key)}
                >
                  {value.label}
                </div>
              ))}
          </div>
        )}
      </div>

      {activeFilters.length > 0 && (
        <button
          onClick={handleClearAllFilters}
          className="px-3 py-1 text-sm text-primary hover:text-primary-dark rounded-md border border-primary hover:bg-primary hover:text-white transition-colors duration-200"
        >
          Clear All Filters
        </button>
      )}
    </div>
  );
};
