import { Favorite } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export interface FetchFavoritesParams {
    type?: string;  // e.g., 'topic.topic'
}

export const fetchFavorites = async (
    params: FetchFavoritesParams,
    navigate: (path: string) => void
): Promise<Favorite[]> => {
    const company_id = getCompanyId();
    if (!company_id) throw new Error('Company ID not found.');

    const queryParams = new URLSearchParams({ company_id });
    if (params.type) {
        return fetchFavoritesByType(params.type, navigate);
    }

    const response = await fetchWithAuth(
        `${API_URL}favorites/?${queryParams.toString()}`,
        {},
        navigate
    );
    if (!response.ok) throw new Error('Failed to fetch favorites');
    return response.json();
};

export const fetchFavoritesByType = async (
    type: string,
    navigate: (path: string) => void
): Promise<Favorite[]> => {
    const company_id = getCompanyId();
    if (!company_id) throw new Error('Company ID not found.');

    const response = await fetchWithAuth(
        `${API_URL}favorites/by_type/?type=${type}&company_id=${company_id}`,
        {},
        navigate
    );
    if (!response.ok) throw new Error('Failed to fetch favorites by type');
    return response.json();
};

export const toggleFavorite = async (
    contentType: string,
    objectId: string,
    navigate: (path: string) => void
): Promise<{ status: string }> => {
    const response = await fetchWithAuth(
        `${API_URL}favorites/toggle/`,
        {
            method: 'POST',
            body: JSON.stringify({
                content_type_str: contentType,
                object_id: objectId
            }),
        },
        navigate
    );

    if (!response.ok) throw new Error('Failed to toggle favorite');
    return response.json();
};