import React, { useState } from 'react';
import TagContainer from '../containers/TagContainer';
import { TagsTable } from '../components/tags/TagsTable';
import { TagModal } from '../components/tags/TagModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Tag } from '../api/tag/types';

const TagsListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);  // Control for TagModal
  const [tagToDelete, setTagToDelete] = useState<string | null>(null);
  const [tagToEdit, setTagToEdit] = useState<Tag | null>(null);  // Tag to edit or null for new tag
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);  // Control for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState('');  // Message for Snackbar
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');  // Type for Snackbar

  // Open the confirmation dialog for deletion
  const handleDelete = (id: string) => {
    setTagToDelete(id);
    setShowDeleteDialog(true);
  };

  // Confirm the deletion of the tag
  const confirmDelete = (onDelete: (id: string) => void) => {
    if (tagToDelete) {
      onDelete(tagToDelete);
      setShowDeleteDialog(false);
      setTagToDelete(null);
      setSnackbarMessage('Tag deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleUpdateName = (id: string, newName: string, onEdit: (id: string, updatedTag: Partial<Tag>) => void) => {
    onEdit(id, { name: newName });  // Call the onEdit method with the updated name
    setSnackbarMessage('Tag name updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateColor = (id: string, newColor: string, onEdit: (id: string, updatedTag: Partial<Tag>) => void) => {
    onEdit(id, { color: newColor });  // Call the onEdit method with the updated color
    setSnackbarMessage('Tag color updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  // Handle search change
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  // Handle opening the modal for adding a new tag
  const handleAddNew = () => {
    setTagToEdit(null);  // Reset the modal to create a new tag
    setShowTagModal(true);
  };

  // Handle opening the modal for editing an existing tag
  const handleEditTag = (tag: Tag) => {
    setTagToEdit(tag);  // Pass the tag data for editing
    setShowTagModal(true);
  };

  // Handle saving a tag (create or edit)
  const handleSaveTag = (
    tagData: Omit<Tag, 'id' | 'created_at' | 'updated_at'>,
    shouldClose: boolean,
    onCreate: (newTag: Omit<Tag, 'id' | 'created_at' | 'updated_at'>) => void,
    onEdit: (id: string, updatedTag: Partial<Tag>) => void
  ) => {
    if (tagToEdit) {
      onEdit(tagToEdit.id, tagData);
      setSnackbarMessage('Tag updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    } else {
      onCreate(tagData);
      setSnackbarMessage('Tag created successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
    
    if (shouldClose) {
      setShowTagModal(false);
    }
  };

  return (
    <div>
      <h1 className='m-4'>Tags</h1>
      <SeparatorHorizontal />

      {/* TableHeader with search and Add New button */}
      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search tags..."
        addButtonLabel="Add New Tag"
      />

      {/* Tag Modal for creating or editing */}
      <TagContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading tags...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          const filteredTags = data.filter(tag => 
            tag.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <TagModal
                isOpen={showTagModal}
                onClose={() => setShowTagModal(false)}
                onSave={(tagData, shouldClose) => handleSaveTag(tagData, shouldClose, onCreate, onEdit)}
                initialData={tagToEdit ? {
                  id: tagToEdit.id,
                  name: tagToEdit.name,
                  company: tagToEdit.company,
                  color: tagToEdit.color,
                  is_favorited: tagToEdit.is_favorited,
                  favorites_count: tagToEdit.favorites_count,
                  created_at: tagToEdit.created_at,
                  updated_at: tagToEdit.updated_at
                } : undefined}
              />

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this tag?</p>
              </Modal>

              <TagsTable
                data={filteredTags} 
                onEdit={handleEditTag} 
                onDelete={(id) => handleDelete(id)}
                onUpdateName={(id, newName) => handleUpdateName(id, newName, onEdit)} 
                onUpdateColor={(id, newColor) => handleUpdateColor(id, newColor, onEdit)}
              />
            </>
          );
        }}
      />

      {/* Snackbar for success message */}
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType} 
          onClose={() => setShowSnackbar(false)} 
        />
      )}
    </div>
  );
};

export default TagsListPage;
