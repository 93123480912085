import { Tag } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all tags
export const fetchTags = async (navigate: (path: string) => void): Promise<Tag[]> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}tags/?company_id=${company_id}`, {}, navigate);
    if (!response.ok) {
        throw new Error('Failed to fetch tags');
    }
    return response.json();
};

// Fetch a single tag by ID
export const fetchTag = async (id: string, navigate: (path: string) => void): Promise<Tag> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}tags/${id}/?company_id=${company_id}`, {}, navigate);
    if (!response.ok) {
        throw new Error(`Failed to fetch tag with id ${id}`);
    }
    return response.json();
};

// Create a new tag
export const createTag = async (tag: Omit<Tag, 'id' | 'created_at' | 'updated_at'>, navigate: (path: string) => void): Promise<Tag> => {
    const company_id = getCompanyId();

    const response = await fetchWithAuth(`${API_URL}tags/`, {
        method: 'POST',
        body: JSON.stringify({
            ...tag,
            company: company_id,
        }),
    }, navigate);
    if (!response.ok) {
        throw new Error('Failed to create tag');
    }
    return response.json();
};

// Edit an existing tag
export const editTag = async (id: string, updatedTag: Partial<Tag>, navigate: (path: string) => void): Promise<Tag> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}tags/${id}/`, {
        method: 'PATCH',  // Assuming partial updates are supported
        body: JSON.stringify({
            ...updatedTag,
            company_id,
        }),
    }, navigate);
    if (!response.ok) {
        throw new Error(`Failed to edit tag with id ${id}`);
    }
    return response.json();
};

// Delete an existing tag
export const deleteTag = async (id: string, navigate: (path: string) => void): Promise<void> => {
    const company_id = getCompanyId();
    if (!company_id) {
        throw new Error('Company ID not found.');
    }

    const response = await fetchWithAuth(`${API_URL}tags/${id}/?company_id=${company_id}`, {
        method: 'DELETE',
    }, navigate);
    if (!response.ok) {
        throw new Error(`Failed to delete tag with id ${id}`);
    }
};
