import React from 'react';
import { Person } from '../../api/person/types';
import { Table, TableDateComponent, TableActionButtons, TableBadgeComponent, TableInlineTextInput, Icon } from '../ui';

interface PersonTableProps {
  data: Person[];
  onEdit: (person: Person) => void;
  onDelete: (slug: string) => void;
  onUpdatePersonType: (slug: string, newPersonType: Person['person_type']) => void;
  onUpdateField: (slug: string, field: keyof Person, value: string) => void;
  onViewMindMap: (slug: string) => void;
}

const personTypeOptions = [
  { label: 'Team Member', value: 'team_member' },
  { label: 'Stakeholder', value: 'stakeholder' },
  { label: 'External Partner', value: 'external_partner' },
  { label: 'Advisor', value: 'advisor' },
  { label: 'Client', value: 'client' },
  { label: 'Other', value: 'other' },
];

export const PersonTable: React.FC<PersonTableProps> = ({ 
  data, 
  onEdit, 
  onDelete, 
  onUpdatePersonType, 
  onUpdateField,
  onViewMindMap
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Name',
          accessor: 'name',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}  // Trigger the onEdit function when clicking the name
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.name}
                onSave={(value) => onUpdateField(row.slug, 'name', value)}
                onCancel={() => {}}
              />
            </div>
          ),
        },        
        {
          header: 'Email',
          accessor: 'email',
          component: (row) => (
            <TableInlineTextInput
              initialValue={row.email}
              onSave={(value) => onUpdateField(row.slug, 'email', value)}
              onCancel={() => {}}
            />
          ),
        },
        {
          header: 'Person Type',
          accessor: 'person_type',
          component: (row) => (
            <TableBadgeComponent
              label={row.person_type.replace('_', ' ')}
              type="state"
              onUpdate={(newPersonType) => onUpdatePersonType(row.slug, newPersonType as Person['person_type'])}
              options={personTypeOptions}
              isEditable={true}
            />
          ),
        },
        {
          header: 'Created At',
          component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
        },
        {
          header: 'Actions',
          component: (row) => (
            <div className="flex items-center gap-2">
              <TableActionButtons 
                onEdit={() => onEdit(row)} 
                onDelete={() => onDelete(row.slug)} 
              />
              <button
                onClick={() => onViewMindMap(row.slug)}
                className="p-2 text-primary hover:text-primary-dark rounded-full hover:bg-gray-100"
                title="View Mind Map"
              >
                <Icon name="Share2" size="sm" className="text-primary" />
              </button>
            </div>
          ),
        },
      ]}
    />
  );
};