import React from 'react';
import { useLogEntriesByObjectId, useCreateLogEntry, useEditLogEntry, useDeleteLogEntry } from '../api/logEntry/queries';
import { LogEntry } from '../api/logEntry/types';

interface LogEntryContainerProps {
  objectId: string;  // Object ID to fetch log entries for
  render: (props: {
    data: LogEntry[];
    loading: boolean;
    error: string | null;
    onCreate: (newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>) => void;
    onEdit: (id: string, updatedLogEntry: Partial<LogEntry>) => void;
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

const LogEntryContainer: React.FC<LogEntryContainerProps> = ({ objectId, render }) => {
  const { data: logEntries = [], isLoading, error } = useLogEntriesByObjectId(objectId);
  const createLogEntryMutation = useCreateLogEntry();
  const editLogEntryMutation = useEditLogEntry();
  const deleteLogEntryMutation = useDeleteLogEntry();

  const handleCreate = (newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by' | 'company'>) => {
    createLogEntryMutation.mutate(newLogEntry);
  };

  const handleEdit = (id: string, updatedLogEntry: Partial<LogEntry>) => {
    editLogEntryMutation.mutate({ id, updatedLogEntry });
  };

  const handleDelete = (id: string) => {
    deleteLogEntryMutation.mutate(id);
  };

  return render({
    data: logEntries,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default LogEntryContainer;