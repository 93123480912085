import { jwtDecode } from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const PERSON_ID_KEY = 'person_id';
const COMPANY_ID_KEY = 'company_id';

// Decode the access token to get the expiration time
export const getTokenExpirationTime = () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) return null;

    try {
        const decoded: { exp: number } = jwtDecode(token);
        return decoded.exp * 1000;  // Convert expiration time to milliseconds
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

// Store tokens and IDs
export const setTokens = (accessToken: string, refreshToken: string, personId?: string, companyId?: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    if (personId) localStorage.setItem(PERSON_ID_KEY, personId);
    if (companyId) localStorage.setItem(COMPANY_ID_KEY, companyId);
};

// Get the access token
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

// Get the refresh token
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

// Clear tokens and user data from localStorage
export const clearTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(PERSON_ID_KEY);
    localStorage.removeItem(COMPANY_ID_KEY);
};

// Check if the access token is valid and not expired
export const isAuthenticated = () => {
    const token = getAccessToken();
    if (!token) return false;

    try {
        const decoded: { exp: number } = jwtDecode(token);  // Decode token to check expiration
        const now = Date.now().valueOf() / 1000;  // Current time in seconds
        return decoded.exp > now;  // Token is valid if expiration is in the future
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
};

// Get the person ID
export const getPersonId = () => localStorage.getItem(PERSON_ID_KEY);

// Get the company ID
export const getCompanyId = () => localStorage.getItem(COMPANY_ID_KEY);
