import { GraphNode } from '../../api/networkGraph/types';
import { X } from 'lucide-react';

interface Props {
  node: GraphNode;
  onClose: () => void;
}

export const NodeDetails = ({ node, onClose }: Props) => (
  <div className="absolute top-4 right-4 w-80 bg-white rounded-lg shadow-lg border">
    <div className="flex items-center justify-between p-4 border-b">
      <h3 className="font-medium">{node.data.label}</h3>
      <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
        <X className="h-4 w-4" />
      </button>
    </div>
    <div className="p-4">
      <p className="text-sm text-gray-600">Type: {node.type}</p>
      {/* Add more details based on node type */}
      {Object.entries(node.data).map(([key, value]) => (
        key !== 'label' && (
          <p key={key} className="text-sm text-gray-600">
            {key}: {value?.toString()}
          </p>
        )
      ))}
    </div>
  </div>
);