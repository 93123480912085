import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnapshot } from '../../api/project/queries';
import { 
  Card, 
  CardContent, 
  Button, 
  Badge,
  SeparatorHorizontal
} from '../ui';
import { formatDate } from '../../utils/dateUtils';
import { SnapshotProjectsTable } from './SnapshotProjectsTable';
import { Generate515Report } from './Generate515Report';
import { ExecutiveSummaryReport } from './ExecutiveSummaryReport';

const SnapshotDetailPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { data: snapshot, isLoading, error } = useSnapshot(slug!);

  if (isLoading) {
    return <div>Loading snapshot...</div>;
  }

  if (error || !snapshot) {
    return <div>Error loading snapshot</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold">{snapshot.title}</h1>
          <div className="flex items-center gap-2 mt-1">
            <Badge variant="primary">{snapshot.status.toUpperCase()}</Badge>
            <span className="text-sm text-gray-500">
              Created by {snapshot.created_by.name} on {formatDate(snapshot.created_at)}
            </span>
          </div>
        </div>
        <div className="flex gap-2">
        <ExecutiveSummaryReport 
            snapshotProjects={snapshot.snapshot_projects}
            periodStart={snapshot.period_start}
            periodEnd={snapshot.period_end}
            title={snapshot.title}
          />
          <Generate515Report 
            snapshotProjects={snapshot.snapshot_projects}
            periodStart={snapshot.period_start}
            periodEnd={snapshot.period_end}
          />
          <Button
            label="Back to List"
            variant="secondary"
            buttonStyle="outlined"
            onClick={() => navigate('/snapshots')}
          />
          {snapshot.status === 'draft' && (
            <Button
              label="Edit"
              variant="primary"
              buttonStyle="outlined"
              onClick={() => navigate(`/snapshots/${slug}/edit`)}
            />
          )}
        </div>
      </div>

      <SeparatorHorizontal />

      {/* Overview Section */}
      <Card>
        <CardContent>
          <div className="space-y-4">
            {snapshot.description && (
              <div>
                <h3 className="text-sm font-medium text-gray-500">Description</h3>
                <p className="mt-1">{snapshot.description}</p>
              </div>
            )}
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Period</h3>
                <p className="mt-1">
                  {formatDate(snapshot.period_start)} - {formatDate(snapshot.period_end)}
                </p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Visibility</h3>
                <p className="mt-1">{snapshot.visibility}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Participants</h3>
                <div className="mt-1 space-y-1">
                  {snapshot.persons.map(person => (
                    <div key={person.id} className="text-sm">
                      {person.name}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Recipients</h3>
                <div className="mt-1 space-y-1">
                  {snapshot.notification_recipients.map(person => (
                    <div key={person.id} className="text-sm">
                      {person.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Projects Table Overview */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Projects Overview</h2>
        <Card>
          <CardContent>
            <SnapshotProjectsTable snapshotProjects={snapshot.snapshot_projects} />
          </CardContent>
        </Card>
      </div>

      {/* Projects Detailed Section */}
      <h2 className="text-xl font-semibold">Project Details</h2>
      <div className="space-y-4">
        {snapshot.snapshot_projects.map(snapshotProject => (
          <Card key={snapshotProject.id}>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium">{snapshotProject.project.title}</h3>
                  <div className="flex gap-2">
                    <Badge variant="primary">{snapshotProject.state}</Badge>
                    <Badge variant="neutral">{snapshotProject.pulse}</Badge>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 text-sm">
                  <div>
                    <span className="text-gray-500">Priority:</span> {snapshotProject.priority}
                  </div>
                  <div>
                    <span className="text-gray-500">Effort:</span> {snapshotProject.effort}
                  </div>
                  <div>
                    <span className="text-gray-500">Progress:</span> {snapshotProject.progress}%
                  </div>
                </div>

                {snapshotProject.summary && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-500">Summary</h4>
                    <p className="mt-1 text-sm">{snapshotProject.summary}</p>
                  </div>
                )}

                {/* Log Entries */}
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Updates</h4>
                  <div className="space-y-2">
                    {snapshotProject.log_entries.map(entry => (
                      <div key={entry.id} className="bg-gray-50 p-3 rounded-md">
                        <div className="flex items-center justify-between">
                          <Badge variant="outline">
                            {entry.log_type.replace(/_/g, ' ')}
                          </Badge>
                          <span className="text-xs text-gray-500">
                            {formatDate(entry.created_at)}
                          </span>
                        </div>
                        <p className="mt-1 text-sm">{entry.content}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Summary Stats */}
                <div className="bg-gray-50 p-3 rounded-md">
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Statistics</h4>
                  <div className="grid grid-cols-3 gap-4 text-sm">
                    <div>Total Updates: {snapshotProject.summary_data.total_updates}</div>
                    <div>State Changes: {snapshotProject.summary_data.state_changes}</div>
                    <div>Priority Changes: {snapshotProject.summary_data.priority_changes}</div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SnapshotDetailPage;