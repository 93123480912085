import React from 'react';

interface StepItem {
  id: number;
  title: string;
  description?: string;
}

interface StepsProps {
  steps: StepItem[];
  currentStep: number;
  variant?: 'horizontal' | 'vertical';
  size?: 'sm' | 'md' | 'lg';
}

export const Steps = ({ 
  steps, 
  currentStep, 
  variant = 'horizontal',
  size = 'md' 
}: StepsProps) => {
  const getStepSize = () => {
    switch (size) {
      case 'sm': return 'w-6 h-6 text-xs';
      case 'lg': return 'w-10 h-10 text-base';
      default: return 'w-8 h-8 text-sm';
    }
  };

  if (variant === 'vertical') {
    return (
      <div className="flex flex-col space-y-4">
        {steps.map((step, index) => (
          <div key={step.id} className="flex items-start">
            <div className="flex flex-col items-center">
              <div 
                className={`${getStepSize()} rounded-full flex items-center justify-center font-medium
                  ${index < currentStep 
                    ? 'bg-primary text-white' 
                    : index === currentStep 
                    ? 'border-2 border-primary bg-white text-primary' 
                    : 'border-2 border-neutral bg-white text-neutral'
                  }`}
              >
                {index + 1}
              </div>
              {index < steps.length - 1 && (
                <div className={`w-px h-10 my-1 
                  ${index < currentStep ? 'bg-primary' : 'bg-neutral'}`} 
                />
              )}
            </div>
            <div className="ml-3">
              <div className={`font-medium ${
                index <= currentStep ? 'text-text' : 'text-neutral'
              }`}>
                {step.title}
              </div>
              {step.description && (
                <div className={`text-sm mt-1 ${
                  index === currentStep ? 'text-gray-600' : 'text-neutral'
                }`}>
                  {step.description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex items-center w-full">
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <div className="flex flex-col items-center flex-1">
            <div 
              className={`${getStepSize()} rounded-full flex items-center justify-center font-medium
                ${index < currentStep 
                  ? 'bg-primary text-white' 
                  : index === currentStep 
                  ? 'border-2 border-primary bg-white text-primary' 
                  : 'border-2 border-neutral bg-white text-neutral'
                }`}
            >
              {index + 1}
            </div>
            <div className={`mt-2 text-sm font-medium text-center
              ${index <= currentStep ? 'text-text' : 'text-neutral'}
            `}>
              {step.title}
            </div>
            {step.description && (
              <div className={`text-xs mt-1 text-center ${
                index === currentStep ? 'text-gray-600' : 'text-neutral'
              }`}>
                {step.description}
              </div>
            )}
          </div>
          {index < steps.length - 1 && (
            <div className={`h-px flex-1 mx-2
              ${index < currentStep ? 'bg-primary' : 'bg-neutral'}`} 
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

interface ProgressIndicatorProps {
  steps: string[];
  currentStep: number;
  variant?: 'dots' | 'circles' | 'bars';
  size?: 'sm' | 'md' | 'lg';
}

export const ProgressIndicator = ({
  steps,
  currentStep,
  variant = 'dots',
  size = 'md'
}: ProgressIndicatorProps) => {
  const getDotSize = () => {
    switch (size) {
      case 'sm': return 'w-2 h-2';
      case 'lg': return 'w-4 h-4';
      default: return 'w-3 h-3';
    }
  };

  if (variant === 'bars') {
    return (
      <div className="flex gap-2">
        {steps.map((_, index) => (
          <div 
            key={index} 
            className={`h-1 flex-1 rounded-full transition-colors
              ${index <= currentStep ? 'bg-primary' : 'bg-neutral'}`}
          />
        ))}
      </div>
    );
  }

  if (variant === 'circles') {
    return (
      <div className="flex items-center">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div 
              className={`${getDotSize()} rounded-full border-2 transition-colors
                ${index < currentStep 
                  ? 'bg-primary border-primary' 
                  : index === currentStep 
                  ? 'border-primary' 
                  : 'border-neutral'
                }`}
            />
            {index < steps.length - 1 && (
              <div className={`h-px w-4 mx-1
                ${index < currentStep ? 'bg-primary' : 'bg-neutral'}`} 
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      {steps.map((_, index) => (
        <div 
          key={index}
          className={`${getDotSize()} rounded-full transition-colors
            ${index <= currentStep ? 'bg-primary' : 'bg-neutral'}`}
        />
      ))}
    </div>
  );
};