import React from 'react';

export interface SeparatorHorizontalProps {
  isDisabled?: boolean;
  className?: string; // Additional Tailwind classes for customization
}

const SeparatorHorizontal: React.FC<SeparatorHorizontalProps> = ({
  isDisabled = false,
  className = '',
}: SeparatorHorizontalProps) => {
  return (
    <div
      className={`border-t border-neutral ${isDisabled ? 'opacity-50' : ''} ${className}`}
      role="separator"
    />
  );
};

export { SeparatorHorizontal };
