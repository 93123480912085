import React, { useState } from 'react';
import { Tooltip, Avatar, Icon } from '../../';  // Import your Icon component here
import PersonSelectMulti from '../../../person/PersonSelectMulti'; // Adjust the import path as necessary
import { Person } from '../../../../api/person/types'; // Import the Person type
import { PersonModal } from '../../../person/PersonModal'; // Import the PersonModal component

interface TablePersonsComponentProps {
  persons: Person[];
  limit?: number;
  isEditable?: boolean;
  onUpdate?: (newPersons: Person[]) => void;
  placeholder?: string;
}

export const TablePersonsComponent: React.FC<TablePersonsComponentProps> = ({ 
  persons, 
  limit = 2, 
  isEditable = false,
  onUpdate,
  placeholder = 'No persons',
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempSelectedPersons, setTempSelectedPersons] = useState<Person[]>(persons);
  const [isPersonModalOpen, setPersonModalOpen] = useState(false); // Modal state
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null); // Selected person

  const visiblePersons = persons.slice(0, limit);
  const hiddenPersons = persons.slice(limit);

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering modal open when clicking on edit
    if (isEditable) {
      setIsEditing(true);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    if (onUpdate && JSON.stringify(tempSelectedPersons) !== JSON.stringify(persons)) {
      onUpdate(tempSelectedPersons);
    }
  };

  const handleSelect = (selectedPersons: Person[]) => {
    setTempSelectedPersons(selectedPersons);
  };

  const handlePersonClick = (person: Person) => {
    setSelectedPerson(person);
    setPersonModalOpen(true); // Open modal on click
  };

  const handleModalClose = () => {
    setPersonModalOpen(false);
    setSelectedPerson(null); // Clear selected person when modal closes
  };

  const renderPersonsOrPlaceholder = () => {
    if (persons.length === 0) {
      return <span className="text-xs text-gray-500">{placeholder}</span>;
    }
    return (
      <div className="flex items-center space-x-2">
        {visiblePersons.map((person) => (
          <Tooltip
            key={person.slug}
            content={person.name}
            position="top"
          >
            {/* Clicking on the avatar opens the modal */}
            <span onClick={() => handlePersonClick(person)} className="cursor-pointer">
              <Avatar
                name={person.name}
                size="sm"
              />
            </span>
          </Tooltip>
        ))}
        {hiddenPersons.length > 0 && (
          <Tooltip
            content={hiddenPersons.map((p) => p.name).join(', ')}
            position="top"
          >
            <span className="text-xs text-gray-500 cursor-pointer">+{hiddenPersons.length} more...</span>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="relative inline-block">
        {isEditable && isEditing ? (
          <PersonSelectMulti
            value={tempSelectedPersons.map(person => person.id)}
            onChange={handleSelect}
            isOpen={true}
            onClose={handleClose}
            enableSearch={true}
            placeholder="Select persons"
          />
        ) : (
          <div className="flex items-center space-x-2">
            {/* Render the avatars for persons */}
            <div className={isEditable ? 'cursor-pointer hover:bg-gray-100 p-1 rounded' : ''}>
              {renderPersonsOrPlaceholder()}
            </div>
            {/* Add an edit icon when in editable mode */}
            {isEditable && (
              <span onClick={handleEditClick} className="cursor-pointer">
                <Icon name="Edit" size="sm" className='text-gray-400 hover:text-gray-600' />
              </span>
            )}
          </div>
        )}
      </div>

      {/* Render PersonModal when selectedPerson is set */}
      {selectedPerson && (
        <PersonModal
          isOpen={isPersonModalOpen}
          onClose={handleModalClose}
          initialData={selectedPerson}  // Pass the selected person's data to the modal
          onSuccess={(message) => console.log(message)}
          onError={(error) => console.log(error)}
        />
      )}
    </>
  );
};
