import React, { useState } from 'react';
import { SelectMulti, SelectOption } from '../ui/SelectMulti';
import PersonContainer from '../../containers/PersonContainer';
import { Person } from '../../api/person/types';
import { Avatar } from '../ui/Avatar';
import { PersonModal } from './PersonModal';

interface PersonSelectMultiProps {
  value: string[];
  onChange: (selectedPersons: Person[]) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  avatarThreshold?: number;
  placeholder?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const PersonSelectMulti: React.FC<PersonSelectMultiProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  avatarThreshold = 3,
  placeholder = "Select Persons",
  isOpen,
  onClose
}) => {
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [prefilledPersonName, setPrefilledPersonName] = useState('');

  return (
    <PersonContainer
      render={({ data: persons, loading, error, onCreate }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const personOptions = persons
        .map((person) => ({
          value: person.id,
          label: person.name,
          icon: <Avatar name={person.name} size="xs" />,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

        const handleSelect = (personIds: string[]) => {
          const selectedPersons = persons.filter((person) =>
            personIds.includes(person.id)
          );
          onChange(selectedPersons);
        };

        const handleCreateNewPerson = (newPersonName: string) => {
          setPrefilledPersonName(newPersonName);
          setIsPersonModalOpen(true);
        };

        const handlePersonModalClose = () => {
          setIsPersonModalOpen(false);
          setPrefilledPersonName('');
        };

        const handlePersonModalSuccess = (message: string, newPerson: Person) => {
          // Add the new person to the list and select it
          const updatedPersons = [...persons, newPerson];
          const updatedSelectedPersons = [...value, newPerson.id];
          onChange(updatedPersons.filter(person => updatedSelectedPersons.includes(person.id)));
        };

        const renderSelectedAvatars = (selectedOptions: SelectOption[]) => (
          <div className="flex space-x-2">
            {selectedOptions.map((option) => (
              <span key={option.value}>{option.icon}</span>
            ))}
          </div>
        );

        return (
          <>
            <SelectMulti
              placeholder={placeholder}
              options={personOptions}
              defaultValues={value}
              onSelect={handleSelect}
              isDisabled={isDisabled}
              enableSearch={enableSearch}
              renderSummary={renderSelectedAvatars}
              isOpen={isOpen}
              onClose={onClose}
              onCreate={handleCreateNewPerson}
              entityName="person"
            />
            {isPersonModalOpen && (
              <PersonModal
                isOpen={isPersonModalOpen}
                onClose={handlePersonModalClose}
                prefilledData={{ name: prefilledPersonName }}
                onSuccess={handlePersonModalSuccess}
                onError={(message) => console.error(message)}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default PersonSelectMulti;