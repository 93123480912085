import { useQuery } from 'react-query';
import { fetchStateTransitions } from './api';
import { StateTransition } from './types';

// Query function to use in React components
export const useStateTransitions = (model: string, objectId: string, navigate: (path: string) => void) => {
    return useQuery<StateTransition[], Error>(
        ['stateTransitions', model, objectId],  // Query key based on model and objectId
        () => fetchStateTransitions(model, objectId, navigate),  // Pass navigate function to fetchStateTransitions
        {
            staleTime: 60000,  // Data will be considered fresh for 1 minute
            cacheTime: 5 * 60000,  // Data will be cached for 5 minutes
            retry: 2,  // Retry fetching data up to 2 times on failure
            onError: (error) => {
                console.error('Error fetching state transitions:', error);  // Log error
            },
        }
    );
};
