import React, { useState, useEffect, useRef } from 'react';
import { Icon } from './base/Icon';

type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

type SelectSize = 'small' | 'medium' | 'large';

interface SelectSingleProps {
  options: SelectOption[];
  placeholder?: string;
  defaultValue?: string;
  defaultIcon?: React.ReactNode;
  onSelect: (value: string) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  onCreate?: (newValue: string) => void;
  entityName?: string;
}

const SelectSingle: React.FC<SelectSingleProps> = ({
  options,
  placeholder = 'Select an option',
  defaultValue = '',
  defaultIcon,
  onSelect,
  isDisabled = false,
  enableSearch = true,
  size = 'medium',
  isOpen,
  onClose,
  onCreate,
  entityName = 'item'
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
  const [internalDropdownOpen, setInternalDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);
  const selectRef = useRef<HTMLDivElement>(null);

  const dropdownOpen = isOpen !== undefined ? isOpen : internalDropdownOpen;

  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-2.5 py-1.5 text-sm', // Adjusted medium size for a balance
    large: 'px-3 py-2 text-base',
  };

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(option => option.value === defaultValue) || null;
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchQuery, options]);

  const handleSelect = (option: SelectOption) => {
    setSelectedOption(option);
    onSelect(option.value);
    setSearchQuery('');
    if (onClose) {
      onClose();
    } else {
      setInternalDropdownOpen(false);
    }
  };

  const handleCreate = () => {
    if (onCreate && searchQuery.trim()) {
      onCreate(searchQuery.trim());
      setSearchQuery('');
    }
  };

  const toggleDropdown = () => {
    if (!isDisabled) {
      if (onClose) {
        onClose();
      } else {
        setInternalDropdownOpen(!internalDropdownOpen);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        if (onClose) {
          onClose();
        } else {
          setInternalDropdownOpen(false);
        }
      }
    };

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen, onClose]);

  return (
    <div className="relative inline-block" ref={selectRef}>
      <button
        className={`flex items-center space-x-2 border border-neutral rounded-md shadow-sm cursor-pointer bg-white focus:outline-none focus:border-primary ${sizeClasses[size]} ${
          isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={toggleDropdown}
        disabled={isDisabled}
      >
        {selectedOption?.icon ? (
          <span className="text-text">
            {React.cloneElement(selectedOption?.icon as React.ReactElement<any>, {
              color: 'text',
            })}
          </span>
        ) : (
          defaultIcon && <span className="text-text">{defaultIcon}</span>
        )}
        <span className="text-text">{selectedOption ? selectedOption.label : placeholder}</span>
        <svg
          className="w-4 h-4 text-neutral"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>

      {dropdownOpen && !isDisabled && (
        <div className="absolute z-10 mt-2 w-auto min-w-[200px] border border-neutral bg-white rounded-md shadow-lg max-h-60 overflow-y-auto">
        {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-1.5 border border-neutral rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isDisabled}
              />
            </div>
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-sm ${
                  selectedOption?.value === option.value ? 'bg-primary text-white' : ''
                }`}
                onClick={() => handleSelect(option)}
              >
                <div className="flex items-center space-x-2">
                  {option.icon && (
                    <span>
                      {React.cloneElement(option.icon as React.ReactElement<any>, {
                        color: selectedOption?.value === option.value ? 'white' : 'text',
                      })}
                    </span>
                  )}
                  <span>{option.label}</span>
                </div>
                {selectedOption?.value === option.value && (
                  <svg
                    className="w-4 h-4 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-sm">No options found</div>
          )}

          {onCreate && searchQuery && !filteredOptions.length && (
            <div
              className="cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-primary text-sm"
              onClick={handleCreate}
            >
              Create new {entityName}: "{searchQuery}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { SelectSingle };
