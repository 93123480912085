import React from 'react';
import { SelectMulti } from '../ui/SelectMulti';
import TagContainer from '../../containers/TagContainer';
import { Tag } from '../../api/tag/types';

interface TagSelectProps {
  value: string[];
  onChange: (selectedTags: Tag[]) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const TagSelect: React.FC<TagSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = "Select Tags",
  isOpen,
  onClose
}) => {
  return (
    <TagContainer
      render={({ data: tags, loading, error, onCreate }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const tagOptions = tags
        .map((tag) => ({
          value: tag.id,
          label: tag.name,
          icon: (
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: tag.color }}
              title={tag.name}
            />
          ),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

        const handleSelect = (tagIds: string[]) => {
          const selectedTags = tags.filter((tag) => tagIds.includes(tag.id));
          onChange(selectedTags);
        };

        const handleCreateNewTag = async (newTagName: string) => {
          onCreate({ name: newTagName, favorites_count: 0, is_favorited: false });
        };

        return (
          <SelectMulti
            placeholder={placeholder}
            options={tagOptions}
            defaultValues={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            onCreate={handleCreateNewTag}
            entityName="tag"
            defaultIcon={<span>{/* Optional default icon */}</span>}
            isOpen={isOpen}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

export default TagSelect;