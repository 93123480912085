import { useCallback, useMemo, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import { GraphData, GraphNode } from '../../api/networkGraph/types';

interface Props {
  data: GraphData;
  onNodeClick?: (node: GraphNode) => void;
}

interface NodeType {
  type: string;
  data: {
    label: string;
    [key: string]: any;
  };
  x?: number;
  y?: number;
}

export const NetworkGraph = ({ data, onNodeClick }: Props) => {
  const validatedData = useMemo(() => {
    const nodeIds = new Set(data.nodes.map(node => node.id));
    const validEdges = data.edges.filter(edge => 
      nodeIds.has(edge.source) && nodeIds.has(edge.target)
    );

    return {
      nodes: data.nodes,
      links: validEdges
    };
  }, [data]);

  const getNodeColor = useCallback((node: NodeType) => {
    const colors: { [key: string]: string } = {
      task: '#3b82f6',
      project: '#f59e0b',
      topic: '#8b5cf6',
      person: '#10b981',
      tag: '#6b7280',
      dial: '#ef4444'
    };
    return colors[node.type] || colors.tag;
  }, []);

  const getNodeSize = useCallback((node: NodeType) => {
    const sizes: { [key: string]: number } = {
      project: 8,
      task: 6,
      person: 7,
      topic: 7,
      tag: 4,
      dial: 5
    };
    return sizes[node.type] || 5;
  }, []);

  // ForceGraph engine configuration through ref
  const fgRef = useRef();

  useEffect(() => {
    // Access and configure the force simulation directly
    const fg = fgRef.current;
    if (fg) {
      // @ts-ignore - ForceGraph internal API
      fg.d3Force('charge')?.strength(-50);  // Node repulsion
      // @ts-ignore
      fg.d3Force('link')?.distance(50);      // Link length
      // @ts-ignore
      fg.d3Force('center')?.strength(1);   // Centering force
    }
  }, []);

  return (
    <div className="w-full h-[800px] border rounded-lg bg-gray-50">
      <ForceGraph2D
        ref={fgRef}
        graphData={validatedData}
        nodeLabel={node => node.data.label}
        nodeColor={node => getNodeColor(node as NodeType)}
        nodeVal={node => getNodeSize(node as NodeType)}
        linkDirectionalParticles={4}
        linkDirectionalParticleWidth={link => 
          link.type === 'accountability' ? 2 : 0
        }
        onNodeClick={(node) => onNodeClick?.(node as unknown as GraphNode)}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const label = (node as NodeType).data.label;
          const fontSize = 12/globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.fillStyle = getNodeColor(node as NodeType);
          ctx.beginPath();
          ctx.arc(node.x!, node.y!, getNodeSize(node as NodeType), 0, 2 * Math.PI);
          ctx.fill();
          
          ctx.textAlign = 'center';
          ctx.fillStyle = '#111';
          ctx.fillText(label, node.x!, node.y! + 10);
        }}
        cooldownTicks={100}
        d3VelocityDecay={0.1}
        warmupTicks={100}
        nodeRelSize={6}
      />
    </div>
  );
};