import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PersonContainer from '../containers/PersonContainer';
import { PersonTable } from '../components/person/PersonTable';
import { PersonModal } from '../components/person/PersonModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Person } from '../api/person/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';

const PersonListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [personToDelete, setPersonToDelete] = useState<string | null>(null);
  const [personToEdit, setPersonToEdit] = useState<Person | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [sortBy, setSortBy] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [persons, setPersons] = useState<Person[]>([]);
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();

  // Effect to open modal if slug is present
  useEffect(() => {
    if (slug && persons.length > 0) {
      const person = persons.find((p) => p.slug === slug);
      if (person) {
        setPersonToEdit(person);
        setShowPersonModal(true);
      }
    }
  }, [slug, persons]);

  const handleDelete = (slug: string) => {
    setPersonToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (personToDelete) {
      onDelete(personToDelete);
      setShowDeleteDialog(false);
      setPersonToDelete(null);
      setSnackbarMessage('Person deleted successfully');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setPersonToEdit(null);
    setShowPersonModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default mode
    }
  
    navigate(`/persons/new?${searchParams.toString()}`);
  };
  

  const handleEditPerson = (person: Person) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/persons/${person.slug}?${searchParams.toString()}`);
    setPersonToEdit(person);
    setShowPersonModal(true);
  };
  

  const handleViewMindMap = (slug: string) => {
    navigate(`/persons/${slug}/mindmap`); // Navigate to the mind map route
  };

  const handleUpdatePersonType = (
    slug: string,
    newPersonType: Person['person_type'],
    onEdit: (slug: string, updatedPerson: Partial<Person>) => void
  ) => {
    onEdit(slug, { person_type: newPersonType });
    setSnackbarMessage('Person type updated successfully');
    setShowSnackbar(true);
  };

  const handleUpdateField = (
    slug: string,
    field: keyof Person,
    value: string,
    onEdit: (slug: string, updatedPerson: Partial<Person>) => void
  ) => {
    onEdit(slug, { [field]: value });
    setSnackbarMessage(`Person ${field} updated successfully`);
    setShowSnackbar(true);
  };

  const sortOptions: SortOption[] = [
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Person Type', value: 'person_type' },
    { label: 'Created At', value: 'created_at' }
  ];

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const compareValues = (a: any, b: any) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const compareDates = (dateA: string, dateB: string) => {
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  };

  const filterConfig: FilterConfig = useMemo(() => {
    return {
      person_type: {
        label: 'Person Type',
        value: 'person_type',
        type: 'select',
        options: [
          { label: 'Team Member', value: 'team_member' },
          { label: 'Stakeholder', value: 'stakeholder' },
          { label: 'External Partner', value: 'external_partner' },
          { label: 'Advisor', value: 'advisor' },
          { label: 'Client', value: 'client' },
          { label: 'Other', value: 'other' },
        ],
      },
    };
  }, []);

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  const filterAndSortPersons = (persons: Person[]): Person[] => {
    let filteredPersons = persons.filter(person =>
      person.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
        
        switch (key) {
          case 'person_type':
            return person.person_type === value;
          default:
            return true;
        }
      })
    );

    return filteredPersons.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'name':
          result = compareValues(a.name, b.name);
          break;
        case 'email':
          result = compareValues(a.email, b.email);
          break;
        case 'person_type':
          result = compareValues(a.person_type, b.person_type);
          break;
        case 'created_at':
          result = compareDates(a.created_at, b.created_at);
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  return (
    <div>
      <h1 className='m-4'>Persons</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search persons..."
        addButtonLabel="Add New Person"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        filterConfig={filterConfig}
        onFilterChange={handleFilterChange}
      />

<PersonContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading persons...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          if (persons.length === 0 && data.length > 0) {
            setPersons(data);
          }

          const filteredAndSortedPersons = filterAndSortPersons(data);

          return (
            <>
              {showPersonModal && (
                <PersonModal
                  isOpen={showPersonModal}
                  onClose={() => {
                    setShowPersonModal(false);
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
                    navigate(`/persons/?${searchParams.toString()}`);
                  }}
                  initialData={personToEdit}
                  onSuccess={(message) => {
                    setSnackbarMessage(message);
                    setShowSnackbar(true);
                  }}
                  onError={(message) => {
                    setSnackbarMessage(message);
                    setShowSnackbar(true);
                  }}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this person?</p>
              </Modal>

              <PersonTable
                data={filteredAndSortedPersons}
                key={filteredAndSortedPersons.map((person) => person.slug).join()}
                onEdit={handleEditPerson}
                onDelete={(slug) => handleDelete(slug)}
                onUpdatePersonType={(slug, newPersonType) => handleUpdatePersonType(slug, newPersonType, onEdit)}
                onUpdateField={(slug, field, value) => handleUpdateField(slug, field, value, onEdit)}
                onViewMindMap={handleViewMindMap}
              />
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type="success"
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default PersonListPage;
