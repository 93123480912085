import { clearTokens } from '../utils/auth';

export const fetchWithAuth = async (
    url: string, 
    options: RequestInit = {}, 
    navigate: (path: string) => void
  ): Promise<Response> => {
      try {
          // Merge user options with default headers including Authorization
          const response = await fetch(url, {
              ...options,
              headers: {
                  ...options.headers,
                  'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json',
              },
          });
  
          // Check if the token is invalid or expired
          if (response.status === 401) {
              const data = await response.json();
  
              // Token is not valid or has expired
              if (data?.code === 'token_not_valid') {
                  clearTokens();  // Clear the tokens from localStorage
                  navigate('/login');  // Redirect user to login page
                  throw new Error('Token is invalid or expired. Redirecting to login.');
              }
          }
  
          return response;  // Return the response for further processing by the caller
      } catch (error) {
          console.error('Error during fetch:', error);
          throw error;  // Rethrow error for any other unhandled cases
      }
  };
  