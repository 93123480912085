import React from 'react';

interface MindMapNodeProps {
  title: string;
  state: string;
  slug: string;
  type: string;
  onClick: () => void;
}

const MindMapNode: React.FC<MindMapNodeProps> = ({ title, state, slug, type, onClick }) => {
  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <g
      className="cursor-pointer"
      onClick={onClick}
    >
      <rect
        width={100}
        height={45}
        x={-50}
        y={-22.5}
        className="fill-primary"
        rx="6"
        ry="6"
      />
      <text
        textAnchor="middle"
        className="fill-white text-xxs font-medium"
        y={-5}
      >
        {truncateText(title, 18)}
      </text>
      <text
        textAnchor="middle"
        className="fill-white text-xxs"
        y={5}
      >
        {state}
      </text>
    </g>
  );
};

export default MindMapNode;