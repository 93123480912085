import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Integration } from '../api/integration/types';
import IntegrationContainer from '../containers/IntegrationContainer';
import AddIntegrationModal from '../components/integration/AddIntegrationModal';
import { Button, Icon, TextInput } from '../components/ui';

interface RenderContentProps {
  integrations: Integration[];
  providers: Array<{
    id: string;
    name: string;
    description: string;
    features: string[];
    required_fields: string[];
  }>;
  loading: boolean;
  error: string | null;
  onVerifyIntegration: (integration: Integration) => Promise<void>;
  onDeleteIntegration: (integration: Integration) => Promise<void>;
  onCreateIntegration: (data: Partial<Integration>) => Promise<Integration>;
  refreshIntegrations: () => Promise<void>;
}

const IntegrationListPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderContent = ({
    integrations,
    providers,
    loading,
    error,
    onVerifyIntegration,
    onDeleteIntegration,
    onCreateIntegration,
  }: RenderContentProps) => {
    const filteredIntegrations = integrations.filter((integration: Integration) =>
      integration.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      integration.provider.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="container mx-auto p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-lg font-bold text-text">Project Integrations</h1>
            <p className="text-sm text-text opacity-70">
              Connect and manage your external project management tools
            </p>
          </div>

          <Button
            label="Add Integration"
            icon="Plus"
            iconColor="white"
            onClick={() => setIsModalOpen(true)}
          />
        </div>

        {/* Search */}
        <div className="w-full max-w-md">
          <TextInput
            placeholder="Search integrations..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            startIcon={<Icon name="Search" size="sm" className="text-neutral" />}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 border border-red-200 rounded-md p-3">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        {/* Integrations List */}
        <div className="space-y-3">
          {loading ? (
            <div className="flex justify-center p-8">
              <Icon name="Loader" className="animate-spin text-primary" />
            </div>
          ) : filteredIntegrations.length > 0 ? (
            filteredIntegrations.map((integration) => (
              <div
                key={integration.id}
                className="bg-white border border-neutral rounded-md p-4 shadow-sm"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center space-x-2">
                      <span className="font-semibold text-text">
                        {integration.name}
                      </span>
                      <span className="text-sm text-text opacity-70">
                        ({integration.provider})
                      </span>
                    </div>
                    <div className="mt-1 flex items-center space-x-2">
                      <span
                        className={`text-xs px-2 py-1 rounded-full ${
                          integration.status === 'active'
                            ? 'bg-green-100 text-green-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        {integration.status}
                      </span>
                      {integration.status_message && (
                        <span className="text-xs text-text opacity-70">
                          {integration.status_message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex space-x-2">
                    <Button
                      icon="RefreshCw"
                      variant="secondary"
                      buttonStyle="ghost"
                      title="Verify Connection"
                      onClick={() => onVerifyIntegration(integration)}
                      rounded
                    />
                    <Button
                      icon="Files"
                      variant="primary"
                      buttonStyle="ghost"
                      title="Import Projects"
                      onClick={() => navigate(`/integrations/${integration.id}/import`)}
                      rounded
                    />
                    <Button
                      icon="Trash2"
                      variant="danger"
                      buttonStyle="ghost"
                      title="Delete Integration"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to delete this integration?'
                          )
                        ) {
                          onDeleteIntegration(integration);
                        }
                      }}
                      rounded
                    />
                    <Button
                      label="Go to Import"
                      variant="secondary"
                      onClick={() => navigate(`/integrations/projects/import`)}
                      />
                  </div>
                </div>

                <div className="mt-3 text-xs text-text opacity-70">
                  <div>
                    Last synced:{' '}
                    {integration.last_sync_at
                      ? new Date(integration.last_sync_at).toLocaleString()
                      : 'Never'}
                  </div>
                  <div>Created by: {integration.created_by?.name}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-8 border border-neutral border-dashed rounded-md">
              <Icon name="Database" size="lg" className="mx-auto text-neutral mb-2" />
              <p className="text-sm text-text opacity-70">
                No integrations found. Click "Add Integration" to get started.
              </p>
            </div>
          )}
        </div>

        <AddIntegrationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAdd={onCreateIntegration}
          providers={providers}
        />
      </div>
    );
  };

  return <IntegrationContainer>{renderContent}</IntegrationContainer>;
};

export default IntegrationListPage;
