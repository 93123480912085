import React, { useState, useRef, useEffect } from 'react';

interface InfoItem {
 key: string;
 value: string;
}

interface InfoBoxContent {
 title: string;
 items: InfoItem[];
}

interface InfoBoxProps {
 content: InfoBoxContent;
 position?: 'top' | 'right' | 'bottom' | 'left';
 children: React.ReactNode;
 delay?: number;
 variant?: 'default' | 'primary' | 'secondary';
}

export const InfoBox: React.FC<InfoBoxProps> = ({
 content,
 position = 'top',
 children,
 delay = 400,
 variant = 'default',
}) => {
 const [isVisible, setIsVisible] = useState(false);
 const timeoutRef = useRef<NodeJS.Timeout | null>(null);
 const infoBoxRef = useRef<HTMLDivElement>(null);

 const showInfoBox = () => {
   timeoutRef.current = setTimeout(() => setIsVisible(true), delay);
 };

 const hideInfoBox = () => {
   if (timeoutRef.current) {
     clearTimeout(timeoutRef.current);
   }
   setIsVisible(false);
 };

 useEffect(() => {
   return () => {
     if (timeoutRef.current) {
       clearTimeout(timeoutRef.current);
     }
   };
 }, []);

 useEffect(() => {
   if (isVisible && infoBoxRef.current) {
     const box = infoBoxRef.current;
     const viewportWidth = window.innerWidth;
     const boxRect = box.getBoundingClientRect();
     
     // If box overflows to the right
     if (boxRect.right > viewportWidth) {
       // Calculate how much we need to move left
       const overflow = boxRect.right - viewportWidth;
       box.style.transform = `translate(calc(-100% - 16px), -50%)`; // 16px is double the offset
     }
   }
 }, [isVisible]);

 const variantClasses = {
   default: 'bg-white text-gray-800 border border-gray-200',
   primary: 'bg-blue-500 text-white border border-blue-600',
   secondary: 'bg-gray-500 text-white border border-gray-600',
 };

 const getInfoBoxStyles = () => {
   if (!infoBoxRef.current) return {};

   const offset = 8;
   
   // For calendar pane, always show to the left
   return { 
     right: `calc(100% + ${offset}px)`, 
     top: '50%',
     transform: 'translateY(-50%)',
   };
 };

 return (
   <div
     className="relative inline-block"
     onMouseEnter={showInfoBox}
     onMouseLeave={hideInfoBox}
   >
     {children}
     {isVisible && (
       <div
         ref={infoBoxRef}
         className={`absolute z-10 p-4 text-sm font-sans rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)] transition-opacity duration-300 ease-in-out w-[250px] ${variantClasses[variant]}`}
         style={{
           ...getInfoBoxStyles(),
           pointerEvents: 'none',
         }}
         role="tooltip"
       >
         <div className="font-semibold text-base mb-2">{content.title}</div>
         <div className="h-px bg-gray-200 mb-2" />
         <div className="space-y-1">
           {content.items.map((item, index) => (
             <div key={index} className="grid grid-cols-[100px_1fr] gap-2">
               <span className="text-gray-500">{item.key}:</span>
               <span className="font-medium">{item.value}</span>
             </div>
           ))}
         </div>
       </div>
     )}
   </div>
 );
};

export default InfoBox;