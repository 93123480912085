import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { SuggestionItem } from './suggestion'
import { Icon } from '../base/Icon'

interface MentionListProps {
  items: SuggestionItem[]
  command: (item: SuggestionItem) => void
}

export const MentionList = forwardRef<MentionListRef, MentionListProps>((props, ref) => {
  console.log('MentionList items:', props.items);
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command(item)
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="mention-list bg-white shadow-lg rounded-md overflow-hidden">
      {props.items.map((item, index) => (
        <button
          className={`mention-list__item flex items-center px-3 py-2 w-full text-left ${
            index === selectedIndex ? 'bg-primary bg-opacity-10' : ''
          }`}
          key={item.id}
          onClick={() => selectItem(index)}
        >
          {item.type === 'person' ? (
            <Icon name="User" size="sm" className="mr-2 text-gray-500" />
          ) : (
            <Icon name="Hash" size="sm" className="mr-2 text-gray-500" />
          )}
          <span className={`text-sm mention-list__item-name ${item.type === 'tag' ? 'text-blue-500' : ''}`}>
            {item.name}
          </span>
          {item.type === 'tag' && (
            <span className="ml-2 text-xs text-gray-400">Tag</span>
          )}
        </button>
      ))}
    </div>
  );
});

export interface MentionListRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean
}