import React, { useState, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Board from '../ui/data/Board';
import { Task } from '../../api/task/types';
import { getTaskStateLabelAndIcon, getDialStageLabelAndIcon } from '../../utils/entitiesOptions';

interface TaskStateBoardViewProps {
  tasks: Task[];
  onOpenTask: (task: Task) => void;
  onEdit: (slug: string, updatedTask: Partial<Task>) => void;
}

interface Badge {
  label: string;
  icon?: React.ReactNode;
  variant?: 'outline' | 'primary' | 'secondary' | 'accent' | 'neutral';
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

interface BoardItem {
  id: string;
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
  isCollapsible?: boolean;
  style?: string;
}

const taskStateOptions = [
  { value: 'IDENTIFIED', label: 'Identified' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'ARCHIVED', label: 'Archived' }
] as const;

export const TaskStateBoardView: React.FC<TaskStateBoardViewProps> = ({
  tasks: initialTasks,
  onOpenTask,
  onEdit,
}) => {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const mapTaskToBoardItem = useCallback((task: Task): BoardItem => {
    const stateBadge = getTaskStateLabelAndIcon(task.state);
    const dialStageBadge = getDialStageLabelAndIcon(task.dial_stage);
    const dueDateBadge = task.due_date ? {
      label: new Date(task.due_date).toLocaleDateString(),
      variant: 'neutral'
    } : null;

    return {
      id: task.slug,
      title: task.title,
      badges: [stateBadge, dialStageBadge, dueDateBadge].filter((badge): badge is Required<Pick<Badge, 'label' | 'icon'>> => !!badge),
    };
  }, []);

  const updateColumns = useCallback(() => {
    const newColumns = taskStateOptions.map((stateOption) => ({
      id: stateOption.value,
      title: stateOption.label,
      isCollapsible: stateOption.value === 'ARCHIVED',
      items: tasks
        .filter((task) => task.state === stateOption.value)
        .map(mapTaskToBoardItem),
      style:
        stateOption.value === 'COMPLETED'
          ? 'bg-green-50 border-t-4 border-green-200'
          : stateOption.value === 'ARCHIVED'
          ? 'bg-gray-50 border-t-4 border-gray-200'
          : '',
    }));
    setColumns(newColumns);
  }, [tasks, mapTaskToBoardItem]);

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks, isUpdating]);

  useEffect(() => {
    updateColumns();
  }, [updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const task = tasks.find(t => t.slug === item.id);
      if (task) {
        onOpenTask(task);
      }
    },
    [tasks, onOpenTask]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId: slug } = result;

      if (!destination || destination.droppableId === source.droppableId) return;

      const newState = destination.droppableId as Task['state'];
      setIsUpdating(true);

      try {
        const updatedTask = {
          ...tasks.find(t => t.slug === slug)!,
          state: newState,
        };

        const updatedTasks = tasks.map(t => 
          t.slug === slug ? updatedTask : t
        );
        setTasks(updatedTasks);

        await onEdit(slug, { state: newState });
      } catch (error) {
        console.error('Failed to update task:', error);
        setTasks(tasks); // Revert to original state
        updateColumns();
      } finally {
        setIsUpdating(false);
      }
    },
    [tasks, onEdit, updateColumns]
  );

  return (
    <Board
      columns={columns}
      minHeight="calc(100vh - 300px)"
      allowAddItems={false}
      onOpenItem={handleOpenItem}
      onMoveItem={handleMoveItem}
    />
  );
};

export default TaskStateBoardView; 