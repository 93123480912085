import { Task } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

// Updated FetchTasksParams
export interface FetchTasksParams {
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;
  startDate?: string;
  endDate?: string;
}

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all tasks
export const fetchTasks = async (
  { topicId, projectId, meetingOccurrenceId, meetingTypeId, personId, startDate, endDate }: FetchTasksParams,
  navigate: (path: string) => void
): Promise<Task[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  // Build the URL based on the provided parameters
  let url = `${API_URL}tasks/?company_id=${company_id}`;
  if (topicId) {
    url += `&topic_id=${topicId}`;
  } else if (projectId) {
    url += `&project_id=${projectId}`;
  } else if (meetingOccurrenceId) {
    url += `&meeting_occurrence_id=${meetingOccurrenceId}`;
  } else if (meetingTypeId) {
    url += `&meeting_type_id=${meetingTypeId}`;
  } else if (personId) {
    url += `&owner_id=${personId}`;
  }
  if (startDate) {
    url += `&start_date=${startDate}`;
  }
  if (endDate) {
    url += `&end_date=${endDate}`;
  }

  const response = await fetchWithAuth(url, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch tasks`);
  }
  return response.json();
};

// Fetch a single task by ID
export const fetchTask = async (slug: string, navigate: (path: string) => void): Promise<Task> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}tasks/${slug}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch task with slug ${slug}`);
  }
  return response.json();
};

export const createTask = async (
  taskData: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>,
  navigate: (path: string) => void
): Promise<Task> => {
  const company_id = getCompanyId();

  const requestData = {
    ...taskData,
    company: company_id,
    owner_id: taskData.owner?.id,
    tag_ids: taskData.tags?.map(tag => tag.id),
    topic: typeof taskData.topic === 'object' ? (taskData.topic as any)?.id : taskData.topic, // Extract ID if object
  };

  const response = await fetchWithAuth(
    `${API_URL}tasks/`,
    {
      method: 'POST',
      body: JSON.stringify(requestData),
    },
    navigate
  );
  if (!response.ok) {
    throw new Error('Failed to create task');
  }
  return response.json();
};

export const editTask = async (
  slug: string,
  updatedTask: Partial<Task>,
  navigate: (path: string) => void
): Promise<Task> => {
  const company_id = getCompanyId();

  const requestData = {
    ...updatedTask,
    company_id,
    owner_id: updatedTask.owner?.id,
    tag_ids: updatedTask.tags?.map(tag => tag.id),
    topic: typeof updatedTask.topic === 'object' ? (updatedTask.topic as any)?.id : updatedTask.topic, // Extract ID if object
  };

  const response = await fetchWithAuth(
    `${API_URL}tasks/${slug}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(requestData),
    },
    navigate
  );
  if (!response.ok) {
    throw new Error(`Failed to edit task with slug ${slug}`);
  }
  return response.json();
};

// Delete an existing task
export const deleteTask = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}tasks/${slug}/?company_id=${company_id}`, {
    method: 'DELETE',
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to delete task with slug ${slug}`);
  }
};