import React from 'react';
import { Icon, IconName } from './base/Icon';
import { Tooltip } from './'; // Import Tooltip component

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger';
type ButtonStyle = 'filled' | 'outlined' | 'text' | 'ghost';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps {
  label?: string;
  variant?: ButtonVariant;
  buttonStyle?: ButtonStyle;
  size?: ButtonSize;
  icon?: IconName;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: 'primary' | 'secondary' | 'accent' | 'text' | 'white';
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  rounded?: boolean;
  className?: string;
  title?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  variant = 'primary',
  buttonStyle = 'filled',
  size = 'medium',
  icon,
  iconSize = 'md',
  iconColor = 'white',
  iconPosition = 'left',
  disabled = false,
  loading = false,
  onClick,
  type = 'button',
  rounded = false,
  title, // Destructure new title prop
}) => {
  const baseClasses = "font-semibold transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1";

  const variantClasses = {
    primary: {
      filled: "bg-primary text-white hover:bg-primary-dark focus:ring-primary",
      outlined: "border border-primary/30 text-primary hover:bg-primary hover:text-white focus:ring-primary",
      text: "text-primary hover:bg-primary hover:bg-opacity-10 focus:ring-primary",
      ghost: "text-primary hover:bg-primary/10 focus:ring-primary",
    },
    secondary: {
      filled: "bg-secondary text-white hover:bg-secondary-dark focus:ring-secondary",
      outlined: "border border-secondary/30 text-secondary hover:bg-secondary hover:text-white focus:ring-secondary",
      text: "text-secondary hover:bg-secondary hover:bg-opacity-10 focus:ring-secondary",
      ghost: "text-secondary hover:bg-secondary/10 focus:ring-secondary",
    },
    tertiary: {
      filled: "bg-accent text-white hover:bg-accent-dark focus:ring-accent",
      outlined: "border border-accent/30 text-accent hover:bg-accent hover:text-white focus:ring-accent",
      text: "text-accent hover:bg-accent hover:bg-opacity-10 focus:ring-accent",
      ghost: "text-accent hover:bg-accent/10 focus:ring-accent",
    },
    danger: {
      filled: "bg-red-600 text-white hover:bg-red-700 focus:ring-red-600",
      outlined: "border border-red-600/30 text-red-600 hover:bg-red-600 hover:text-white focus:ring-red-600",
      text: "text-red-600 hover:bg-red-600 hover:bg-opacity-10 focus:ring-red-600",
      ghost: "text-red-600 hover:bg-red-600/10 focus:ring-red-600",
    },
  };

  const sizeClasses = {
    small: "py-1 px-2 text-xs",
    medium: "py-2 px-3 text-sm",
    large: "py-2 px-4 text-base",
  };

  const iconOnlySize = size === 'small' ? 'p-2' : size === 'medium' ? 'p-3' : 'p-4';
  const disabledClasses = "opacity-50 cursor-not-allowed";

  const classes = `
    ${baseClasses}
    ${variantClasses[variant][buttonStyle]}
    ${sizeClasses[size]}
    ${disabled ? disabledClasses : ''}
    ${icon ? 'flex items-center justify-center' : ''}
    ${icon && !label && rounded ? 'rounded-full ' + iconOnlySize : 'rounded'}
  `;

  const buttonContent = (
    <button
      className={classes}
      onClick={!disabled && !loading ? onClick : undefined}
      disabled={disabled || loading}  // Disable button when loading
      type={type}
    >
      {loading ? (
        <>
          <Icon 
            name="Loader" 
            size={iconSize} 
            color={iconColor} 
            className={`animate-spin ${label ? 'mr-2' : ''}`} 
          />
          {label && (
            <span className="opacity-90">
              {label === 'Submit' ? 'Submitting...' : 
               label === 'Save' ? 'Saving...' :
               label === 'Delete' ? 'Deleting...' :
               label === 'Import' ? 'Importing...' :
               'Loading...'}
            </span>
          )}
        </>
      ) : (
        <>
          {icon && iconPosition === 'left' && (
            <Icon 
              name={icon} 
              size={iconSize} 
              color={iconColor} 
              className={label ? 'mr-2' : ''} 
            />
          )}
          {label}
          {icon && iconPosition === 'right' && (
            <Icon 
              name={icon} 
              size={iconSize} 
              color={iconColor} 
              className={label ? 'ml-2' : ''} 
            />
          )}
        </>
      )}
    </button>
  );

  return title ? (
    <Tooltip content={title} position="top">
      {buttonContent}
    </Tooltip>
  ) : (
    buttonContent
  );
};

export { Button };
