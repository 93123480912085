import { useState, useMemo } from 'react';
import { useGraphData } from '../../api/networkGraph/queries';
import { GraphFilters, GraphNode } from '../../api/networkGraph/types';
import { NetworkGraph } from './NetworkGraph';
import { NodeDetails } from './NodeDetails';
import { NetworkGraphFilters } from './NetworkGraphFilters';

export const NetworkGraphContainer = () => {
    const [filters, setFilters] = useState<GraphFilters>({
      includeTasks: true,
      includeProjects: true,
      includeTopics: true,
      includePersons: true,
      includeTags: true,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  
    const { data, isLoading, error } = useGraphData(filters);
  
    // Filter data based on search term
    const filteredData = useMemo(() => {
      if (!data || !searchTerm) return data;
  
      const lowercaseSearch = searchTerm.toLowerCase();
      const filteredNodes = data.nodes.filter(node =>
        node.data.label.toLowerCase().includes(lowercaseSearch)
      );
      const filteredNodeIds = new Set(filteredNodes.map(node => node.id));
  
      return {
        nodes: filteredNodes,
        edges: data.edges.filter(edge =>
          filteredNodeIds.has(edge.source) && filteredNodeIds.has(edge.target)
        )
      };
    }, [data, searchTerm]);
  
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading graph data</div>;
    if (!data) return null;
  
    return (
      <div className="space-y-4">
        <NetworkGraphFilters 
          filters={filters} 
          onChange={setFilters}
          onSearchChange={setSearchTerm}
        />
        {filteredData && <NetworkGraph 
          data={filteredData}
          onNodeClick={(node) => setSelectedNode(node)}
        />}
        {selectedNode && (
          <NodeDetails 
            node={selectedNode}
            onClose={() => setSelectedNode(null)}
          />
        )}
      </div>
    );
  };