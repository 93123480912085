import React, { useEffect, useState } from 'react';
import { useStrategies, useCreateStrategy, useEditStrategy, useDeleteStrategy } from '../api/strategy/queries';
import { Strategy, CreateStrategyRequest, UpdateStrategyRequest } from '../api/strategy/types';
import { useQueryClient } from 'react-query';

interface StrategyContainerProps {
  collaboratorId?: string;  // Optional prop for filtering strategies by collaboratorId
  teamId?: string;          // Optional prop for filtering strategies by teamId
  fetchOnMount?: boolean;   // Optional prop to control whether to fetch strategies on mount
  render: (props: {
    data: Strategy[];
    loading: boolean;
    error: string | null;
    onCreate: (newStrategy: Omit<Strategy, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedFields: Partial<Strategy>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const normalizeStrategy = (strategy: Partial<Strategy>) => ({
  ...strategy,
  diagnosis: strategy.diagnosis ?? undefined,
  guiding_policies: strategy.guiding_policies ?? undefined,
  coherent_actions: strategy.coherent_actions ?? undefined,
  owner_id: strategy.owner?.id,
  team: strategy.team?.id,
  tags: strategy.tags?.map((tag) => tag.id),
  collaborators: strategy.collaborators?.map((person) => person.id),
});

const StrategyContainer: React.FC<StrategyContainerProps> = ({
  fetchOnMount = true,
  collaboratorId,
  teamId,
  render,
}) => {
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const params = { collaboratorId, teamId };
  const { data, isLoading, isError } = useStrategies(params);

  const createStrategyMutation = useCreateStrategy();
  const editStrategyMutation = useEditStrategy();
  const deleteStrategyMutation = useDeleteStrategy();

  useEffect(() => {
    if (fetchOnMount) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
        if (data) setStrategies(data);
      }

      if (isError) {
        setError('Failed to fetch Strategies');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  const handleCreate = (newStrategy: Omit<Strategy, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
    const createRequest: CreateStrategyRequest = normalizeStrategy(newStrategy) as CreateStrategyRequest;

    createStrategyMutation.mutate(createRequest, {
      onSuccess: (createdStrategy) => {
        setStrategies((prevStrategies) => [...prevStrategies, createdStrategy]);
        queryClient.invalidateQueries(['strategies', params]);
      },
      onError: () => setError('Failed to create Strategy'),
    });
  };

  const handleEdit = (slug: string, updatedFields: Partial<Strategy>) => {
    const editRequest: UpdateStrategyRequest = normalizeStrategy(updatedFields) as UpdateStrategyRequest;

    editStrategyMutation.mutate({ slug, ...editRequest }, {
      onSuccess: (editedStrategy) => {
        setStrategies((prevStrategies) =>
          prevStrategies.map((strategy) =>
            strategy.slug === slug ? editedStrategy : strategy
          )
        );
        queryClient.invalidateQueries(['strategies', params]);
        queryClient.invalidateQueries(['strategy', slug]);
      },
      onError: () => setError('Failed to edit Strategy'),
    });
  };

  const handleDelete = (slug: string) => {
    deleteStrategyMutation.mutate(slug, {
      onSuccess: () => {
        setStrategies((prevStrategies) => prevStrategies.filter((strategy) => strategy.slug !== slug));
        queryClient.invalidateQueries(['strategies', params]);
      },
      onError: () => setError('Failed to delete Strategy'),
    });
  };

  return render({
    data: strategies,
    loading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default StrategyContainer;
