import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import PersonContainer from '../../containers/PersonContainer';
import { Person } from '../../api/person/types';
import { Avatar } from '../ui/Avatar';
import { Icon } from '../ui';
import { Tooltip } from '../ui'; // Import Tooltip component
import { useUser } from '../../contexts/UserContext';

type SelectSize = 'small' | 'medium' | 'large';

interface PersonSelectProps {
  value: string;
  onChange: (selectedPerson: Person) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  showAssignButton?: boolean;
}

const PersonSelect: React.FC<PersonSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select a person',
  size = 'medium',
  isOpen,
  onClose,
  showAssignButton = false,
}) => {
  const { personId: currentUserId } = useUser();

  return (
    <PersonContainer
      render={({ data: persons, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const personOptions = persons
          .map((person) => ({
            value: person.id,
            label: person.name,
            icon: <Avatar name={person.name} size="xs" />,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        const handleSelect = (personId: string) => {
          const selectedPerson = persons.find((person) => person.id === personId);
          if (selectedPerson) {
            onChange(selectedPerson);
          }
          if (onClose) {
            onClose();
          }
        };

        const assignToCurrentUser = () => {
          if (currentUserId) {
            const selectedPerson = persons.find((person) => person.id === currentUserId);
            if (selectedPerson) {
              onChange(selectedPerson);
            }
          }
        };

        return (
          <div className={`flex items-center ${showAssignButton ? 'space-x-2' : ''}`}>
            <SelectSingle
              placeholder={placeholder}
              options={personOptions}
              defaultValue={value}
              onSelect={handleSelect}
              isDisabled={isDisabled}
              enableSearch={enableSearch}
              size={size}
              isOpen={isOpen}
              onClose={onClose}
            />
            {showAssignButton && (
              <Tooltip content="Assign to me">
                <button
                  onClick={assignToCurrentUser}
                  className="p-1"
                  // Remove the title attribute here
                  disabled={!currentUserId}
                >
                  <Icon name="UserPlus" size="sm" color="primary" />
                </button>
              </Tooltip>
            )}
          </div>
        );
      }}
    />
  );
};

export default PersonSelect;
