import React, { ForwardRefRenderFunction, forwardRef, useState, useRef, useEffect, ChangeEvent } from 'react';
import { SuggestionItem } from './utilities/suggestion';
import { MentionList, MentionListRef } from './utilities/MentionList';
import getCaretCoordinates from 'textarea-caret';
import PersonContainer from '../../containers/PersonContainer';
import TagContainer from '../../containers/TagContainer';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';

// Props for the base MentionInput component
interface MentionInputProps {
  placeholder?: string;
  value?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
}

// Props for the MentionInputWithData component
interface MentionInputWithDataProps extends MentionInputProps {
  persons: Person[];
  tags: Tag[];
}

const MentionInputBase: ForwardRefRenderFunction<HTMLTextAreaElement, MentionInputWithDataProps> = (
  { placeholder = "Add description...", value = "", isDisabled = false, onChange, persons, tags },
  ref
) => {
  const [inputValue, setInputValue] = useState(value);
  const [showMentionList, setShowMentionList] = useState(false);
  const [suggestions, setSuggestions] = useState<SuggestionItem[]>([]);
  const [mentionListPosition, setMentionListPosition] = useState({ top: 0, left: 0 });
  const mentionRef = useRef<HTMLTextAreaElement>(null);
  const mentionListRef = useRef<MentionListRef>(null);

  // Synchronize inputValue with value prop
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue.includes('@')) {
      setShowMentionList(true);
      const query = newValue.split('@').pop()?.toLowerCase() || '';
      const personSuggestions: SuggestionItem[] = persons.map(person => ({
        id: person.id,
        name: person.name,
        type: 'person'
      }));
      const tagSuggestions: SuggestionItem[] = tags.map(tag => ({
        id: tag.id,
        name: tag.name,
        type: 'tag'
      }));
      const filteredSuggestions = [...personSuggestions, ...tagSuggestions].filter(item =>
        item.name.toLowerCase().startsWith(query)
      );
      setSuggestions(filteredSuggestions);

      // Calculate mention list position
      if (mentionRef.current) {
        const textarea = mentionRef.current;
        const cursorPosition = textarea.selectionStart;
        const coordinates = getCaretCoordinates(textarea, cursorPosition);
        const topPosition = coordinates.top - textarea.scrollTop;
        const leftPosition = coordinates.left - textarea.scrollLeft;
        const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);

        setMentionListPosition({
          top: topPosition + lineHeight,
          left: leftPosition,
        });
      }
    } else {
      setShowMentionList(false);
    }

    if (onChange) onChange(newValue);
  };

  const handleSelectSuggestion = (item: SuggestionItem) => {
    // Replace last "@" with selected item, wrapped in *
    const lastAtIndex = inputValue.lastIndexOf('@');
    const newValue = inputValue.substring(0, lastAtIndex) + `*@${item.name}* `;
    setInputValue(newValue);
    setShowMentionList(false);

    if (onChange) onChange(newValue);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (showMentionList && mentionListRef.current) {
      const handled = mentionListRef.current.onKeyDown({ event: e.nativeEvent });
      if (handled) e.preventDefault();
    }
  };

  return (
    <div className="relative">
      <textarea
        ref={ref || mentionRef}
        className={`w-full bg-transparent text-base text-text font-normal placeholder-gray-400 focus:outline-none focus:ring-0 resize-none ${
          isDisabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
        placeholder={placeholder}
        value={inputValue} // Controlled by inputValue state
        disabled={isDisabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        rows={4}
        autoComplete="off"
      />
      {showMentionList && suggestions.length > 0 && (
        <div
          className="absolute z-10 bg-white border border-gray-200 rounded-md shadow-lg"
          style={{
            top: `${mentionListPosition.top}px`,
            left: `${mentionListPosition.left}px`,
          }}
        >
          <MentionList
            items={suggestions}
            command={handleSelectSuggestion}
            ref={mentionListRef}
          />
        </div>
      )}
    </div>
  );
};

const MentionInputWithData = forwardRef<HTMLTextAreaElement, MentionInputWithDataProps>(MentionInputBase);

// Higher-order component to wrap MentionInput with PersonContainer and TagContainer
const MentionInput: React.FC<MentionInputProps> = (props) => {
  return (
    <PersonContainer
      render={({ data: persons, loading: personsLoading, error: personsError }) => (
        <TagContainer
          render={({ data: tags, loading: tagsLoading, error: tagsError }) => {
            if (personsLoading || tagsLoading) {
              return <div>Loading...</div>;
            }
            if (personsError || tagsError) {
              return <div>Error loading data</div>;
            }
            return <MentionInputWithData {...props} persons={persons} tags={tags} />;
          }}
        />
      )}
    />
  );
};

export { MentionInput };
