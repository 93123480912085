import React from 'react';
import { Strategy } from '../../api/strategy/types';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import { Team } from '../../api/team/types';
import { 
  Table, 
  TableActionButtons, 
  TablePersonsComponent, 
  TableTagsComponent, 
  TableBadgeComponent, 
  TablePersonComponent,
  TableInlineTextInput,
} from '../ui';
import { strategyStateOptions } from '../../utils/entitiesOptions';

interface StrategyTableProps {
    data: Strategy[];
    onEdit: (strategy: Strategy) => void;
    onDelete: (slug: string) => void;
    onUpdateState: (slug: string, newState: Strategy['state']) => void;
    onUpdateTitle: (slug: string, newTitle: string) => void;
    onUpdateTags: (slug: string, newTags: Tag[]) => void;
    onUpdateCollaborators: (slug: string, newCollaborators: Person[]) => void;
    onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  }

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const StrategyTable: React.FC<StrategyTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateTitle,
  onUpdateTags,
  onUpdateCollaborators,
  onUpdateOwner,
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}
              className="cursor-pointer hover:underline"
            >
              <TableInlineTextInput
                initialValue={row.title}
                onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
                onCancel={() => {}}
              />
            </div>
          ),
        },
        {
          header: 'State',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.state, strategyStateOptions)}
              type="strategyState"
              onUpdate={(newState) => onUpdateState(row.slug, newState as Strategy['state'])}
              options={strategyStateOptions}
              isEditable={row.state !== 'APPROVED'}
            />
          ),
        },
        {
          header: 'Owner',
          component: (row) => (
            <TablePersonComponent
              person={row.owner}
              isEditable={row.state !== 'APPROVED'}
              onUpdate={(newOwner) => onUpdateOwner(row.slug, newOwner)}
            />
          ),
        },
        // {
        //   header: 'Team',
        //   component: (row) => (
        //     <TableTeamComponent
        //       team={row.team}
        //       isEditable={row.state !== 'APPROVED'}
        //       onUpdate={(newTeam) => onUpdateTeam(row.slug, newTeam)}
        //     />
        //   ),
        // },
        {
          header: 'Collaborators',
          component: (row) => (
            <TablePersonsComponent
              persons={row.collaborators || []}  // Ensure an empty array if undefined or null
              isEditable={row.state !== 'APPROVED'}
              onUpdate={(newCollaborators) => onUpdateCollaborators(row.slug, newCollaborators)}
            />
          ),
        },
        {
          header: 'Tags',
          component: (row) => (
            <TableTagsComponent
              tags={row.tags}
              isEditable={row.state !== 'APPROVED'}
              onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
            />
          ),
        },
        {
          header: 'Last Updated',
          accessor: 'updated_at',
          component: (row) => (
            <span>{new Date(row.updated_at).toLocaleDateString()}</span>
          ),
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};