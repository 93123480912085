import React from 'react';
import { useTagGraphData } from '../../api/networkGraph/queries';
import { NetworkGraph } from '../networkGraph/NetworkGraph';
import { useParams } from 'react-router-dom';

interface TagNetworkViewProps {
  tagId: string;
}

export const TagNetworkView: React.FC<TagNetworkViewProps> = ({ tagId }) => {
  const { data, isLoading, error } = useTagGraphData(tagId);

  if (isLoading) return (
    <div className="flex items-center justify-center h-64">
      <div className="text-gray-500">Loading network data...</div>
    </div>
  );

  if (error) return (
    <div className="text-red-500 p-4">
      Error loading network data
    </div>
  );

  if (!data) return null;

  return (
    <div className="space-y-4">
      <NetworkGraph 
        data={data}
        onNodeClick={(node) => {
          // Handle node clicks - e.g., navigation to node detail
          console.log('Node clicked:', node);
        }}
      />
    </div>
  );
};

export const TagNetworkViewWrapper = () => {
  const { id } = useParams();
  return <TagNetworkView tagId={id!} />;
};