import React, { useEffect, useState } from 'react';
import { 
  useMeetingTopicNotes, 
  useCreateMeetingTopicNote, 
  useEditMeetingTopicNote, 
  useDeleteMeetingTopicNote 
} from '../api/meeting/queries';
import { MeetingTopicNote } from '../api/meeting/types';
import { useQueryClient } from 'react-query'; // Import query client if using React Query

interface MeetingTopicNoteContainerProps {
  meetingOccurrenceId: string;
  fetchOnMount?: boolean;
  render: (props: {
    data: MeetingTopicNote[];
    loading: boolean;
    error: string | null;
    onCreate: (newNote: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at' | 'meeting_occurrence'>, occurrenceId: string) => void;
    onEdit: (id: string, updatedNote: Partial<MeetingTopicNote>) => void;
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

const MeetingTopicNoteContainer: React.FC<MeetingTopicNoteContainerProps> = ({ 
  meetingOccurrenceId, 
  fetchOnMount = true, 
  render 
}) => {
  const [notes, setNotes] = useState<MeetingTopicNote[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);

  // Fetch notes
  const { data, isLoading, isError, refetch } = useMeetingTopicNotes(meetingOccurrenceId);

  // Mutations
  const createMutation = useCreateMeetingTopicNote();
  const editMutation = useEditMeetingTopicNote();
  const deleteMutation = useDeleteMeetingTopicNote();

  // Query client for cache management
  const queryClient = useQueryClient();

  // Handle data fetching
  useEffect(() => {
    if (fetchOnMount) {
      setLoading(isLoading);
      if (data) {
        setNotes(data);
        setError(null); // Clear any previous errors
      }
      if (isError) {
        setError('Failed to fetch Meeting Topic Notes');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create new note
  const handleCreate = (newNote: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at' | 'meeting_occurrence'>, occurrenceId: string) => {
    createMutation.mutate(
      { ...newNote, meeting_occurrence: occurrenceId },
      {
        onSuccess: (created) => {
          setNotes((prev) => [...prev, created]);
          queryClient.invalidateQueries(['meetingTopicNotes', occurrenceId]);
          refetch();
        },
        onError: () => setError('Failed to create Meeting Topic Note'),
      }
    );
  };

  // Edit existing note
  const handleEdit = (id: string, updatedNote: Partial<MeetingTopicNote>) => {
    editMutation.mutate(
      { id, updatedNote },
      {
        onSuccess: (edited) => {
          setNotes((prev) => prev.map((note) => (note.id === id ? edited : note)));
          queryClient.invalidateQueries(['meetingTopicNotes', meetingOccurrenceId]); // Invalidate cache
          refetch(); // Refetch data from server
        },
        onError: () => setError('Failed to edit Meeting Topic Note'),
      }
    );
  };

  // Delete note
  const handleDelete = (id: string) => {
    deleteMutation.mutate(id, {
      onSuccess: () => {
        setNotes((prev) => prev.filter((note) => note.id !== id));
        queryClient.invalidateQueries(['meetingTopicNotes', meetingOccurrenceId]); // Invalidate cache
        refetch(); // Refetch data from server
      },
      onError: () => setError('Failed to delete Meeting Topic Note'),
    });
  };

  return render({
    data: notes,
    loading: loading || isLoading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default MeetingTopicNoteContainer;
