import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { fetchGraphData } from './api';
import { GraphFilters } from './types';

export const graphKeys = {
    all: ['graph'] as const,
    filters: (filters: GraphFilters) => [...graphKeys.all, 'filters', filters] as const,
    tag: (tagId: string) => [...graphKeys.all, 'tag', tagId] as const,
};

export const useGraphData = (filters: GraphFilters = {}) => {
    const navigate = useNavigate();
    
    return useQuery(
        graphKeys.filters(filters),
        () => fetchGraphData(filters, navigate),
        {
            staleTime: 0,
            select: (data) => data,
        }
    );
};

export const useTagGraphData = (tagId: string) => {
    const navigate = useNavigate();
    
    // Debug log
    console.log('useTagGraphData called with tagId:', tagId);
    
    return useQuery(
        graphKeys.tag(tagId),
        () => {
            // Debug log
            console.log('Calling fetchGraphData with tagId:', tagId);
            return fetchGraphData({ tagId }, navigate);
        },
        {
            staleTime: 0,
            select: (data) => data,
            // Only fetch if tagId exists
            enabled: !!tagId,
        }
    );
};