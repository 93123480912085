import React from 'react';
import { useFavorites, useToggleFavorite } from '../api/favorite/queries';
import { Favorite } from '../api/favorite/types';
import { useQueryClient } from 'react-query';

interface FavoriteContainerProps {
  type?: string;  // e.g., 'topic.topic'
  render: (props: {
    data: Favorite[];
    loading: boolean;
    error: string | null;
    onToggle: (contentType: string, objectId: string) => void;
    isFavorited: (objectId: string) => boolean;
  }) => JSX.Element;
}

const FavoriteContainer: React.FC<FavoriteContainerProps> = ({ type, render }) => {
  const params = { type };
  const queryClient = useQueryClient();
  
  const { 
    data: favorites = [], 
    isLoading, 
    error 
  } = useFavorites(params);
  
  const toggleFavoriteMutation = useToggleFavorite();

  const handleToggle = (contentType: string, objectId: string) => {
    toggleFavoriteMutation.mutate(
      { contentType, objectId },
      {
        onSuccess: () => {
          // Invalidate favorites queries
          queryClient.invalidateQueries(['favorites', params]);
          
          // Invalidate the specific content type queries
          const baseType = contentType.split('.')[1]; // e.g., 'topic' from 'topic.topic'
          queryClient.invalidateQueries([baseType]);
        }
      }
    );
  };

  const isFavorited = (objectId: string): boolean => {
    return favorites.some(favorite => favorite.object_id === objectId);
  };

  return render({
    data: favorites,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onToggle: handleToggle,
    isFavorited
  });
};

export default FavoriteContainer;