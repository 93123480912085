import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ModalContent, SelectSingle, MarkDownEditor } from '../ui';
import { Strategy } from '../../api/strategy/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import { Team } from '../../api/team/types';
import PersonSelect from '../person/PersonSelect';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TeamSelect from '../team/TeamSelect';
import TagSelect from '../tags/TagSelect';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import { strategyStateOptions } from '../../utils/entitiesOptions';
import { Icon } from '../ui/base/Icon';
import FavoriteContainer from '../../containers/FavoriteContainer';

interface StrategyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (strategyData: Partial<Strategy>) => void;
  onEdit?: (slug: string, updatedStrategy: Partial<Strategy>) => void;
  onDelete?: (slug: string) => void;
  initialData?: Strategy;
}

export const StrategyModal: React.FC<StrategyModalProps> = ({
  isOpen,
  onClose,
  onSave,
  onEdit,
  onDelete,
  initialData,
}) => {
  const [strategyData, setStrategyData] = useState<Partial<Strategy>>({
    title: '',
    diagnosis: '',
    guiding_policies: '',
    coherent_actions: '',
    state: 'DRAFT',
    owner: null,
    team: null,
    collaborators: [],
    tags: [],
    visibility: 'private',
  });

  const editFunctionRef = useRef<((slug: string, updatedStrategy: Partial<Strategy>) => void) | null>(null);
  const initialTitle = useRef('');
  const initialDescription = useRef('');
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('modal');

  useEffect(() => {
    editFunctionRef.current = onEdit || null;
  }, [onEdit]);

  useEffect(() => {
    if (initialData) {
      setStrategyData(initialData);
      initialTitle.current = initialData.title || '';
      initialDescription.current = initialData.diagnosis || '';
    }
  }, [initialData]);

  const handleFieldUpdate = useCallback(
    (field: keyof Strategy, value: any) => {
      setStrategyData((prevData) => ({ ...prevData, [field]: value }));

      if (initialData?.slug && onEdit) {
        onEdit(initialData.slug, { [field]: value });
      }
    },
    [initialData?.slug, onEdit]
  );

  const handleSave = useCallback(() => {
    if (!strategyData.title) {
      return alert('Please provide a title');
    }
    if (!strategyData.owner) {
      return alert('Please select an owner');
    }
    onSave(strategyData);
    onClose();
  }, [strategyData, onSave, onClose]);

  const handleDelete = useCallback(() => {
    if (initialData?.slug && onDelete) {
      onDelete(initialData.slug);
      onClose();
    }
  }, [initialData?.slug, onDelete, onClose]);

  const handleVisibilityToggle = useCallback(
    (isPublic: boolean) => {
      handleFieldUpdate('visibility', isPublic ? 'public' : 'private');
    },
    [handleFieldUpdate]
  );

  if (!isOpen) return null;

  const getStateLabels = (states: string[], options: { value: string; label: string }[]) => {
    return states.map((state) => {
      const option = options.find((opt) => opt.value === state);
      return option ? option.label : state;
    });
  };

  const friendlyStrategyStates = getStateLabels(['DRAFT', 'IN_REVIEW', 'APPROVED', 'ARCHIVED'], strategyStateOptions);

  // Update the isReadOnly helper function to handle both APPROVED and ARCHIVED states
  const isReadOnly = (state?: string): boolean => {
    return state === 'APPROVED' || state === 'ARCHIVED';
  };

  return (
    <FavoriteContainer
      type="strategy.strategy"
      render={({ onToggle, isFavorited }) => (
        <ModalContent
          title={strategyData.title || ''}
          onCancel={onClose}
          onCreate={handleSave}
          onDelete={initialData ? handleDelete : undefined}
          onTitleChange={(newTitle) => handleFieldUpdate('title', newTitle)}
          actionButtonLabel={initialData ? 'Save Changes' : 'Create Strategy'}
          showFooterButtons={true}
          showFavorite={!!initialData}
          isFavorited={!!initialData && isFavorited(initialData.id)}
          onFavoriteToggle={() => initialData?.id && onToggle('strategy.strategy', initialData.id)}
          selects={[
            {
              component: SelectSingle,
              props: {
                options: strategyStateOptions,
                placeholder: 'Select State',
                defaultIcon: <Icon name="Flag" size="sm" />,
                defaultValue: strategyData.state,
                onSelect: (value: string) => handleFieldUpdate('state', value as Strategy['state']),
              },
            },
            {
              component: PersonSelect,
              props: {
                value: strategyData.owner?.id || '',
                onChange: (person: Person) => handleFieldUpdate('owner', person),
                enableSearch: true,
                placeholder: 'Select an owner',
                isDisabled: isReadOnly(strategyData.state),
              },
            },
            {
              component: TeamSelect,
              props: {
                value: strategyData.team?.id || '',
                onChange: (selectedTeam: Team) => handleFieldUpdate('team', selectedTeam),
                placeholder: 'Select a team',
                enableSearch: true,
                isDisabled: isReadOnly(strategyData.state),
                size: 'medium',
              },
            },
            {
              component: PersonSelectMulti,
              props: {
                value: strategyData.collaborators?.map((person) => person.id) || [],
                onChange: (newCollaborators: Person[]) => handleFieldUpdate('collaborators', newCollaborators),
                enableSearch: true,
                isDisabled: isReadOnly(strategyData.state),
                placeholder: 'Select collaborators',
              },
            },
            {
              component: TagSelect,
              props: {
                value: strategyData.tags?.map((tag) => tag.id) || [],
                onChange: (newTags: Tag[]) => handleFieldUpdate('tags', newTags),
                enableSearch: true,
                isDisabled: isReadOnly(strategyData.state),
                placeholder: 'Select tags',
              },
            },
          ]}
          activityFeed={
            initialData ? (
              <LogEntryActivityFeed objectId={initialData.id} objectType="strategy" />
            ) : null
          }
          showActivityFeed={!!initialData}
          onVisibilityToggle={handleVisibilityToggle}
          initialVisibility={strategyData.visibility}
          isVisibilityEditable={strategyData.state !== 'APPROVED'}
          lifecycleStates={friendlyStrategyStates}
          currentState={getStateLabels([strategyData?.state || ''], strategyStateOptions)[0]}
          showLifecycle={true}
          isSidePanelInitiallyCollapsed={true}
          showSidePanel={false}
          viewMode={modalViewMode}
          onViewModeChange={(mode) => setModalViewMode(mode)}
        >
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">Diagnosis</label>
              <MarkDownEditor
                initialContent={strategyData.diagnosis || ''}
                onChange={(value) => handleFieldUpdate('diagnosis', value)}
                placeholder="Analyze the situation and identify the key challenge..."
                menuType="bubble"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Guiding Policies</label>
              <MarkDownEditor
                initialContent={strategyData.guiding_policies || ''}
                onChange={(value) => handleFieldUpdate('guiding_policies', value)}
                placeholder="Define the overall approach to overcome obstacles..."
                menuType="bubble"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Coherent Actions</label>
              <MarkDownEditor
                initialContent={strategyData.coherent_actions || ''}
                onChange={(value) => handleFieldUpdate('coherent_actions', value)}
                placeholder="List the coordinated actions to carry out the guiding policies..."
                menuType="bubble"
              />
            </div>
          </div>
        </ModalContent>
      )}
    />
  );
};
