import { MeetingType, MeetingOccurrence, SmartSuggestion, MeetingTopicNote } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// ========================= Meeting Types ========================= //

// Fetch all Meeting Types
export const fetchMeetingTypes = async (navigate: (path: string) => void): Promise<MeetingType[]> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Types');

  return response.json();
};

// Fetch a single Meeting Type by slug
export const fetchMeetingType = async (slug: string, navigate: (path: string) => void): Promise<MeetingType> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) throw new Error(`Failed to fetch Meeting Type with slug ${slug}`);

  return response.json();
};

// Create a new Meeting Type
export const createMeetingType = async (
  meetingTypeData: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>,
  navigate: (path: string) => void
): Promise<MeetingType> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...meetingTypeData,
      company: company_id,
      regular_attendee_ids: meetingTypeData.regular_attendees.map((attendee) => attendee.id),
      tag_ids: meetingTypeData.tags.map((tag) => tag.id),
      backlog_topic_ids: meetingTypeData.backlog_topics.map((topic) => topic.id),
    }),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Meeting Type');

  return response.json();
};

// Edit an existing Meeting Type
export const editMeetingType = async (
  slug: string,
  updatedMeetingType: Partial<MeetingType>,
  navigate: (path: string) => void
): Promise<MeetingType> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...updatedMeetingType,
      regular_attendee_ids: updatedMeetingType.regular_attendees?.map((attendee) => attendee.id),
      tag_ids: updatedMeetingType.tags?.map((tag) => tag.id),
      backlog_topic_ids: updatedMeetingType.backlog_topics?.map((topic) => topic.id),
      company: company_id,
    }),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Type with slug ${slug}`);

  return response.json();
};

// Delete an existing Meeting Type
export const deleteMeetingType = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/?company_id=${company_id}`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Type with slug ${slug}`);
};


// ========================= Meeting Occurrences ========================= //

// Fetch all Meeting Occurrences
export const fetchMeetingOccurrences = async (navigate: (path: string) => void): Promise<MeetingOccurrence[]> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Occurrences');

  return response.json();
};

// Fetch a single Meeting Occurrence by slug
export const fetchMeetingOccurrence = async (slug: string, navigate: (path: string) => void): Promise<MeetingOccurrence> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) throw new Error(`Failed to fetch Meeting Occurrence with slug ${slug}`);

  return response.json();
};

// Create a new Meeting Occurrence
export const createMeetingOccurrence = async (
  occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>,
  navigate: (path: string) => void
): Promise<MeetingOccurrence> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...occurrenceData,
      company: company_id,
      attendee_ids: occurrenceData.attendees.map((attendee) => attendee.id),
      tag_ids: occurrenceData.tags.map((tag) => tag.id),
      task_ids: occurrenceData.tasks?.map((task) => task.id) || [],
      project_ids: occurrenceData.projects?.map((project) => project.id) || [],
      meeting_type: typeof occurrenceData.meeting_type === 'object' ? occurrenceData.meeting_type?.id : occurrenceData.meeting_type,
    }),
  }, navigate);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to create Meeting Occurrence: ${JSON.stringify(errorData)}`);
  }

  const createdOccurrence = await response.json();
  return {
    ...createdOccurrence,
    attendees: occurrenceData.attendees,
    tags: occurrenceData.tags,
    tasks: occurrenceData.tasks || [],
    projects: occurrenceData.projects || [],
  };
};

// Edit an existing Meeting Occurrence
export const editMeetingOccurrence = async (
  slug: string,
  updatedOccurrence: Partial<MeetingOccurrence>,
  navigate: (path: string) => void
): Promise<MeetingOccurrence> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...updatedOccurrence,
      attendee_ids: updatedOccurrence.attendees?.map((attendee) => attendee.id),
      tag_ids: updatedOccurrence.tags?.map((tag) => tag.id),
      task_ids: updatedOccurrence.tasks?.map((task) => task.id),
      project_ids: updatedOccurrence.projects?.map((project) => project.id),
      meeting_type: typeof updatedOccurrence.meeting_type === 'object' ? updatedOccurrence.meeting_type?.id : updatedOccurrence.meeting_type,
      company: company_id,
    }),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Occurrence with slug ${slug}`);

  return response.json();
};

// Delete an existing Meeting Occurrence
export const deleteMeetingOccurrence = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/?company_id=${company_id}`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Occurrence with slug ${slug}`);
};



// ========================= Meeting Topic Notes ========================= //

// Fetch all Meeting Topic Notes for a Meeting Occurrence
export const fetchMeetingTopicNotes = async (meetingOccurrenceId: string, navigate: (path: string) => void): Promise<MeetingTopicNote[]> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/?meeting_occurrence=${meetingOccurrenceId}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Topic Notes');

  return response.json();
};

// Create a new Meeting Topic Note
export const createMeetingTopicNote = async (
  noteData: Omit<MeetingTopicNote, 'id' | 'created_at' | 'updated_at'>,
  navigate: (path: string) => void
): Promise<MeetingTopicNote> => {
  // Transform noteData to create a payload without the full topic object
  const payload = {
    ...noteData,
    topic_id: noteData.topic.id, // Extract topic ID
    // Exclude the full topic object
  };
  
  // Remove the `topic` from the payload type
  const { topic, ...restPayload } = payload;

  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restPayload), // Send the transformed payload
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Meeting Topic Note');

  return response.json();
};


// Edit an existing Meeting Topic Note
export const editMeetingTopicNote = async (
  id: string,
  updatedNote: Partial<MeetingTopicNote>,
  navigate: (path: string) => void
): Promise<MeetingTopicNote> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedNote),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Topic Note with ID ${id}`);

  return response.json();
};

// Delete an existing Meeting Topic Note
export const deleteMeetingTopicNote = async (id: string, navigate: (path: string) => void): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/${id}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Topic Note with ID ${id}`);
};



// ========================= Smart Suggestions ========================= //

// Fetch all Smart Suggestions
export const fetchSmartSuggestions = async (meetingOccurrenceId: string, navigate: (path: string) => void): Promise<SmartSuggestion[]> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/?meeting_occurrence_id=${meetingOccurrenceId}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Smart Suggestions');

  return response.json();
};

// Create a new Smart Suggestion
export const createSmartSuggestion = async (
  suggestionData: Omit<SmartSuggestion, 'id' | 'created_at'>,
  navigate: (path: string) => void
): Promise<SmartSuggestion> => {
  const response = await fetchWithAuth(`${API_URL}smart-suggestions/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(suggestionData),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Smart Suggestion');

  return response.json();
};

// Edit an existing Smart Suggestion
export const editSmartSuggestion = async (
  id: string,
  updatedSuggestion: Partial<SmartSuggestion>,
  navigate: (path: string) => void
): Promise<SmartSuggestion> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedSuggestion),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Smart Suggestion with ID ${id}`);

  return response.json();
};

// Delete an existing Smart Suggestion
export const deleteSmartSuggestion = async (id: string, navigate: (path: string) => void): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/${id}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Smart Suggestion with ID ${id}`);
};
