import { Tabs } from '../ui';
import MeetingOccurrenceTopicPanel from './MeetingOccurrenceTopicPanel';
import TaskPanel from '../task/TaskPanel'; // Import TaskPanel
import MeetingPastOccurrencesPanel from './MeetingPastOccurrencesPanel'; // Import the Past Occurrences Panel
import { Topic } from '../../api/topic/types';

interface MeetingOccurrenceSidePanelProps {
  meetingTypeId: string;
  onTopicClick?: (topic: Topic) => void; // Accept the handler
}

export function MeetingOccurrenceSidePanel({
  meetingTypeId,
  onTopicClick, // Accept the handler
}: MeetingOccurrenceSidePanelProps) {
  const tabs = [
    {
      label: 'Open Topics',
      content: (
        <MeetingOccurrenceTopicPanel
          isOpen={true} // Always open since this is the side panel
          onClose={() => {}}
          meetingTypeId={meetingTypeId}
          onTopicClick={onTopicClick} // Pass the handler down
        />
      ),
    },
    {
      label: 'Open Tasks',
      content: (
        <TaskPanel
          isOpen={true} // Always open
          onClose={() => {}}
          meetingTypeId={meetingTypeId} // Use meetingTypeId for filtering tasks
        />
      ),
    },
    {
      label: 'Past Meetings',
      content: (
        <MeetingPastOccurrencesPanel
          isOpen={true} // Always open
          onClose={() => {}}
          meetingTypeId={meetingTypeId} // Use meetingTypeId for filtering past meeting occurrences
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-w-md  p-4">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default MeetingOccurrenceSidePanel;
