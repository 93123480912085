import React from 'react';

export interface TagProps {
  label: string;
  variant?: 'primary' | 'secondary' | 'accent' | 'neutral';
  onRemove?: () => void;
}

export const Tag: React.FC<TagProps> = ({
  label,
  variant = 'primary',
  onRemove,
}) => {
  const baseClasses = 'inline-flex items-center justify-center font-sans font-medium rounded-full text-xs px-2 py-0.5';
  
  const variantClasses = {
    primary: 'bg-primary text-white',
    secondary: 'bg-secondary text-white',
    accent: 'bg-accent text-white',
    neutral: 'bg-neutral text-text',
  };

  const classes = `${baseClasses} ${variantClasses[variant]}`;

  return (
    <span className={classes}>
      {label}
      {onRemove && (
        <button
          onClick={onRemove}
          className="ml-1 focus:outline-none"
          aria-label="Remove tag"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}
    </span>
  );
};