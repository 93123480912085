import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Project } from '../../api/project/types'; // Assume Project type is defined similarly to Topic
import { getProjectStateLabelAndIcon } from '../../utils/entitiesOptions'; // Custom utility for project state

interface ProjectHeatmapViewProps {
  projects?: Project[];
  onOpenProject: (project: Project) => void; // onOpenProject prop for opening modal
  onEdit: (slug: string, updatedProject: Partial<Project>) => void; // onEdit prop for updates
}

const priorityLabels: { [key: number]: string } = {
  5: 'Critical',
  4: 'High',
  3: 'Medium',
  2: 'Low',
  1: 'Very Low',
};

export const ProjectHeatmapView: React.FC<ProjectHeatmapViewProps> = ({ projects: propProjects, onOpenProject, onEdit }) => {
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (propProjects) {
      setProjects(propProjects);
    }
  }, [propProjects]);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;
  
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return;
  
    const [destPriority, destPulse] = destination.droppableId.split('-');
  
    const updatedProjects = projects.map(project => {
      if (project.id === draggableId) {
        return {
          ...project,
          priority: parseInt(destPriority, 10) as 1 | 2 | 3 | 4 | 5,
          pulse: destPulse as Project['pulse'],
        };
      }
      return project;
    });
  
    // Directly find the updated project before setProjects to ensure the right data is sent
    const updatedProject = updatedProjects.find(project => project.id === draggableId);
  
    if (updatedProject) {
      onEdit(updatedProject.slug, {
        priority: updatedProject.priority,
        pulse: updatedProject.pulse,
      });
    }
  
    // Update state after calling onEdit to avoid stale values in the callback
    setProjects(updatedProjects);
  };
  

  const renderHeatmapGrid = () => {
    return [...Array(5)].map((_, idx) => {
      const priority = 5 - idx;
      return (
        <tr key={priority} className="border-b border-neutral">
          <td className="p-2 font-bold text-left w-32">{priorityLabels[priority]}</td>
          {['GREEN', 'YELLOW', 'ORANGE', 'RED'].map((status) => (
            <td key={status} className="p-2 border-l border-neutral">
              <Droppable droppableId={`${priority}-${status}`} direction="vertical" type="HEATMAP">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="min-h-[50px]">
                    {projects
                      .filter(project => project.priority === priority && project.pulse === status)
                      .map((project, index) => {
                        const { label: stateLabel, icon: stateIcon } = getProjectStateLabelAndIcon(project.state);

                        return (
                          <Draggable key={project.id} draggableId={project.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`p-2 rounded-md shadow-sm ${getStatusColor(project.pulse)} hover:opacity-80 transition-opacity duration-200 mb-2`}
                                onClick={() => onOpenProject(project)} // Open modal without changing styles
                              >
                                <span className="text-sm font-medium text-text">{project.title}</span>
                                <span className="block text-xs text-text-muted flex items-center space-x-1">
                                  {stateIcon} <span>{stateLabel}</span>
                                </span>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </td>
          ))}
        </tr>
      );
    });
  };

  const getStatusColor = (status: Project['pulse']) => {
    switch (status) {
      case 'GREEN': return 'bg-accent bg-opacity-20';
      case 'YELLOW': return 'bg-secondary bg-opacity-20';
      case 'ORANGE': return 'bg-secondary-dark bg-opacity-20';
      case 'RED': return 'bg-red-500 bg-opacity-20';
      default: return 'bg-neutral';
    }
  };

  return (
    <div className="bg-white mt-20">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="overflow-x-auto">
          <table className="w-full max-w-6xl mx-auto table-fixed border-collapse">
            <thead>
              <tr>
                <th className="p-2 text-left font-semibold text-text w-32"></th>
                <th className="p-2 text-center font-semibold text-accent">Green</th>
                <th className="p-2 text-center font-semibold text-secondary">Yellow</th>
                <th className="p-2 text-center font-semibold text-secondary-dark">Orange</th>
                <th className="p-2 text-center font-semibold text-red-500">Red</th>
              </tr>
            </thead>
            <tbody>
              {projects.length > 0 ? renderHeatmapGrid() : (
                <tr>
                  <td colSpan={5} className="p-4 text-center text-placeholder">No projects available for the heatmap.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DragDropContext>
    </div>
  );
};

export default ProjectHeatmapView;
