import { Integration, ProjectSource, ProjectImport, ProjectMapping, Team } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const LINEAR_STATE_MAPPING: { [key: string]: string } = {
  'backlog': 'BACKLOG',
  'planned': 'DEFINITION_AND_ANALYSIS',
  'started': 'DEVELOPMENT_IN_PROGRESS',
  'completed': 'DONE',
  'canceled': 'CANDIDATE'
};

const JIRA_STATE_MAPPING: { [key: string]: string } = {
  'to do': 'BACKLOG',
  'in progress': 'DEVELOPMENT_IN_PROGRESS',
  'done': 'DONE',
  'closed': 'DONE',
  'blocked': 'BLOCKED'
};

export interface FetchIntegrationsParams {
  provider?: string;
}

// List integrations
export const fetchIntegrations = async (
  params: FetchIntegrationsParams,
  navigate: (path: string) => void
): Promise<Integration[]> => {
  const queryParams = new URLSearchParams();
  if (params.provider) queryParams.append('provider', params.provider);

  const response = await fetchWithAuth(
    `${API_URL}integrations/${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
    {},
    navigate
  );
  if (!response.ok) throw new Error('Failed to fetch integrations');
  return response.json();
};

// Get single integration
export const fetchIntegration = async (
  id: string,
  navigate: (path: string) => void
): Promise<Integration> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/`,
    {},
    navigate
  );
  if (!response.ok) throw new Error(`Failed to fetch integration with id ${id}`);
  return response.json();
};

// Create integration
export const createIntegration = async (
  integrationData: Omit<Integration, 'id' | 'created_at' | 'updated_at' | 'status' | 'status_message' | 'last_sync_at'>,
  navigate: (path: string) => void
): Promise<Integration> => {
  const { company, ...data } = integrationData;

  const response = await fetchWithAuth(
    `${API_URL}integrations/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to create integration');
  return response.json();
};

// Update integration
export const updateIntegration = async (
  id: string,
  updatedIntegration: Partial<Integration>,
  navigate: (path: string) => void
): Promise<Integration> => {
  const { company, ...data } = updatedIntegration;

  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to update integration with id ${id}`);
  return response.json();
};

// Delete integration
export const deleteIntegration = async (
  id: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/`,
    {
      method: 'DELETE',
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to delete integration with id ${id}`);
};

// Verify connection
export const verifyIntegration = async (
  id: string,
  navigate: (path: string) => void
): Promise<{ is_valid: boolean; status: string; message: string }> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/verify/`,
    {
      method: 'POST',
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to verify integration');
  return response.json();
};

// List teams
export const fetchTeams = async (
  id: string,
  navigate: (path: string) => void
): Promise<Team[]> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/teams/`,
    {},
    navigate
  );

  if (!response.ok) throw new Error('Failed to fetch teams');
  return response.json();
};


// List available projects from integration
export const fetchProjectSources = async (
  id: string,
  navigate: (path: string) => void,
  teamId?: string // Optional team filter
): Promise<ProjectSource[]> => {
  const queryParams = new URLSearchParams();
  if (teamId) queryParams.append('team_id', teamId);

  const integration = await fetchIntegration(id, navigate);
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/project_sources/${
      queryParams.toString() ? `?${queryParams.toString()}` : ''
    }`,
    {},
    navigate
  );

  if (!response.ok) throw new Error('Failed to fetch project sources');
  const projects: ProjectSource[] = await response.json();

  return projects.map((project) => {
    const sourceStatus = project.status?.toLowerCase();
    let mappedState = 'BACKLOG';

    // Map state based on provider
    if (integration.provider === 'linear') {
      mappedState = LINEAR_STATE_MAPPING[sourceStatus] || 'BACKLOG';
    } else if (integration.provider === 'jira') {
      mappedState = JIRA_STATE_MAPPING[sourceStatus] || 'BACKLOG';
    }

    // Normalize dates
    const metadata = {
      ...project.metadata,
      start_date: project.metadata.start_date,
      target_date:
        integration.provider === 'jira'
          ? project.metadata.due_date
          : project.metadata.target_date,
    };

    return {
      ...project,
      metadata,
      status: mappedState,
    };
  });
};

// Import projects
export const importProjects = async (
  id: string,
  projects: ProjectImport[],
  navigate: (path: string) => void
): Promise<ProjectMapping[]> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/${id}/import_projects/`,
    {
      method: 'POST',
      body: JSON.stringify(projects),
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to import projects');
  return response.json();
};

// List available providers
export const fetchProviders = async (
  navigate: (path: string) => void
): Promise<{ id: string; name: string; description: string; features: string[]; required_fields: string[] }[]> => {
  const response = await fetchWithAuth(
    `${API_URL}integrations/providers/`,
    {},
    navigate
  );

  if (!response.ok) throw new Error('Failed to fetch providers');
  return response.json();
};