import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  fetchTeams,
  fetchTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  fetchTeamMemberships,
  addTeamMember,
  removeTeamMember
} from './api';
import { Team, TeamMembership } from './types';

// Hook for fetching all teams
export const useTeams = () => {
  const navigate = useNavigate();
  return useQuery(
    'teams',
    () => fetchTeams(navigate),
    {
      keepPreviousData: true,
    }
  );
};

// Hook for fetching a single team by slug
export const useTeam = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(
    ['team', slug],
    () => fetchTeam(slug, navigate),
    {
      enabled: !!slug,
    }
  );
};

// Hook for creating a new team
export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newTeam: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>) => createTeam(newTeam, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teams');
      },
    }
  );
};

// Hook for updating an existing team by slug
export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, updatedTeam }: { slug: string; updatedTeam: Partial<Team> }) =>
      updateTeam(slug, updatedTeam, navigate),
    {
      onSuccess: (_, { slug }) => {
        queryClient.invalidateQueries('teams');
        queryClient.invalidateQueries(['team', slug]);
      },
    }
  );
};

// Hook for deleting a team by slug
export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteTeam(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teams');
      },
    }
  );
};

// Hook for fetching team memberships for a specific team by teamSlug
export const useTeamMemberships = (teamSlug: string) => {
  const navigate = useNavigate();
  return useQuery(
    ['teamMemberships', teamSlug],
    () => fetchTeamMemberships(teamSlug, navigate),
    {
      enabled: !!teamSlug,
    }
  );
};

// Hook for adding a member to a team by teamSlug
export const useAddTeamMember = (teamSlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (personId: string) => addTeamMember(teamSlug, personId, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMemberships', teamSlug]);
        queryClient.invalidateQueries(['team', teamSlug]);
      },
    }
  );
};

// Hook for removing a member from a team by teamSlug
export const useRemoveTeamMember = (teamSlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (membershipId: string) => removeTeamMember(teamSlug, membershipId, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMemberships', teamSlug]);
        queryClient.invalidateQueries(['team', teamSlug]);
      },
    }
  );
};
