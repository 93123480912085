// src/api/alert/queries.ts
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { fetchAlerts } from './api';
import { Alert } from './types';

export const ALERTS_QUERY_KEY = 'alerts';

export const useAlerts = () => {
  const navigate = useNavigate();
  
  return useQuery<Alert[], Error>(
    [ALERTS_QUERY_KEY],
    () => fetchAlerts(navigate),
    {
      staleTime: 0, // Always consider stale to refetch on window focus
      refetchInterval: 1000 * 60 * 5, // Refresh every 5 minutes
      select: (data) => data ?? [],
    }
  );
};