import React from 'react';
import { SnapshotProject } from '../../api/project/types';
import { Button, Snackbar } from '../ui';
import { formatDate } from '../../utils/dateUtils';
import { useSnackbar } from '../../utils/useSnackbar';

interface ExecutiveSummaryReportProps {
  snapshotProjects: SnapshotProject[];
  periodStart: string;
  periodEnd: string;
  title: string;
}

const generateExecutiveSummary = (
  projects: SnapshotProject[], 
  periodStart: string, 
  periodEnd: string,
  title: string
) => {
  // Calculate high-level metrics
  const totalProjects = projects.length;
  const activeProjects = projects.filter(p => 
    !['DONE'].includes(p.state)
  ).length;
  const completedProjects = projects.filter(p => p.state === 'DONE').length;
  const highPriorityProjects = projects.filter(p => p.priority >= 3).length;
  
  // Group projects by state for portfolio health
  const projectsByState = projects.reduce((acc: Record<string, number>, project) => {
    acc[project.state] = (acc[project.state] || 0) + 1;
    return acc;
  }, {});

  // Identify key achievements
  const keyAchievements = projects
    .filter(project => 
      project.state === 'DONE' || 
      project.log_entries.some(entry => 
        entry.log_type.toLowerCase().includes('milestone') || 
        entry.log_type.toLowerCase().includes('accomplishment')
      )
    )
    .map(project => {
      const milestones = project.log_entries
        .filter(entry => 
          entry.log_type.toLowerCase().includes('milestone') || 
          entry.log_type.toLowerCase().includes('accomplishment')
        )
        .map(entry => entry.content);
      
      return {
        project: project.project.title,
        state: project.state,
        progress: project.progress,
        milestones
      };
    });

  // Identify risks and challenges
  const risks = projects
    .filter(project => 
      project.log_entries.some(entry => 
        entry.log_type.toLowerCase().includes('risk') || 
        entry.log_type.toLowerCase().includes('blocker')
      )
    )
    .map(project => ({
      project: project.project.title,
      issues: project.log_entries
        .filter(entry => 
          entry.log_type.toLowerCase().includes('risk') || 
          entry.log_type.toLowerCase().includes('blocker')
        )
        .map(entry => entry.content)
    }));

  // Format state for display (replace underscores with spaces)
  const formatState = (state: string) => state.replace(/_/g, ' ');

  return `# Executive Summary: ${title}
Period: ${formatDate(periodStart)} - ${formatDate(periodEnd)}

## Portfolio Overview
- Total Projects: ${totalProjects}
- Active Projects: ${activeProjects}
- Completed Projects: ${completedProjects}
- High Priority Projects: ${highPriorityProjects}

## Portfolio Health
${Object.entries(projectsByState)
  .map(([state, count]) => 
    `- ${formatState(state)}: ${count} projects (${Math.round(count/totalProjects*100)}%)`
  )
  .join('\n')}

## Key Achievements
${keyAchievements.map(achievement => `
### ${achievement.project} (${formatState(achievement.state)} - ${achievement.progress}% complete)
${achievement.milestones.map(milestone => `- ${milestone}`).join('\n')}`).join('\n')}

## Strategic Projects Status
${projects
  .filter(p => p.priority >= 3)
  .map(project => `
### ${project.project.title}
- Priority: P${project.priority}
- Status: ${formatState(project.state)}
- Progress: ${project.progress}%
- Pulse: ${project.pulse}
${project.summary ? `- Summary: ${project.summary}` : ''}`).join('\n')}

${risks.length > 0 ? `
## Key Risks and Challenges
${risks.map(risk => `
### ${risk.project}
${risk.issues.map(issue => `- ${issue}`).join('\n')}`).join('\n')}` : ''}

## Next Steps and Focus Areas
${projects
  .filter(p => p.state !== 'DONE')
  .filter(p => p.priority >= 3 || p.log_entries.some(entry => entry.log_type.toLowerCase().includes('next_steps')))
  .map(project => `
### ${project.project.title}
${project.log_entries
  .filter(entry => entry.log_type.toLowerCase().includes('next_steps'))
  .map(entry => `- ${entry.content}`)
  .join('\n')}`).join('\n')}`;
};

export const ExecutiveSummaryReport: React.FC<ExecutiveSummaryReportProps> = ({
    snapshotProjects,
    periodStart,
    periodEnd,
    title
  }) => {
    const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  
    const handleReportGeneration = () => {
      const reportContent = generateExecutiveSummary(
        snapshotProjects, 
        periodStart, 
        periodEnd,
        title
      );
      
      navigator.clipboard.writeText(reportContent).then(
        () => {
          showSnackbar('Executive Summary copied to clipboard!', 'success');
        },
        (err) => {
          showSnackbar('Failed to copy report to clipboard', 'error');
          console.error('Failed to copy report:', err);
        }
      );
    };
  
    return (
      <>
        <Button
          onClick={handleReportGeneration}
          variant="primary"
          buttonStyle="outlined"
          label="Generate Executive Summary"
          icon="FileText"
        />
        {snackbar.visible && (
          <Snackbar
            message={snackbar.message}
            type={snackbar.type}
            onClose={hideSnackbar}
          />
        )}
      </>
    );
  };
  
  export default ExecutiveSummaryReport;