import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Button } from '../../ui';

export interface ModalExtendedProps {
  isOpen: boolean;
  title: string;
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  onConfirm?: () => void;
  confirmLabel?: string;
  isConfirmDisabled?: boolean;
  isFullScreen?: boolean;
  size?: 'small' | 'large';
}

export function ModalExtended({
  isOpen,
  title,
  children,
  onClose,
  onConfirm,
  confirmLabel = "Confirm",
  isConfirmDisabled = false,
  isFullScreen = false,
  size = 'large'
}: ModalExtendedProps) {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isFullScreen ? '' : 'p-4'}`}>
      <div className={`${isFullScreen ? 'w-full h-full max-w-none' : size === 'large' ? 'w-full max-w-lg mx-auto' : 'w-full max-w-sm mx-auto'} bg-white rounded-lg shadow-lg p-4 relative`}>
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-600 hover:text-gray-800">
          <FaTimes />
        </button>

        <h2 className="text-lg font-semibold mb-4">{title}</h2>

        <div>{children}</div>

        <div className="mt-4 flex justify-end space-x-2">
          <Button label="Cancel" variant="secondary" buttonStyle="filled" onClick={onClose} />
          {onConfirm && (
            <Button
              label={confirmLabel}
              variant="primary"
              buttonStyle="filled"
              onClick={onConfirm}
              disabled={isConfirmDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
}