import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useGlobalSearch } from '../api/globalSearch/hooks';

interface CommandKModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateTask: () => void;
}

interface Option {
  id: string;
  label: string;
  path: string;
  action?: () => void;
  type: 'default' | 'topic' | 'task' | 'project' | 'person' | 'tag' | 'meeting' | 'meeting type' | 'team' | 'goal' | 'strategy';
}

const defaultOptions: Option[] = [
  
  { id: 'meeting-types', label: 'Meetings Types', path: '/meeting-types', type: 'default' },
  { id: 'meeting-occurrences', label: 'Meetings Occurrences', path: '/meeting-occurrences', type: 'default' },
  { id: 'persons', label: 'Persons', path: '/persons', type: 'default' },
  { id: 'projects', label: 'Projects', path: '/projects', type: 'default' },
  { id: 'strategies', label: 'Strategies', path: '/strategies', type: 'default' },
  { id: 'tags', label: 'Tags', path: '/tags', type: 'default' },
  { id: 'tasks', label: 'Tasks', path: '/tasks', type: 'default' },
  { id: 'goals', label: 'Goals', path: '/goals', type: 'default' },
  { id: 'teams', label: 'Teams', path: '/teams', type: 'default' }, 
  { id: 'topics', label: 'Topics', path: '/topics', type: 'default' },
  { id: 'create-task', label: 'Create New Task', path: '', type: 'default', action: () => {} },
];

const CommandKModal: React.FC<CommandKModalProps> = ({ isOpen, onClose, onCreateTask }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(defaultOptions);
  const [isSearching, setIsSearching] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { search, searchResults } = useGlobalSearch();

  const performSearch = useCallback(async (term: string) => {
    setIsSearching(true);
    try {
      await search(term);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsSearching(false);
    }
  }, [search]);

  useEffect(() => {
    if (isOpen) {
      setSearchTerm('');
      setFilteredOptions(defaultOptions);
      setIsSearching(false);
      setSelectedIndex(0);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    defaultOptions[0].action = onCreateTask;
  }, [onCreateTask]);

  useEffect(() => {
    if (searchTerm) {
      const debounceTimer = setTimeout(() => performSearch(searchTerm), 300);
      return () => clearTimeout(debounceTimer);
    }
  }, [searchTerm, performSearch]);

  useEffect(() => {
    if (searchTerm) {
      const searchResultOptions: Option[] = searchResults.map(result => ({
        id: result.id,
        label: result.title,
        path: result.path,
        type: result.type
      }));
      
      const filteredDefaultOptions = defaultOptions.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const newFilteredOptions = [...filteredDefaultOptions, ...searchResultOptions];
      setFilteredOptions(newFilteredOptions);
      
      // Adjust selectedIndex if it's out of bounds
      if (selectedIndex >= newFilteredOptions.length) {
        setSelectedIndex(Math.max(0, newFilteredOptions.length - 1));
      }
    } else {
      setFilteredOptions(defaultOptions);
    }
  }, [searchResults, searchTerm, selectedIndex]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prevIndex => 
          prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prevIndex => 
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (filteredOptions[selectedIndex]) {
          handleOptionSelect(filteredOptions[selectedIndex]);
        }
        break;
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleOptionSelect = (option: Option) => {
    if (option.action) {
      option.action();
    } else {
      navigateTo(option.path);
    }
    onClose();
  };

  const navigateTo = (path: string) => {
    navigate(path);
    onClose();
  };

  if (!isOpen) return null;

  const modalContent = (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      style={{ zIndex: 9999 }}
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg shadow-xl w-full max-w-lg"
        onClick={e => e.stopPropagation()}
        onKeyDown={handleKeyDown}
      >
        <div className="p-4">
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search or select an option..."
            className="w-full p-2 border rounded text-sm"
          />
        </div>
        <div className="border-t max-h-60 overflow-y-auto">
          {isSearching ? (
            <div className="p-4 text-center text-sm">Loading...</div>
          ) : filteredOptions.length === 0 ? (
            <div className="p-4 text-center text-sm">No results found</div>
          ) : (
            filteredOptions.map((option, index) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(option)}
                className={`block w-full text-left px-4 py-2 text-sm ${
                  index === selectedIndex ? 'bg-blue-100' : 'hover:bg-gray-100'
                }`}
              >
                <span className="font-medium">{option.label}</span>
                {option.type !== 'default' && (
                  <span className="ml-2 text-xs text-gray-500">{option.type}</span>
                )}
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default CommandKModal;
