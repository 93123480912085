// components/snapshot/SnapshotTable.tsx
import React from 'react';
import { ProjectsSnapshotListItem } from '../../api/project/types';
import { 
  Table, 
  TableDateComponent, 
  TableActionButtons, 
  TablePersonComponent,
  TableBadgeComponent
} from '../ui';
import { Person } from '../../api/person/types';

const snapshotStatusOptions = [
  { value: 'draft', label: 'Draft' },
  { value: 'sent', label: 'Sent' },
  { value: 'archived', label: 'Archived' },
];

interface SnapshotTableProps {
  data: ProjectsSnapshotListItem[];
  onView: (snapshot: ProjectsSnapshotListItem) => void;
  onDelete: (slug: string) => void;
  onSend: (slug: string) => void;
  onArchive: (slug: string) => void;
  onUpdateStatus?: (slug: string, newStatus: string) => void;
}

export const SnapshotTable: React.FC<SnapshotTableProps> = ({
  data,
  onView,
  onDelete,
  onSend,
  onArchive,
  onUpdateStatus
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Title',
          accessor: 'title',
          component: (row) => (
            <div
              onClick={() => onView(row)}
              className="cursor-pointer hover:underline"
            >
              {row.title}
            </div>
          ),
        },
        {
          header: 'Status',
          component: (row) => (
            <TableBadgeComponent
              label={row.status}
              type="snapshotStatus"
              onUpdate={onUpdateStatus ? 
                (newStatus) => onUpdateStatus(row.slug, newStatus) : 
                undefined
              }
              options={snapshotStatusOptions}
              isEditable={row.status === 'draft'}
            />
          ),
        },
        {
          header: 'Period',
          component: (row) => (
            <div className="flex items-center gap-2">
              <TableDateComponent dateString={row.period_start} />
              <span>→</span>
              <TableDateComponent dateString={row.period_end} />
            </div>
          ),
        },
        {
          header: 'Created By',
          component: (row) => (
            <TablePersonComponent
              person={row.created_by}
              isEditable={false}
            />
          ),
        },
        {
          header: 'Projects',
          component: (row) => (
            <span className="text-sm text-gray-600">
              {row.project_count} projects
            </span>
          ),
        },
        {
          header: 'Updates',
          component: (row) => (
            <span className="text-sm text-gray-600">
              {row.log_entries_count} updates
            </span>
          ),
        },
        {
          header: 'Created',
          component: (row) => (
            <TableDateComponent 
              dateString={row.created_at} 
              showTime={true}
            />
          ),
        },
        {
          header: 'Actions',
          component: (row) => (
            <div className="flex items-center gap-2">
              <TableActionButtons
                onView={() => onView(row)}
                onDelete={row.status === 'draft' ? () => onDelete(row.slug) : undefined}
              />
              {row.status === 'draft' && (
                <button
                  onClick={() => onSend(row.slug)}
                  className="px-2 py-1 text-sm text-blue-600 hover:text-blue-800"
                >
                  Send
                </button>
              )}
              {row.status === 'sent' && (
                <button
                  onClick={() => onArchive(row.slug)}
                  className="px-2 py-1 text-sm text-gray-600 hover:text-gray-800"
                >
                  Archive
                </button>
              )}
            </div>
          ),
        },
      ]}
    />
  );
};