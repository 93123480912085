import React, { useState } from 'react';
import { Icon, Card } from '../../ui';
import { CalendarPane } from './CalendarPane';
import { FavoritesPane } from '../../Favorite/FavoritesPane';
import { PulsePane } from '../../pulse/PulsePane';

type PaneType = 'calendar' | 'favorites' | 'pulse';

export const SidePanesContainer = () => {
  const [activePane, setActivePane] = useState<PaneType>('calendar');
  const [collapsed, setCollapsed] = useState(true);

  if (collapsed) {
    return (
      <Card className="h-full w-12 !p-0 !border-0 flex flex-col !bg-white">
        <div className="relative h-12 w-full">
          <button 
            onClick={() => setCollapsed(false)}
            className="absolute inset-0 flex items-center justify-center group"
            title="Expand pane"
          >
            <div className="absolute inset-2 rounded-md group-hover:bg-neutral transition-colors" />
            <Icon name="ChevronLeft" size="sm" color="primary" className="relative" />
          </button>
        </div>

        <button
          onClick={() => {
            setActivePane('calendar');
            setCollapsed(false);
          }}
          className="relative h-24 w-full group"
          title="Calendar view"
        >
          <div className="absolute inset-2 rounded-md group-hover:bg-neutral transition-colors" />
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform -rotate-90 whitespace-nowrap text-xs font-medium text-text"
          >
            Calendar
          </span>
        </button>

        <button
          onClick={() => {
            setActivePane('favorites');
            setCollapsed(false);
          }}
          className="relative h-24 w-full group"
          title="Favorites"
        >
          <div className="absolute inset-2 rounded-md group-hover:bg-neutral transition-colors" />
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform -rotate-90 whitespace-nowrap text-xs font-medium text-text"
          >
            Favorites
          </span>
        </button>

        <button
          onClick={() => {
            setActivePane('pulse');
            setCollapsed(false);
          }}
          className="relative h-24 w-full group"
          title="Pulse"
        >
          <div className="absolute inset-2 rounded-md group-hover:bg-neutral transition-colors" />
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform -rotate-90 whitespace-nowrap text-xs font-medium text-text"
          >
            Pulse
          </span>
        </button>
      </Card>
    );
  }

  return (
    <Card className="h-full w-64 !p-0 !rounded-lg flex flex-col !bg-white">
      {/* Header with navigation */}
      <div className="flex items-center justify-between p-2 border-b border-neutral shrink-0">
        <div className="flex gap-2">
          <button
            onClick={() => setActivePane('calendar')}
            className={`p-2 rounded-md hover:bg-gray-100 ${
              activePane === 'calendar' ? 'bg-gray-100' : ''
            }`}
            title="Calendar view"
          >
            <Icon name="Calendar" size="sm" color={activePane === 'calendar' ? 'primary' : 'text'} />
          </button>
          <button
            onClick={() => setActivePane('favorites')}
            className={`p-2 rounded-md hover:bg-gray-100 ${
              activePane === 'favorites' ? 'bg-gray-100' : ''
            }`}
            title="Favorites"
          >
            <Icon name="Star" size="sm" color={activePane === 'favorites' ? 'primary' : 'text'} />
          </button>
          <button
            onClick={() => setActivePane('pulse')}
            className={`p-2 rounded-md hover:bg-gray-100 ${
              activePane === 'pulse' ? 'bg-gray-100' : ''
            }`}
            title="Pulse"
          >
            <Icon name="Activity" size="sm" color={activePane === 'pulse' ? 'primary' : 'text'} />
          </button>
        </div>
        <button 
          onClick={() => setCollapsed(true)}
          className="p-2 rounded-md hover:bg-gray-100"
          title="Collapse pane"
        >
          <Icon name="ChevronRight" size="sm" />
        </button>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-hidden">
        {activePane === 'calendar' ? (
          <CalendarPane />
        ) : activePane === 'favorites' ? (
          <FavoritesPane />
        ) : (
          <PulsePane />
        )}
      </div>
    </Card>
  );
};