import React, { useState, useMemo, useEffect } from 'react';
import PersonContainer from '../../containers/PersonContainer';
import { Person } from '../../api/person/types';
import { Card, CardHeader, CardContent, CardTitle, Tag, Button, Icon } from '../ui';
import TagSelect from '../tags/TagSelect';
import { SelectSingle } from '../ui/SelectSingle';

interface PersonPickerProps {
  onSelect: (persons: Person[]) => void;
  initialSelected?: Person[] | string[];
  maxSelect?: number;
}

const PersonPicker: React.FC<PersonPickerProps> = ({
  onSelect,
  initialSelected = [],
  maxSelect
}) => {
  const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);
  const [filters, setFilters] = useState({ person_type: '', tags: [] as string[] });
  const [view, setView] = useState<'list' | 'grid'>('list');

  const handleSelectPerson = (person: Person) => {
    if (maxSelect && selectedPersons.length >= maxSelect) return;
    if (!selectedPersons.some((p) => p.slug === person.slug)) {
      const newSelected = [...selectedPersons, person];
      setSelectedPersons(newSelected);
      onSelect(newSelected); // Trigger selection callback only when user interacts
    }
  };

  const handleRemovePerson = (slug: string) => {
    const newSelected = selectedPersons.filter((p) => p.slug !== slug);
    setSelectedPersons(newSelected);
    onSelect(newSelected); // Trigger selection callback only on removal
  };

  const clearFilters = () => {
    setFilters({ person_type: '', tags: [] });
  };

  const RenderContent = ({ data: persons, loading, error }: { 
    data: Person[], 
    loading: boolean, 
    error: string | null 
  }) => {
    useEffect(() => {
      if (initialSelected.length > 0 && persons.length > 0) {
        setSelectedPersons((prevSelected) => {
          const newSelection =
            typeof initialSelected[0] === 'string'
              ? (initialSelected as string[]).map((id) => persons.find((p: Person) => p.id === id)).filter(Boolean) as Person[]
              : (initialSelected as Person[]);
          return JSON.stringify(prevSelected) === JSON.stringify(newSelection) ? prevSelected : newSelection;
        });
      }
    }, [initialSelected, persons]);

    const filteredPersons = useMemo(() => {
      return persons
        .filter((person) => {
          if (selectedPersons.some((p) => p.slug === person.slug)) return false;
          const matchesType = !filters.person_type || person.person_type === filters.person_type;
          const matchesTags = filters.tags.length === 0 || filters.tags.every((tag) =>
            person.tags?.some((personTag) => personTag.id === tag)
          );
          return matchesType && matchesTags;
        })
        .sort((a, b) => a.name.localeCompare(b.name));  // Sort by name in ascending order
    }, [persons, selectedPersons, filters]);

    const personTypeOptions = useMemo(() => {
      const uniqueTypes = Array.from(new Set(persons.map((p) => p.person_type)));
      return uniqueTypes.map((type) => ({ label: type.replace('_', ' '), value: type }));
    }, [persons]);

    if (error) {
      return <div className="text-red-500 text-sm">Error loading persons: {error}</div>;
    }

    return (
      <div className="w-full max-w-4xl">
        {/* Selected Persons */}
        <Card className="mb-4">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Icon name="Users" size="sm" color="text" />
              Selected ({selectedPersons.length}{maxSelect ? `/${maxSelect}` : ''})
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {selectedPersons.map((person) => (
                <Tag
                  key={person.slug}
                  label={`${person.name} • ${person.person_type.replace('_', ' ')}`}
                  variant="neutral"
                  onRemove={() => handleRemovePerson(person.slug)}
                />
              ))}
              {selectedPersons.length === 0 && (
                <div className="text-sm text-placeholder">No persons selected</div>
              )}
            </div>
          </CardContent>
        </Card>

        {/* Filters */}
        <Card className="mb-4">
          <CardContent>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <Button
                  label="Clear Filters"
                  variant="secondary"
                  buttonStyle="outlined"
                  onClick={clearFilters}
                />
              </div>

              {/* Person Type and Tags Filter */}
              <div className="flex gap-4">
                <SelectSingle
                  options={personTypeOptions}
                  placeholder="Select Person Type"
                  onSelect={(selectedType) => setFilters((prev) => ({ ...prev, person_type: selectedType }))}
                  defaultValue={filters.person_type}
                />
                <TagSelect
                  value={filters.tags}
                  onChange={(selectedTags) => setFilters((prev) => ({ ...prev, tags: selectedTags.map((tag) => tag.id) }))}
                  placeholder="Filter by Tags"
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Results */}
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle>
                Available ({filteredPersons.length})
              </CardTitle>
              <div className="flex gap-2">
                <Button
                  icon="List"
                  variant={view === 'list' ? 'primary' : 'tertiary'}
                  buttonStyle="ghost"
                  size="small"
                  onClick={() => setView('list')}
                />
                <Button
                  icon="Grid"
                  variant={view === 'grid' ? 'primary' : 'tertiary'}
                  buttonStyle="ghost"
                  size="small"
                  onClick={() => setView('grid')}
                />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {loading ? (
              <div className="text-center py-8 text-placeholder">Loading...</div>
            ) : (
              view === 'list' ? (
                <div className="space-y-2">
                  {filteredPersons.map((person) => (
                    <div
                      key={person.slug}
                      onClick={() => handleSelectPerson(person)}
                      className="flex items-center justify-between p-2 hover:bg-neutral/10 rounded-md cursor-pointer"
                    >
                      <div>
                        <div className="font-medium">{person.name}</div>
                        <div className="text-sm text-placeholder">
                          {person.email} • {person.person_type.replace('_', ' ')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {filteredPersons.map((person) => (
                    <Card
                      key={person.slug}
                      className="cursor-pointer hover:border-primary/30"
                      onClick={() => handleSelectPerson(person)}
                    >
                      <CardContent>
                        <div className="font-medium">{person.name}</div>
                        <div className="text-sm text-placeholder mt-1">{person.email}</div>
                        <div className="mt-2">
                          <Tag
                            label={person.person_type.replace('_', ' ')}
                            variant="neutral"
                          />
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )
            )}

            {!loading && filteredPersons.length === 0 && (
              <div className="text-center py-8 text-placeholder">
                No persons found matching your criteria
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <PersonContainer
      render={(props) => <RenderContent {...props} />}
    />
  );
};

export default PersonPicker;
