import { SelectOption } from '../components/ui/Select'; 
import { Icon } from '../components/ui';

// Topic states
export const topicStateOptions: SelectOption[] = [
  { value: 'IDENTIFIED', label: 'Identified', icon: <Icon name="Flag" size="sm" /> },
  { value: 'IN_PROGRESS', label: 'In Progress', icon: <Icon name="Loader" size="sm" /> },
  { value: 'ONGOING', label: 'Ongoing', icon: <Icon name="RefreshCcw" size="sm" /> },
  { value: 'COMPLETED', label: 'Completed', icon: <Icon name="CheckCircle" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
];

// Project states
export const projectStateOptions: SelectOption[] = [
  { value: 'CANDIDATE', label: 'Candidate', icon: <Icon name="Compass" size="sm" /> },
  { value: 'BACKLOG', label: 'Backlog', icon: <Icon name="Clipboard" size="sm" /> },
  { value: 'DEFINITION_AND_ANALYSIS', label: 'Definition & Analysis', icon: <Icon name="Layers" size="sm" /> },
  { value: 'DEVELOPMENT_IN_PROGRESS', label: 'Development in Progress', icon: <Icon name="Code" size="sm" /> },
  { value: 'TESTING', label: 'Testing', icon: <Icon name="CheckSquare" size="sm" /> },
  { value: 'ROLLING_OUT', label: 'Rolling Out', icon: <Icon name="Truck" size="sm" /> },
  { value: 'DONE', label: 'Done', icon: <Icon name="CheckCircle" size="sm" /> },
];

// Task states
export const taskStateOptions: SelectOption[] = [
  { value: 'IDENTIFIED', label: 'Identified', icon: <Icon name="Flag" size="sm" /> },
  { value: 'IN_PROGRESS', label: 'In Progress', icon: <Icon name="Loader" size="sm" /> },
  { value: 'COMPLETED', label: 'Completed', icon: <Icon name="CheckCircle" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
  { value: 'BLOCKED', label: 'Blocked', icon: <Icon name="Ban" size="sm" /> },
];

// Dial stages for tasks
export const dialStageOptions: SelectOption[] = [
  { value: 'MENTION', label: 'Mention', icon: <Icon name="MessageSquare" size="sm" /> },
  { value: 'INVITATION', label: 'Invitation', icon: <Icon name="Mail" size="sm" /> },
  { value: 'CONVERSATION', label: 'Conversation', icon: <Icon name="MessageCircle" size="sm" /> },
  { value: 'BOUNDARY', label: 'Boundary', icon: <Icon name="Shield" size="sm" /> },
  { value: 'LIMIT', label: 'Limit', icon: <Icon name="Slash" size="sm" /> },
];

// Pulse options for projects (health status)
export const pulseOptions: SelectOption[] = [
  { value: 'GREEN', label: 'Green', icon: <Icon name="Heart" size="sm" /> },
  { value: 'YELLOW', label: 'Yellow', icon: <Icon name="AlertTriangle" size="sm" /> },
  { value: 'ORANGE', label: 'Orange', icon: <Icon name="AlertOctagon" size="sm" /> },
  { value: 'RED', label: 'Red', icon: <Icon name="XCircle" size="sm" /> },
];

// Adding icons to heatmapStatusOptions
export const heatmapStatusOptions: SelectOption[] = [
  { value: 'GREEN', label: 'Green', icon: <Icon name="Activity" size="sm" /> },
  { value: 'YELLOW', label: 'Yellow', icon: <Icon name="AlertTriangle" size="sm" /> },
  { value: 'ORANGE', label: 'Orange', icon: <Icon name="AlertOctagon" size="sm" /> },
  { value: 'RED', label: 'Red', icon: <Icon name="XCircle" size="sm" /> },
];

// Adding icons to importanceOptions (shared between entities and projects)
export const importanceOptions: SelectOption[] = [
  { value: '1', label: 'Very Low', icon: <Icon name="SignalZero" size="sm" /> },
  { value: '2', label: 'Low', icon: <Icon name="SignalLow" size="sm" /> },
  { value: '3', label: 'Medium', icon: <Icon name="SignalMedium" size="sm" /> },
  { value: '4', label: 'High', icon: <Icon name="SignalHigh" size="sm" /> },
  { value: '5', label: 'Critical', icon: <Icon name="Signal" size="sm" /> },
];

// Adding icons to effortOptions for Projects
export const effortOptions: SelectOption[] = [
  { value: 'XS', label: 'Extra Small', icon: <Icon name="ArrowDown" size="sm" /> },
  { value: 'S', label: 'Small', icon: <Icon name="ArrowDownLeft" size="sm" /> },
  { value: 'M', label: 'Medium', icon: <Icon name="ArrowRight" size="sm" /> },
  { value: 'L', label: 'Large', icon: <Icon name="ArrowUpRight" size="sm" /> },
  { value: 'XL', label: 'Extra Large', icon: <Icon name="ArrowUp" size="sm" /> },
];

// Adding icons to priorityOptions for Projects
export const priorityOptions: SelectOption[] = [
  { value: '1', label: 'Very Low', icon: <Icon name="SignalZero" size="sm" /> },
  { value: '2', label: 'Low', icon: <Icon name="SignalLow" size="sm" /> },
  { value: '3', label: 'Medium', icon: <Icon name="SignalMedium" size="sm" /> },
  { value: '4', label: 'High', icon: <Icon name="SignalHigh" size="sm" /> },
  { value: '5', label: 'Critical', icon: <Icon name="Signal" size="sm" /> },
];

// Strategy states with icons
export const strategyStateOptions: SelectOption[] = [
  { value: 'DRAFT', label: 'Draft', icon: <Icon name="Edit" size="sm" /> },
  { value: 'IN_REVIEW', label: 'In Review', icon: <Icon name="FileSearch" size="sm" /> },
  { value: 'APPROVED', label: 'Approved', icon: <Icon name="CheckCircle2" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
];

// Utility function to get label and icon by value
const findOptionByValue = (options: SelectOption[], value: string) =>
  options.find(option => option.value === value);

// Helper functions to get friendly name and icon for each entity
export const getTopicStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(topicStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getProjectStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(projectStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getTaskStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(taskStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getDialStageLabelAndIcon = (stage: string) => {
  const option = findOptionByValue(dialStageOptions, stage);
  return option ? { label: option.label, icon: option.icon } : { label: stage, icon: null };
};

export const getPulseLabelAndIcon = (status: string) => {
  const option = findOptionByValue(pulseOptions, status);
  return option ? { label: option.label, icon: option.icon } : { label: status, icon: null };
};

export const getHeatmapStatusLabelAndIcon = (status: string) => {
  const option = findOptionByValue(heatmapStatusOptions, status);
  return option ? { label: option.label, icon: option.icon } : { label: status, icon: null };
};

export const getImportanceLabelAndIcon = (importance: string) => {
  const option = findOptionByValue(importanceOptions, importance);
  return option ? { label: option.label, icon: option.icon } : { label: importance, icon: null };
};

export const getEffortLabelAndIcon = (effort: string) => {
  const option = findOptionByValue(effortOptions, effort);
  return option ? { label: option.label, icon: option.icon } : { label: effort, icon: null };
};

export const getPriorityLabelAndIcon = (priority: string) => {
  const option = findOptionByValue(priorityOptions, priority);
  return option ? { label: option.label, icon: option.icon } : { label: priority, icon: null };
};

export const getStrategyStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(strategyStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};
