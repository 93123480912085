import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import StrategyContainer from '../containers/StrategyContainer';
import { StrategyTable } from '../components/strategy/StrategyTable';
import { StrategyModal } from '../components/strategy/StrategyModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Strategy } from '../api/strategy/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';
import { strategyStateOptions } from '../utils/entitiesOptions';

const StrategyListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [strategyToDelete, setStrategyToDelete] = useState<string | null>(null);
  const [strategyToEdit, setStrategyToEdit] = useState<Strategy | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [sortBy, setSortBy] = useState<string>('updated_at');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();

  useEffect(() => {
    if (slug && strategies.length > 0) {
      const strategy = strategies.find((s) => s.slug === slug);
      if (strategy) {
        setStrategyToEdit(strategy);
        setShowModal(true);
      }
    }
  }, [slug, strategies]);

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedStrategy: Partial<Strategy>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Strategy title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: Strategy['state'],
    onEdit: (slug: string, updatedStrategy: Partial<Strategy>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Strategy state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedStrategy: Partial<Strategy>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateCollaborators = (
    slug: string,
    newCollaborators: Person[],
    onEdit: (slug: string, updatedStrategy: Partial<Strategy>) => void
  ) => {
    onEdit(slug, { collaborators: newCollaborators });
    setSnackbarMessage('Collaborators updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateOwner = (
    slug: string,
    newOwner: Person | null,
    onEdit: (slug: string, updatedStrategy: Partial<Strategy>) => void
  ) => {
    onEdit(slug, { owner: newOwner });
    setSnackbarMessage('Owner updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setStrategyToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (strategyToDelete) {
      onDelete(strategyToDelete);
      setStrategies((prev) => prev.filter((strategy) => strategy.slug !== strategyToDelete));
      setShowDeleteDialog(false);
      setStrategyToDelete(null);
      setSnackbarMessage('Strategy deleted successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
  };
  

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setStrategyToEdit(undefined);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); 
    }
  
    navigate(`/strategies/new?${searchParams.toString()}`);
  };
  

  const handleEditStrategy = (strategy: Strategy) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default mode if none present
    }
  
    navigate(`/strategies/${strategy.slug}?${searchParams.toString()}`);
    setStrategyToEdit(strategy);
    setShowModal(true);
  };
  

  const filterConfig: FilterConfig = useMemo(() => {
    const allTags = Array.from(new Set(strategies.flatMap(strategy => strategy.tags.map(tag => tag.name))));
    const allOwners = Array.from(new Set(strategies.map(strategy => strategy.owner?.name).filter((name): name is string => name !== undefined)));

    return {
      state: {
        label: 'State',
        value: 'state',
        type: 'select',
        options: strategyStateOptions.map(option => ({
          label: option.label,
          value: option.value
        })),
      },
      owner: {
        label: 'Owner',
        value: 'owner',
        type: 'select',
        options: allOwners.map(owner => ({ label: owner, value: owner })),
      },
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
    };
  }, [strategies]);

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Last Updated', value: 'updated_at' },
  ];

  const filterAndSortStrategies = (strategies: Strategy[]): Strategy[] => {
    let filteredStrategies = strategies.filter(strategy =>
      strategy.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true;

        switch (key) {
          case 'state':
            return strategy.state === value;
          case 'tags':
            if (Array.isArray(value) && value.length > 0) {
              return value.some(tag => strategy.tags.some(strategyTag => strategyTag.name === tag));
            }
            return true;
          case 'owner':
            return strategy.owner?.name === value;
          default:
            return true;
        }
      })
    );

    return filteredStrategies.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'title':
          result = a.title.localeCompare(b.title);
          break;
        case 'state':
          result = a.state.localeCompare(b.state);
          break;
        case 'updated_at':
          result = new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  return (
    <div>
      <h1 className="m-4">Strategies</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Strategies..."
        addButtonLabel="Add New Strategy"
        sortOptions={sortOptions}
        onSortChange={setSortBy}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        filterConfig={filterConfig}
        onFilterChange={setFilters}
      />

      <StrategyContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {

          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading strategies...</p>
            </div>
          );

          if (error) return <div>{error}</div>;

          if (strategies.length === 0 && data.length > 0) {
            setStrategies(data);
          }

          const filteredAndSortedStrategies = filterAndSortStrategies(data);

          return (
            <>
              {showModal && (
                <StrategyModal
                isOpen={showModal}
                onClose={() => {
                  setShowModal(false);
                  const searchParams = new URLSearchParams(location.search);
                  if (!searchParams.has('mode')) {
                    searchParams.set('mode', 'c'); 
                  }
                  navigate(`/strategies?${searchParams.toString()}`);
                }}
                onSave={(data) => {
                  if (!data.title) {
                    console.error('Title is required');
                    setSnackbarMessage('Title is required');
                    setSnackbarType('error');
                    setShowSnackbar(true);
                    return;
                  }
              
                  if (strategyToEdit) {
                    onEdit(strategyToEdit.slug, data);
                  } else {
                    onCreate(data as Omit<Strategy, 'id' | 'created_at' | 'updated_at' | 'slug'>);
                  }
                }}
                onEdit={onEdit}
                onDelete={onDelete}
                initialData={strategyToEdit}
              />
              
              
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this strategy?</p>
              </Modal>

              <StrategyTable
                data={filteredAndSortedStrategies}
                onEdit={handleEditStrategy}
                onDelete={handleDelete}
                onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                onUpdateCollaborators={(slug, newCollaborators) => handleUpdateCollaborators(slug, newCollaborators, onEdit)}
                onUpdateOwner={(slug, newOwner) => handleUpdateOwner(slug, newOwner, onEdit)}
              />
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default StrategyListPage;
