import React from 'react';
import { useTasks, useCreateTask, useEditTask, useDeleteTask } from '../api/task/queries';
import { Task } from '../api/task/types';
import { useQueryClient } from 'react-query';
import { ALERTS_QUERY_KEY } from '../api/alert/queries';

interface TaskContainerProps {
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;
  startDate?: string;
  endDate?: string;
  render: (props: {
    data: Task[];
    loading: boolean;
    error: string | null;
    onCreate: (newTask: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedTask: Partial<Task>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const TaskContainer: React.FC<TaskContainerProps> = ({ 
  topicId, 
  projectId, 
  meetingOccurrenceId, 
  meetingTypeId, 
  personId,
  startDate,
  endDate,
  render 
}) => {
  const params = { topicId, projectId, meetingOccurrenceId, meetingTypeId, personId, startDate, endDate };
  const queryClient = useQueryClient();
  const queryKey = ['tasks', params];

  const { data: tasks = [], isLoading, error } = useTasks(params);
  const createTaskMutation = useCreateTask(params);
  const editTaskMutation = useEditTask(params);
  const deleteTaskMutation = useDeleteTask(params);

  const handleCreate = (newTask: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
    createTaskMutation.mutate(newTask, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    });
  };
  
  const handleEdit = (slug: string, updatedTask: Partial<Task>) => {
    // Get the current tasks from the cache
    const previousTasks = queryClient.getQueryData<Task[]>(queryKey) || [];
    const taskIndex = previousTasks.findIndex(t => t.slug === slug);
    
    if (taskIndex === -1) return;

    // Create an optimistically updated task list
    const optimisticTasks = [...previousTasks];
    optimisticTasks[taskIndex] = {
      ...optimisticTasks[taskIndex],
      ...updatedTask
    };

    // Update the cache immediately with optimistic data
    queryClient.setQueryData(queryKey, optimisticTasks);

    // Perform the actual update
    editTaskMutation.mutate(
      { slug, updatedTask },
      {
        // On error, roll back to the previous state
        onError: () => {
          queryClient.setQueryData(queryKey, previousTasks);
        },
        // On success, invalidate the query to ensure consistency
        onSuccess: () => {
          // Delay the invalidation to prevent flicker
          setTimeout(() => {
            queryClient.invalidateQueries(queryKey);
            queryClient.invalidateQueries(ALERTS_QUERY_KEY);
          }, 1000);
        }
      }
    );
  };
  
  const handleDelete = (slug: string) => {
    const previousTasks = queryClient.getQueryData<Task[]>(queryKey) || [];

    // Optimistically remove the task
    queryClient.setQueryData(queryKey, previousTasks.filter(t => t.slug !== slug));

    deleteTaskMutation.mutate(slug, {
      onError: () => {
        queryClient.setQueryData(queryKey, previousTasks);
      },
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(queryKey);
          queryClient.invalidateQueries(ALERTS_QUERY_KEY);
        }, 1000);
      }
    });
  };

  const sortedTasks = [...tasks].sort((a, b) => a.title.localeCompare(b.title));

  return render({
    data: sortedTasks,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default TaskContainer;