import React, { useState, useEffect } from 'react';
import { Icon } from './base/Icon';
import { Button } from './Button';
import { MarkDownEditorActivityFeed } from './utilities/MarkDownEditorActivityFeed'; // Import the editor

interface AccordionProps {
  title: string;
  content: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
  onRemove?: () => void;
  descriptionPlaceholder?: string;
  onContentChange?: (newContent: string) => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isDisabled = false,
  isLoading = false,
  onChange,
  defaultOpen = false,
  onRemove,
  descriptionPlaceholder = "Enter description...",
  onContentChange,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [description, setDescription] = useState(content || '');
  const [editorKey, setEditorKey] = useState(0); // Add key for editor to handle re-renders

  useEffect(() => {
    setDescription(content);
  }, [content]);

  const handleToggle = () => {
    if (isDisabled || isLoading) return;

    const newState = !isOpen;
    setIsOpen(newState);
    if (onChange) onChange(newState);
  };

  const handleDescriptionChange = (newValue: string) => {
    setDescription(newValue);
    if (onContentChange) {
      onContentChange(newValue);
    }
  };

  return (
    <div className="w-full my-4 border border-gray-300 rounded-lg overflow-hidden">
      <div
        className={`w-full px-4 py-2 text-left font-medium text-gray-600 bg-gray-100
        ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}
        ${isLoading ? 'animate-pulse' : ''}
        flex items-center justify-between`}
      >
        <button
          onClick={handleToggle}
          disabled={isDisabled}
          aria-expanded={isOpen}
          className="flex-grow text-left"
        >
          <span className='text-sm'>{title}</span>
        </button>

        {/* Remove Icon */}
        <button
          onClick={onRemove}
          disabled={isDisabled}
          className="ml-2 p-1"
          aria-label="Remove Accordion"
        >
          <Icon
            name="Trash"
            size="sm"
            color="text"
          />
        </button>
      </div>
      {isOpen && (
        <div className="p-4 bg-white border-t border-gray-300">
          {isLoading ? (
            <div className="animate-pulse">Loading...</div>
          ) : (
            <div>
              {/* Replaced InputDescription with MarkDownEditorActivityFeed */}
              <MarkDownEditorActivityFeed
                key={editorKey}  // Reset key to force re-render if needed
                initialContent={description}
                placeholder={descriptionPlaceholder}
                onChange={handleDescriptionChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
