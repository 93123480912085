import React, { useState, useRef, useEffect } from 'react';
import { Icon } from './';

export type FilterOptionType = 'select' | 'multi-select' | 'input' | 'boolean';

export interface FilterOption {
  label: string;
  value: string;
  type: FilterOptionType;
  options?: Array<{ label: string; value: string }>;
}

interface FilterSelectProps {
  option: FilterOption;
  value: any;
  onChange: (value: any) => void;
  onRemove: () => void;
  initiallyOpen?: boolean;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  option,
  value,
  onChange,
  onRemove,
  initiallyOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [searchTerm, setSearchTerm] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsOpen(initiallyOpen);
  }, [initiallyOpen]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleChange = (newValue: string) => {
    if (option.type === 'multi-select') {
      const updatedValue = Array.isArray(value) ? [...value] : [];
      const index = updatedValue.indexOf(newValue);
      if (index > -1) {
        updatedValue.splice(index, 1);
      } else {
        updatedValue.push(newValue);
      }
      onChange(updatedValue);
    } else {
      onChange(newValue);
      setIsOpen(false);
    }
  };

  const renderOptions = () => {
    if (!option.options) return null;

    const filteredOptions = option.options.filter(opt => 
      opt.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div className="max-h-48 overflow-auto">
        {filteredOptions.map(opt => (
          <div
            key={opt.value}
            className={`px-3 py-2 cursor-pointer hover:bg-neutral ${
              option.type === 'multi-select'
                ? (Array.isArray(value) && value.includes(opt.value)) ? 'bg-primary text-white' : ''
                : value === opt.value ? 'bg-primary text-white' : ''
            }`}
            onClick={() => handleChange(opt.value)}
          >
            <span className="text-sm">{opt.label}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderValue = () => {
    if (option.type === 'multi-select' && Array.isArray(value)) {
      if (value.length === 0) {
        return option.label; // Show just the label when nothing is selected
      }
      return `${value.length} ${option.label} Selected`;
    }
    if (option.type === 'select' || option.type === 'multi-select') {
      const selectedOption = option.options?.find(opt => opt.value === value);
      return selectedOption ? selectedOption.label : option.label;
    }
    if (option.type === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value || option.label;
  };

  return (
    <div ref={ref} className="relative inline-flex items-center bg-background rounded-md">
      <div
        onClick={handleToggle}
        className="flex items-center justify-between space-x-2 px-3 py-2 cursor-pointer hover:bg-neutral min-w-[120px]"
      >
        <span className="text-sm text-text truncate">{renderValue()}</span>
        <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} size="sm" color="text" />
      </div>
      <button
        onClick={onRemove}
        className="px-2 py-2 text-text hover:text-primary"
      >
        <Icon name="X" size="sm" />
      </button>
      {isOpen && (
        <div className="absolute z-10 top-full left-0 mt-1 w-full min-w-[200px] bg-white border border-neutral rounded-md shadow-lg">
          {(option.type === 'select' || option.type === 'multi-select') && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border border-neutral rounded-md text-sm"
              />
            </div>
          )}
          {renderOptions()}
        </div>
      )}
    </div>
  );
};
