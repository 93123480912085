import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchStrategies, 
  fetchStrategy, 
  createStrategy, 
  editStrategy, 
  deleteStrategy,
  createComment,
  deleteComment,
  toggleReaction
} from './api';
import { 
  Strategy, 
  CreateStrategyRequest, 
  UpdateStrategyRequest,
  CreateCommentRequest,
  CreateReactionRequest
} from './types';

// Hook for fetching all strategies, supporting optional filtering
export const useStrategies = (params?: { 
  collaboratorId?: string;
  teamId?: string;
}) => {
  const navigate = useNavigate();
  return useQuery(
    ['strategies', params], 
    () => fetchStrategies(params || {}, navigate)
  );
};

// Hook for fetching a single strategy
export const useStrategy = (slug: string) => {
  const navigate = useNavigate();
  return useQuery(
    ['strategy', slug], 
    () => fetchStrategy(slug, navigate),
    {
      enabled: !!slug
    }
  );
};

// Hook for creating a new strategy
export const useCreateStrategy = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newStrategy: CreateStrategyRequest) => createStrategy(newStrategy, navigate),
    {
      onSuccess: (data: Strategy) => {
        queryClient.invalidateQueries('strategies');
        navigate(`/strategies/${data.slug}`);
      },
    }
  );
};

// Hook for editing a strategy
export const useEditStrategy = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ slug, ...updatedStrategy }: UpdateStrategyRequest & { slug: string }) => 
      editStrategy(slug, updatedStrategy, navigate),
    {
      onSuccess: (data, { slug }) => {
        queryClient.invalidateQueries(['strategies']);
        queryClient.invalidateQueries(['strategy', slug]);
      },
      onError: (error) => {
        console.error('Failed to edit strategy:', error);
      },
    }
  );
};


// Hook for deleting a strategy
export const useDeleteStrategy = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (slug: string) => deleteStrategy(slug, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('strategies');
        navigate('/strategies');
      },
    }
  );
};

// Hook for creating a comment
export const useCreateComment = (strategySlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (commentData: CreateCommentRequest) => createComment(commentData, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['strategy', strategySlug]);
      },
    }
  );
};

// Hook for deleting a comment
export const useDeleteComment = (strategySlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (commentId: string) => deleteComment(commentId, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['strategy', strategySlug]);
      },
    }
  );
};

// Hook for toggling a reaction
export const useToggleReaction = (strategySlug: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (reactionData: CreateReactionRequest) => toggleReaction(reactionData, navigate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['strategy', strategySlug]);
      },
    }
  );
};

// Hook for prefetching strategy data
export const usePrefetchStrategy = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (slug: string) => {
    queryClient.prefetchQuery(
      ['strategy', slug],
      () => fetchStrategy(slug, navigate)
    );
  };
};

// Helper hook for checking if a strategy is editable
export const useIsStrategyEditable = (strategy: Strategy | undefined) => {
  if (!strategy) return false;
  return strategy.state !== 'APPROVED';
};

// Helper hook for checking if comments are allowed
export const useAreCommentsAllowed = (strategy: Strategy | undefined) => {
  if (!strategy) return false;
  return strategy.state !== 'APPROVED';
};