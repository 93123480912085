import React, { useState, useEffect } from 'react';
import { TextInput } from '../TextInput';
import { Button } from '../Button';
import { Icon } from '../base/Icon';
import { SelectSingle } from '../SelectSingle';
import { Filter, FilterConfig } from './Filter';

export interface SortOption {
  label: string;
  value: string;
}

export interface TableHeaderProps {
  onSearchChange: (value: string) => void;
  onAddNew: () => void;
  onShowArchive?: () => void;
  onShowComplete?: () => void;
  searchPlaceholder?: string;
  addButtonLabel?: string;
  bulkActions?: React.ReactNode;
  showBulkActions?: boolean;
  showArchived?: boolean;
  showCompleted?: boolean;
  sortOptions?: SortOption[];
  onSortChange?: (value: string) => void;
  defaultSortValue?: string;
  currentSortValue?: string;
  sortDirection?: 'asc' | 'desc';
  onDirectionToggle?: () => void;
  additionalButtons?: React.ReactNode;
  filterConfig?: FilterConfig;
  onFilterChange?: (filters: Record<string, any>) => void;
  initialFilter?: Record<string, any>;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  onSearchChange,
  onAddNew,
  onShowArchive,
  onShowComplete,
  searchPlaceholder = 'Search...',
  addButtonLabel = 'Add New',
  bulkActions,
  showBulkActions = false,
  showArchived = false,
  showCompleted = false,
  sortOptions,
  onSortChange,
  defaultSortValue,
  currentSortValue,
  sortDirection = 'asc',
  onDirectionToggle,
  additionalButtons,
  filterConfig,
  onFilterChange,
  initialFilter = {},
}) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>(initialFilter);

  useEffect(() => {
    // Set initial filters if provided
    setSelectedFilters((prevFilters) => {
      const hasChanged = Object.keys(initialFilter).some(
        (key) => initialFilter[key] !== prevFilters[key]
      );
      if (hasChanged) {
        onFilterChange && onFilterChange(initialFilter);
        return initialFilter;
      }
      return prevFilters;
    });
  }, [initialFilter, onFilterChange]);
  

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setSelectedFilters(newFilters);
    if (onFilterChange) onFilterChange(newFilters);
  };

  return (
    <div className="space-y-4">
  <div className="flex items-center justify-between py-2 border-b border-neutral mb-4 whitespace-nowrap">
  {/* Left Section: Filter, Search, Sorting */}
    <div className="flex items-center space-x-4 flex-grow">
      {/* Filter */}
      {filterConfig && onFilterChange && (
        <div className="min-w-[120px]">
          <Filter config={filterConfig} onFilterChange={handleFilterChange} initialValues={selectedFilters} />
        </div>
      )}

      {/* Search */}
      <div className="flex items-center h-10">
        <TextInput
          placeholder={searchPlaceholder}
          onChange={(e) => onSearchChange(e.target.value)}
          startIcon={<Icon name="Search" size="sm" color="text" />}
          className="w-64 mb-0"
        />
      </div>

      {/* Sorting */}
      {sortOptions && onSortChange && (
        <div className="flex items-center h-10">
          <SelectSingle
            options={sortOptions}
            placeholder="Sort by"
            defaultValue={currentSortValue}
            onSelect={onSortChange}
            enableSearch={false}
            size="medium"
            defaultIcon={<Icon name="ArrowDownUp" size="sm" color="text" />}
          />
          <button
            onClick={onDirectionToggle}
            className="ml-2 p-2 border border-neutral rounded-md bg-white"
          >
            {sortDirection === 'asc' ? (
              <Icon name="ArrowUp" size="sm" color="text" />
            ) : (
              <Icon name="ArrowDown" size="sm" color="text" />
            )}
          </button>
        </div>
      )}
    </div>

    {/* Right Section: Buttons */}
    <div className="flex items-center space-x-4 flex-shrink-0">
      {onShowArchive && (
        <Button
          label=""
          onClick={onShowArchive}
          variant="secondary"
          buttonStyle={showArchived ? "filled" : "outlined"}
          size="small"
          icon="Archive"
          iconSize="sm"
          iconColor='text'
          rounded={true}
          title={showArchived ? "Hide Archived" : "Show Archived"}
        />
      )}

      {onShowComplete && (
        <Button
          label=""
          onClick={onShowComplete}
          variant="secondary"
          buttonStyle={showCompleted ? "filled" : "outlined"}
          size="small"
          icon="CheckCircle"
          iconSize="sm"
          iconColor='text'
          rounded={true}
          title={showCompleted ? "Hide Completed" : "Show Completed"}
        />
      )}

      {additionalButtons}

      <Button
        label={addButtonLabel}
        onClick={onAddNew}
        variant="primary"
        buttonStyle="filled"
        size="medium"
        icon="Plus"
        iconColor="white"
        iconSize="sm"
      />
    </div>
  </div>
</div>

  );
};
