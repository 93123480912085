import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Button, TextInput, Datepicker, Steps, ProgressIndicator } from '../ui';
import ProjectSelector from '../project/ProjectSelector';
import PersonSelectMulti from '../person/PersonSelectMulti';
import { Project, CreateProjectsSnapshot } from '../../api/project/types';
import { Person } from '../../api/person/types';
import { useCreateSnapshot } from '../../api/project/queries';
import { LogEntry } from '../../api/logEntry/types';

const steps = [
  { 
    id: 1, 
    title: 'Basic Info', 
    description: 'Enter snapshot details' 
  },
  { 
    id: 2, 
    title: 'Select Projects', 
    description: 'Choose projects to include' 
  },
  { 
    id: 3, 
    title: 'Review & Send', 
    description: 'Review and create snapshot' 
  }
];

const CreateSnapshotPage: React.FC = () => {
  const navigate = useNavigate();
  const createSnapshotMutation = useCreateSnapshot();

  // State
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<Partial<CreateProjectsSnapshot>>({
    title: '',
    description: '',
    period_start: '',
    period_end: '',
    project_ids: [],
    person_ids: [],
    notification_recipient_ids: [],
    visibility: 'private'
  });

  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<Person[]>([]);
  const [selectedLogEntries, setSelectedLogEntries] = useState<Record<string, LogEntry[]>>({});

  const [touched, setTouched] = useState({
    title: false,
    period_start: false,
    period_end: false,
  });

  // Fetch projects (dummy implementation, replace with actual fetch logic)
  useEffect(() => {
    async function fetchProjects() {
      const response = await fetch('/api/projects');
      const data = await response.json();
      setProjects(data);
    }
    fetchProjects();
  }, []);

  // Handlers
  const handleNext = () => {
    setTouched({
      title: true,
      period_start: true,
      period_end: true,
    });
  
    if (validateCurrentStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleCreate = async () => {
    try {
      const snapshotData: CreateProjectsSnapshot = {
        ...formData as CreateProjectsSnapshot,
        project_ids: selectedProjects.map(p => p.id),
        person_ids: selectedPersons.map(p => p.id),
        notification_recipient_ids: selectedRecipients.map(p => p.id),
        // Add selected log entries
        log_entries: Object.entries(selectedLogEntries).flatMap(([projectId, entries]) => 
          entries.map(entry => ({
            project_id: projectId,
            log_entry_id: entry.id
          }))
        )
      };
  
      await createSnapshotMutation.mutateAsync(snapshotData);
      navigate('/snapshots');
    } catch (error) {
      // Handle error
    }
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return formData.title && formData.period_start && formData.period_end;
      case 2:
        return selectedProjects.length > 0;
      default:
        return true;
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Card>
            <CardContent className="space-y-4">
              <TextInput
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                placeholder="Enter snapshot title"
              />

              <TextInput
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Enter description"
              />

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Datepicker
                    value={formData.period_start}
                    onChange={(date) => {
                      setFormData({ ...formData, period_start: date });
                      setTouched((prev) => ({ ...prev, period_start: true }));
                    }}
                    placeholder="Period Start"
                    error={touched.period_start && !formData.period_start ? 'Required' : ''}
                  />
                </div>
                <div>
                  <Datepicker
                    value={formData.period_end}
                    onChange={(date) => {
                      setFormData({ ...formData, period_end: date });
                      setTouched((prev) => ({ ...prev, period_end: true }));
                    }}
                    placeholder="Period End"
                    error={touched.period_end && !formData.period_end ? 'Required' : ''}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardContent className="space-y-4">
              <div>
                <ProjectSelector
                    startDate={formData.period_start}
                    endDate={formData.period_end}
                    selectedProjects={selectedProjects}
                    selectedLogEntries={selectedLogEntries}
                    onChange={setSelectedProjects}
                    onLogEntriesChange={(projectId, logEntries) => {
                        setSelectedLogEntries(prev => ({
                        ...prev,
                        [projectId]: logEntries
                        }));
                    }}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Select Participants
                </label>
                <PersonSelectMulti
                  value={selectedPersons.map(person => person.id)} 
                  onChange={setSelectedPersons}
                  placeholder="Select Participants"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Select Recipients
                </label>
                <PersonSelectMulti
                  value={selectedRecipients.map(person => person.id)} 
                  onChange={setSelectedRecipients}
                  placeholder="Select Recipients"
                />
              </div>
            </CardContent>
          </Card>
        );

      case 3:
        return (
          <Card>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <h3 className="text-lg font-medium">Summary</h3>
                <div>
                  <p className="text-sm text-gray-500">Title</p>
                  <p>{formData.title}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Period</p>
                  <p>{formData.period_start} - {formData.period_end}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Selected Projects</p>
                  <p>{selectedProjects.length} projects selected</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Participants</p>
                  <p>{selectedPersons.length} participants selected</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Recipients</p>
                  <p>{selectedRecipients.length} recipients selected</p>
                </div>
              </div>
            </CardContent>
          </Card>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Create Snapshot</h1>
        <Button
          label="Cancel"
          variant="secondary"
          buttonStyle="outlined"
          onClick={() => navigate('/snapshots')}
        />
      </div>

      <Steps
        steps={steps}
        currentStep={currentStep - 1}
        variant="horizontal"
      />

      <ProgressIndicator
        steps={steps.map(s => s.title)}
        currentStep={currentStep - 1}
        variant="bars"
      />

      <div className="my-8">
        {renderStepContent()}
      </div>

      <div className="flex justify-between mt-6">
        {currentStep > 1 && (
          <Button
            label="Back"
            variant="secondary"
            buttonStyle="outlined"
            onClick={handleBack}
          />
        )}
        {currentStep < steps.length ? (
          <Button
            label="Next"
            variant="primary"
            buttonStyle="filled"
            onClick={handleNext}
            disabled={!validateCurrentStep()}
          />
        ) : (
          <Button
            label="Create Snapshot"
            variant="primary"
            buttonStyle="filled"
            onClick={handleCreate}
          />
        )}
      </div>
    </div>
  );
};

export default CreateSnapshotPage;
