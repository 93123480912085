import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Card, CardContent, CardHeader, CardTitle, IconName } from '../ui';
import FavoriteContainer from '../../containers/FavoriteContainer';
import { groupBy } from 'lodash';

const typeToIcon: Record<string, { name: IconName; color: string }> = {
    topic: { name: 'Library', color: 'text-primary' },
    task: { name: 'ListChecks', color: 'text-primary' },
    project: { name: 'Layers', color: 'text-secondary' },
    person: { name: 'Users', color: 'text-accent' },
    strategy: { name: 'Flag', color: 'text-secondary' },
    goal: { name: 'Goal', color: 'text-primary' }
};

const typeToTitle: Record<string, string> = {
    topic: 'Topics',
    accountability_dial_task: 'Tasks',
    project: 'Projects',
    person: 'People',
    strategy: 'Strategies',
    goal: 'Goals'
};

const contentTypeMapping: Record<string, string> = {
    topic: 'topic.topic',
    task: 'accountability_dial.task',
    project: 'project.project',
    person: 'organization.person',
    strategy: 'strategy.strategy',
    goal: 'goals.goal'
};

const getSlugPath = (type: string): string => {
  const paths: Record<string, string> = {
    'topic.topic': 'topics',
    'accountability_dial.task': 'tasks',
    'project.project': 'projects',
    'organization.person': 'persons',
    'strategy.strategy': 'strategies',
    'goals.goal': 'goals'
  };
  return paths[type] || '';
};

export const FavoritesPane = () => {
  const navigate = useNavigate();

  const handleItemClick = (type: string, slug: string) => {
    const fullType = contentTypeMapping[type.toLowerCase()];
    const slugPath = getSlugPath(fullType);
    navigate(`/${slugPath}/${slug}`);
  };

  return (
    <Card className="bg-white w-64 !p-0 !shadow-none !border-0">
      <CardHeader className="!px-4 !py-2">
        <div className="flex items-center mb-2 mt-2">
          <CardTitle className="text-md font-medium">
            <span>Favorites</span>
          </CardTitle>
        </div>
      </CardHeader>

      <CardContent className="!px-4 !py-2">
        <FavoriteContainer
          render={({ data: favorites, onToggle }) => {
            const groupedFavorites = groupBy(favorites, 'content_type_name');
            
            return (
              <div className="mt-2">
                {Object.entries(groupedFavorites).map(([type, items]) => (
                  <div key={type} className="mb-6">
                    <div className="text-sm font-medium text-text mb-2 flex items-center gap-2">
                      <Icon 
                        name={typeToIcon[type.toLowerCase()]?.name || 'Star'} 
                        size="sm"
                        className={typeToIcon[type.toLowerCase()]?.color || 'text-primary'}
                      />
                      {typeToTitle[type.toLowerCase()] || type}
                    </div>
                    {items.map((favorite, index) => (
                      <div 
                        key={favorite.id}
                        className={`
                          flex items-center justify-between text-xs rounded px-2 py-2 group mb-2
                          border-l-2 border-l-primary/40
                          hover:border-l-primary hover:bg-background/30
                          transition-all duration-200
                        `}
                      >
                        <div 
                          className="flex items-center gap-2 flex-1 cursor-pointer"
                          onClick={() => handleItemClick(type, favorite.content_slug)}
                        >
                          <span className="truncate">{favorite.content_title}</span>
                        </div>
                        <button
                          onClick={() => onToggle(contentTypeMapping[type.toLowerCase()], favorite.object_id)}
                          className="opacity-0 group-hover:opacity-100 p-1 hover:bg-background rounded-md transition-opacity"
                          title="Remove from favorites"
                        >
                          <Icon name="X" size="sm" />
                        </button>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            );
          }}
        />
      </CardContent>
    </Card>
  );
};

export default FavoritesPane;