// TabContainer.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { Icon } from '../ui/base/Icon';

export interface Tab {
  id: string;
  title: string;
  isActive: boolean;
  path: string;
  search: string;
  state?: Record<string, any>;
}

interface TabContainerProps {
  children: React.ReactNode;
}

const MAX_SLOTS = 5;

export const TabContainer: React.FC<TabContainerProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const loadSavedTabs = (): (Tab | null)[] => {
    try {
      const savedTabs = localStorage.getItem('align-tabs');
      if (savedTabs) {
        const parsedTabs = JSON.parse(savedTabs) as Tab[];
        const truncated = parsedTabs.slice(0, MAX_SLOTS);

        if (!truncated.some(tab => tab.isActive) && truncated.length > 0) {
          truncated[truncated.length - 1].isActive = true;
        }

        const filled: (Tab | null)[] = [...truncated];
        while (filled.length < MAX_SLOTS) {
          filled.push(null);
        }
        return filled;
      }
    } catch (error) {
      console.error('Error loading saved tabs:', error);
    }

    const defaultTabs: (Tab | null)[] = [
      {
        id: '1',
        title: 'Home',
        isActive: true,
        path: location.pathname,
        search: location.search,
      },
    ];
    while (defaultTabs.length < MAX_SLOTS) {
      defaultTabs.push(null);
    }
    return defaultTabs;
  };

  const [slots, setSlots] = useState<(Tab | null)[]>(loadSavedTabs());

  useEffect(() => {
    const nonNullTabs = slots.filter((slot): slot is Tab => slot !== null);
    localStorage.setItem('align-tabs', JSON.stringify(nonNullTabs));
  }, [slots]);

  useEffect(() => {
    const activeTab = slots.find(slot => slot?.isActive);
    if (activeTab) {
      navigate(activeTab.path + activeTab.search);
    }
  }, []); // Initial navigation

  useEffect(() => {
    setSlots(currentSlots => {
      const activeTab = currentSlots.find(slot => slot?.isActive);
      if (activeTab && (activeTab.path !== location.pathname || activeTab.search !== location.search)) {
        return currentSlots.map(slot => {
          if (slot?.id === activeTab.id) {
            return { ...slot, path: location.pathname, search: location.search };
          }
          return slot;
        });
      }
      return currentSlots;
    });
  }, [location.pathname, location.search]);

  const activateTab = (index: number) => {
    const tabToActivate = slots[index];
    if (!tabToActivate) return;

    const newSlots = slots.map((slot, i) =>
      slot ? { ...slot, isActive: i === index } : null
    );

    setSlots(newSlots);
    navigate(tabToActivate.path + tabToActivate.search);
  };

  const closeTab = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    if (index === 0) return;

    const tabToClose = slots[index];
    if (!tabToClose) return;

    const isActiveTab = tabToClose.isActive;
    let newSlots = [...slots];
    newSlots[index] = null;

    if (isActiveTab) {
      const remainingTabs = newSlots.filter(slot => slot !== null);
      if (remainingTabs.length > 0) {
        const lastIndex = newSlots.lastIndexOf(remainingTabs[remainingTabs.length - 1]);
        if (lastIndex !== -1 && newSlots[lastIndex]) {
          newSlots = newSlots.map((slot, i) => slot ? { ...slot, isActive: i === lastIndex } : null);
          const newActive = newSlots[lastIndex];
          if (newActive) navigate(newActive.path + newActive.search);
        }
      }
    }

    setSlots(newSlots);
  };

  const addTab = (index: number) => {
    if (slots[index] !== null) return;

    const updatedSlots = slots.map(slot => slot ? { ...slot, isActive: false } : null);

    const newTab: Tab = {
      id: crypto.randomUUID(),
      title: 'Home',
      isActive: true,
      path: '/',
      search: '',
    };

    updatedSlots[index] = newTab;
    setSlots(updatedSlots);
    navigate('/');
  };

  const getTabTitle = (tab: Tab) => {
    if (tab.path === '/') return 'Home';
    const pathParts = tab.path.split('/').filter(Boolean);
    return pathParts[pathParts.length - 1] || 'Home';
  };

  const firstEmptySlotIndex = slots.findIndex(slot => slot === null);

  return (
    <div className="flex flex-col flex-1 h-full bg-gray-800">
      <div className="flex items-center flex-shrink-0 gap-4 pt-3 pb-0 pl-8 bg-gray-800">
        {slots.map((slot, i) => {
          const isActive = slot?.isActive ?? false;
          const isFirstEmpty = i === firstEmptySlotIndex && slot === null;

          const baseClasses = `
            group rounded-md border transition-colors
            h-8 w-32 flex items-center
            text-sm
            ${slot 
              ? 'justify-between px-2' 
              : 'justify-center px-2'}
            ${isActive 
              ? 'bg-blue-50 text-blue-600 border-blue-300' 
              : slot 
                ? 'text-gray-200 border-gray-600 hover:bg-gray-700' 
                : isFirstEmpty 
                  ? 'text-gray-400 border-gray-500 hover:text-blue-400 hover:bg-gray-700' 
                  : 'text-gray-400 border-gray-500 opacity-50 pointer-events-none'}
          `;

          if (slot) {
            return (
              <div
                key={slot.id}
                onClick={() => activateTab(i)}
                className={baseClasses}
              >
                <span
                    className={`font-medium whitespace-nowrap overflow-hidden text-ellipsis ${
                        isActive ? 'text-text' : 'text-gray-200'
                    }`}
                    >
                    {getTabTitle(slot)}
                </span>
                {i !== 0 && (
                  <button
                    onClick={(e) => closeTab(i, e)}
                    className={`
                      p-1 rounded-full flex-shrink-0
                      ${isActive
                        ? 'hover:bg-blue-100 text-blue-400 hover:text-blue-600'
                        : 'hover:bg-gray-500 text-gray-300 hover:text-gray-100'
                      }
                    `}
                  >
                    <Icon name="X" size="sm" />
                  </button>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={i}
                onClick={isFirstEmpty ? () => addTab(i) : undefined}
                className={baseClasses}
                title={isFirstEmpty ? "New Tab" : ""}
              >
                {isFirstEmpty && <Plus className="w-4 h-4" />}
              </div>
            );
          }
        })}
      </div>

      <div className="flex-1 flex flex-col min-h-0 overflow-auto">
        {children}
      </div>
    </div>
  );
};
