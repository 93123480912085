import React, { useState } from 'react';
import { Tooltip } from '../../';
import { SelectMulti, SelectOption } from '../../SelectMulti'; // Adjust the import path as necessary
import TagContainer from '../../../../containers/TagContainer'; // Adjust the import path as necessary
import { Tag } from '../../../../api/tag/types'; // Import the correct Tag type

interface TableTagsComponentProps {
  tags: Tag[];
  limit?: number;
  isEditable?: boolean;
  onUpdate?: (newTags: Tag[]) => void;
}

export const TableTagsComponent: React.FC<TableTagsComponentProps> = ({ 
  tags, 
  limit = 3, 
  isEditable = false,
  onUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempSelectedTags, setTempSelectedTags] = useState<Tag[]>(tags);

  const visibleTags = tags.slice(0, limit);
  const hiddenTags = tags.slice(limit);

  const handleClick = () => {
    if (isEditable) {
      setIsEditing(true);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    if (onUpdate && JSON.stringify(tempSelectedTags) !== JSON.stringify(tags)) {
      onUpdate(tempSelectedTags);
    }
  };

  const handleSelect = (selectedTagIds: string[], allTags: Tag[]) => {
    const selectedTags = allTags.filter(tag => selectedTagIds.includes(tag.id));
    setTempSelectedTags(selectedTags);
  };

  return (
    <TagContainer
      render={({ data: allTags, loading, error }) => {
        if (loading) return <span>Loading tags...</span>;
        if (error) return <span>Error loading tags</span>;

        const tagOptions: SelectOption[] = allTags.map(tag => ({
          value: tag.id,
          label: tag.name,
          icon: (
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: tag.color }}
              title={tag.name}
            />
          ),
        }));

        const renderTagsOrPlaceholder = () => {
          if (tags.length === 0) {
            return <span className="text-xs text-gray-500">No tags</span>;
          }
          return (
            <div className="flex flex-wrap items-center gap-y-1 space-x-2">
              {visibleTags.map((tag) => (
                <span
                  key={tag.id}
                  className="px-2 py-1 text-xs text-white rounded-full flex items-center justify-center mb-1"
                  style={{
                    backgroundColor: tag.color,
                    minWidth: '60px',
                    height: '24px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {tag.name}
                </span>
              ))}
              {hiddenTags.length > 0 && (
                <Tooltip
                  content={hiddenTags.map((tag) => tag.name).join(', ')}
                  position="top"
                >
                  <span className="text-sm text-gray-500 cursor-pointer">+{hiddenTags.length} more...</span>
                </Tooltip>
              )}
            </div>
          );
        };

        return (
          <div className="relative inline-block">
            {isEditable && isEditing ? (
              <SelectMulti
                options={tagOptions}
                defaultValues={tempSelectedTags.map(tag => tag.id)}
                onSelect={(selectedIds) => handleSelect(selectedIds, allTags)}
                isOpen={true}
                onClose={handleClose}
                enableSearch={true}
                placeholder="Select tags"
              />
            ) : (
              <div 
                onClick={handleClick} 
                className={isEditable ? 'cursor-pointer hover:bg-gray-100 p-1 rounded' : ''}
              >
                {renderTagsOrPlaceholder()}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
