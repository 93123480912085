import React, { useState } from 'react';
import { Avatar, Icon } from '../../'; // Import Avatar and Icon
import PersonSelect from '../../../person/PersonSelect'; // Adjust the path accordingly
import { Person } from '../../../../api/person/types'; // Adjust the path accordingly
import { PersonModal } from '../../../person/PersonModal'; // Import the PersonModal component

interface TablePersonComponentProps {
  person: Person | null;
  isEditable?: boolean;
  onUpdate?: (newPerson: Person | null) => void;
}

export const TablePersonComponent: React.FC<TablePersonComponentProps> = ({ 
  person, 
  isEditable = false,
  onUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isPersonModalOpen, setPersonModalOpen] = useState(false); // Modal state

  // Ensure that modal opens only when clicking the avatar
  const handlePersonClick = () => {
    if (person) {
      setPersonModalOpen(true); // Open modal only if a person exists
    }
  };

  const handleModalClose = () => {
    setPersonModalOpen(false); // Close the modal when done
  };

  // Handle the edit icon click to open inline editing (PersonSelect)
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent modal opening on icon click
    if (isEditable) {
      setIsEditing(true);
    }
  };

  const handleClose = () => {
    setIsEditing(false); // Close editing mode
  };

  const handleSelect = (selectedPerson: Person) => {
    if (onUpdate) {
      onUpdate(selectedPerson); // Update the person on selection
    }
    setIsEditing(false); // Close editing mode after selection
  };

  return (
    <>
      <div className={`flex items-center space-x-2 ${isEditable ? 'cursor-pointer hover:bg-gray-100 p-1 rounded' : ''}`}>
        {/* Clicking on the avatar opens the PersonModal */}
        {person ? (
          <>
            <span onClick={handlePersonClick} className="cursor-pointer">
              <Avatar name={person.name} size="sm" />
            </span>
            <span className="text-sm text-text">{person.name}</span>
          </>
        ) : (
          <span className="text-sm text-gray-500">No person assigned</span>
        )}

        {/* Edit icon for editable mode */}
        {isEditable && (
          <span onClick={handleClick} className="cursor-pointer">
            <Icon name="Edit" size="sm" className='text-gray-400 hover:text-gray-600' />
          </span>
        )}
      </div>

      {/* Inline editing mode using PersonSelect */}
      {isEditable && isEditing && (
        <PersonSelect
          value={person?.id || ''}
          onChange={handleSelect}
          onClose={handleClose}
          isOpen={true}
          placeholder="Select a person"
        />
      )}

      {/* PersonModal should only be rendered when opened by clicking on avatar */}
      {isPersonModalOpen && person && (
        <PersonModal
          isOpen={isPersonModalOpen}
          onClose={handleModalClose}
          initialData={person}  // Pass the selected person's data to the modal
          onSuccess={(message) => console.log(message)}
          onError={(error) => console.log(error)}
        />
      )}
    </>
  );
};
