import { GraphData, GraphFilters } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchGraphData = async (
  filters: GraphFilters,
  navigate: (path: string) => void
): Promise<GraphData> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const queryParams = new URLSearchParams({ company_id });

  // Debug log
  console.log('Received filters:', filters);

  // Add filters to query params, including tagId if present
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      // Convert tagId to tag_id for backend compatibility
      const paramKey = key === 'tagId' ? 'tagId' : key;
      queryParams.append(paramKey, value.toString());
    }
  });

  // Debug log
  console.log('Final URL:', `${API_URL}graph/?${queryParams.toString()}`);

  const response = await fetchWithAuth(
    `${API_URL}graph/?${queryParams.toString()}`,
    {},
    navigate
  );

  if (!response.ok) throw new Error('Failed to fetch graph data');
  return response.json();
};