import { useState } from 'react';

interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  labelPosition?: 'left' | 'right';
}

export function ToggleSwitch({
  checked = false,
  onChange,
  label,
  disabled = false,
  error = false,
  labelPosition = 'left',
}: ToggleSwitchProps) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    if (!disabled) {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      if (onChange) {
        onChange(newChecked);
      }
    }
  };

  return (
    <div className="flex items-center">
      {label && labelPosition === 'left' && (
        <span className={`mr-2 text-sm ${error ? 'text-red-600' : 'text-text'}`}>{label}</span>
      )}
      <button
        type="button"
        onClick={handleToggle}
        disabled={disabled}
        className={`relative inline-flex items-center h-5 w-10 rounded-full transition-colors duration-150 ease-in-out focus:outline-none ${
          disabled ? 'bg-neutral cursor-not-allowed' : isChecked ? 'bg-primary' : error ? 'bg-red-600' : 'bg-neutral'
        }`}
      >
        <span
          className={`${
            isChecked ? 'translate-x-[18px]' : 'translate-x-[2px]'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-150 ease-in-out`}
        />
      </button>
      {label && labelPosition === 'right' && (
        <span className={`ml-2 text-sm ${error ? 'text-red-600' : 'text-text'}`}>{label}</span>
      )}
    </div>
  );
}
