import { useQuery } from 'react-query';
import { fetchPersonById } from '../api/person/api';
import { useNavigate } from 'react-router-dom';

export const usePerson = (personId: string | null) => {
  const navigate = useNavigate();
  
  return useQuery(
    ['person', personId],
    () => personId ? fetchPersonById(personId, navigate) : Promise.reject('No person ID'),
    {
      enabled: !!personId,
      staleTime: 5 * 60 * 1000,
    }
  );
};