import React, { useEffect, useState } from 'react';
import { useMeetingTypes, useCreateMeetingType, useEditMeetingType, useDeleteMeetingType } from '../api/meeting/queries';
import { MeetingType } from '../api/meeting/types';

interface MeetingTypeContainerProps {
  fetchOnMount?: boolean; // Optional prop to control whether to fetch Meeting Types on mount
  render: (props: {
    data: MeetingType[];
    loading: boolean;
    error: string | null;
    onCreate: (newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedMeetingType: Partial<MeetingType>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const MeetingTypeContainer: React.FC<MeetingTypeContainerProps> = ({ fetchOnMount = true, render }) => {
  const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);

  // Fetch meeting types using the useMeetingTypes hook
  const { data, isLoading, isError } = useMeetingTypes();

  // Mutations for create, edit, and delete operations
  const createMutation = useCreateMeetingType();
  const editMutation = useEditMeetingType();
  const deleteMutation = useDeleteMeetingType();

  // Handle data fetching
  useEffect(() => {
    if (fetchOnMount) {
      setLoading(isLoading);
      if (data) setMeetingTypes(data);
      if (isError) setError('Failed to fetch Meeting Types');
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create new meeting type
  const handleCreate = (newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
    createMutation.mutate(newMeetingType, {
      onSuccess: (created) => setMeetingTypes((prev) => [...prev, created]),
      onError: () => setError('Failed to create Meeting Type'),
    });
  };

  // Edit existing meeting type using slug
  const handleEdit = (slug: string, updatedMeetingType: Partial<MeetingType>) => {
    editMutation.mutate({ slug, updatedMeetingType }, {
      onSuccess: (edited) => setMeetingTypes((prev) => prev.map((mt) => (mt.slug === slug ? edited : mt))),
      onError: () => setError('Failed to edit Meeting Type'),
    });
  };

  // Delete meeting type using slug
  const handleDelete = (slug: string) => {
    deleteMutation.mutate(slug, {
      onSuccess: () => setMeetingTypes((prev) => prev.filter((mt) => mt.slug !== slug)),
      onError: () => setError('Failed to delete Meeting Type'),
    });
  };

  return render({
    data: meetingTypes,
    loading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default MeetingTypeContainer;
