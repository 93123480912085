import React, { useState, useEffect, useCallback } from 'react';
import { ModalContent, SeparatorHorizontal } from '../ui';
import TagSelect from '../tags/TagSelect';
import { Team, TeamMembership } from '../../api/team/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import PersonPicker from '../person/PersonPicker';
import TeamSelect from './TeamSelect';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';

interface TeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (teamData: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>, slug?: string) => void;
  onEdit: (slug: string, updatedTeam: Partial<Team>) => void;
  onDelete?: (slug: string) => void;
  initialData?: Omit<Team, 'createdAt' | 'updatedAt'>;
}

export const TeamModal: React.FC<TeamModalProps> = ({
  isOpen,
  onClose,
  onSave,
  onEdit,
  onDelete,
  initialData,
}) => {
  const [teamData, setTeamData] = useState<Partial<Team>>({
    name: '',
    description: '',
    tags: [],
    company: '',
    parent: null,
  });

  const [selectedMembers, setSelectedMembers] = useState<Person[]>([]);

  useEffect(() => {
    if (initialData) {
      setTeamData(initialData);
      
      if (initialData.members && initialData.members[0]?.person) {
        // Make sure we're getting the IDs from the person objects
        const memberPersons = initialData.members.map(member => member.person);
        setSelectedMembers(memberPersons);
      }
    } else {
      setTeamData({
        name: '',
        description: '',
        tags: [],
        company: '',
        parent: null,
      });
      setSelectedMembers([]);
    }
  }, [initialData]);

  const handleChange = useCallback(
    (field: keyof Team, value: any) => {
      setTeamData((prevData) => ({ ...prevData, [field]: value }));
  
      // Trigger an immediate update for tags and parent fields
      if (field === 'tags' || field === 'parent') {
        const updatedTeam = { [field]: value } as Partial<Team>;
        if (initialData?.slug) {
          onEdit(initialData.slug, updatedTeam);
        }
      }
  
      if (field === 'members') {
        setSelectedMembers(value as Person[]);
      }
    },
    [initialData?.slug, onEdit]
  );
  

  const handleSave = () => {
    if (!teamData.name) {
      return alert('Please provide a team name');
    }
  
    const memberIds = selectedMembers.map(person => {
      // Handle both cases where person is a Person object or a member object
      if ('person' in person) {
        return (person as any).person.id;
      }
      return person.id;
    });
  
    const teamDataToSave = {
      ...teamData,
      member_ids: memberIds,
    };
    onSave(teamDataToSave as Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>, initialData?.slug);
    onClose();
  };

  const handleDelete = () => {
    if (initialData?.slug && onDelete) {
      onDelete(initialData.slug);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <ModalContent
      title={teamData.name || ''}
      description={teamData.description || ''}
      onCancel={onClose}
      onCreate={handleSave}
      onDelete={initialData ? handleDelete : undefined}
      onTitleChange={(newName) => setTeamData((prevData) => ({ ...prevData, name: newName }))}
      onDescriptionChange={(newDescription) => 
        setTeamData((prevData) => ({ ...prevData, description: newDescription }))
      }
      actionButtonLabel={initialData ? 'Save Changes' : 'Create Team'}
      showFooterButtons={true}
      selects={[
        {
          component: TeamSelect,
          props: {
            value: typeof teamData.parent === 'string' 
              ? teamData.parent 
              : teamData.parent?.id || '',
            onChange: (selectedTeam: Team) => handleChange('parent', selectedTeam),
            enableSearch: true,
            placeholder: 'Select Parent Team (optional)',
            isDisabled: false,
            currentTeamId: initialData?.id 
          },
        },
        {
          component: TagSelect,
          props: {
            value: teamData.tags?.map((tag) => tag.id) || [],
            onChange: (newTags: Tag[]) => handleChange('tags', newTags),
            enableSearch: true,
            placeholder: 'Select Tags',
          },
        }
      ]}
      isSidePanelInitiallyCollapsed={true}
      activityFeed={initialData ? 
        <LogEntryActivityFeed objectId={initialData.id} objectType='team' /> : null}
      showActivityFeed={!!initialData}
      showDescription={true}
      descriptionSize='small'
      descriptionPlaceholder='Team Description'
    >
      {/* Move PersonPicker here as a child */}
        <SeparatorHorizontal />
      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Team Members</h3>
        <PersonPicker
          initialSelected={selectedMembers}
          onSelect={(newMembers: Person[]) => handleChange('members', newMembers)}
          maxSelect={undefined}
        />
      </div>
    </ModalContent>
  );
};

export default TeamModal;