import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useValidateCSVPersons } from '../../api/person/queries';
import { Button, Table, Snackbar, Icon, Card, CardContent, Steps, ProgressIndicator, Tooltip } from '../ui';
import { TableCheckboxComponent } from '../ui/data/table-components/TableCheckboxComponent';
import PersonContainer from '../../containers/PersonContainer';
import { Person } from '../../api/person/types';

const steps = [
  {
    id: 1,
    title: 'Upload',
    description: 'Upload CSV file'
  },
  {
    id: 2,
    title: 'Review',
    description: 'Review and validate data'
  },
  {
    id: 3,
    title: 'Import',
    description: 'Confirm and import'
  }
];

type PersonCreationStatus = 'pending' | 'creating' | 'success' | 'error';

interface PersonWithStatus {
    data: Record<string, any>;
    status: PersonCreationStatus;
    error?: string;
  }

const PersonImportPage = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [csvData, setCSVData] = useState<Array<Record<string, any>>>([]);
  const [preview, setPreview] = useState<{
    new_persons: Array<Record<string, any>>;
    duplicates: Array<Record<string, any>>;
    errors: string[];
  } | null>(null);
  const [showSnackbar, setShowSnackbar] = useState<{
    show: boolean;
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
  }>({ show: false, message: '', type: 'info' });
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [creationStatuses, setCreationStatuses] = useState<Record<string, PersonWithStatus>>({});
  const [isValidating, setIsValidating] = useState(false);

  const validateMutation = useValidateCSVPersons();

  const tableColumns = [
    {
      header: 'To Import',
      accessor: 'select',
      component: (row: any) => (
        <TableCheckboxComponent
          isChecked={selectedRows[row.email] ?? true} // Default to true for new rows
          onChange={(checked) => handleRowSelection(row.email, checked)}
          label=""
        />
      )
    },
    { header: 'Name', accessor: 'name', sortable: true },
    { header: 'Email', accessor: 'email', sortable: true },
  ];

  const handleRowSelection = (email: string, checked: boolean) => {
    setSelectedRows(prev => ({
      ...prev,
      [email]: checked
    }));
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;
  
    if (!selectedFile.name.endsWith('.csv')) {
      setShowSnackbar({
        show: true,
        message: 'Please select a valid CSV file',
        type: 'error'
      });
      return;
    }
  
    setFile(selectedFile);
    
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result as string;
      const rows = text.split('\n').map(row => {
        const values = row.split(',');
        return values.reduce((acc, val, index) => {
          acc[`column${index}`] = val.trim();
          return acc;
        }, {} as Record<string, string>);
      });
      
      setCSVData(rows);
    };
    reader.readAsText(selectedFile);
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const createSinglePerson = async (person: Record<string, any>, onCreate: (person: any) => void) => {
    try {
      setCreationStatuses(prev => ({
        ...prev,
        [person.email]: { data: person, status: 'creating' }
      }));
  
      await onCreate({
        name: person.name,
        email: person.email,
        person_type: person.person_type || 'other'
      });
  
      setCreationStatuses(prev => ({
        ...prev,
        [person.email]: { data: person, status: 'success' }
      }));
    } catch (error) {
      setCreationStatuses(prev => ({
        ...prev,
        [person.email]: { 
          data: person, 
          status: 'error', 
          error: error instanceof Error ? error.message : 'Failed to create person'
        }
      }));
    }
  };
  
  // Update handleConfirmImport
  const handleConfirmImport = async (onCreate: (person: any) => void) => {
    if (!preview?.new_persons) return;
    
    const selectedPersons = preview.new_persons.filter(
      person => selectedRows[person.email]
    );
    
    const initialStatuses = selectedPersons.reduce((acc, person) => {
      acc[person.email] = { data: person, status: 'pending' };
      return acc;
    }, {} as Record<string, PersonWithStatus>);
    
    setCreationStatuses(initialStatuses);
  
    // Create persons sequentially
    for (const person of selectedPersons) {
      await createSinglePerson(person, onCreate);
    }
  };

  const handleNext = async () => {
    if (currentStep === 1) {
      setIsValidating(true);
      try {
        const result = await validateMutation.mutateAsync(csvData);
        setPreview(result);
        
        // Initialize selectedRows
        setSelectedRows(
          result.new_persons.reduce((acc: Record<string, boolean>, person: Record<string, any>) => {
            if (person.email) {
              acc[person.email] = true;
            }
            return acc;
          }, {})
        );
  
        if (result.duplicates.length > 0) {
          setShowSnackbar({
            show: true,
            message: `Found ${result.duplicates.length} duplicate entries`,
            type: 'warning'
          });
        }
        setCurrentStep(2);
      } catch (error) {
        setShowSnackbar({
          show: true,
          message: 'Failed to validate CSV data',
          type: 'error'
        });
      } finally {
        setIsValidating(false);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
        case 1:
            return (
              <Card>
                <CardContent className="space-y-6">
                  {/* Info Section */}
                  <div className="space-y-4">
                    <h3 className="text-lg font-medium">Upload a CSV file to import persons</h3>
                    
                    <div className="bg-blue-50 p-4 rounded-lg">
                      <div className="flex items-start space-x-3">
                        <Icon name="Info" className="mt-0.5" size="sm" color="primary" />
                        <div className="space-y-2">
                          <p className="text-sm text-gray-700">
                            You can import multiple persons at once using a CSV file. The file should contain:
                          </p>
                          <ul className="text-sm text-gray-700 list-disc pl-4 space-y-1">
                            <li><span className="font-medium">Required:</span> Names and email addresses</li>
                            <li><span className="font-medium">Optional:</span> Roles, titles, or any additional information</li>
                          </ul>
                          <div className="mt-3">
                            <p className="text-sm text-gray-700 font-medium">Example CSV format:</p>
                            <code className="text-xs bg-white p-2 rounded border mt-1 block">
                              name,email<br/>
                              John Smith,john@example.com<br/>
                              Jane Doe,jane@example.com
                            </code>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          
                  {/* Upload Section */}
                  <div
                    className="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center space-y-4"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const droppedFile = e.dataTransfer.files[0];
                      if (droppedFile && droppedFile.name.endsWith('.csv')) {
                        const mockEvent = {
                          target: { files: [droppedFile] },
                        } as unknown as React.ChangeEvent<HTMLInputElement>;
                        handleFileSelect(mockEvent);
                      } else {
                        setShowSnackbar({
                          show: true,
                          message: 'Please drop a valid CSV file',
                          type: 'error',
                        });
                      }
                    }}
                  >
                    {isValidating && (
                      <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-10">
                        <div className="flex items-center space-x-2">
                          <Icon name="Loader" className="animate-spin" size="sm" color="primary" />
                          <span className="text-sm text-gray-500">Validating CSV data...</span>
                        </div>
                      </div>
                    )}
                    
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={handleFileSelect}
                      className="hidden"
                      accept=".csv"
                      id="csv-upload"
                    />
                
                    {!file ? (
                      <>
                        <Icon name="Upload" size="lg" color="primary" />
                        <div className="text-center">
                          <Button
                            label="Select CSV File"
                            variant="primary"
                            buttonStyle="outlined"
                            icon="Upload"
                            iconPosition="left"
                            onClick={triggerFileInput}
                            title="Select a CSV file to import persons"
                          />
                          <p className="mt-2 text-sm text-gray-500">or drag and drop your file here</p>
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center space-x-2">
                          <Icon name="CheckCircle" size="sm" color="accent" />
                          <span className="text-sm truncate">{file.name}</span>
                        </div>
                        <Button
                          icon="X"
                          variant="tertiary"
                          buttonStyle="ghost"
                          rounded
                          onClick={() => {
                            setFile(null);
                            setCSVData([]);
                            setPreview(null);
                          }}
                          title="Clear selected file"
                        />
                      </div>
                    )}
                  </div>
          
                  {/* What happens next */}
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-700">What happens next?</h4>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="flex items-start space-x-2">
                        <div className="rounded-full bg-primary/10 p-2">
                          <Icon name="CheckCircle" size="sm" color="primary" />
                        </div>
                        <div>
                          <p className="text-sm font-medium">Validation</p>
                          <p className="text-xs text-gray-500">We'll check the data format and identify any duplicates</p>
                        </div>
                      </div>
                      <div className="flex items-start space-x-2">
                        <div className="rounded-full bg-primary/10 p-2">
                          <Icon name="ListChecks" size="sm" color="primary" />
                        </div>
                        <div>
                          <p className="text-sm font-medium">Review</p>
                          <p className="text-xs text-gray-500">You'll be able to review and select which persons to import</p>
                        </div>
                      </div>
                      <div className="flex items-start space-x-2">
                        <div className="rounded-full bg-primary/10 p-2">
                          <Icon name="UserPlus" size="sm" color="primary" />
                        </div>
                        <div>
                          <p className="text-sm font-medium">Import</p>
                          <p className="text-xs text-gray-500">Selected persons will be added to your organization</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
  
      case 2:
        return (
          <Card>
            <CardContent className="space-y-6">
              {preview && (
                <>
                  <div>
                    <h3 className="text-lg font-medium">New Persons to Import ({preview.new_persons.length})</h3>
                    {preview.new_persons.length > 0 && (
                      <Table
                        columns={[
                            {
                            header: 'Select',
                            accessor: 'select',
                            component: (row: any) => (
                                <TableCheckboxComponent
                                isChecked={selectedRows[row.email] ?? true}
                                onChange={(checked) => handleRowSelection(row.email, checked)}
                                label=""
                                />
                            )
                            },
                            { header: 'Name', accessor: 'name', sortable: true },
                            { header: 'Email', accessor: 'email', sortable: true },
                        ]}
                        data={preview.new_persons}
                        paginated={preview.new_persons.length > 100}
                        rowsPerPage={100}
                        />
                    )}
                  </div>
  
                  {preview.duplicates.length > 0 && (
                    <div>
                      <h3 className="text-lg font-medium">Duplicate Entries ({preview.duplicates.length})</h3>
                      <div className="bg-secondary/10 p-4 rounded-lg mb-4">
                        <div className="flex items-center space-x-2 text-secondary">
                          <Icon name="AlertCircle" size="sm" />
                          <span className="text-sm">
                            These persons already exist in the system and will be skipped.
                          </span>
                        </div>
                      </div>
                      <Table
                        columns={[
                            { header: 'Name', accessor: 'name', sortable: true },
                            { header: 'Email', accessor: 'email', sortable: true },
                            { 
                            header: 'Type', 
                            accessor: 'person_type', 
                            component: () => <span></span>
                            }
                        ]}
                        data={preview.duplicates}
                        paginated={preview.duplicates.length > 100}
                        rowsPerPage={100}
                      />
                    </div>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        );
  
      case 3:
        const selectedPersons = preview?.new_persons.filter(person => selectedRows[person.email]) || [];
        
        return (
          <Card>
            <CardContent className="space-y-4">
              <div className="space-y-4">
                <h3 className="text-lg font-medium">Selected Persons to Import ({selectedPersons.length})</h3>
                
                <div className="space-y-2">
                  {selectedPersons.map(person => {
                    const status = creationStatuses[person.email] || { status: 'pending' };
                    
                    return (
                      <div 
                        key={person.email}
                        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                      >
                        <div className="flex items-center space-x-4">
                          {status.status === 'creating' ? (
                            <Icon name="Loader" className="animate-spin" size="sm" color="primary" />
                          ) : status.status === 'success' ? (
                            <Icon name="CheckCircle" size="sm" color="accent" />
                          ) : status.status === 'error' ? (
                            <Icon name="XCircle" size="sm" color="secondary" />
                          ) : (
                            <Icon name="Circle" size="sm" color="text" />
                          )}
                          
                          <div>
                            <p className="font-medium">{person.name}</p>
                            <p className="text-sm text-gray-500">{person.email}</p>
                          </div>
                        </div>
  
                        <div className="text-sm">
                          {status.status === 'pending' && 'Pending'}
                          {status.status === 'creating' && 'Creating...'}
                          {status.status === 'success' && 'Created'}
                          {status.status === 'error' && (
                            <Tooltip content={status.error || 'Error creating person'}>
                              <span className="text-secondary">Failed</span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
  
                {Object.values(creationStatuses).some(status => status.status === 'success') && (
                  <div className="flex justify-center">
                    <Button
                      label="Go to Persons"
                      variant="primary"
                      buttonStyle="outlined"
                      onClick={() => navigate('/persons')}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        );
  
      default:
        return null;
    }
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return !!file;
      case 2:
        return preview?.new_persons && preview.new_persons.length > 0;
      default:
        return true;
    }
  };

  return (
    <PersonContainer
      fetchOnMount={false}
      render={({ onCreate }) => {
        // Move handleConfirmImport inside to access onCreate
        const handleConfirmImport = async () => {
            if (!preview?.new_persons) return;
            
            const selectedPersons = preview.new_persons.filter(
              person => selectedRows[person.email]
            );
            
            const initialStatuses = selectedPersons.reduce((acc, person) => {
              acc[person.email] = { data: person, status: 'pending' };
              return acc;
            }, {} as Record<string, PersonWithStatus>);
            
            setCreationStatuses(initialStatuses);
          
            // Create persons sequentially
            for (const person of selectedPersons) {
              try {
                setCreationStatuses(prev => ({
                  ...prev,
                  [person.email]: { data: person, status: 'creating' }
                }));
          
                const newPerson: Omit<Person, 'created_at' | 'updated_at' | 'id'> = {
                  name: person.name,
                  email: person.email,
                  person_type: 'team_member', // Default to team_member
                  slug: '', // Backend will generate the slug
                  tags: [], // Start with no tags
                  stakeholder_role: undefined,
                  favorites_count: 0,
                  is_favorited: false,
                };
          
                await onCreate(newPerson);
          
                setCreationStatuses(prev => ({
                  ...prev,
                  [person.email]: { data: person, status: 'success' }
                }));
              } catch (error) {
                setCreationStatuses(prev => ({
                  ...prev,
                  [person.email]: { 
                    data: person, 
                    status: 'error', 
                    error: error instanceof Error ? error.message : 'Failed to create person'
                  }
                }));
              }
            }
          };

        return (
          <div className="max-w-4xl mx-auto p-4 space-y-6">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">Import Persons</h1>
                <Button
                label="Cancel"
                variant="secondary"
                buttonStyle="outlined"
                onClick={() => navigate('/persons')}
                />
            </div>

            <Steps
                steps={steps}
                currentStep={currentStep - 1}
                variant="horizontal"
            />

            <ProgressIndicator
                steps={steps.map(s => s.title)}
                currentStep={currentStep - 1}
                variant="bars"
            />

            <div className="my-8">
                {renderStepContent()}
            </div>

            <div className="flex justify-between mt-6">
                {currentStep > 1 && (
                <Button
                    label="Back"
                    variant="secondary"
                    buttonStyle="outlined"
                    onClick={handleBack}
                />
                )}
                {currentStep < steps.length ? (
                <Button
                    label={isValidating ? "Validating..." : "Next"}
                    variant="primary"
                    buttonStyle="filled"
                    onClick={handleNext}
                    disabled={!validateCurrentStep() || isValidating}
                    icon={isValidating ? "Loader" : undefined}
                    iconPosition="left"
                />
                ) : (
                <Button
                    label="Import Persons"
                    variant="primary"
                    buttonStyle="filled"
                    onClick={handleConfirmImport}
                    //disabled={processCSVMutation.isLoading}
                />
                )}
            </div>

            {showSnackbar.show && (
                <Snackbar
                message={showSnackbar.message}
                type={showSnackbar.type}
                onClose={() => setShowSnackbar(prev => ({ ...prev, show: false }))}
                />
            )}
            </div>
        );
      }}
    />
  );
};

export default PersonImportPage;