import React from 'react';
import { Icon } from '../'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom'; // Assuming you use react-router for navigation

export interface ListItem {
  id: string;
  slug?: string; // Optional slug for detail pages
  title: string;
}

interface ListMinimalProps {
  items: ListItem[];
  onRemoveItem: (id: string) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  emptyMessage?: string; // Add a prop for an empty state message
  onDetailClick?: (slug: string) => void; // Optional function to handle button click for details
}

export const ListMinimal: React.FC<ListMinimalProps> = ({
  items,
  onRemoveItem,
  isLoading = false,
  isDisabled = false,
  emptyMessage = "No items", // Default message if none is provided
  onDetailClick, // Optional function for detail button click
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="p-4 bg-white">
        <div className="animate-pulse space-y-2">
          <div className="h-6 bg-neutral rounded w-1/4"></div>
          <div className="h-10 bg-neutral rounded"></div>
          <div className="h-10 bg-neutral rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      {/* Display empty message if no items */}
      {items.length === 0 ? (
        <div className="p-4 text-sm text-neutral-dark">
          {emptyMessage}
        </div>
      ) : (
        // Render the list of items
        <ul className="divide-y divide-neutral">
          {items.map((item) => (
            <li key={item.id} className="p-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-text">{item.title}</span>
                <div className="flex items-center space-x-2">
                  {/* Add button to view details */}
                  {onDetailClick && (
                    <button
                      onClick={() => onDetailClick(item.slug!)}
                      className="text-primary hover:text-primary-dark focus:outline-none"
                      title="View Details"
                    >
                      <Icon name="Maximize" size="sm" />
                    </button>
                  )}
                  <button
                    onClick={() => onRemoveItem(item.id)}
                    disabled={isDisabled}
                    className="text-text hover:text-primary disabled:opacity-50 focus:outline-none"
                    title="Remove Item"
                  >
                    <Icon name="X" size="sm" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
