import { Team, TeamMembership } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';
import { Person } from '../person/types';


const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all Teams
export const fetchTeams = async (
  navigate: (path: string) => void
): Promise<Team[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}teams/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error('Failed to fetch Teams');
  }
  return response.json();
};

// Fetch a single Team by slug
export const fetchTeam = async (slug: string, navigate: (path: string) => void): Promise<Team> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}teams/${slug}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch Team with slug ${slug}`);
  }
  return response.json();
};

// Create a new Team
export const createTeam = async (
  teamData: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>,
  navigate: (path: string) => void
): Promise<Team> => {
  const { company, members, ...restTeamData } = teamData;

  const response = await fetchWithAuth(`${API_URL}teams/`, {
    method: 'POST',
    body: JSON.stringify({
      ...restTeamData,
      parent: restTeamData.parent?.id,  // Only include parent ID
      tag_ids: restTeamData.tags.map(tag => tag.id),  // Map tags to tag_ids
      member_ids: members.map(member => member.id),  // Send only member IDs
    }),
  }, navigate);

  if (!response.ok) {
    throw new Error('Failed to create Team');
  }
  return response.json();
};

// Update an existing Team by slug
export const updateTeam = async (
  slug: string,
  updatedTeam: Partial<Team>,
  navigate: (path: string) => void
): Promise<Team> => {
  const { company, members, ...restUpdatedTeam } = updatedTeam;

  console.log('Members before processing:', members); // Debug log

  const response = await fetchWithAuth(`${API_URL}teams/${slug}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...restUpdatedTeam,
      parent: restUpdatedTeam.parent?.id,
      tag_ids: restUpdatedTeam.tags?.map(tag => tag.id),
      member_ids: members ? members.map((member: Person | TeamMembership) => {
        if ((member as Person).id && !(member as TeamMembership).person) {
          return (member as Person).id;
        }
        return (member as TeamMembership).person.id;
      }) : undefined,
    }),
  }, navigate);

  if (!response.ok) {
    throw new Error(`Failed to update Team with slug ${slug}`);
  }
  return response.json();
};

// Delete a Team by slug
export const deleteTeam = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}teams/${slug}/?company_id=${company_id}`, {
    method: 'DELETE',
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to delete Team with slug ${slug}`);
  }
};

// Fetch Team Memberships for a specific Team by teamSlug
export const fetchTeamMemberships = async (
  teamSlug: string,
  navigate: (path: string) => void
): Promise<TeamMembership[]> => {
  const response = await fetchWithAuth(`${API_URL}teams/${teamSlug}/memberships/`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch memberships for Team with slug ${teamSlug}`);
  }
  return response.json();
};

// Add a Person to a Team (Create Membership)
export const addTeamMember = async (
  teamSlug: string,
  personId: string,
  navigate: (path: string) => void
): Promise<TeamMembership> => {
  const response = await fetchWithAuth(`${API_URL}teams/${teamSlug}/memberships/`, {
    method: 'POST',
    body: JSON.stringify({ person: personId }),
  }, navigate);

  if (!response.ok) {
    throw new Error(`Failed to add member to Team with slug ${teamSlug}`);
  }
  return response.json();
};

// Remove a Person from a Team
export const removeTeamMember = async (
  teamSlug: string,
  membershipId: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}teams/${teamSlug}/memberships/${membershipId}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) {
    throw new Error(`Failed to remove member with ID ${membershipId} from Team ${teamSlug}`);
  }
};
