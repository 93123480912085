import React, { useState } from 'react';
import { Topic } from '../../api/topic/types';
import { PanelList, Icon, Button, Snackbar } from '../ui';
import TopicContainer from '../../containers/TopicContainer';
import { TopicModal } from '../topic/TopicModal';
import { useQueryClient } from 'react-query';

interface TopicPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  meetingTypeId?: string;
  personId?: string;
  onTopicClick?: (topic: Topic) => void;
  title?: string;
  showAddTopicButton?: boolean;
}

const TopicPanel: React.FC<TopicPanelProps> = ({
  isOpen,
  onClose,
  meetingTypeId,
  personId,
  onTopicClick,
  title = 'Topics',
  showAddTopicButton = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [topicToEdit, setTopicToEdit] = useState<Topic | null>(null);
  const queryClient = useQueryClient();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  const openTopicCreateModal = () => {
    setTopicToEdit(null);
    setShowModal(true);
  };

  const openTopicEditModal = (topic: Topic) => {
    setTopicToEdit(topic);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {showAddTopicButton && (
          <Button
            size="medium"
            variant="primary"
            label="Add New Topic"
            onClick={openTopicCreateModal}
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <TopicContainer
          meetingTypeId={meetingTypeId}
          personId={personId}
          render={({ data: topicsFromContainer, loading, error, onCreate, onEdit }) => {
            const sortedTopics = [...topicsFromContainer].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            return (
              <>
                {loading && <div className="p-4">Loading topics...</div>}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedTopics.length === 0 && (
                  <div className="p-4 text-gray-500 text-sm">No topics associated</div>
                )}

                {!loading && !error && sortedTopics.length > 0 && (
                  <PanelList
                    items={sortedTopics}
                    loading={loading}
                    renderItem={(topic) => (
                      <div key={topic.id} className="relative p-4">
                        <div className="absolute top-2 right-2 flex space-x-1">
                          <button
                            onClick={() => openTopicEditModal(topic)}
                            className="text-primary hover:text-primary-dark focus:outline-none"
                            title="Edit Topic"
                          >
                            <Icon name="Maximize" size="sm" />
                          </button>
                        </div>
                        <div className="flex flex-col space-y-1">
                          <span className="text-base font-semibold">{topic.title}</span>
                          <div className="flex text-xs text-gray-500 space-x-2">
                            <p>Created by: {topic.created_by.name}</p>
                            <p>• Created: {new Date(topic.created_at).toLocaleDateString()}</p>
                          </div>
                          <p className="text-sm text-gray-600">{topic.description}</p>
                          <div className="flex text-xs text-gray-500 space-x-2 mt-1">
                            <p>State: {topic.state}</p>
                            <p>• Importance: {topic.importance}</p>
                            <p>• Heatmap: {topic.heatmap_status}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                )}

                {showModal && (
                  <TopicModal
                    isOpen={showModal}
                    initialData={topicToEdit || undefined}
                    onClose={() => setShowModal(false)}
                    onSuccess={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('success');
                      setShowSnackbar(true);
                      setShowModal(false);
                      queryClient.invalidateQueries([
                        'topics',
                        { meetingTypeId, personId },
                      ]);
                    }}
                    onError={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('error');
                      setShowSnackbar(true);
                    }}
                  />
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TopicPanel;