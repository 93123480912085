import React, { useState, useEffect, useCallback } from 'react';
import { ModalContent, SelectSingle, TextInput } from '../ui';
import { Person } from '../../api/person/types';
import TagSelect from '../tags/TagSelect';
import PersonContainer from '../../containers/PersonContainer';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import PersonSidePanel from './PersonSidePanel';
import FavoriteContainer from '../../containers/FavoriteContainer';

interface PersonModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: Omit<Person, 'created_at' | 'updated_at'> | null;
  prefilledData?: { name: string };
  onSuccess: (message: string, person: Person) => void;
  onError: (message: string) => void;
  isFullscreen?: boolean;
}

export const PersonModal: React.FC<PersonModalProps> = ({
  isOpen,
  onClose,
  initialData,
  onSuccess,
  onError,
  prefilledData,
  isFullscreen = false,
}) => {
  const [personData, setPersonData] = useState<Partial<Person>>({
    email: '',
    person_type: 'team_member',
    tags: [],
  });
  const [personName, setPersonName] = useState<string>(prefilledData?.name || initialData?.name || '');
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('modal');

  const personTypeOptions = [
    { value: 'team_member', label: 'Team Member' },
    { value: 'stakeholder', label: 'Stakeholder' },
    { value: 'external_partner', label: 'External Partner' },
    { value: 'advisor', label: 'Advisor' },
    { value: 'client', label: 'Client' },
    { value: 'other', label: 'Other' },
  ];

  useEffect(() => {
    if (initialData) {
      setPersonData({
        email: initialData.email,
        person_type: initialData.person_type,
        tags: initialData.tags || [],
      });
      setPersonName(initialData.name);
    }
  }, [initialData]);

  const handleChange = useCallback((field: keyof Omit<Person, 'name'>, value: any, onEdit?: (slug: string, updatedPerson: Partial<Person>) => void) => {
    setPersonData(prevData => ({ ...prevData, [field]: value }));

    if (initialData?.slug && onEdit) {
      onEdit(initialData.slug, { [field]: value });
    }
  }, [initialData]);

  const handleSave = (
    onCreate: (personData: Omit<Person, 'created_at' | 'updated_at' | 'id'>) => void,
    onEdit: (slug: string, updatedPerson: Partial<Omit<Person, 'created_at' | 'updated_at'>>) => void
  ) => {
    if (!personName || !personData.email) {
      onError('Please fill in all fields');
      return;
    }

    try {
      const completePersonData: Omit<Person, 'created_at' | 'updated_at' | 'id'> = {
        ...personData,
        name: personName,
        slug: initialData?.slug || '',
      } as Omit<Person, 'created_at' | 'updated_at' | 'id'>;

      if (initialData?.slug) {
        onEdit(initialData.slug, completePersonData);
        onSuccess('Person updated successfully', { ...initialData, ...completePersonData } as Person);
      } else {
        onCreate(completePersonData);
        onSuccess('Person created successfully', completePersonData as Person);
      }
      onClose();
    } catch (error) {
      onError('Failed to save person');
    }
  };

  const handleDelete = (onDelete: (slug: string) => void) => {
    if (initialData?.slug) {
      try {
        onDelete(initialData.slug);
        onSuccess('Person deleted successfully', initialData as Person);
        onClose();
      } catch (error) {
        onError('Failed to delete person');
      }
    }
  };

  return (
    
    <PersonContainer
      render={({ onCreate, onEdit, onDelete }) => (
        <FavoriteContainer
          type="organization.person"  // Assuming your Person model is in the 'organization' app
          render={({ onToggle, isFavorited }) => (
            <ModalContent
              title={personName}
              onTitleChange={setPersonName}
              onCancel={onClose}
              titleInputPlaceholder='Enter person name'
              onCreate={() => handleSave(onCreate, onEdit)}
              onDelete={initialData ? () => handleDelete(onDelete) : undefined}
              actionButtonLabel={initialData ? 'Update Person' : 'Create Person'}
              showDescription={false}
              selects={[
                {
                  component: SelectSingle,
                  props: {
                    options: personTypeOptions,
                    placeholder: 'Select Person Type',
                    defaultValue: personData.person_type?.toString(),
                    onSelect: (value: string) => handleChange('person_type', value as Person['person_type'], onEdit),
                  },
                },
                {
                  component: TagSelect,
                  props: {
                    value: (personData.tags || []).map(tag => tag.id),
                    onChange: (selectedTags: Person['tags']) => handleChange('tags', selectedTags, onEdit),
                    enableSearch: true,
                  },
                },
              ]}
              formFields={[
                {
                  component: TextInput,
                  props: {
                    placeholder: 'Enter email',
                    value: personData.email,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => 
                      handleChange('email', e.target.value, onEdit),
                  },
                },
              ]}
              activityFeed={initialData ? 
                <LogEntryActivityFeed objectId={initialData.id} objectType='person' /> : null}
              showActivityFeed={!!initialData}
              showSidePanel={!!initialData}
              isSidePanelInitiallyCollapsed={true}
              sidePanelContent={
                initialData && (
                  <PersonSidePanel
                    personId={initialData.id}
                  />
                )
              }
              showFavorite={!!initialData}
              isFavorited={!!initialData && isFavorited(initialData.id)}
              onFavoriteToggle={() => initialData?.id && onToggle('organization.person', initialData.id)}
              viewMode={modalViewMode}
              onViewModeChange={(mode) => setModalViewMode(mode)}
            />
          )}
        />
      )}
    />
  );
};
