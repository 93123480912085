import React, { useState } from 'react';
import { Avatar, Icon } from '../../';
import TeamSelect from '../../../team/TeamSelect';
import { Team } from '../../../../api/team/types';

interface TableTeamComponentProps {
  team: Team | null;
  isEditable?: boolean;
  onUpdate?: (newTeam: Team | null) => void;
}

export const TableTeamComponent: React.FC<TableTeamComponentProps> = ({ 
  team, 
  isEditable = false,
  onUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isEditable) {
      setIsEditing(true);
    }
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
  };

  const handleSelectTeam = (selectedTeam: Team) => {
    if (onUpdate) {
      onUpdate(selectedTeam);
    }
    setIsEditing(false);
  };

  return (
    <div className={`flex items-center space-x-2 ${isEditable ? 'cursor-pointer hover:bg-gray-100 p-1 rounded' : ''}`}>
      {team ? (
        <>
          <Avatar name={team.name} size="sm" />
          <span className="text-sm text-text">{team.name}</span>
        </>
      ) : (
        <span className="text-sm text-gray-500">No team assigned</span>
      )}

      {isEditable && (
        <span onClick={handleEditIconClick} className="cursor-pointer">
          <Icon name="Edit" size="sm" className='text-gray-400 hover:text-gray-600' />
        </span>
      )}

      {isEditable && isEditing && (
        <TeamSelect
          value={team?.id || ''}
          onChange={handleSelectTeam}
          onClose={handleCloseEdit}
          isOpen={true}
          placeholder="Select a team"
        />
      )}
    </div>
  );
};
