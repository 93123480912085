import { GraphFilters } from '../../api/networkGraph/types';
import { Search } from 'lucide-react';

interface Props {
  filters: GraphFilters;
  onChange: (filters: GraphFilters) => void;
  onSearchChange: (search: string) => void;
}

export const NetworkGraphFilters = ({ filters, onChange, onSearchChange }: Props) => {
  return (
    <div className="p-4 bg-white border rounded-lg mb-4 space-y-4">
      {/* Search */}
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
        <input
          type="text"
          placeholder="Search nodes..."
          className="pl-8 pr-4 py-2 w-full border rounded-md"
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
      
      {/* Entity Type Filters */}
      <div className="flex flex-wrap gap-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeTasks}
            onChange={(e) => onChange({ ...filters, includeTasks: e.target.checked })}
            className="mr-2"
          />
          Tasks
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeProjects}
            onChange={(e) => onChange({ ...filters, includeProjects: e.target.checked })}
            className="mr-2"
          />
          Projects
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeTopics}
            onChange={(e) => onChange({ ...filters, includeTopics: e.target.checked })}
            className="mr-2"
          />
          Topics
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includePersons}
            onChange={(e) => onChange({ ...filters, includePersons: e.target.checked })}
            className="mr-2"
          />
          Persons
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={filters.includeTags}
            onChange={(e) => onChange({ ...filters, includeTags: e.target.checked })}
            className="mr-2"
          />
          Tags
        </label>
      </div>
    </div>
  );
};