import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogEntryContainer from '../../containers/LogEntryContainer';
import StateTransitionContainer from '../../containers/StateTransitionContainer'; // Import StateTransitionContainer
import { ActivityFeed } from '../ui/data/ActivityFeed';
import { LogEntry } from '../../api/logEntry/types';
import { StateTransition } from '../../api/stateTransition/types'; // Import StateTransition type
import { timeAgo } from '../../utils/utils';
import { IconName } from '../ui/base/Icon';

// Define the valid LogType values for FeedEvent
type LogType =
  | 'Created'
  | 'Comment'
  | 'Notes Update'
  | 'State Change'
  | 'Priority Change'
  | 'Person Change'
  | 'Task Change'
  | 'Heatmap Change'
  | 'Effort Change'
  | 'Dial Stage Change'
  | 'Meeting Associated'
  | 'Discussed in Meeting'
  | 'Owner Change'
  | 'Person Assigned as Task Owner'
  | 'Person Participated in Meeting'
  | 'Person Assigned as Project Owner'
  | 'Person Assigned as Strategy Owner'
  | 'Person Added to Topic'
  | 'Due Date Change'
  | 'Team Change'
  | 'Project Assigned to Team' 
  | 'Person Removed'
  | 'Content Update'
  | 'Collaborators Updated'
  | 'Start Date Change'
  | 'Target Date Change'
  | 'Progress Update';

// Helper function to convert LogEntry to FeedEvent
const convertLogEntryToFeedEvent = (logEntry: LogEntry) => {
  // Define TagType
  type TagType = 'Manual' | 'AI' | 'Automatic';

  // Map generation_method values to tag values
  const generationMethodToTagMap: { [key in LogEntry['generation_method']]: TagType } = {
    manual: 'Manual',
    ai_generated: 'AI',
    automatic: 'Automatic',
  };

  // Map log_type values to event type names using LogType
  const logTypeToEventTypeMap: { [key in LogEntry['log_type']]: LogType } = {
    created: 'Created',
    comment: 'Comment',
    notes_update: 'Notes Update',
    state_change: 'State Change',
    priority_change: 'Priority Change',
    person_added: 'Person Change',
    person_removed: 'Person Removed',
    task_assigned: 'Task Change',
    heatmap_change: 'Heatmap Change',
    effort_change: 'Effort Change',
    dial_stage_change: 'Dial Stage Change',
    meeting_associated: 'Meeting Associated',
    discussed_in_meeting: 'Discussed in Meeting',
    owner_change: 'Owner Change',
    person_assigned_task_owner: 'Person Assigned as Task Owner',
    person_participated_meeting: 'Person Participated in Meeting',
    person_assigned_project_owner: 'Person Assigned as Project Owner',
    person_assigned_strategy_owner: 'Person Assigned as Strategy Owner',
    person_added_to_topic: 'Person Added to Topic',
    due_date_change: 'Due Date Change',
    team_change: 'Team Change',                       
    project_assigned_team: 'Project Assigned to Team',
    content_update: 'Content Update',
    collaborators_updated: 'Collaborators Updated',
    progress_update: 'Progress Update',
    start_date_change: 'Start Date Change',
    target_date_change: 'Target Date Change',
  };

  return {
    id: logEntry.id,
    log_type: logTypeToEventTypeMap[logEntry.log_type], // Map log_type correctly to LogType
    user: logEntry.created_by.name,
    content: logEntry.content,
    object_id: logEntry.object_id,
    timestamp: timeAgo(logEntry.created_at),  // Use timeAgo for relative timestamps, display this in the UI
    date: new Date(logEntry.created_at).toISOString(),  // Store actual date for sorting
    tag: generationMethodToTagMap[logEntry.generation_method], // Get the tag from the map
  };
};

// Helper function to convert StateTransition to FeedEvent with Icons
const convertStateTransitionToTimelineEvent = (transition: StateTransition) => {
  const iconMap: { [key: string]: IconName } = {
    CREATED: 'PlusCircle',
    IDENTIFIED: 'Search',
    IN_PROGRESS: 'Activity',
    COMPLETED: 'CheckCircle',
    ONGOING: 'RefreshCw',
    ARCHIVED: 'Archive',
    CANDIDATE: 'UserPlus',
    BACKLOG: 'Layers',
    DEFINITION_AND_ANALYSIS: 'Book',
    DEVELOPMENT_IN_PROGRESS: 'Code',
    TESTING: 'CheckSquare',
    ROLLING_OUT: 'Truck',
    DONE: 'Check',
  };

  // Filter out any transitions without a valid start state or transition data
  if (!transition.to_state_friendly || (!transition.from_state_friendly && transition.from_state_friendly !== 'None')) {
    return null;
  }

  // If from_state_friendly is "None", it means the item was created
  if (transition.from_state_friendly === 'None' || !transition.from_state_friendly) {
    return null;
  }

  // Otherwise, show a regular state transition
  return {
    title: `${transition.from_state_friendly} → ${transition.to_state_friendly}`,
    date: timeAgo(transition.transition_timestamp),  // Use timeAgo for relative timestamps, display this
    timestamp: new Date(transition.transition_timestamp).toISOString(), // Store actual date for sorting
    icon: iconMap[transition.to_state_friendly.toUpperCase()] || 'Circle', // Use the icon for the "to" state
  };
};

interface LogEntryActivityFeedProps {
  objectId: string;
  objectType: 'topic' | 'task' | 'person' | 'project' | 'team' | 'strategy' | 'goal'; // Restrict to allowed values
  showTimeline?: boolean; // Add a prop to toggle timeline visibility
}

const LogEntryActivityFeed: React.FC<LogEntryActivityFeedProps> = ({
  objectId,
  objectType,
  showTimeline = false, // Default to false if timeline view is not enabled
}) => {
  const navigate = useNavigate();

  return (
    <LogEntryContainer
      objectId={objectId}
      render={({ data: logEntries, loading, error, onCreate, onDelete }) => {
        // Convert log entries to FeedEvents and filter by objectId
        const feedEvents = logEntries
          .map(convertLogEntryToFeedEvent)
          .filter(logEntry => logEntry.object_id === objectId);

        // Format "created" events to have the same structure as state transitions
        const createdEvents = logEntries
          .filter(logEntry => logEntry.log_type === 'created')
          .map(logEntry => ({
            title: `Topic '${logEntry.content}' created`,  // Format title for created event
            date: timeAgo(logEntry.created_at),  // Use timeAgo for relative timestamps
            timestamp: new Date(logEntry.created_at).toISOString(),  // Store actual date for sorting
            icon: 'PlusCircle' as IconName,  // Add a creation-specific icon
          }));

        const handleAddComment = (comment: string) => {
          onCreate({
            object_id: objectId,
            log_type: 'comment',
            content: comment,
            object_type: objectType, // Pass object type dynamically
            generation_method: 'manual',
          });
        };

        const handleDeleteEvent = (eventId: string) => {
          onDelete(eventId);
        };

        // Render timeline events conditionally based on showTimeline
        const renderTimeline = showTimeline ? (
          <StateTransitionContainer
            model={objectType} // Use objectType to fetch state transitions
            objectId={objectId}
            render={({ data: stateTransitions, loading: transitionsLoading, error: transitionsError }) => {
              // Convert state transitions to timeline events with icons
              const stateTransitionEvents = stateTransitions
                .map(convertStateTransitionToTimelineEvent)
                .filter((event): event is NonNullable<typeof event> => event !== null); // Ensure no null values

              // Combine state transitions and "created" events for the timeline
              const timelineEvents = [...stateTransitionEvents, ...createdEvents];

              // Sort timeline events by the `timestamp` field (actual date) in **ascending** order (oldest first)
              const sortedTimelineEvents = timelineEvents.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

              return (
                <ActivityFeed
                  events={feedEvents} // Pass log entry events for activity feed
                  timelineEvents={sortedTimelineEvents} // Pass sorted timeline events
                  showTimeline={showTimeline} // Show/hide the timeline tab
                  isDisabled={loading || transitionsLoading}
                  onAddComment={handleAddComment}
                  onDeleteEvent={handleDeleteEvent} // Pass the delete handler to ActivityFeed
                />
              );
            }}
          />
        ) : (
          <ActivityFeed
            events={feedEvents} // Pass log entry events for activity feed
            timelineEvents={createdEvents} // Only pass created events if timeline is not shown
            showTimeline={false} // Disable the timeline tab
            isDisabled={loading}
            onAddComment={handleAddComment}
            onDeleteEvent={handleDeleteEvent} // Pass the delete handler to ActivityFeed
          />
        );

        return renderTimeline;
      }}
    />
  );
};

export default LogEntryActivityFeed;
