import React from 'react';

interface ProgressIndicatorProps {
    steps: string[];
    currentStep: number;
    variant?: 'dots' | 'circles' | 'bars';
    size?: 'sm' | 'md' | 'lg';
  }
  
  export const ProgressIndicator = ({
    steps,
    currentStep,
    variant = 'dots',
    size = 'md'
  }: ProgressIndicatorProps) => {
    const getDotSize = () => {
      switch (size) {
        case 'sm': return 'w-2 h-2';
        case 'lg': return 'w-4 h-4';
        default: return 'w-3 h-3';
      }
    };
  
    if (variant === 'bars') {
      return (
        <div className="flex gap-2">
          {steps.map((_, index) => (
            <div 
              key={index} 
              className={`h-1 flex-1 rounded-full transition-colors
                ${index <= currentStep ? 'bg-primary' : 'bg-neutral'}`}
            />
          ))}
        </div>
      );
    }
  
    if (variant === 'circles') {
      return (
        <div className="flex items-center">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div 
                className={`${getDotSize()} rounded-full border-2 transition-colors
                  ${index < currentStep 
                    ? 'bg-primary border-primary' 
                    : index === currentStep 
                    ? 'border-primary' 
                    : 'border-neutral'
                  }`}
              />
              {index < steps.length - 1 && (
                <div className={`h-px w-4 mx-1
                  ${index < currentStep ? 'bg-primary' : 'bg-neutral'}`} 
                />
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }
  
    return (
      <div className="flex gap-2">
        {steps.map((_, index) => (
          <div 
            key={index}
            className={`${getDotSize()} rounded-full transition-colors
              ${index <= currentStep ? 'bg-primary' : 'bg-neutral'}`}
          />
        ))}
      </div>
    );
  };