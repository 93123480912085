import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GanttChart } from '../ui/data/GanttChart';
import { Project } from '../../api/project/types';
import { Task } from '../../api/task/types';

interface ProjectTimelineViewProps {
  data: Project[];
  onOpenProject?: (project: Project) => void;  // Added this prop for modal handling
  onOpenTask?: (task: Task) => void;
}

const getStatusFromProject = (project: Project): 'completed' | 'in-progress' | 'delayed' => {
  if (project.state === 'DONE') {
    return 'completed';
  }

  if (project.pulse === 'RED' || project.pulse === 'ORANGE') {
    return 'delayed';
  }

  return 'in-progress';
};

const getStatusFromTask = (task: Task): 'completed' | 'in-progress' | 'delayed' => {
  if (task.state === 'COMPLETED' || task.state === 'ARCHIVED') return 'completed';
  if (task.due_date && new Date(task.due_date) < new Date()) return 'delayed';
  return 'in-progress';
};

export const ProjectTimelineView: React.FC<ProjectTimelineViewProps> = ({
  data: projects,
  onOpenProject,
  onOpenTask,
}) => {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = React.useState<Set<string | number>>(new Set());

  const timelineItems = useMemo(() => {
    return projects
      .filter(project => project.start_date && project.target_date)
      .map(project => ({
        id: project.id,
        title: project.title,
        startDate: new Date(project.start_date!),
        endDate: new Date(project.target_date!),
        status: getStatusFromProject(project),
        isExpanded: expandedItems.has(project.id),
        tasks: project.tasks
          .filter(task => task.start_date && task.due_date)
          .map(task => ({
            id: task.id,
            title: task.title,
            startDate: new Date(task.start_date!),
            endDate: new Date(task.due_date!),
            status: getStatusFromTask(task)
          }))
      }))
      .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  }, [projects, expandedItems]);

  const handleItemClick = (itemId: string | number) => {
    const project = projects.find(p => p.id === itemId);
    if (project && onOpenProject) {
      onOpenProject(project);
    }
  };

  const handleTaskClick = (projectId: string | number, taskId: string | number) => {
    console.log('Timeline - Project ID:', projectId);
    const project = projects.find(p => p.id === projectId);
    console.log('Timeline - Found Project:', project);
    
    const task = project?.tasks.find(t => t.id === taskId);
    console.log('Timeline - Complete Task from Project:', task);
    
    if (task && onOpenTask) {
      onOpenTask(task); // Just pass the task directly, don't try to reconstruct it
    }
  };

  const handleExpandToggle = (itemId: string | number) => {
    setExpandedItems(prev => {
      const next = new Set(prev);
      if (next.has(itemId)) {
        next.delete(itemId);
      } else {
        next.add(itemId);
      }
      return next;
    });
  };

  if (timelineItems.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-gray-500">No projects with dates found</div>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <GanttChart 
        items={timelineItems}
        className="min-h-[400px]"
        onItemClick={handleItemClick}
        onTaskClick={handleTaskClick}
        onExpandToggle={handleExpandToggle}
      />
    </div>
  );
};

// Helper function to create a date range view
type DateRangeViewProps = {
  data: Project[];
  startDate: Date;
  endDate: Date;
  onOpenProject?: (project: Project) => void;  // Added this prop
};

const DateRangeView: React.FC<DateRangeViewProps> = ({ 
  data, 
  startDate, 
  endDate,
  onOpenProject,  // Add this prop
}) => {
  const filteredProjects = useMemo(() => {
    return data.filter(project => {
      if (!project.start_date || !project.target_date) return false;
      const projectStart = new Date(project.start_date);
      const projectEnd = new Date(project.target_date);
      return projectStart >= startDate && projectEnd <= endDate;
    });
  }, [data, startDate, endDate]);

  return <ProjectTimelineView data={filteredProjects} onOpenProject={onOpenProject} />;
};

// Optional: Export a component that shows projects for the next 6 months
export const UpcomingProjectTimelineView: React.FC<{ 
  data: Project[]; 
  onOpenProject?: (project: Project) => void;  // Added this prop
}> = ({ 
  data,
  onOpenProject,
}) => {
  const today = new Date();
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(today.getMonth() + 6);

  return (
    <DateRangeView
      data={data}
      startDate={today}
      endDate={sixMonthsFromNow}
      onOpenProject={onOpenProject}
    />
  );
};

// Optional: Export a component that shows projects for the current quarter
export const QuarterlyProjectTimelineView: React.FC<{ 
  data: Project[];
  onOpenProject?: (project: Project) => void;  // Added this prop
}> = ({ 
  data,
  onOpenProject,
}) => {
  const today = new Date();
  const quarterStart = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
  const quarterEnd = new Date(quarterStart);
  quarterEnd.setMonth(quarterEnd.getMonth() + 3);
  quarterEnd.setDate(quarterEnd.getDate() - 1);

  return (
    <DateRangeView
      data={data}
      startDate={quarterStart}
      endDate={quarterEnd}
      onOpenProject={onOpenProject}
    />
  );
};

export default ProjectTimelineView;