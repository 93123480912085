import React from 'react';
import { useAlerts } from '../api/alert/queries';
import { Alert } from '../api/alert/types';

interface AlertContainerProps {
  render: (props: {
    data: Alert[];
    loading: boolean;
    error: string | null;
  }) => JSX.Element;
}

const AlertContainer: React.FC<AlertContainerProps> = ({ render }) => {
  const { 
    data: alerts = [], 
    isLoading, 
    error,
    refetch
  } = useAlerts();

  return render({
    data: alerts,
    loading: isLoading,
    error: error ? (error as Error).message : null,
  });
};

export default AlertContainer;