import React from 'react';
import { Tooltip } from '../'; // Assuming Tooltip is in the same folder

interface LifecycleProps {
  lifecycleStates: string[];
  currentState: string;
  size?: 'small' | 'medium';  // Added size prop
  showActiveLabel?: boolean;  // Added prop to toggle the label for the active state
}

const Lifecycle: React.FC<LifecycleProps> = ({ lifecycleStates, currentState, size = 'medium', showActiveLabel = true }) => {
  // Define size-based classes
  const sizeClasses = {
    small: {
      circle: 'w-4 h-4',  // Smaller circles
      line: 'h-0.5',      // Thin line
      text: 'text-xs',    // Smaller text size
      spacing: 'space-x-4 px-4',  // Smaller space between items
    },
    medium: {
      circle: 'w-6 h-6',  // Default medium circles
      line: 'h-0.5',      // Default line height
      text: 'text-sm',    // Default text size
      spacing: 'space-x-8 px-8',  // Default spacing between items
    },
  };

  return (
    <div className={`flex items-center justify-center ${sizeClasses[size].spacing}`}>
      {lifecycleStates.map((state, index) => {
        const isCurrent = state === currentState;
        const isBeforeCurrent = lifecycleStates.indexOf(currentState) > index;
        const isAfterCurrent = lifecycleStates.indexOf(currentState) < index;

        return (
          <React.Fragment key={state}>
            <Tooltip content={state} position="bottom" variant="default">
              <div className="relative flex flex-col items-center">
                {/* Circle */}
                <div
                  className={`${sizeClasses[size].circle} rounded-full border-2 flex justify-center items-center 
                  ${isCurrent ? 'bg-primary-dark border-primary-dark' : 
                    isBeforeCurrent ? 'bg-secondary-dark border-secondary-dark' : 
                    'bg-transparent border-neutral'}`}
                />
                {/* Label below the current state (only if showActiveLabel is true) */}
                {isCurrent && showActiveLabel && (
                  <div className={`absolute top-full mt-2 ${sizeClasses[size].text} text-primary-dark text-center`}>
                    {state}
                  </div>
                )}
              </div>
            </Tooltip>

            {/* Line between circles */}
            {index !== lifecycleStates.length - 1 && (
              <div className={`flex-grow ${sizeClasses[size].line} bg-neutral`} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Lifecycle;
