import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Icon, IconName } from '../';
import { useUser } from '../../../contexts/UserContext';
import { usePerson } from '../../../utils/usePerson';
import { Logo } from '../../Logo';

interface MenuItemProps {
  to?: string;
  icon: IconName;
  label: string;
  children?: MenuItemProps[];
}

interface SidebarMenuProps {
  initialOpenMenus?: string[];
}

const MenuItem: React.FC<MenuItemProps & {
  isOpen: boolean;
  toggleOpen: (label: string) => void;
  isTopLevel?: boolean;
  collapsed: boolean;
}> = ({ to, icon, label, children, isOpen, toggleOpen, isTopLevel = false, collapsed }) => {
  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen(label);
  };

  if (children) {
    return (
      <div className="mb-1">
        <div className="w-full flex items-center justify-between px-3 py-2 rounded-md hover:bg-gray-700">
          <NavLink
            to={to!}
            className="flex items-center space-x-2"
          >
            <Icon name={icon} size="sm" color="white" />
            {!collapsed && (
              <span className={`text-sm ${isTopLevel ? 'font-semibold' : 'font-medium'} text-gray-200`}>{label}</span>
            )}
          </NavLink>
          {!collapsed && (
            <button onClick={handleToggle} className="ml-2">
              <Icon name={isOpen ? "ChevronUp" : "ChevronDown"} size="sm" color="white" />
            </button>
          )}
        </div>
        {isOpen && !collapsed && (
          <div className="ml-3 mt-1 space-y-1">
            {children.map((child, index) => (
              <MenuItem key={index} {...child} isOpen={false} toggleOpen={toggleOpen} collapsed={collapsed} />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <NavLink
      to={to!}
      className={({ isActive }) =>
        `flex items-center space-x-2 px-3 py-2 rounded-md transition-all ${
          isActive
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
        }`
      }
    >
      {({ isActive }) => (
        <>
          <Icon name={icon} size="sm" color="white" />
          {!collapsed && (
            <span className={`${isTopLevel ? 'text-sm font-semibold' : 'text-xs font-medium'} text-gray-200`}>{label}</span>
          )}
        </>
      )}
    </NavLink>
  );
};

const UserInfo: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { personId } = useUser();
  const navigate = useNavigate();
  const { data: person, isLoading } = usePerson(personId);

  return (
    <div className="flex items-center space-x-2 px-3 py-2 text-gray-300">
      <div className="flex-shrink-0">
        <Icon name="User" size="sm" color="white" />
      </div>
      {!collapsed && (
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium truncate">
            {isLoading ? 'Loading...' : person?.name || 'Unknown User'}
          </p>
          {person?.email && (
            <p className="text-xs text-gray-500 truncate">
              {person.email}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ initialOpenMenus = [] }) => {
  const [openMenu, setOpenMenu] = useState<string | null>(initialOpenMenus[0] || null);
  const [collapsed, setCollapsed] = useState(false);

  const toggleOpen = (label: string) => {
    setOpenMenu(prev => (prev === label ? null : label));
  };

  const toggleCollapse = () => setCollapsed(!collapsed);

  const menuItems: MenuItemProps[] = [
    { to: "/tasks/board", icon: "Home", label: "Dashboard" },
    {
      to: "/tasks/board",
      icon: "ListChecks",
      label: "Tasks",
      children: [
        { to: "/tasks/all", icon: "List", label: "List View" },
        { to: "/tasks/board", icon: "Kanban", label: "Board View" },
        { to: "/tasks/overdue", icon: "AlertCircle", label: "Over Due" },
        { to: "/tasks/assigned", icon: "UserCheck", label: "Assigned to me" },
      ]
    },
    {
      to: "/topics",
      icon: "Library",
      label: "Topics",
      children: [
        { to: "/topics/all", icon: "Library", label: "All" },
        { to: "/topics/backlog", icon: "Inbox", label: "Backlog" },
        { to: "/topics/active", icon: "Play", label: "Active" },
        { to: "/topics/complete", icon: "CheckCircle", label: "Complete" },
        { to: "/topics/heatmap", icon: "Grid", label: "Heatmap View" }
      ]
    },
    {
      to: "/projects",
      icon: "Layers",
      label: "Projects",
      children: [
        { to: "/projects/all", icon: "Layers", label: "All" },
        { to: "/projects/timeline", icon: "ChartGantt", label: "Timeline View" },
        { to: "/projects/active", icon: "Play", label: "Active" },
        { to: "/projects/completed", icon: "CheckCircle", label: "Completed" },
        { to: "/snapshots", icon: "Camera", label: "Snapshots" }
      ]
    },
    {
      to: "/strategies",
      icon: "Flag",
      label: "Strategies",
      children: [
        { to: "/strategies/all", icon: "Flag", label: "All" },
        { to: "/strategies/active", icon: "Play", label: "Active" },
        { to: "/strategies/approved", icon: "CheckCircle", label: "Approved" },
      ]
    },
    {
      to: "/goals",
      icon: "Goal",
      label: "Goals",
    },
    {
      to: "/meetings",
      icon: "Calendar",
      label: "Meetings",
      children: [
        { to: "/meeting-types", icon: "UserRoundCog", label: "Meeting Types" },
        { to: "/meeting-occurrences", icon: "UsersRound", label: "Meetings" },
        { to: "/meetings/upcoming", icon: "Clock", label: "Upcoming" },
        { to: "/meetings/past", icon: "Archive", label: "Past" },
      ]
    },
    {
      to: "/persons",
      icon: "Users",
      label: "Persons",
      children: [
        { to: "/persons/all", icon: "User", label: "Person List" },
        { to: "/teams", icon: "Users", label: "Team List View" },
        { to: "/persons/import", icon: "Upload", label: "Import" },
        { to: "/network", icon: "Network", label: "Network View" },
      ]
    },
    {
      to: "/integrations/list",
      icon: "Plug",
      label: "Integrations",
      children: [
        { to: "/integrations/list", icon: "Plug", label: "Integration List" },
        { to: "/integrations/projects/import", icon: "Import", label: "Project Import" },
      ]
    },
    { to: "/tags", icon: "Tag", label: "Tags" },
    { to: "/network", icon: "Network", label: "Network" },
  ];
  

  return (
    <div className={`h-screen bg-gray-800 flex flex-col shadow-sm transition-all ${collapsed ? 'w-12' : 'w-48'}`}>
        {/* Header Section */}
        <div className={`flex items-center border-b border-gray-700 ${collapsed ? 'justify-center px-2 py-4' : 'justify-between pt-5 pb-4'}`}>
          {!collapsed && (
            <div className="flex items-center space-x-3">
              <Logo variant="dark" size="lg" className="flex-shrink-0" />
              <h1 className="text-lg font-semibold text-white">Align</h1>
            </div>
          )}
          <button 
            onClick={toggleCollapse} 
            className="text-white hover:bg-gray-700 p-1 rounded-md"
          >
            <Icon name={collapsed ? "ChevronRight" : "ChevronLeft"} size="sm" color="white" />
          </button>
        </div>

        {/* Navigation Section */}
        <nav className="flex-1 px-2 py-2 space-y-1 overflow-y-auto">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              {...item}
              isOpen={openMenu === item.label}
              toggleOpen={toggleOpen}
              isTopLevel={true}
              collapsed={collapsed}
            />
          ))}
        </nav>

        {/* Footer Section */}
        <div className="border-t border-gray-700 flex flex-col items-center space-y-1">
          <UserInfo collapsed={collapsed} />
          <MenuItem
            //to="/profile"
            to="/integrations/list"
            icon="Settings"
            label="Settings"
            isOpen={false}
            toggleOpen={toggleOpen}
            isTopLevel={true}
            collapsed={collapsed}
          />
        </div>
      </div>
  );
};

export default SidebarMenu;
