import React, { FC, useState, useEffect } from 'react';

interface InputTitleProps {
  placeholder?: string;
  value?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void; // Add onBlur prop to trigger blur event
}

export const InputTitle: FC<InputTitleProps> = ({
  placeholder = "Issue title", // Default placeholder
  value = "",
  isDisabled = false,
  onChange,
  onBlur, // Destructure onBlur
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <input
      type="text"
      className={`w-full bg-transparent text-xl font-semibold text-base placeholder-placeholder focus:outline-none focus:ring-0 ${
        isDisabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
      placeholder={placeholder}
      value={inputValue}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={onBlur} // Trigger onBlur when the input loses focus
      autoComplete="off" // Disable autocomplete for this input
    />
  );
};

export default InputTitle;
