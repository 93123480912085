import React, { useEffect, useState } from 'react';
import { ProjectSource, ProjectMapping, ProjectImport, Team } from '../api/integration/types';
import { fetchProjectSources, importProjects, fetchTeams } from '../api/integration/api';
import { useNavigate } from 'react-router-dom';

interface ProjectImportContainerProps {
  integrationId?: string;
  children: (props: {
    projects: ProjectSource[];
    teams: Team[];
    selectedTeam: string | null;
    loading: boolean;
    error: string | null;
    importSelectedProjects: (projects: ProjectImport[]) => Promise<{ message: string; projects: ProjectMapping[] }>;
    refreshProjects: () => Promise<void>;
    setTeamFilter: (teamId: string | null) => void;
  }) => React.ReactElement;
}

const ProjectImportContainer: React.FC<ProjectImportContainerProps> = ({
  integrationId,
  children,
}) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectSource[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadProjects = async () => {
    if (!integrationId) return; // Early return if no ID
    try {
      setLoadingProjects(true);
      setError(null);
      console.log('selectedTeam:', selectedTeam); // Debug log to verify selectedTeam
      const projectSources = await fetchProjectSources(
        integrationId,
        navigate,
        selectedTeam || undefined // Convert null to undefined
      );
      setProjects(projectSources);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load projects');
    } finally {
      setLoadingProjects(false);
    }
  };

  const loadTeams = async () => {
    if (!integrationId) return; // Early return if no ID
    try {
      setLoadingTeams(true);
      setError(null);
      const fetchedTeams = await fetchTeams(integrationId, navigate);
      setTeams(fetchedTeams); // Populate teams
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load teams');
    } finally {
      setLoadingTeams(false);
    }
  };

  const importSelectedProjects = async (projectsToImport: ProjectImport[]) => {
    if (!integrationId) throw new Error('No integration selected'); // Throw if no ID
    try {
      setLoadingProjects(true);
      setError(null);
      const result = await importProjects(integrationId, projectsToImport, navigate);
      if (Array.isArray(result)) {
        return {
          message: 'Successfully imported projects',
          projects: result, // Assuming result is an array of `ProjectMapping`
        };
      }
      return result;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to import projects');
      throw err;
    } finally {
      setLoadingProjects(false);
    }
  };

  const setTeamFilter = (teamId: string | null) => {
    setSelectedTeam(teamId); // Update the selected team
  };

  useEffect(() => {
    if (integrationId) {
      loadTeams(); // Load teams when the integration changes
    }
  }, [integrationId]);

  useEffect(() => {
    if (integrationId) {
      loadProjects(); // Load projects when integration or team filter changes
    }
  }, [integrationId, selectedTeam]);

  return children({
    projects,
    teams,
    selectedTeam,
    loading: loadingTeams || loadingProjects, // Combine both loading states
    error,
    importSelectedProjects,
    refreshProjects: loadProjects,
    setTeamFilter,
  });
};

export default ProjectImportContainer;
