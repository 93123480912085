import React, { useState } from 'react';
import { SelectSingle } from '../../'; // Ensure the correct import path
import { SelectOption } from '../../Select'; // Assuming SelectOption is defined elsewhere

interface TableBadgeComponentProps {
  label: string;
  type: 'state' | 'dial_stage' | 'heatmap_status' | 'importance' | 'projectState' | 'effort' | 'priority' | 'pulse' | 'snapshotStatus' | 'strategyState' | 'goal_state' | 'timeframe';
  onUpdate?: (newValue: string) => void;
  options?: SelectOption[]; // Make sure this is the right path to SelectOption
  isEditable?: boolean;
}

export const TableBadgeComponent: React.FC<TableBadgeComponentProps> = ({
  label,
  type,
  onUpdate,
  options = [],
  isEditable = false
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to find the option value based on label
  const getValueFromLabel = (label: string): string | undefined => {
    const option = options.find((opt) => opt.label === label);
    return option?.value;
  };

  const selectedValue = getValueFromLabel(label) || label;

  // Pastel style mappings
  const stateStyles: Record<string, string> = {
    IDENTIFIED: 'bg-primary bg-opacity-20 text-text',
    IN_PROGRESS: 'bg-secondary bg-opacity-20 text-text',
    COMPLETED: 'bg-neutral bg-opacity-20 text-text',
    ONGOING: 'bg-primary-dark bg-opacity-20 text-text',
    ARCHIVED: 'bg-placeholder bg-opacity-20 text-text',
  };

  const dialStageStyles: Record<string, string> = {
    MENTION: 'bg-accent bg-opacity-20 text-text',
    INVITATION: 'bg-secondary bg-opacity-20 text-text',
    CONVERSATION: 'bg-primary bg-opacity-20 text-text',
    BOUNDARY: 'bg-neutral bg-opacity-20 text-text',
    LIMIT: 'bg-secondary-dark bg-opacity-20 text-text',
  };

  const heatmapStatusStyles: Record<string, string> = {
    GREEN: 'bg-green-500 bg-opacity-20 text-text',
    YELLOW: 'bg-yellow-500 bg-opacity-20 text-text',
    ORANGE: 'bg-orange-500 bg-opacity-20 text-text',
    RED: 'bg-red-500 bg-opacity-20 text-text',
  };

  const importanceStyles: Record<string, string> = {
    'Very Low': 'bg-gray-300 bg-opacity-20 text-text',
    'Low': 'bg-blue-300 bg-opacity-20 text-text',
    'Medium': 'bg-yellow-300 bg-opacity-20 text-text',
    'High': 'bg-orange-300 bg-opacity-20 text-text',
    'Critical': 'bg-red-300 bg-opacity-20 text-text',
  };

  const projectStateStyles: Record<string, string> = {
    CANDIDATE: 'bg-gray-300 bg-opacity-20 text-text',
    BACKLOG: 'bg-blue-300 bg-opacity-20 text-text',
    DEFINITION_AND_ANALYSIS: 'bg-yellow-300 bg-opacity-20 text-text',
    DEVELOPMENT_IN_PROGRESS: 'bg-green-300 bg-opacity-20 text-text',
    TESTING: 'bg-purple-300 bg-opacity-20 text-text',
    ROLLING_OUT: 'bg-indigo-300 bg-opacity-20 text-text',
    DONE: 'bg-gray-500 bg-opacity-20 text-text',
  };

  const effortStyles: Record<string, string> = {
    XS: 'bg-blue-100 bg-opacity-20 text-text',
    S: 'bg-blue-200 bg-opacity-20 text-text',
    M: 'bg-blue-300 bg-opacity-20 text-text',
    L: 'bg-blue-400 bg-opacity-20 text-text',
    XL: 'bg-blue-500 bg-opacity-20 text-text',
  };

  const priorityStyles: Record<string, string> = {
    '1': 'bg-red-500 bg-opacity-20 text-text',
    '2': 'bg-orange-400 bg-opacity-20 text-text',
    '3': 'bg-yellow-300 bg-opacity-20 text-text',
    '4': 'bg-green-300 bg-opacity-20 text-text',
    '5': 'bg-blue-300 bg-opacity-20 text-text',
  };

  const pulseStyles: Record<string, string> = {
    GREEN: 'bg-green-500 bg-opacity-20 text-text',
    YELLOW: 'bg-yellow-500 bg-opacity-20 text-text',
    ORANGE: 'bg-orange-500 bg-opacity-20 text-text',
    RED: 'bg-red-500 bg-opacity-20 text-text',
  };

  // New snapshotStatusStyles
  const snapshotStatusStyles: Record<string, string> = {
    draft: 'bg-gray-400 bg-opacity-20 text-text',
    sent: 'bg-blue-400 bg-opacity-20 text-text',
    archived: 'bg-gray-500 bg-opacity-20 text-text',
  };

  const strategyStateStyles: Record<string, string> = {
    DRAFT: 'bg-gray-300 bg-opacity-20 text-text',
    IN_REVIEW: 'bg-yellow-300 bg-opacity-20 text-text',
    APPROVED: 'bg-green-400 bg-opacity-20 text-text',
  };

  // Add goal state styles with meaningful colors for each state
  const goalStateStyles: Record<string, string> = {
    DRAFT: 'bg-gray-300 bg-opacity-20 text-text',      // Neutral color for draft
    ACTIVE: 'bg-blue-400 bg-opacity-20 text-text',     // Blue for active/in-progress
    ACHIEVED: 'bg-green-500 bg-opacity-20 text-text',  // Green for success
    MISSED: 'bg-red-500 bg-opacity-20 text-text',      // Red for missed
    CANCELED: 'bg-gray-500 bg-opacity-20 text-text',   // Dark gray for canceled
  };

  const timeframeStyles: Record<string, string> = {
    ANNUAL: 'bg-blue-400 bg-opacity-20 text-text',
    QUARTERLY: 'bg-green-400 bg-opacity-20 text-text',
  };

  // Determine styles based on the type
  let styles: Record<string, string>;
  switch (type) {
    case 'state':
      styles = stateStyles;
      break;
    case 'dial_stage':
      styles = dialStageStyles;
      break;
    case 'heatmap_status':
      styles = heatmapStatusStyles;
      break;
    case 'importance':
      styles = importanceStyles;
      break;
    case 'projectState':
      styles = projectStateStyles;
      break;
    case 'effort':
      styles = effortStyles;
      break;
    case 'priority':
      styles = priorityStyles;
      break;
    case 'pulse':
      styles = pulseStyles;
      break;
    case 'snapshotStatus':
      styles = snapshotStatusStyles;
      break;
    case 'strategyState':
      styles = strategyStateStyles;
      break;
    case 'goal_state':
      styles = goalStateStyles;
      break;
    case 'timeframe':
      styles = timeframeStyles;
      break;
    default:
      styles = {};
  }

  const defaultStyle = 'bg-neutral bg-opacity-20 text-text';

  const handleSelect = (value: string) => {
    if (onUpdate) {
      onUpdate(value);
    }
    setIsEditing(false);
    setIsDropdownOpen(false);
  };

  const handleClick = () => {
    if (isEditable) {
      setIsEditing(true);
      setIsDropdownOpen(true);
    }
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    setIsEditing(false);
  };

  return (
    <div className="relative inline-block">
      {isEditable && isEditing && options ? (
        <SelectSingle
          options={options}
          defaultValue={label}
          onSelect={handleSelect}
          size="small"
          isOpen={isDropdownOpen}
          onClose={handleClose}
        />
      ) : (
      <span
        className={`px-2 py-1 rounded-md text-sm ${isEditable ? 'cursor-pointer' : ''} ${
          styles[selectedValue] || defaultStyle
        } flex items-center justify-center text-center max-w-xs leading-tight`}
        onClick={handleClick}
      >
        {label}
      </span>
      )}
    </div>
  );
};
