import React, { useState, useRef } from 'react';
import { Table, TableBadgeComponent, Button } from '../ui';
import { Checkbox } from '../ui/Checkbox';
import { SnapshotProject } from '../../api/project/types';
import { projectStateOptions, pulseOptions, priorityOptions, effortOptions } from '../../utils/entitiesOptions';
import html2canvas from 'html2canvas';
import { Camera } from 'lucide-react';
import { formatDate } from '../../utils/dateUtils';

interface SnapshotProjectsTableProps {
  snapshotProjects: SnapshotProject[];
}

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const SnapshotProjectsTable: React.FC<SnapshotProjectsTableProps> = ({ snapshotProjects }) => {
  const tableRef = useRef<HTMLDivElement>(null);

  // Column definitions
  const allColumns = [
    {
      header: 'Project',
      accessor: 'project.title',
      component: (row: SnapshotProject) => <div className="font-medium">{row.project.title}</div>,
    },
    {
      header: 'Team',
      accessor: 'team.name',
      component: (row: SnapshotProject) => (
        <div>{row.team ? row.team.name : 'N/A'}</div>
      ),
    },
    {
      header: 'Owner',
      accessor: 'owner.name',
      component: (row: SnapshotProject) => (
        <div>{row.owner ? row.owner.name : 'N/A'}</div>
      ),
    },
    {
      header: 'State',
      accessor: 'state',
      component: (row: SnapshotProject) => (
        <TableBadgeComponent
          label={getLabelForValue(row.state, projectStateOptions)}
          type="projectState"
          isEditable={false}
          options={projectStateOptions}
        />
      ),
    },
    {
      header: 'Pulse',
      accessor: 'pulse',
      component: (row: SnapshotProject) => (
        <TableBadgeComponent
          label={getLabelForValue(row.pulse, pulseOptions)}
          type="pulse"
          isEditable={false}
          options={pulseOptions}
        />
      ),
    },
    {
      header: 'Priority',
      accessor: 'priority',
      component: (row: SnapshotProject) => (
        <TableBadgeComponent
          label={getLabelForValue(row.priority.toString(), priorityOptions)}
          type="priority"
          isEditable={false}
          options={priorityOptions}
        />
      ),
    },
    {
      header: 'Effort',
      accessor: 'effort',
      component: (row: SnapshotProject) => (
        <TableBadgeComponent
          label={getLabelForValue(row.effort, effortOptions)}
          type="effort"
          isEditable={false}
          options={effortOptions}
        />
      ),
    },
    {
      header: 'Progress',
      accessor: 'progress',
      component: (row: SnapshotProject) => (
        <div className="flex items-center">
          <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
            <div
              className="bg-primary h-2.5 rounded-full"
              style={{ width: `${row.progress}%` }}
            />
          </div>
          <span className="text-sm text-gray-600">{row.progress}%</span>
        </div>
      ),
    },
    {
      header: 'Start Date',
      accessor: 'start_date',
      component: (row: SnapshotProject) => (
        <div>{row.start_date ? formatDate(row.start_date) : 'N/A'}</div>
      ),
    },
    {
      header: 'Target Date',
      accessor: 'target_date',
      component: (row: SnapshotProject) => (
        <div>{row.target_date ? formatDate(row.target_date) : 'N/A'}</div>
      ),
    },
    {
      header: 'Updates',
      accessor: 'summary_data.total_updates',
      component: (row: SnapshotProject) => (
        <div className="text-right">
          {row.summary_data.total_updates}
        </div>
      ),
    }
  ];

  // State to manage selected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>(allColumns.map(col => col.accessor));

  // Function to toggle column visibility
  const toggleColumnVisibility = (accessor: string, checked: boolean) => {
    setVisibleColumns(prev =>
      checked ? [...prev, accessor] : prev.filter(col => col !== accessor)
    );
  };

  // Filter columns based on user selection
  const columnsToDisplay = allColumns.filter(col => visibleColumns.includes(col.accessor));

  const copyTableAsImage = async () => {
    if (!tableRef.current) return;

    try {
      const canvas = await html2canvas(tableRef.current, {
        backgroundColor: 'white',
        scale: 2,
        logging: false,
      });

      canvas.toBlob((blob) => {
        if (!blob) return;

        const data = [new ClipboardItem({ 'image/png': blob })];
        navigator.clipboard.write(data).then(() => {
          console.log('Table copied to clipboard as image');
        });
      }, 'image/png');
    } catch (err) {
      console.error('Failed to copy table as image:', err);
    }
  };

  return (
    <div className="space-y-4">
      {/* Column Selection Label */}
      <div className="text-lg font-semibold text-gray-700 mb-2">Select Columns to Show</div>

      {/* Column Selection Controls */}
      <div className="flex gap-4">
        {allColumns.map(col => (
          <Checkbox
            key={col.accessor}
            label={col.header}
            checked={visibleColumns.includes(col.accessor)}
            onChange={(checked) => toggleColumnVisibility(col.accessor, checked)}
          />
        ))}
      </div>

      <div className="flex justify-end">
        <Button
          onClick={copyTableAsImage}
          variant="secondary"
          buttonStyle="outlined"
          label="Copy as Image"
          icon="Camera"
          iconColor="secondary"
        />
      </div>

      {/* Table with Conditional Columns */}
      <div ref={tableRef} className="bg-white p-4 rounded-lg overflow-x-auto">
        <Table
          data={snapshotProjects}
          columns={columnsToDisplay}
        />
      </div>
    </div>
  );
};
