import React, { useState, useEffect } from 'react';
import { ModalExtended, SelectSingle } from '../ui';
import MeetingTypeSelect from './MeetingTypeSelect';
import { MeetingType, MeetingOccurrence } from '../../api/meeting/types';

interface MeetingOccurrenceStartModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStartMeeting: (meetingTypeId: string, meetingOccurrenceId?: string) => void;
  futureMeetingOccurrences: MeetingOccurrence[];
}

export const MeetingOccurrenceStartModal: React.FC<MeetingOccurrenceStartModalProps> = ({
  isOpen,
  onClose,
  onStartMeeting,
  futureMeetingOccurrences,
}) => {
  const [selectedMeetingType, setSelectedMeetingType] = useState<MeetingType | null>(null);
  const [selectedMeetingOccurrence, setSelectedMeetingOccurrence] = useState<string | null>(null);
  const [filteredMeetingOccurrences, setFilteredMeetingOccurrences] = useState<MeetingOccurrence[]>([]);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  useEffect(() => {
    if (selectedMeetingType) {
      const filtered = futureMeetingOccurrences.filter(
        occurrence => occurrence.meeting_type === selectedMeetingType.id
      );
      setFilteredMeetingOccurrences(filtered);
    } else {
      setFilteredMeetingOccurrences([]);
    }
  }, [selectedMeetingType, futureMeetingOccurrences]);

  useEffect(() => {
    setIsConfirmDisabled(!(selectedMeetingType && (selectedMeetingOccurrence || selectedMeetingOccurrence === 'blank')));
  }, [selectedMeetingType, selectedMeetingOccurrence]);

  const handleStartMeeting = () => {
    if (selectedMeetingType && !isConfirmDisabled) {
      onStartMeeting(selectedMeetingType.id, selectedMeetingOccurrence === 'blank' ? undefined : selectedMeetingOccurrence || undefined);
      onClose();
    }
  };

  return (
    <ModalExtended
      isOpen={isOpen}
      onClose={onClose}
      title="Start a New Meeting"
      onConfirm={handleStartMeeting}
      confirmLabel="Start Meeting"
      isConfirmDisabled={isConfirmDisabled}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <MeetingTypeSelect
          value={selectedMeetingType?.id || null}
          onChange={setSelectedMeetingType}
          placeholder="Select Meeting Type"
          enableSearch={true}
        />
        <SelectSingle
          options={[
            { label: "Start from scratch", value: "blank" },
            ...filteredMeetingOccurrences.map(occurrence => ({
              label: occurrence.title,
              value: occurrence.id || occurrence.slug || 'undefined'
            }))
          ]}
          placeholder="Select Meeting Occurrence or start from scratch"
          onSelect={(value) => setSelectedMeetingOccurrence(value)}
          enableSearch={true}
          isDisabled={!selectedMeetingType}
        />
      </div>
    </ModalExtended>
  );
};