import React, { useState } from 'react';
import { Icon } from '../'; // Adjust the import path as needed

interface ListMinimalHeaderProps {
  headerText?: string;
  selectPlaceholder?: string;
  onAddItem: (item: { label: string; value: string }) => void;
  isDisabled?: boolean;
  SelectComponent: React.ComponentType<{
    onSelect: (item: { label: string; value: string }) => void;
    isDisabled?: boolean;
    placeholder?: string;
  }>;
}

export const ListMinimalHeader: React.FC<ListMinimalHeaderProps> = ({
  headerText = "Items",
  selectPlaceholder = "Add item",
  onAddItem,
  isDisabled = false,
  SelectComponent,
}) => {
  const [isAddingItem, setIsAddingItem] = useState(false);

  const handleAddItem = (selectedOption: { label: string; value: string }) => {
    onAddItem(selectedOption);
    setIsAddingItem(false);
  };

  return (
    <div className="flex justify-between items-center p-4 border-b border-neutral">
      <h2 className="text-lg font-semibold text-text">{headerText}</h2>
      {isAddingItem ? (
        <SelectComponent
          onSelect={handleAddItem}
          isDisabled={isDisabled}
          placeholder={selectPlaceholder}
        />
      ) : (
        <button
          onClick={() => setIsAddingItem(true)}
          disabled={isDisabled}
          className="p-1 rounded-full bg-primary text-white hover:bg-primary-dark disabled:opacity-50"
        >
          <Icon name="Plus" size="sm" color="white" />
        </button>
      )}
    </div>
  );
};
