import React, { useEffect, useState } from 'react';
import { useTeams, useCreateTeam, useUpdateTeam, useDeleteTeam, useAddTeamMember, useRemoveTeamMember } from '../api/team/queries';
import { Team } from '../api/team/types';
import { useQueryClient } from 'react-query';

interface TeamContainerProps {
  fetchOnMount?: boolean;  // Optional prop to control whether to fetch Teams on mount
  render: (props: {
    data: Team[];
    loading: boolean;
    error: string | null;
    onCreate: (newTeam: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>) => void;
    onEdit: (slug: string, updatedTeam: Partial<Team>) => void;
    onDelete: (slug: string) => void;
    onAddMember: (teamSlug: string, personId: string) => void;
    onRemoveMember: (teamSlug: string, membershipId: string) => void;
  }) => JSX.Element;
}

const TeamContainer: React.FC<TeamContainerProps> = ({ 
  fetchOnMount = true, 
  render 
}) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useTeams();
  const createTeamMutation = useCreateTeam();
  const updateTeamMutation = useUpdateTeam();
  const deleteTeamMutation = useDeleteTeam();
  const addTeamMemberMutation = useAddTeamMember;
  const removeTeamMemberMutation = useRemoveTeamMember;

  useEffect(() => {
    if (fetchOnMount) {
      setLoading(isLoading);

      if (data) setTeams(data);
      if (isError) setError('Failed to fetch Teams');
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create a new team
  const handleCreate = (newTeam: Omit<Team, 'id' | 'createdAt' | 'updatedAt' | 'slug'>) => {
    createTeamMutation.mutate(newTeam, {
      onSuccess: (createdTeam) => {
        setTeams((prevTeams) => [...prevTeams, createdTeam]);
        queryClient.invalidateQueries('teams');
      },
      onError: () => setError('Failed to create Team'),
    });
  };

  // Edit an existing team by slug
  const handleEdit = (slug: string, updatedTeam: Partial<Team>) => {
    updateTeamMutation.mutate(
      { slug, updatedTeam },
      {
        onSuccess: (editedTeam) => {
          setTeams((prevTeams) =>
            prevTeams.map(team => (team.slug === slug ? editedTeam : team))
          );
          queryClient.invalidateQueries('teams');
        },
        onError: () => setError('Failed to edit Team'),
      }
    );
  };

  // Delete a team by slug
  const handleDelete = (slug: string) => {
    deleteTeamMutation.mutate(slug, {
      onSuccess: () => {
        setTeams((prevTeams) => prevTeams.filter(team => team.slug !== slug));
        queryClient.invalidateQueries('teams');
      },
      onError: () => setError('Failed to delete Team'),
    });
  };

  // Add a member to a team by teamSlug
  const handleAddMember = (teamSlug: string, personId: string) => {
    addTeamMemberMutation(teamSlug).mutate(personId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMemberships', teamSlug]);
        queryClient.invalidateQueries(['team', teamSlug]);
      },
      onError: () => setError('Failed to add team member'),
    });
  };

  // Remove a member from a team by teamSlug
  const handleRemoveMember = (teamSlug: string, membershipId: string) => {
    removeTeamMemberMutation(teamSlug).mutate(membershipId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMemberships', teamSlug]);
        queryClient.invalidateQueries(['team', teamSlug]);
      },
      onError: () => setError('Failed to remove team member'),
    });
  };

  return render({
    data: teams,
    loading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onAddMember: handleAddMember,
    onRemoveMember: handleRemoveMember,
  });
};

export default TeamContainer;
