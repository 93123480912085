// components/Pulse/PulsePane.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, Icon, IconName } from '../ui/';
import AlertContainer from '../../containers/AlertContainer';
import { Alert, AlertType } from '../../api/alert/types';

const typeToIcon: Record<AlertType, { name: IconName; className: string }> = {
 'OVERDUE_TASK': { name: 'Clock', className: 'text-red-500' },
 'BLOCKED_TASK': { name: 'AlertTriangle', className: 'text-amber-500' },
 'CRITICAL_TOPIC': { name: 'AlertCircle', className: 'text-red-500' },
 'AT_RISK_PROJECT': { name: 'AlertOctagon', className: 'text-amber-500' },
 'GOAL_PROGRESS': { name: 'Target', className: 'text-amber-500' }
};

const severityToColor: Record<string, string> = {
 'CRITICAL': 'border-l-red-500',
 'HIGH': 'border-l-amber-500',
 'MEDIUM': 'border-l-yellow-500'
};

const typeToPrefix: Record<string, string> = {
  'TASK': '[Task]',
  'TOPIC': '[Topic]',
  'PROJECT': '[Proj]',
  'GOAL': '[Goal]'
};

// Get the entity type from the alert type (e.g., "OVERDUE_TASK" -> "TASK")
const getEntityType = (alertType: AlertType): string => {
 if (alertType.includes('TASK')) return 'TASK';
 if (alertType.includes('TOPIC')) return 'TOPIC';
 if (alertType.includes('PROJECT')) return 'PROJECT';
 if (alertType.includes('GOAL')) return 'GOAL';
 return '';
};

// Map entity types to their routes
const getSlugPath = (entityType: string): string => {
 const paths: Record<string, string> = {
   'TASK': 'tasks',
   'TOPIC': 'topics',
   'PROJECT': 'projects',
   'GOAL': 'goals'
 };
 return paths[entityType] || '';
};

export const PulsePane = () => {
 const navigate = useNavigate();

 const handleAlertClick = (alert: Alert) => {
   const entityType = getEntityType(alert.type as AlertType);
   const path = getSlugPath(entityType);
   if (path && alert.metadata.slug) {
     navigate(`/${path}/${alert.metadata.slug}`);
   }
 };

 return (
   <AlertContainer
     render={({ data: alerts, loading, error }) => (
       <Card className="bg-white w-64 !p-0 !shadow-none !border-0">
        <CardHeader className="!px-4 !py-2">
            <div className="flex items-center mb-2 mt-2">
            <CardTitle className="text-md font-medium">
                <span>Pulse</span>
            </CardTitle>
            </div>
        </CardHeader>
         
         <CardContent className="!px-4 !py-2">
           {loading ? (
             <div className="flex items-center justify-center py-4">
               <Icon name="Loader2" size="sm" className="animate-spin" />
             </div>
           ) : error ? (
             <div className="text-sm text-red-500 text-center py-4">
               {error}
             </div>
           ) : alerts.length === 0 ? (
             <div className="text-sm text-gray-500 text-center py-4">
               No alerts at this time
             </div>
           ) : (
             <div className="space-y-3">
               {alerts.map((alert) => (
                 <div
                   key={alert.id}
                   onClick={() => handleAlertClick(alert)}
                   className={`
                     flex flex-col gap-1 p-2 rounded-md
                     border-l-2 ${severityToColor[alert.severity]}
                     hover:bg-gray-100 transition-all duration-200
                     cursor-pointer
                   `}
                 >
                   <div className="flex items-center gap-2">
                     <Icon
                       name={typeToIcon[alert.type]?.name || 'Bell'}
                       size="sm"
                       className={typeToIcon[alert.type]?.className}
                     />
                     <span className="text-sm font-medium truncate">
                       <span className="text-gray-500">
                         {typeToPrefix[getEntityType(alert.type as AlertType)]}
                       </span>
                       {' '}
                       {alert.title}
                     </span>
                   </div>
                   
                   <div className="text-xs text-gray-500">
                     {alert.message}
                   </div>
                   
                   {alert.metadata.project && (
                     <div className="flex items-center gap-1 text-xs text-gray-400">
                       <Icon name="Folder" size="sm" />
                       <span>{alert.metadata.project}</span>
                     </div>
                   )}
                   
                   {alert.metadata.dial_stage && (
                     <div className="flex items-center gap-1 text-xs text-gray-400">
                       <Icon name="GitCommit" size="sm" />
                       <span>{alert.metadata.dial_stage}</span> - 
                       <span>{alert.metadata.owner}</span>
                     </div>
                   )}
                 </div>
               ))}
             </div>
           )}
         </CardContent>
       </Card>
     )}
   />
 );
};

export default PulsePane;