import React from 'react';
import { MeetingType } from '../../api/meeting/types';
import { Table, TableActionButtons } from '../ui';

interface MeetingTypeTableProps {
  data: MeetingType[];
  onEdit: (meetingType: MeetingType) => void;
  onDelete: (id: string) => void;
}

const MeetingTypeTable: React.FC<MeetingTypeTableProps> = ({ data, onEdit, onDelete }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Name',
          accessor: 'name',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}  // Trigger the onEdit function when clicking the name
              className="cursor-pointer hover:underline"
            >
              {row.name}
            </div>
          ),
        },
        {
          header: 'Recurrence',
          accessor: 'recurrence',
        },
        {
          header: 'Duration',
          accessor: 'duration',
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};

export default MeetingTypeTable;
