import React from 'react';
import { Tabs } from '../ui';
import TaskPanel from '../task/TaskPanel'; // Import TaskPanel for tasks related to MeetingType
import MeetingPastOccurrencesPanel from './MeetingPastOccurrencesPanel'; // Import the Past Occurrences Panel

interface MeetingTypeSidePanelProps {
  meetingTypeId: string;
}

export function MeetingTypeSidePanel({
  meetingTypeId,
}: MeetingTypeSidePanelProps) {
  const tabs = [
    {
      label: 'Tasks',
      content: (
        <TaskPanel
              isOpen={true}
              meetingTypeId={meetingTypeId}
              showAddTaskButton={false}
        />
      ),
    },
    {
      label: 'Past Meetings',
      content: (
        <MeetingPastOccurrencesPanel
          isOpen={true}
          meetingTypeId={meetingTypeId}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-w-md p-4">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default MeetingTypeSidePanel;
