import { Person } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all persons
export const fetchPersons = async (navigate: (path: string) => void): Promise<Person[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}persons/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error('Failed to fetch persons');
  }
  return response.json();
};

// Fetch a single person by slug
export const fetchPerson = async (slug: string, navigate: (path: string) => void): Promise<Person> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}persons/${slug}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch person with slug ${slug}`);
  }
  return response.json();
};

// Fetch a single person by id
export const fetchPersonById = async (id: string, navigate: (path: string) => void): Promise<Person> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}persons/${id}/?company_id=${company_id}`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch person with id ${id}`);
  }
  return response.json();
};

// Create a new person
export const createPerson = async (
  person: Omit<Person, 'id' | 'created_at' | 'updated_at' | 'slug'>, 
  navigate: (path: string) => void
): Promise<Person> => {
  const company_id = getCompanyId();

  const response = await fetchWithAuth(`${API_URL}persons/`, {
    method: 'POST',
    body: JSON.stringify({
      ...person,
      company: company_id,
      tag_ids: person.tags?.map(tag => tag.id), // Send tag IDs instead of full tag objects
    }),
  }, navigate);
  if (!response.ok) {
    throw new Error('Failed to create person');
  }
  return response.json();
};

// Update an existing person using slug
export const updatePerson = async (
  slug: string, 
  person: Partial<Omit<Person, 'created_at' | 'updated_at'>>, 
  navigate: (path: string) => void
): Promise<Person> => {
  const company_id = getCompanyId();

  const response = await fetchWithAuth(`${API_URL}persons/${slug}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...person,
      company: company_id,
      tag_ids: person.tags?.map(tag => tag.id), // Send tag IDs instead of full tag objects
    }),
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to update person with slug ${slug}`);
  }
  return response.json();
};

// Delete a person using slug
export const deletePerson = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}persons/${slug}/?company_id=${company_id}`, {
    method: 'DELETE',
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to delete person with slug ${slug}`);
  }
};

// Validate CSV data before creating persons
export const validateCSVPersons = async (
  csvData: Array<Record<string, any>>,
  navigate: (path: string) => void
): Promise<{
  new_persons: Array<Partial<Person>>;
  duplicates: Array<Partial<Person>>;
  errors: string[];
}> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(
    `${API_URL}persons/validate_csv/`,
    {
      method: 'POST',
      body: JSON.stringify({
        csv_data: csvData
      }),
    },
    navigate
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to validate CSV data');
  }

  return response.json();
};
