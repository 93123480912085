import React from 'react';
import { Tooltip, Icon, IconName } from './'; // Importing your Tooltip component

export interface TimelineEvent {
    title: string;
    description?: string; // Make description optional
    date: string;
    icon?: IconName;  // Changed to IconName type
  }
  
  interface TimelineProps {
    events: TimelineEvent[];
    showElapsed?: boolean;  // Prop to show time elapsed
  }
  
  const getTimeElapsed = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date(); // Use current date if no end date provided
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  };
  
  export const Timeline: React.FC<TimelineProps> = ({ events, showElapsed = false }) => {
    return (
      <div className="relative flex flex-col items-start pl-10 pt-10">
        {events.map((event, index) => (
          <div key={index} className="relative flex items-start mb-12">
            {/* Icon or default circle */}
            <div className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary-dark rounded-full">
              {event.icon ? <Icon name={event.icon} size="sm" color="white" /> : null}
            </div>
  
            {/* Line connecting the events with small gaps at both ends */}
            {index !== events.length - 1 && (
              <div 
                className="absolute left-4 w-0.5 bg-neutral" 
                style={{ 
                  top: 'calc(2rem + 4px)', // 2rem (32px) for circle height + 4px gap
                  height: 'calc(100% + 0.5rem)', // Extend slightly beyond the next circle
                }}
              ></div>
            )}
  
            {/* Event content */}
            <div className="ml-6 max-w-md">
              <h3 className="text-lg font-semibold text-text">{event.title}</h3>
              {event.description && (
                <p className="text-sm text-placeholder">{event.description}</p>
              )}
              {/* Display date with time elapsed tooltip */}
              {showElapsed ? (
                <Tooltip content={`Time elapsed: ${getTimeElapsed(event.date, events[index + 1]?.date)}`}>
                  <div className="text-xs text-placeholder mt-2">
                    {event.date}
                  </div>
                </Tooltip>
              ) : (
                <div className="text-xs text-placeholder mt-2">
                  {event.date}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Timeline;