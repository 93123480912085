import React from 'react';
import * as LucideIcons from 'lucide-react';

// Create a type that includes all icon names from Lucide
export type IconName = keyof typeof LucideIcons;

interface IconProps {
  name: IconName;
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary' | 'accent' | 'text' | 'white'; // Optional color fallback
  className?: string; // Allow full custom className for flexibility (including colors like `text-red-500`)
}

export const Icon: React.FC<IconProps> = ({
  name,
  size = 'sm',
  color,
  className = '', // Default empty className
}) => {
  // Type assertion to ensure TypeScript understands this is a valid Lucide icon
  const IconComponent = LucideIcons[name] as React.FC<React.SVGProps<SVGSVGElement>>;

  // Size classes as per Tailwind with !important
  const sizeClasses = {
    sm: '!w-4 !h-4',
    md: '!w-5 !h-5',
    lg: '!w-8 !h-8',
  };

  // Color classes matching your tailwind.config.js, fallback to `color` if no `className` color is provided
  const colorClasses = color ? {
    primary: 'text-primary',
    secondary: 'text-secondary',
    accent: 'text-accent',
    text: 'text-text',
    white: 'text-white',
  }[color] : ''; // If no color prop is provided, fallback to className

  // Change the order to let className override the default classes
  return (
    <IconComponent
      className={`inline-block flex-shrink-0 ${sizeClasses[size]} ${colorClasses} ${className}`.trim()}
      style={{ width: '', height: '' }}
    />
  );
};
