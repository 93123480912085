import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TopicContainer from '../containers/TopicContainer';
import { TopicTable } from '../components/topic/TopicTable';
import { TopicModal } from '../components/topic/TopicModal';
import { Modal, TableHeader, Snackbar } from '../components/ui';
import { Topic } from '../api/topic/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { FilterConfig } from '../components/ui/data/Filter';
import { Button, SeparatorHorizontal } from '../components/ui';
import { TopicHeatmapView } from '../components/topic/TopicHeatmapView';
import { TopicBoardView } from '../components/topic/TopicBoardView';

type TopicState = 'IDENTIFIED' | 'IN_PROGRESS' | 'COMPLETED' | 'ONGOING' | 'ARCHIVED';

const TopicListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [topicToDelete, setTopicToDelete] = useState<string | null>(null);
  const [topicToEdit, setTopicToEdit] = useState<Topic | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [viewMode, setViewMode] = useState<'table' | 'heatmap' | 'board'>('table');
  const [topics, setTopics] = useState<Topic[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();

  useEffect(() => {
    if (slug && topics.length > 0) {
      const topic = topics.find((topic) => topic.slug === slug);
      if (topic) {
        setTopicToEdit(topic);
        setShowModal(true);
      }
    }
  }, [slug, topics]);

  // Apply path-based filters
  useEffect(() => {
    const path = location.pathname;
    let newFilter = {};
    let mode: 'table' | 'heatmap' | 'board' = 'table';  // Default view mode
  
    // Determine filters and view mode based on the path
    if (path.includes("/topics/backlog")) {
      newFilter = { state: ["IDENTIFIED"] };
      setShowCompleted(false);
    } else if (path.includes("/topics/active")) {
      newFilter = { state: ["IN_PROGRESS", "ONGOING"] };
      setShowCompleted(false);
    } else if (path.includes("/topics/complete")) {
      newFilter = { state: ["COMPLETED"] };
      setShowCompleted(true);
    } else if (path.includes("/topics/all")) {
      newFilter = {}; // Clear filters for "All"
    }
  
    // Set view mode based on specific keywords in path
    if (path.includes("/topics/heatmap")) {
      mode = 'heatmap';
    } else if (path.includes("/topics/board")) {
      mode = 'board';
    }
  
    // Apply filter and view mode updates
    setFilters(newFilter);
    setViewMode(mode);
  }, [location.pathname]);  

  // Show heatmap/board or list view based on URL
  useEffect(() => {
    const path = location.pathname;
    if (path === "/topics/heatmap") {
      setViewMode('heatmap');
    } else if (path === "/topics/board") {
      setViewMode('board');
    } else {
      setViewMode('table');
    }
  }, [location.pathname]);

  const toggleViewMode = (mode: 'table' | 'heatmap' | 'board') => {
    setViewMode(mode);
    navigate(`/topics/${mode === 'table' ? '' : mode}`);
  };

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Topic title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: TopicState,
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Topic state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Topic tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePersons = (
    slug: string,
    newPersons: Person[],
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { persons: newPersons });
    setSnackbarMessage('Topic persons updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setTopicToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (topicToDelete) {
      onDelete(topicToDelete);
      setShowDeleteDialog(false);
      setTopicToDelete(null);
      setSnackbarMessage('Topic deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleAddNew = () => {
    setTopicToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default to modal if none is present
    }
  
    navigate(`/topics/new?${searchParams.toString()}`);
  };

  const handleEditTopic = (topic: Topic) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/topics/${topic.slug}?${searchParams.toString()}`);
    setTopicToEdit(topic);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTopicToEdit(null);
  
    // Navigate to the appropriate view based on viewMode
    switch (viewMode) {
      case 'heatmap':
        navigate('/topics/heatmap');
        break;
      case 'board':
        navigate('/topics/board');
        break;
      default:
        navigate('/topics');  // Default to the list/table view
        break;
    }
  };
  

  const handleSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('success');
    setShowSnackbar(true);
    setShowModal(false);
    navigate('/topics/');
  };

  const handleError = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('error');
    setShowSnackbar(true);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const handleShowCompletedToggle = () => {
    setShowCompleted(!showCompleted);
  };
  
  const handleShowArchivedToggle = () => {
    setShowArchived(!showArchived);
  };

  const sortOptions = [
    { label: 'Title', value: 'title' },
    { label: 'Importance', value: 'importance' },
    { label: 'State', value: 'state' },
    { label: 'Heatmap Status', value: 'heatmap_status' },
    { label: 'Created At', value: 'created_at' },
  ];

  // Filter configuration
  const filterConfig: FilterConfig = useMemo(() => {
    const allTags = Array.from(new Set(topics.flatMap(topic => topic.tags.map(tag => tag.name))));
    const allPersons = Array.from(new Set(topics.flatMap(topic => topic.persons.map(person => person.name))));

    return {
      state: {
        label: 'State',
        value: 'state',
        type: 'select',
        options: [
          { label: 'Identified', value: 'IDENTIFIED' },
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Completed', value: 'COMPLETED' },
          { label: 'Ongoing', value: 'ONGOING' },
          { label: 'Archived', value: 'ARCHIVED' },
        ],
      },
      importance: {
        label: 'Importance',
        value: 'importance',
        type: 'select',
        options: [
          { label: 'Very Low', value: '1' },
          { label: 'Low', value: '2' },
          { label: 'Medium', value: '3' },
          { label: 'High', value: '4' },
          { label: 'Critical', value: '5' },
        ],
      },
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
      persons: {
        label: 'Persons',
        value: 'persons',
        type: 'multi-select',
        options: allPersons.map(person => ({ label: person, value: person })),
      },
      heatmap_status: {
        label: 'HeatMap Status',
        value: 'heatmap_status',
        type: 'select',
        options: [
          { label: 'Green', value: 'GREEN' },
          { label: 'Yellow', value: 'YELLOW' },
          { label: 'Orange', value: 'ORANGE' },
          { label: 'Red', value: 'RED' },
        ],
      },
    };
  }, [topics]);

  const filterAndSortTopics = (topics: Topic[]): Topic[] => {
    return topics
      .filter(topic => 
        topic.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (showArchived || topic.state !== 'ARCHIVED') &&
        (showCompleted || topic.state !== 'COMPLETED') &&
        Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          switch (key) {
            case 'state':
              return Array.isArray(value) ? value.includes(topic.state) : topic.state === value;
            case 'importance':
              return topic.importance?.toString() === value;
            case 'tags':
              if (Array.isArray(value) && value.length > 0) {
                return value.some(tag => topic.tags.some(topicTag => topicTag.name === tag));
              }
              return true;
            case 'persons':
              if (Array.isArray(value) && value.length > 0) {
                return value.some(person => topic.persons.some(topicPerson => topicPerson.name === person));
              }
              return true;
            case 'heatmap_status':
              return topic.heatmap_status === value;
            default:
              return true;
          }
        })
      )
      .sort((a, b) => {
        let result = 0;
        switch (sortBy) {
          case 'title':
            result = a.title.localeCompare(b.title);
            break;
          case 'importance':
            result = (a.importance || 0) - (b.importance || 0);
            break;
          case 'state':
            result = a.state.localeCompare(b.state);
            break;
          case 'created_at':
            result = new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            break;
          default:
            break;
        }
        return sortDirection === 'asc' ? result : -result;
      });
  };

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => toggleViewMode('table')}
        variant={viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Grid"
        onClick={() => toggleViewMode('heatmap')}
        variant={viewMode === 'heatmap' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Heatmap View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => toggleViewMode('board')}
        variant={viewMode === 'board' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Board View"
        iconColor="white"
      />
    </>
  );
  

  return (
    <div>
      <h1 className="m-4">{viewMode === 'heatmap' ? "Topics - Heatmap" : viewMode === 'board' ? "Topics - Board" : "Topics"}</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={setSearchTerm}
        onAddNew={handleAddNew}
        onShowArchive={handleShowArchivedToggle}  // Use dedicated toggle function
        onShowComplete={handleShowCompletedToggle}  // Existing toggle for Show Completed
        showArchived={showArchived}
        showCompleted={showCompleted}
        searchPlaceholder="Search Topics..."
        addButtonLabel="Add New Topic"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        filterConfig={filterConfig}
        onFilterChange={setFilters}
        additionalButtons={additionalButtons}
      />

      <TopicContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading topics...</p>
            </div>
          );
                    if (error) return <div>{error}</div>;

          if (data) {
            setTopics(data);
          }

          const filteredAndSortedTopics = filterAndSortTopics(data);

          return (
            <>
              {showModal && (
                <TopicModal
                  isOpen={showModal}
                  onClose={handleCloseModal}
                  onSuccess={handleSuccess}
                  onError={handleError}
                  initialData={topicToEdit ? { ...topicToEdit } : undefined}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this topic?</p>
              </Modal>

              {viewMode === 'heatmap' ? (
                <TopicHeatmapView
                  topics={filteredAndSortedTopics}
                  onOpenTopic={(topic) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setTopicToEdit(topic);
                    setShowModal(true);
                    navigate(`/topics/${topic.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(slug, updatedTopic) => onEdit(slug, updatedTopic)}
                />
              ) : viewMode === 'board' ? (
                <TopicBoardView
                  onOpenTopic={(topic) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setTopicToEdit(topic);
                    setShowModal(true);
                    navigate(`/topics/${topic.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(slug, updatedTopic) => onEdit(slug, updatedTopic)}
                />
              ) : (
                <TopicTable
                  data={filteredAndSortedTopics}
                  key={filteredAndSortedTopics.map(t => t.slug).join()}
                  onEdit={handleEditTopic}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdateState={(slug, newState) => handleUpdateState(slug, newState as TopicState, onEdit)}
                  onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                  onUpdatePersons={(slug, newPersons) => handleUpdatePersons(slug, newPersons, onEdit)}
                  onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default TopicListPage;
