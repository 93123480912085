import React from 'react';
import { Tag } from '../../api/tag/types';
import { Table, TableDateComponent, TableActionButtons, TableInlineTextInput, Avatar, ColorPalette, Icon } from '../ui';

interface TagsTableProps {
  data: Tag[];  // The tags data
  onEdit: (tag: Tag) => void;  // Callback for editing the tag
  onDelete: (id: string) => void;  // Callback for deleting
  onUpdateName: (id: string, newName: string) => void;  // Callback for updating the name
  onUpdateColor: (id: string, newColor: string) => void;  // Callback for updating the color
}

export const TagsTable: React.FC<TagsTableProps> = ({ data, onEdit, onDelete, onUpdateName, onUpdateColor }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Name',
          accessor: 'name',
          component: (row) => (
            <div className="flex items-center space-x-2">
              <ColorPalette
                currentColor={row.color}
                onChange={(newColor) => onUpdateColor(row.id, newColor)}
              />
              <div
                className="cursor-pointer hover:underline"
                onClick={() => onEdit(row)}
              >
                {row.name}
              </div>
            </div>
          ),
        },
        {
          header: 'Created By',
          component: (row) =>
            row.created_by ? (
              <div className="flex items-center space-x-2">
                <Avatar
                  name={row.created_by.name}
                  avatarUrl={row.created_by.avatar_url}
                  size="sm"
                />
              </div>
            ) : (
              <span>N/A</span>
            ),
        },
        {
          header: 'Created At',
          component: (row) => (
            <TableDateComponent dateString={row.created_at} showTime={false} />
          ),
        },
        {
          header: 'Actions',
          component: (row) => (
            <div className="flex items-center gap-2">
              <TableActionButtons
                onDelete={() => onDelete(row.id)}
              />
              <button
                onClick={() => window.location.href = `/tags/${row.id}/network`}
                className="p-2 text-primary hover:text-primary-dark rounded-full hover:bg-gray-100"
                title="View Tag Network"
              >
                <Icon name="Share2" size="sm" className="text-primary" />
              </button>
            </div>
          ),
        },
      ]}
    />
  );
};
