import React from 'react';

interface TableCheckboxComponentProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
}

export const TableCheckboxComponent: React.FC<TableCheckboxComponentProps> = ({
  isChecked,
  onChange,
  label = "Select",
  disabled = false
}) => {
  return (
    <label 
      className={`flex items-center gap-2 text-sm cursor-pointer ${disabled ? 'text-placeholder cursor-not-allowed' : 'text-text'}`}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        className={`w-4 h-4 rounded border-neutral text-primary focus:ring focus:ring-primary-dark ${disabled ? 'opacity-50' : ''}`}
      />
      {label && <span>{label}</span>}
    </label>
  );
};
