import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ModalContent, SelectSingle, Datepicker } from '../ui';
import { Project } from '../../api/project/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import { Team } from '../../api/team/types';
import TeamSelect from '../team/TeamSelect';
import PersonSelectMulti from '../person/PersonSelectMulti';
import PersonSelect from '../person/PersonSelect';
import TagSelect from '../tags/TagSelect';
import TaskPanel from '../task/TaskPanel';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import { projectStateOptions, priorityOptions, heatmapStatusOptions, effortOptions } from '../../utils/entitiesOptions';
import { Icon } from '../ui/base/Icon';
import FavoriteContainer from '../../containers/FavoriteContainer';

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (projectData: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>, slug?: string) => void;
  onEdit: (slug: string, updatedProject: Partial<Project>) => void;
  onDelete?: (slug: string) => void;
  initialData?: Omit<Project, 'created_at' | 'updated_at'>;
  isFullscreen?: boolean;
}

export const ProjectModal: React.FC<ProjectModalProps> = ({
  isOpen,
  onClose,
  onSave,
  onEdit,
  onDelete,
  initialData,
  isFullscreen = false,
}) => {
  const [projectData, setProjectData] = useState<Partial<Project>>({
    title: '',
    description: '',
    state: 'CANDIDATE',
    effort: 'M',
    priority: 3,
    pulse: 'GREEN',
    owner: null,
    persons: [],
    tags: [],
    company: '',
    topics: [],
    dependencies: [],
    progress: 0,
    start_date: null,
    target_date: null,
    team: null,
    visibility: 'private',
    editable_visibility: false,
  });
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const isInitialMount = useRef(true);
  const initialTitle = useRef('');
  const initialDescription = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedProject: Partial<Project>) => void) | null>(null);
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('sidePeek');

  useEffect(() => {
    console.log('initialData', initialData);
  
    if (initialData) {
      setProjectData({
        ...initialData,
        start_date: initialData.start_date || null,
        target_date: initialData.target_date || null,
        team: initialData.team,
        visibility: initialData.visibility || 'private',
        editable_visibility: initialData.editable_visibility || false,
      });
      initialTitle.current = initialData.title || '';
      initialDescription.current = initialData.description || '';
    } else {
      // If no initialData, reset to default state
      setProjectData({
        title: '',
        description: '',
        state: 'CANDIDATE',
        effort: 'M',
        priority: 3,
        pulse: 'GREEN',
        owner: null,
        persons: [],
        tags: [],
        company: '',
        topics: [],
        dependencies: [],
        progress: 0,
        start_date: null,
        target_date: null,
        team: null,
        visibility: 'private',
        editable_visibility: false,
      });
      initialTitle.current = '';
      initialDescription.current = '';
    }
  
    setIsSidePanelOpen(false); // Reset side panel state
  }, [initialData, isOpen]);
  
  

  // Save on refresh/unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (initialData?.slug && editFunctionRef.current && projectData.description !== initialDescription.current) {
        editFunctionRef.current(initialData.slug, { description: projectData.description });
        initialDescription.current = projectData.description || '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [initialData?.slug, projectData.description]);

  // 30m autosave
  useEffect(() => {
    if (!initialData?.slug || !editFunctionRef.current || projectData.description === initialDescription.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && projectData.description !== initialDescription.current) {
        editFunctionRef.current(initialData.slug, { description: projectData.description });
        initialDescription.current = projectData.description || '';
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(autoSaveTimer);
  }, [projectData.description, initialData?.slug]);

  const handleChange = useCallback(
    (field: keyof Project, value: any) => {
      setProjectData((prevData) => ({ ...prevData, [field]: value }));

      if (initialData?.slug && field !== 'title' && field !== 'description') {
        onEdit(initialData.slug, { [field]: value });
      }
    },
    [initialData, onEdit]
  );

  const handleDelete = useCallback(() => {
    if (initialData?.slug && onDelete) {
      onDelete(initialData.slug);
      onClose();
    }
  }, [initialData, onDelete, onClose]);

  const handleClose = useCallback(() => {
    if (initialData?.slug && projectData.description !== initialDescription.current) {
      onEdit(initialData.slug, { description: projectData.description });
      initialDescription.current = projectData.description || '';
    }
    onClose();
  }, [initialData, projectData.description, onEdit, onClose]);

  const handleVisibilityToggle = useCallback(
    (isPublic: boolean) => {
      handleChange('visibility', isPublic ? 'public' : 'private');
    },
    [handleChange]
  );

  const handleTitleBlur = useCallback(() => {
    if (initialData?.slug && projectData.title !== initialTitle.current) {
      onEdit(initialData.slug, { title: projectData.title });
      initialTitle.current = projectData.title || '';
    }
  }, [initialData, projectData.title, onEdit]);

  const handleDescriptionChange = useCallback((newDescription: string) => {
    setProjectData(prevData => ({
      ...prevData,
      description: newDescription
    }));
  }, []);

  const handleSave = useCallback(() => {
    if (!projectData.title) {
      return alert('Please provide a title');
    }
    if (!projectData.owner) {
      return alert('Please select an owner');
    }

    onSave(projectData as Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>, initialData?.slug);
    initialDescription.current = projectData.description || '';
    onClose();
  }, [projectData, initialData, onSave, onClose]);

  const toggleSidePanel = useCallback(() => {
    setIsSidePanelOpen((prev) => !prev);
  }, []);

  const getStateLabels = (states: string[], options: { value: string; label: string }[]) => {
    return states.map(state => {
      const option = options.find(opt => opt.value === state);
      return option ? option.label : state;
    });
  };

  const lifecycleStates = ['CANDIDATE', 'BACKLOG', 'DEFINITION_AND_ANALYSIS', 'DEVELOPMENT_IN_PROGRESS', 'TESTING', 'ROLLING_OUT', 'DONE'];
  const friendlyLifecycleStates = getStateLabels(lifecycleStates, projectStateOptions);
  const reversedPriorityOptions = useMemo(() => [...priorityOptions].reverse(), []);

  if (!isOpen) return null;

  editFunctionRef.current = onEdit;

  return (
    <FavoriteContainer
      type="project.project"
      render={({ onToggle, isFavorited }) => (
        <ModalContent
          title={projectData.title || ''}
          description={projectData.description || ''}
          onCancel={handleClose}
          onCreate={handleSave}
          onDelete={initialData ? handleDelete : undefined}
          onTitleChange={(newTitle) =>
            setProjectData((prevData) => ({ ...prevData, title: newTitle }))
          }
          onDescriptionChange={handleDescriptionChange}
          onTitleBlur={handleTitleBlur}
          actionButtonLabel={initialData ? 'Save Changes' : 'Create Project'}
          isSidePanelInitiallyCollapsed={true}
          showSidePanel={!!initialData}
          showFooterButtons={true}
          sidePanelContent={
            initialData && (
              <TaskPanel
                isOpen={isSidePanelOpen}
                onClose={toggleSidePanel}
                projectId={initialData.id}
                title="Related Tasks"
              />
            )
          }
          selects={[
            {
              component: SelectSingle,
              props: {
                options: reversedPriorityOptions,
                placeholder: 'Select Priority',
                defaultIcon: <Icon name="Signal" size="sm" />,
                defaultValue: String(projectData.priority ?? ''),
                onSelect: (value: string) =>
                  handleChange('priority', parseInt(value)),
              },
            },
            {
              component: SelectSingle,
              props: {
                options: projectStateOptions,
                placeholder: 'Select State',
                defaultIcon: <Icon name="Flag" size="sm" />,
                defaultValue: projectData.state,
                onSelect: (value: string) =>
                  handleChange('state', value as Project['state']),
              },
            },
            {
              component: SelectSingle,
              props: {
                options: effortOptions,
                placeholder: 'Select Effort',
                defaultIcon: <Icon name="Activity" size="sm" />,
                defaultValue: projectData.effort,
                onSelect: (value: string) =>
                  handleChange('effort', value as Project['effort']),
              },
            },
            {
              component: SelectSingle,
              props: {
                options: heatmapStatusOptions,
                placeholder: 'Select Pulse',
                defaultIcon: <Icon name="AlertCircle" size="sm" />,
                defaultValue: projectData.pulse,
                onSelect: (value: string) =>
                  handleChange('pulse', value as Project['pulse']),
              },
            },
            {
              component: PersonSelect,
              props: {
                value: projectData.owner?.id || '',
                onChange: (person: Person) => handleChange('owner', person),
                enableSearch: true,
                placeholder: 'Select an owner',
                showAssignButton: true,
              },
            },{
              component: TeamSelect,
              props: {
                value: projectData.team?.id || '',
                onChange: (selectedTeam: Team) => handleChange('team', selectedTeam),
                placeholder: 'Select a team',
                enableSearch: true,
                isDisabled: false,
                size: 'medium',
              },
            },
            {
              component: Datepicker,
              props: {
                value: projectData.start_date || '',
                onChange: (date: string) => handleChange('start_date', date),
                placeholder: 'Start Date',
              },
            },
            {
              component: Datepicker,
              props: {
                value: projectData.target_date || '',
                onChange: (date: string) => handleChange('target_date', date),
                placeholder: 'Target Date',
              },
            },
            {
              component: PersonSelectMulti,
              props: {
                value: projectData.persons?.map((person) => person.id) || [],
                onChange: (newPersons: Person[]) =>
                  handleChange('persons', newPersons),
                enableSearch: true,
              },
            },
            {
              component: TagSelect,
              props: {
                value: projectData.tags?.map((tag) => tag.id) || [],
                onChange: (newTags: Tag[]) => handleChange('tags', newTags),
                enableSearch: true,
              },
            },
          ]}
          activityFeed={
            initialData ? (
              <LogEntryActivityFeed objectId={initialData.id} objectType="project" />
            ) : null
          }
          showActivityFeed={!!initialData}
          onVisibilityToggle={handleVisibilityToggle}
          initialVisibility={projectData.visibility}
          isVisibilityEditable={projectData.editable_visibility}
          lifecycleStates={friendlyLifecycleStates}
          currentState={getStateLabels([projectData?.state || ''], projectStateOptions)[0]}
          showLifecycle={true}
          showFavorite={!!initialData}
          isFavorited={!!initialData && isFavorited(initialData.id)}
          onFavoriteToggle={() => initialData?.id && onToggle('project.project', initialData.id)}
          viewMode='sidePeek'
          onViewModeChange={(mode) => setModalViewMode(mode)}
      />
    )}
  />
);
};