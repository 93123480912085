import React from 'react';
import { useGoals, useCreateGoal, useEditGoal, useDeleteGoal } from '../api/goal/queries';
import { Goal } from '../api/goal/types';
import { useQueryClient } from 'react-query';
import { ALERTS_QUERY_KEY } from '../api/alert/queries';

interface GoalContainerProps {
 personId?: string;
 teamId?: string; 
 render: (props: {
   data: Goal[];
   loading: boolean;
   error: string | null;
   onCreate: (newGoal: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
   onEdit: (slug: string, updatedGoal: Partial<Goal>) => void;
   onDelete: (slug: string) => void;
 }) => JSX.Element;
}

const GoalContainer: React.FC<GoalContainerProps> = ({ personId, teamId, render }) => {
 const params = { personId, teamId };
 const queryClient = useQueryClient();
 
 const { data: goals = [], isLoading, error } = useGoals(params);
 const createGoalMutation = useCreateGoal(params);
 const editGoalMutation = useEditGoal(params);
 const deleteGoalMutation = useDeleteGoal(params);

 const handleCreate = (newGoal: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
   createGoalMutation.mutate(newGoal, {
     onSuccess: () => {
       queryClient.invalidateQueries(['goals', params]);
     }
   });
 };

 const handleEdit = (slug: string, updatedGoal: Partial<Goal>) => {
   editGoalMutation.mutate({
     slug,
     updatedGoal: {
       ...updatedGoal,
       tag_ids: updatedGoal.tags?.map(t => t.id),
       participant_ids: updatedGoal.participants?.map(p => p.id),
       project_ids: updatedGoal.projects?.map(p => p.id),
       topic_ids: updatedGoal.topics?.map(t => t.id),
       team_id: updatedGoal.team?.id,
       owner_id: updatedGoal.owner?.id
     }
   }, {
     onSuccess: () => {
       queryClient.invalidateQueries(['goals', params]);
       queryClient.invalidateQueries(ALERTS_QUERY_KEY);
     }
   });
 };

 const handleDelete = (slug: string) => {
   deleteGoalMutation.mutate(slug, {
     onSuccess: () => {
       queryClient.invalidateQueries(['goals', params]);
       queryClient.invalidateQueries(ALERTS_QUERY_KEY);
     }
   });
 };

 return render({
   data: goals,
   loading: isLoading,
   error: error ? (error as Error).message : null,
   onCreate: handleCreate,
   onEdit: handleEdit,
   onDelete: handleDelete,
 });
};

export default GoalContainer;